import axios from 'axios';

const baseURL = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_BASE_URL_DEV
  : process.env.REACT_APP_BASE_URL_PROD;

const axiosInstance = axios.create({ baseURL });

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  }, 
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  }, 
  async (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error during request setup:', error.message);
    }
    return Promise.reject(error);
  }
);

// This function will be used to update the token on the axios instance
function updateToken() {
    const token = localStorage.getItem('token');
  if (token) {
    axiosInstance.defaults.headers.common = {'Authorization': `Bearer ${token}`}
  } else {
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
 
}

function isTokenValid() {
  const token = localStorage.getItem('token');
  return token ? true : false;
}
export { axiosInstance, updateToken, isTokenValid };

