import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import {getCandidateDocumentById, getPanelDocumentById} from "../../service/api";
import {useEffect, useState} from "react";
const CandidateModal = ({ open, candidate, handleClose }) => {
    console.log("candidate"+candidate.resume);
    const [resume, setResume] = useState(null);

    useEffect(() => {
        if (candidate && candidate.candidateResumeId) {
            console.log(candidate.candidateResumeId);
            getCandidateDocumentById(candidate.candidateResumeId)
                .then(resumeData => {
                    const blob = new Blob([resumeData], { type: 'application/pdf' });
                    setResume(URL.createObjectURL(blob));
                })
                .catch(err => console.error(err));
        }
    }, [candidate]);
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md" // Set maximum width
            PaperProps={{
                style: {
                    position: 'fixed',
                    bottom: 16, // Distance from bottom
                    right: 16, // Distance from right
                    width: 600, // Width of the modal
                    maxHeight: 1200, // Max height of the modal
                },
            }}

        >
            <DialogTitle>{candidate ? `${candidate.candidateName}'s Resume` : 'Candidate Resume'}</DialogTitle>
            <DialogContent
            style={{
                border:"none", // Define the border style
            }}
            >
                {candidate && (
                    <>
                        {/* ...existing candidate information... */}
                        {resume && <iframe
                            src={`${resume}#toolbar=0`}
                            width="100%"
                            height="600px"
                            style={{ border: 'none' }}
                        ></iframe>}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CandidateModal;
