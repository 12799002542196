import React, {useEffect, useState} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  deleteJob, getAdminDashboardMetrics,
  getAssignedUsers,
  getCountries,
  getCustomerDashboardMetrics,
  getJobs,
  getSkillFamilies,
  updateJob
} from "../../service/api";
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import FilterModal from "./FilterModel";
import "./Job.css";
import "../../CSS/dashboard.css";
import {toast} from "react-toastify";
import Papa from 'papaparse';
import JobDetailsDialog from "../../helpers/JobDetailsDialog";

function filterDataByDateRange(data, startDate, endDate) {
  return data.filter((item) => {
    const itemDate = new Date(item.createdAt).toISOString().split('T')[0];
    return itemDate >= startDate && itemDate <= endDate;
  });
}

export default function Jobs() {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dashboardFilter = queryParams.get('dashboardFilter');
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');

  const { filterOptions } = useParams();
  const [searchStatus, setSearchStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [jobsData, setJobsData] = useState([]);
  const [skillFamilyData, setSkillFamiliesData] = useState([]);
  // const [skillFamilyDict, setSkillFamiliesDict] = useState({});
  const [countries, setCountries] = useState([]);
  const [countriesDict, setCountriesDict] = useState({});

  const [filteredJobsData, setFilteredJobs] = useState([]);
  const [dataLoading, setDataLoading] = useState();
  const navigate = useNavigate();
  const [filters, setFilters] = useState({});
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [metrcisData, setMetricsData] = useState({
    jobsMetrics: [],
  });
  const [activeCard, setActiveCard] = useState('');
  const handleOpenFilterModal = () => {
    setFilterModalOpen(true);
  };

  const handleCloseFilterModal = () => {
    setFilterModalOpen(false);
  };

  const handleApplyFilters = (newFilters) => {
    if (['all', 'CREATED'].includes(newFilters.status) ) {
      newFilters.status = '';
    }

    if (['UNASSIGNED'].includes(newFilters.status)) {
      delete newFilters.status;
      newFilters.assigneeName = 'UNASSIGNED';
    }

    const updatedFilters = Object.fromEntries(Object.entries(newFilters).filter(([key, value]) => value !== ""));
    setFilters(updatedFilters);
  };

  const populateJobs = () => {
    setDataLoading(true);
    getSkillFamilies().then((response) => {
      setSkillFamiliesData(response.data);
      getAssignedUsers().then((res) => {
        const userDict = res?.data.reduce((acc, { userId, firstName, lastName }) => {
          acc[userId] = `${firstName} ${lastName}`;
          return acc;
        }, {});

        getJobs().then((response) => {
          let jobs = response.data;
          for (let i=0; i< jobs.length; i++) {
            jobs[i]['assigneeFullName'] = userDict[jobs[i]['assigneeId']]
          }
          if (startDate !== '' && endDate !== '') {
            jobs = filterDataByDateRange(jobs, startDate, endDate);
          }
          setJobsData(jobs.reverse());
        });
      });
      setDataLoading(false);
    });

    getCountries().then((res) => {
      setCountries(res.data);
      const dict = res?.data.reduce((acc, obj) => {
        acc[obj.countryCode] = obj.countryName;
        return acc;
      }, {});
      setCountriesDict(dict);
    });
  }

  const filterBasedOnCards = (status) => {
    // Filter the candidate data based on the selected status
    const filteredData = jobsData.filter(candidate => candidate.status === status);
    // setSearchStatus(status);
    setActiveCard(status);
    // Update the state with the filtered data
    setFilteredJobs(filteredData);
  };
  const getAllMetrics = () => {
    if(user["profileType"] === "ADMIN") {
      getAdminDashboardMetrics(filters).then((response) => {
        setMetricsData(response.data)
        console.log(response.data)
      }).catch((err) => {
        console.error(err);
      });
    }else {
      getCustomerDashboardMetrics(filters).then((response) => {
        setMetricsData(response.data)
        console.log(response.data)
      }).catch((err) => {
        console.error(err);
      });
    }
  }
  useEffect(() => {
    if (filterOptions) {
      let [status, startDate, endDate] = filterOptions.split('|')
      console.log(status, startDate, endDate)
      setSearchStatus(status);
      setStartDate(startDate || '');
      setEndDate(endDate || '');
    }
    populateJobs();
    handleApplyFilters({'status': searchStatus})
  }, [searchStatus]);

  useEffect(() => {
    const filterKeys = Object.keys(filters);
    if (filterKeys.length > 0) {
      const filteredData = jobsData.filter(job =>
        filterKeys.every(key => {
          if (filters[key] === 'UNASSIGNED') {
            return job['assigneeEmailId'] === '';
          } else {
            return String(job[key]).toLowerCase().includes(String(filters[key]).toLowerCase())
          }
        })
      );
      setFilteredJobs(filteredData);
    } else {
      setFilteredJobs(jobsData);
      console.log('filteredJobsData:', jobsData)
    }
  }, [filters, jobsData]);
  useEffect(() => {
    getAllMetrics();
    console.log('dashboardFilter:', dashboardFilter);
    if (dashboardFilter) {
      const filteredData = jobsData.filter(candidate => candidate.status === dashboardFilter.toUpperCase());
      console.log('filteredData:', filteredData)
      setActiveCard(dashboardFilter.toUpperCase());
      setFilteredJobs(filteredData);
    } else {
      // If dashboardFilter is empty, reset filteredSchedules to all schedulesData
      setFilteredJobs(jobsData);
    }
  }, [dashboardFilter, jobsData]);
  const addNewJob = () => {
    navigate(`/user/addJob`);
  };
  const jobsColumns = [
    {
      dataField: 'jobId',
      text: 'Job ID',
      sort: true,
    },
    {
      dataField: 'jobName',
      text: 'Job Name',
      sort: true,
      style: {
        maxWidth: '180px',
        overflow: 'hidden',
      },
      formatter: (cell, row) => (
          <button
              style={{ background: 'none', border: 'none', padding: 0, color: '#3E67B1'}}
              onClick={() => {
                setSelectedJob(row);
                setDialogOpen(true);
              }}
          >
            {cell}
          </button>
      )
    },
    {
      dataField: 'skillFamily',
      text: 'Skill Family',
      sort: true,
    },
    {
      dataField: 'customerCompany',
      text: 'Customer Name',
      sort: true,
      // hidden: (!["ADMIN","SUPER"].includes(user.profileType))
    },
    {
      dataField: 'deliveryUnit',
      text: 'Delivery Unit',
      sort: true,
      hidden: (!["ADMIN","SUPER"].includes(user.profileType))
    },
    // {
    //   dataField: 'jobCreatedBy.emailId',
    //   text: 'Created By',
    //   sort: true,
    // },
    {
      dataField: 'primarySkills',
      text: 'Primary Skills',
      sort: true,
      style: {
        maxWidth: '200px',
        overflow: 'hidden',
      }
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
    },
    {
      dataField: 'assigneeName',
      text: 'Assignee',
      formatter: (cell, row) => (
        <>
          <button
            className="btn btn-primary"
            style={{ display: (["ADMIN","SUPER"].includes(user.profileType) && row.assigneeId == null) ? 'inline': 'None'}}
            onClick={() => {
              updateJob(row._id, {
                assigneeId: user.userId,
                assigneeEmailId: user.emailId,
                assigneeName: user.userName
              }).then((res) => {
                populateJobs();
                toast.success(`Job assigned to ${user.emailId}`);
              }).catch((error) => {
                toast.error(error.response.data.message);
              })
            }}
          > Assign
          </button>
          <span
            style={{ display: row.assigneeId > 0 ? 'inline': 'None'}}
          > {row.assigneeFullName}
          </span>

        </>
      ),
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: (cell, row) => (
        <>
          <i
              title="Edit Job"
            onClick={() => {
              if ((hasAllPermissions || userPermissions.includes('EDIT_JOB'))) {
                navigate(`/user/editJob/${row._id}/${row.customerId}`);
              }
            }}
            style={{
              opacity: !(hasAllPermissions || userPermissions.includes('EDIT_JOB')) ? 0.2 : 1,
              pointerEvents: !(hasAllPermissions || userPermissions.includes('EDIT_JOB')) ? 'none' : 'auto',
            }}
            className="fa fa-light fa-pen-to-square"
          ></i>
          <i
              title="View Job"
            onClick={() => {
              if ((hasAllPermissions || userPermissions.includes('VIEW_JOB'))) {
                navigate(`/user/viewJob/${row._id}/${row.customerId}`);
              }
            }}
            style={{
              paddingLeft: "10px",
              cursor: "pointer",
              opacity: !(hasAllPermissions || userPermissions.includes('VIEW_JOB')) ? 0.2 : 1,
              pointerEvents: !(hasAllPermissions || userPermissions.includes('VIEW_JOB')) ? 'none' : 'auto',
            }}
            className="fa fa-light fa-eye"
          ></i>
          <i
            title="Delete Job"
            onClick={ async () => {
              if ((hasAllPermissions || userPermissions.includes('DELETE_JOB'))) {
                const isConfirmed = window.confirm('Are you sure you want to delete this job?');
                if (isConfirmed) {
                  try {
                    await deleteJob(row._id);
                    populateJobs();
                    toast.success('Job deleted Successfully.!');
                  } catch (err) {
                    console.error(err);
                    toast.error('Unable to delete Job.');
                  }
                }
              }
            }}
            style={{
              color: '#3E67B1',
              display: ['SUPER', 'ADMIN'].includes(user.profileType) ? 'inline' : 'none',
              paddingLeft: "10px",
              opacity: !(hasAllPermissions || userPermissions.includes('DELETE_JOB')) ? 0.2 : 1,
              pointerEvents: !(hasAllPermissions || userPermissions.includes('DELETE_JOB')) ? 'none' : 'auto',
            }}
            className="fa fa-light fa-trash-can"
          ></i>
          <i
            title ="add Candidate to Job"
            onClick={() => {
              navigate(`/user/addCandidate`, { state: { job: row } });
            }}
            style={{
              color: '#3E67B1',
              paddingLeft: "10px",
              display: (hasAllPermissions || userPermissions.includes('CREATE_JOB')) ? 'inline' : 'none',
            }}
            className="fa fa-light fa-user-plus"
          ></i>
        </>
      ),
    },
  ];
  const options = {
    sizePerPage: 10,
    sizePerPageList: [
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '50', value: 50 },
    ],
    hideSizePerPage: false,
    hidePageListOnlyOnePage: false,
    alwaysShowAllBtns: true,
    showTotal: true,
    nextPageText: "Next",
    prePageText: "Prev",
  };

  const filterDict = {
    'jobName': 'Job Name',
     'customerName': 'Customer Name',
    'skillFamily': 'Skill Family',
    'primarySkills': 'Primary Skills',
    'assigneeName': 'Assignee',
    'status': 'Status',
    'deliveryUnit': 'Delivery Unit'
  }

  const rateCategoryDict = {
    ratesInIndia: 'Rates In India',
    ratesInUS: 'Rates In US',
    ratesInternal: 'Rates Internal',
    ratesExternal: 'Rates External',
  }

  const rateCardDict = {
    highDemand: 'High Demand',
    niche: 'Niche',
    legacy: 'Legacy',
    leadership: 'Leadership',
    others: 'Others',
  }

  const today = new Date().toISOString().slice(0, 10);

  const downloadJobs = (filteredData) => {
    let modifiedData = [];
    if(['ADMIN', 'SUPER'].includes(user.profileType)) {
      modifiedData = filteredData.map(job => ({
        'Customer ID': job.customerId,
        'Customer Name': job.customerCompany,
        'Customer Email ID': job.customerEmailId,
        'Job ID': job.jobId,
        'Job Name': job.jobName,
        'Job Description': job.jobDescription,
        'Key Responsibilities': job.keyResponsibilities,
        'Skill Family': job.skillFamily,
        'Primary Skills': job.primarySkills,
        'Secondary Skills': job.secondarySkills,
        'Required Experience': job.requiredExperience,
        'Interview Duration': job.interviewDuration,
        'Sourcing': job.sourcing,
        'Delivery Unit': job.deliveryUnit,
        'Vertical': job.vertical,
        'Line Of Business': job.lineOfBusiness,
        'Account Name': job.accountName,
        // 'Customer Admin Name': job.custCoordinatorName,
        // 'Customer Admin Email ID': job.custCoordinatorEmailId,
        'Assignee Name': job.assigneeFullName,
        'Assignee Email ID': job.assigneeEmailId,
        'Rate Category': rateCategoryDict[job.rateCategory],
        'Rate Card': rateCardDict[job.rateCard],
        'Is Bonus Applicable': job.isBonusApplicable ? 'Yes' : 'No',
        'Country': countriesDict[job.country],
        'Status': job.status,
        'Remarks': job.remarks
      }));
    } else {
      modifiedData = filteredData.map(job => ({
        'Customer ID': job.customerId,
        'Customer Name': job.customerCompany,
        'Customer Email ID': job.customerEmailId,

        'Job ID': job.jobId,
        'Job Name': job.jobName,
        'Job Description': job.jobDescription,
        'Key Responsibilities': job.keyResponsibilities,
        'Skill Family': job.skillFamily,
        'Primary Skills': job.primarySkills,
        'Secondary Skills': job.secondarySkills,
        'Required Experience': job.requiredExperience,
        'Interview Duration': job.interviewDuration,
        'Sourcing': job.sourcing,

        'Delivery Unit': job.deliveryUnit,
        'Vertical': job.vertical,
        'Line Of Business': job.lineOfBusiness,
        'Account Name': job.accountName,

        // 'Customer Admin Name': job.custCoordinatorName,
        // 'Customer Admin Email ID': job.custCoordinatorEmailId,

        'Assignee Name': job.assigneeFullName,
        'Assignee Email ID': job.assigneeEmailId,
        'Country': countriesDict[job.country],
        'Status': job.status,
        'Remarks': job.remarks
      }));
    }

    const csv = Papa.unparse(modifiedData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Jobs.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  if (hasAllPermissions || userPermissions.includes('LIST_JOBS')) {

    return (
        <div className="container-page">
          <div style={{marginBottom: '20px'}}>
            <div className="row">
              <div className="col-3">
                <label><h6>Start Date</h6></label>
                <input
                    type="date"
                    id="startDate"
                    value={startDate}
                    max={today}
                    className="form-control"
                    onChange={(e) => setStartDate(e.target.value)}
                    style={{
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: "1px solid #C7C7C7",
                      borderRadius: "6px",
                      opacity: "1",
                    }}
                    placeholder="Start Date"
                />
              </div>
              <div className="col-3">
                <label><h6>End Date</h6></label>
                <input
                    type="date"
                    id="endDate"
                    value={endDate}
                    // min={startDate}
                    max={today}
                    className="form-control"
                    onChange={(e) => setEndDate(e.target.value)}
                    style={{
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: "1px solid #C7C7C7",
                      borderRadius: "6px",
                      opacity: "1",
                    }}
                    placeholder="End Date"
                />
              </div>
              <div className="col-2">
                <button
                    className="btn btn-primary"
                    onClick={() => {
                      console.log(startDate, endDate)
                      if (startDate !== '' && endDate !== '') {
                        const filteredData = filterDataByDateRange(filteredJobsData, startDate, endDate)
                        setJobsData(filteredData.reverse())
                        downloadJobs(filteredData.reverse())
                      } else {
                        setJobsData(filteredJobsData.reverse())
                        downloadJobs(filteredJobsData.reverse())
                      }
                    }}
                    style={{
                      height: "50px",
                      width: "150px",
                      background: "#3E67B1 0% 0% no-repeat padding-box",
                      borderRadius: "4px",
                      opacity: "1",
                      marginTop: "30px"
                    }}
                >
                  Download
                </button>
              </div>
              <div className="col" style={{
                textAlign: "right",
                alignContent: "end",
              }}>
                <button
                    className="filters"
                    variant="contained"
                    color="primary"
                    onClick={handleOpenFilterModal}
                >
                  &nbsp;
                  <span className="filter-icon">
                      <img src="/Images/Filter.svg"/>
                    </span>
                  &nbsp;&nbsp;
                  <span className="filter-text">Filters</span>
                </button>
                &nbsp;&nbsp;
                <button
                    style={{
                      display: (hasAllPermissions || userPermissions.includes('CREATE_JOB')) ? 'inline' : 'none',
                      textAlign: 'center', // Aligns text center within the button
                      margin: 'auto', // Centers the button horizontally
                      marginTop: '10px',
                    }}
                    onClick={addNewJob}
                    className="create-candidate">
                  Create JD
                </button>
              </div>
            </div>
          </div>

          <div className="row shadow-radius"
               style={{
                 backgroundColor: "#FFFFFF",
                 padding: "20px 20px 0px 20px",
                 marginBottom: "20px",
                 marginRight: "0px",
                 marginLeft: "0px",
               }}
          >
            {metrcisData.jobsMetrics.map((item, index) => (
                // if item.name ==="Feedback Pending orFeedback Provided" make class name col-2
                <div
                    className={`table-card-filters ${item.name === 'Feedback Pending' || item.name === 'Feedback Provided' ? 'col-2' : 'col-1'} ${activeCard === item.status ? 'btn btn-primary' : ''}`}
                    style={{
                      display: item.name === 'Un Assigned' || item.name === 'Un Claimed' ? 'none' : 'inherit',
                      border: activeCard === item.status ? 'none' : 'inherit'
                    }}
                    onClick={() => filterBasedOnCards(item.status)}
                    key={index}>
                  <div>{item.name}</div>
                  <div
                      style={{
                        color: item.status === activeCard ? '#ffffff' : 'inherit'
                      }}
                  >{item.value}</div>
                </div>
            ))}
          </div>
          <div
              className="main-cxontent shadow-radius"
              style={{marginBottom:"20px"}}
          >
            <div className="applied-filters" style={{display: Object.keys(filters).length > 0 ? 'block' : 'none'}}>
              <div className="applied-filters-text">
                <h4>Applied Filters ({Object.keys(filters).length})</h4>
              </div>
              <br></br>
              <div className="applied-filters-list ">
                {Object.keys(filters).map((d, i) => {
                  return (filters[d] !== '') ? <div>
                  <span className="applied-filter">
                    <label key={i}>{filterDict[d]}:- {filters[d]} &nbsp;{"  "}</label>
                    <i
                        className="fa fa-thin fa-circle-xmark"
                        style={{color: "#3c77dd"}}
                        onClick={() => {
                          console.log('removeFilter called with:', d, filters);
                          const newFilters = {...filters};
                          delete newFilters[d];
                          console.log("updatedFilters::", newFilters)
                          setFilters(newFilters);
                          handleApplyFilters(newFilters);
                        }}
                    ></i>
                  </span>
                  </div> : <></>
                })}
              </div>
              <br/>
            </div>
            <div className="custom-table-container">
              <BootstrapTable
                  hover
                  bootstrap4
                  keyField="jobId"
                  data={filteredJobsData}
                  columns={jobsColumns}
                  pagination={paginationFactory(options)}
                  noDataIndication={
                    dataLoading ?
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          width: '100%'
                        }}>
                          <CircularProgress/>
                        </Box>
                        :
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          width: '100%'
                        }}>
                          <span>No Data Found</span>
                        </Box>
                  }
              />
              {
                filterModalOpen ? <FilterModal
                    filters={filters}
                    open={filterModalOpen}
                    handleClose={handleCloseFilterModal}
                    handleApply={handleApplyFilters}
                /> : <></>
              }
              {
                <JobDetailsDialog
                    job={selectedJob}
                    open={dialogOpen}
                    handleClose={() => setDialogOpen(false)}
                />
              }
            </div>
          </div>
        </div>
    );

  } else {
    return (
        <>
          <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
            <div className="card">
              <div className="card-body text-center">
                <h2 className="card-title">
                  <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                  Access Denied
                </h2>
                <p className="card-text">You do not have the required permissions to access this page.</p>
                <p className="card-text">Please contact your administrator for assistance.</p>
              </div>
            </div>
          </div>
        </>
    )
  }
}
