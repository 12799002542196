import React from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const JobDetailsDialog = ({ job, open, handleClose }) => {
    console.log(job);
    return (
    <Dialog open={open} onClose={handleClose}
        aria-labelledby="form-dialog-title"
            maxWidth="md" // Set maximum width
            PaperProps={{
                style: {
                    position: 'fixed',
                    bottom: 16, // Distance from bottom
                    right: 16, // Distance from right
                    width: 600, // Width of the modal
                    maxHeight: 1200, // Max height of the modal
                },
            }}
    >
        <DialogTitle>{"Job Details"}</DialogTitle>
        <DialogContent>
            <Typography variant="body1"><strong>Job ID:</strong> {job?.jobId}</Typography>
            <Typography variant="body1"><strong>Job Name:</strong> {job?.jobName}</Typography>
            <Typography variant="body1"><strong>Primary Skills:</strong> {job?.primarySkills}</Typography>
            <Typography variant="body1"><strong>Skill Family:</strong> {job?.skillFamily}</Typography>
            <Typography variant="body1"><strong>Job Description:</strong> {job?.jobDescription}</Typography>
            <Typography variant="body1"><strong>Status:</strong> {job?.status}</Typography>
            <Typography variant="body1"><strong>Created Date:</strong> {job?.createdDate}</Typography>
            <Typography variant="body1"><strong>Key Responsibilities:</strong> {job?.keyResponsibilities}</Typography>
            <Typography variant="body1"><strong>Positions Required:</strong> {job?.positionsRequired}</Typography>
            <Typography variant="body1"><strong>Assignee Full Name:</strong> {job?.assigneeFullName}</Typography>
            <Typography variant="body1"><strong>Assignee Email ID:</strong> {job?.assigneeEmailId}</Typography>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                Close
            </Button>
        </DialogActions>
    </Dialog>
    );
};

export default JobDetailsDialog;
