import React, { useRef, useState, useEffect } from "react";
import { useParams, useLocation,useNavigate  } from "react-router-dom";
import { getProfileTypes, 
  getRoles,getAssignedUsers,
  getCustomerRequest, updateCustomerRequest, getCustomerCompanies } from "../../service/api";
import { Grid, TextField, Checkbox } from "@mui/material";
import { toast } from "react-toastify";
import { 
    Box, 
    Select,
    MenuItem
} from '@mui/material';

const EditCustomerTicket = ({navigation}) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');
  
  const { cusReqId } = useParams();
  const formRef = useRef(null);
  const [cusReqData, setCusReqData] = useState({});
  // const [profileTypes, setProfileTypes] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);

  const navigate = useNavigate();

  const fetchUsers = async () => {
    try {
      const res = await getAssignedUsers();
      setUsers(res.data);
    } catch (error) {
      toast.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
    // getProfileTypes().then((res) => {
    //     setProfileTypes(res.data);
    // });
    getRoles().then((res) => {
      setRoles(res.data);
    });
    getCustomerRequest(cusReqId).then((res) => {
      setCusReqData(res.data);
    });
    getCustomerCompanies().then((res) => {
      const customers = (res.data).map( c => {
        return {'customerId': c.userId, 'customerCompany': c.companyName}
      });
      setCustomers(customers)
    });
  }, [cusReqId]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    navigate(-1);
  };

  if (hasAllPermissions || userPermissions.includes('EDIT_CUSTOMER_TICKET')) {

    return (
      <>
      <Box sx={{ display: "flex", flexDirection: 'column' }}>
        <div>
            <Box
                component="main"
                style={{ backgroundColor: "#F4F6FB" }}
                sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
              >
              <form ref={formRef} onSubmit={handleFormSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <label htmlFor="profileType">Profile Type</label>
                      <select
                        type="text"
                        disabled="true"
                        className="form-control"
                        value={cusReqData.profileType}
                        name="profileType"
                        onChange={(event) =>
                          setCusReqData((prevUserData) => ({
                            ...prevUserData,
                            profileType: event.target.value,
                          }))
                        }
                        style={{
                          height: "50px",
                          background: "#FFFFFF 0% 0% no-repeat padding-box",
                          border: "1px solid #C7C7C7",
                          borderRadius: "6px",
                          opacity: "1",
                        }}
                      >
                        <option value="">Select Profile Type</option>
                        <option value='CUSTOMER_ADMIN'>CUSTOMER_ADMIN</option>
                        <option value='VENDOR'>VENDOR</option>

                      </select>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label htmlFor="roleId">Role</label>
                      <select
                        type="text"
                        className="form-control"
                        placeholder="Enter Role"
                        name="roleId"
                        value={cusReqData.roleId}
                        onChange={(event) =>
                          setCusReqData((prevUserData) => ({
                            ...prevUserData,
                            roleId: event.target.value,
                          }))
                        }
                        style={{
                          height: "50px",
                          background: "#FFFFFF 0% 0% no-repeat padding-box",
                          border: "1px solid #C7C7C7",
                          borderRadius: "6px",
                          opacity: "1",
                        }}
                      >
                        <option value="">Select Role Type</option>
                        {roles.map((role) => {
                          return (
                            <option key={role.roleId} value={role.roleId}>
                              {role.roleName}
                            </option>
                          );
                        })}
                      </select>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label htmlFor="userId">Customer Company</label>
                      <select
                        type="text"
                        disabled={true}
                        className="form-control"
                        placeholder="Enter Role"
                        name="customerId"
                        value={cusReqData.customerId}
                        style={{
                          height: "50px",
                          background: "#FFFFFF 0% 0% no-repeat padding-box",
                          border: "1px solid #C7C7C7",
                          borderRadius: "6px",
                          opacity: "1",
                        }}
                      >
                        <option value="">Select Customer Company</option>
                        {customers.map((customer) => {
                          return (
                            <option key={customer.customerId} value={customer.customerId}>
                              {customer.customerCompany}
                            </option>
                          );
                        })}
                      </select>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Name"
                        name="name"
                        defaultValue={cusReqData.name}
                        style={{
                          height: "50px",
                          background: "#FFFFFF 0% 0% no-repeat padding-box",
                          border: "1px solid #C7C7C7",
                          borderRadius: "6px",
                          opacity: "1",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label htmlFor="emailId">Email ID</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Email ID"
                        name="emailId"
                        value={cusReqData.emailId}
                        // disabled={true}
                        onChange={(event) =>
                          setCusReqData((prevUserData) => ({
                            ...prevUserData,
                            emailId: event.target.value,
                          }))
                        }
                        style={{
                          height: "50px",
                          background: "#FFFFFF 0% 0% no-repeat padding-box",
                          border: "1px solid #C7C7C7",
                          borderRadius: "6px",
                          opacity: "1",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label htmlFor="primaryMobile">Phone Number</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Phone Number"
                        name="primaryMobile"
                        defaultValue={cusReqData.primaryMobile}
                        style={{
                          height: "50px",
                          background: "#FFFFFF 0% 0% no-repeat padding-box",
                          border: "1px solid #C7C7C7",
                          borderRadius: "6px",
                          opacity: "1",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label htmlFor="profileType">Status</label>
                      <select
                        type="text"
                        className="form-control"
                        value={cusReqData.status}
                        name="status"
                        onChange={(event) =>
                          setCusReqData((prevUserData) => ({
                            ...prevUserData,
                            status: event.target.value,
                          }))
                        }
                        style={{
                          height: "50px",
                          background: "#FFFFFF 0% 0% no-repeat padding-box",
                          border: "1px solid #C7C7C7",
                          borderRadius: "6px",
                          opacity: "1",
                        }}
                      >
                        <option value="">Select Status</option>
                        <option value='CREATED'>CREATED</option>
                        <option value='IN PROGRESS'>IN PROGRESS</option>
                        <option value='APPROVED'>APPROVED</option>
                        <option value='CLOSED'>CLOSED</option>
                      </select>
                    </Grid>
                    <br></br>
                    <Grid item xs={12} sm={4}>
                      <label>
                        <h6>Assignee</h6>
                      </label>
                      <select
                        type="text"
                        className="form-control"
                        name="assigneeId"
                        value={cusReqData.assigneeId + ""}
                        onChange={(event) =>
                          setCusReqData((prevUserData) => ({
                            ...prevUserData,
                            assigneeId: event.target.value,
                          }))
                        }
                        style={{
                          height: "50px",
                          background: "#FFFFFF 0% 0% no-repeat padding-box",
                          border: "1px solid #C7C7C7",
                          borderRadius: "6px",
                          opacity: "1",
                        }}
                      >
                        <option value="">Select Assignee</option>
                        {users.map((user) => {
                          return (
                            <option key={user.userId} value={user.userId}>
                              {user.firstName} {user.lastName}
                            </option>
                          );
                        })}
                      </select>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label>
                        <h6>Assignee Email ID</h6>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Phone Number"
                        name="primaryMobile"
                        value={users.filter((d) => d.userId == cusReqData.assigneeId)[0]?.emailId}
                        disabled={true}
                        style={{
                          height: "50px",
                          background: "#FFFFFF 0% 0% no-repeat padding-box",
                          border: "1px solid #C7C7C7",
                          borderRadius: "6px",
                          opacity: "1",
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}
                      style={{
                        display: cusReqData.profileType === 'VENDOR' ? 'inline': 'none'
                      }}
                    >
                      <label htmlFor="vendorCompanyName">Vendor Company</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Vendor Company Name"
                        name="vendorCompanyName"
                        defaultValue={cusReqData.vendorCompanyName}
                        style={{
                          height: "50px",
                          background: "#FFFFFF 0% 0% no-repeat padding-box",
                          border: "1px solid #C7C7C7",
                          borderRadius: "6px",
                          opacity: "1",
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <label htmlFor="remarks">Remarks</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Remarks"
                        name="remarks"
                        defaultValue={cusReqData?.remarks || ''}
                        multiline
                        rows={2}
                        style={{
                          height: "50px",
                          background: "#FFFFFF 0% 0% no-repeat padding-box",
                          border: "1px solid #C7C7C7",
                          borderRadius: "6px",
                          opacity: "1",
                        }}
                      />
                    </Grid>
                  </Grid>
                <br />

              <div className="row justify-content-center">
                <button
                  className="btn btn-outline-primary me-2"
                  style={{
                    border: "1px solid #3E67B1",
                    borderRadius: " 4px",
                    opacity: "1",
                    width: "96px",
                    height: "50px",
                  }}
                  onClick={() => {
                    navigate('/admin/customerTickets')
                  }}
                >
                  BACK
                </button>
                <button
                  onClick={() => {
                    const fd= new FormData(formRef.current);
                    const formDataJson ={}
                    Array.from(fd.entries()).reduce((a,b)=>{
                      a[b[0]] = b[1];
                      return a
                    }, formDataJson)
                    let updatedObj = {...cusReqData, ...formDataJson}
                    console.log(updatedObj)
                    updatedObj['assigneeName'] = users.filter((d) => d.userId == updatedObj.assigneeId)[0]?.emailId;
                    updateCustomerRequest(cusReqId, updatedObj).then((res) => {
                      console.log(res.data) 
                      if (res.data?.error){
                        toast.error(res.data?.error);
                      }
                      if (res.data?.success && !res.data?.error) {
                        toast.success('Updated the Customer Request.!');
                        navigate('/admin/customerTickets');
                      }
                    }).catch(err=>{
                      toast.error('Unable to update Customer Request, Please try again.!');
                    });
                  }}
                  type="button"
                  className="btn btn-primary"
                  style={{
                    border: "1px solid #3E67B1",
                    borderRadius: " 4px",
                    opacity: "1",
                    width: "150px",
                    height: "50px",
                    display: (hasAllPermissions || userPermissions.includes('EDIT_CUSTOMER_TICKET')) ? 'inline' : 'none'
                  }}
                >
                  UPDATE REQUEST
                </button>
              </div>
              </form>
            </Box>
        </div>

      </Box>
      <br></br>
      <br></br>
      
    </>
    );
  
  } else {
    return (
      <>
        <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
          <div className="card">
            <div className="card-body text-center">
              <h2 className="card-title">
                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                Access Denied
              </h2>
              <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }

};

export default EditCustomerTicket;
        