import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";


import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import {Grid, TextField, InputAdornment, Card, CardContent,} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import "../../CSS/dashboard.css";

import {
    getApprovedCandidates, getPanelClaimedCandidates,
    getPanelSchedules, getPanelDashboardMetrics
} from "../../service/api";
import CircularProgress from '@mui/material/CircularProgress';
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import DashboardCard from "../../helpers/DashboardCard";

export default function PanelDashboard() {
    const user = JSON.parse(localStorage.getItem('user'));
    const userPermissions = user.permissions.map(e => e.permissionName);
    const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');

    const [candidatesData, setCandidatesData] = useState([]);
    const [filteredCandidatesData, setFilteredCandidatesData] = useState([]);
    const [candidateSearch, setCandidateSearch] = useState("");

    const [claimedCandidatesData, setClaimedCandidatesData] = useState([]);
    const [filteredClaimedCandidatesData, setFilteredClaimedCandidatesData] = useState([]);
    const [claimedCandidateSearch, setClaimedCandidateSearch] = useState("");

    const [panelSchedulesData, setPanelSchedulesData] = useState([]);
    const [filteredSchedulesData, setFilteredSchedulesData] = useState([]);
    const [scheduleSearch, setScheduleSearch] = useState("");

    // const [popupData, setPopupData] = useState([
    //   { ssd: "10-03-2023", sst: "09:00 - 10:00" },
    //   { ssd: "10-03-2023", sst: "09:00 - 10:00" },
    // ]);

    const [panelMetrics, setPanelMetrics] = useState({
        interviewsMetrics: [
            {name: "Scheduled", value: "87690123"},
            {name: "Rescheduled", value: "87690123"},
            {name: "Pending", value: "87690123"},
            {name: "Completed", value: "87690123"},
            {name: "Revenue", value: "87690123"},
        ]
    });

    const [filters, setFilters] = useState({
        startDate: '',
        endDate: '',
    });
    // const today = new Date().toISOString().slice(0, 10);
    const today = new Date();
    today.setDate(today.getDate() + 10);
    const futureDate = today.toISOString().slice(0, 10);
    const getAllMetrics = () => {
        getPanelDashboardMetrics(user.emailId, filters).then((response) => {
            setPanelMetrics(response.data)
        }).catch((err) => {
            console.error(err);
        });
    }

    useEffect(() => {
        getAllMetrics();
    }, []);

    const [dataLoading, setDataLoading] = useState();
    const navigate = useNavigate();

    const [openScheduleInterview, setOpenScheduleInterview] = useState(false);
    const [openClaimInterview, setOpenClaimInterview] = useState(false);
    const [openClaimedCandidate, setOpenClaimedCandidate] = useState(false);


    const populateApprovedCandidates = () => {
        setDataLoading(true);
        getApprovedCandidates().then((response) => {
            setCandidatesData(response.data);
            setFilteredCandidatesData(response.data)
            setDataLoading(false);
        }).catch((err) => {
            console.error(err);
        });
    }

    const populatePanelCandidates = () => {
        setDataLoading(true);
        getPanelClaimedCandidates().then((response) => {
            setClaimedCandidatesData(response.data);
            setFilteredClaimedCandidatesData(response.data)
            setDataLoading(false);
        }).catch((err) => {
            console.error(err);
        });
    }

    const populateSchedules = () => {
        setDataLoading(true);
        getPanelSchedules().then((response) => {
            // let cancelled = response?.data.filter(e => e.status === 'CANCELED');
            setPanelSchedulesData(response.data);
            setFilteredSchedulesData(response.data);
            setDataLoading(false);
        }).catch((err) => {
            console.error(err);
        });
    }

    useEffect(() => {
        populateApprovedCandidates();
        populatePanelCandidates();
        populateSchedules();
    }, []);

    useEffect(() => {
        setFilteredCandidatesData(
            candidatesData.filter((candidate) =>
                Object.values(candidate).some(
                    (value) =>
                        value.toString().toLowerCase().includes(candidateSearch.toLowerCase())
                )
            )
        );
    }, [candidateSearch, candidatesData]);

    useEffect(() => {
        setFilteredClaimedCandidatesData(
            claimedCandidatesData.filter((candidate) =>
                Object.values(candidate).some(
                    (value) =>
                        value.toString().toLowerCase().includes(claimedCandidateSearch.toLowerCase())
                )
            )
        );
    }, [claimedCandidateSearch, claimedCandidatesData]);
    useEffect(() => {
        setFilteredSchedulesData(
            panelSchedulesData.filter((schedule) =>
                Object.values(schedule).some(
                    (value) =>
                        value.toString().toLowerCase().includes(scheduleSearch.toLowerCase())
                )
            )
        );
    }, [scheduleSearch, panelSchedulesData]);
    const ClaimInterviewAccordionProps = {
        sx: {
            pointerEvents: "none",
        },
        expandIcon: (
            <ExpandMoreIcon
                onClick={() => setOpenClaimInterview(!openClaimInterview)}
                sx={{
                    pointerEvents: "auto",
                }}
            />
        ),
    };
    const ClaimedCandidatesAccordionProps = {
        sx: {
            pointerEvents: "none",
        },
        expandIcon: (
            <ExpandMoreIcon
                onClick={() => setOpenClaimedCandidate(!openClaimInterview)}
                sx={{
                    pointerEvents: "auto",
                }}
            />
        ),
    };
    const ScheduleInterviewAccordionProps = {
        sx: {
            pointerEvents: "none",
        },
        expandIcon: (
            <ExpandMoreIcon
                onClick={() => setOpenScheduleInterview(!openScheduleInterview)}
                sx={{
                    pointerEvents: "auto",
                }}
            />
        ),
    };
     const candidateColumns = [
        // {
        //     dataField: 'candidateId',
        //     text: 'Candidate ID',
        //     sort: true,
        //     formatter: (cell, row) => (
        //         <>
        //             <span> {row.candidateId} </span>
        //         </>
        //     )
        // },
        {
            dataField: 'jobName',
            text: 'Job Name',
            sort: true,
            formatter: (cell, row) => (
                <>
                    <span>{row.jobName} </span>
                </>
            )
        },
        {
            dataField: 'candidateName',
            text: 'Candidate Name',
            sort: true,
            formatter: (cell, row) => (
                <>
                    <span> {row.candidateName} </span>
                </>
            )
        },
        // {
        //   dataField: 'jobId',
        //   text: 'Job ID',
        //   sort: true,
        //   formatter: (cell, row) => (
        //     <>
        //       <span>{row.jobId} </span>
        //     </>
        //   )
        // },

        {
            dataField: 'assigneeEmailId',
            text: 'Coordinator Email ID',
            sort: true,
        },
        // {
        //     dataField: 'skillFamily',
        //     text: 'Skill Family',
        //     sort: true,
        // },
        {
            dataField: 'primarySkills',
            text: 'Primary Skills',
            sort: true,
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
        },
        {
            dataField: "action",
            text: "Actions",
            formatter: (cell, row) => (
                <>
                    {/* <i
            className="fa fa-solid fa-calendar fa-lg"
            style={{ color: "#3E67B1" }}
            data-bs-toggle="modal"
            data-bs-target="#exampleModal-1"
          ></i> */}
                    &nbsp;
                    <img
                        src="/../Images/view.svg"
                        onClick={() => {
                            navigate(`/panel/viewCandidateForPanel/${row._id}/${row.customerId}`);
                        }}
                        style={{
                            paddingLeft: "10px",
                            cursor: "pointer"
                        }}
                    />
                    {/* &nbsp; &nbsp; */}

                    {/* <i
            className="fa fa-light fa-eye"
            onClick={() => {
              navigate(`/panel/viewCandidateForPanel/${row._id}/${row.customerId}`);
            }}
            style={{
              paddingLeft: "10px",
              cursor: "pointer"
            }}
          ></i> */}
                </>
            ),
            style: () => {
                return {
                    font: "Muli",
                    minWidth: "120px",
                    letterSpacing: "0.28px",
                    color: "#3A3A3A",
                    opacity: "2",
                };
            },
        },
    ];

    const claimedCandidateColumns = [
        {
            dataField: 'jobName',
            text: 'Job Name',
            sort: true,
            formatter: (cell, row) => (
                <>
                    <span>{row.jobName} </span>
                </>
            )
        },
        {
            dataField: 'candidateName',
            text: 'Candidate Name',
            sort: true,
            formatter: (cell, row) => (
                <>
                    <span> {row.candidateName} </span>
                </>
            )
        },
        {
            dataField: 'candidateEmailId',
            text: 'Candidate Email ID',
            sort: true,
            formatter: (cell, row) => (
                <>
                    <span> {row.candidateEmailId} </span>
                </>
            )
        },

        {
            dataField: 'primarySkills',
            text: 'Primary Skills',
            sort: true,
        },
        {
            dataField: 'slotDate',
            text: 'Selected Slot',
            formatter: (cell, row) => (
                <>
                    <span>{row[`slotDate${row.slotNumber}`]}, {row[`slotTime${row.slotNumber}`]}</span>
                </>
            ),
            sort: true,
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
        },
        {
            dataField: "action",
            text: "Actions",
            formatter: (cell, row) => (
                <>
                    <img
                        src="/../Images/view.svg"
                        onClick={() => {
                            navigate(`/panel/viewCandidateForPanel/${row._id}/${row.customerId}`);
                        }}
                        style={{
                            paddingLeft: "10px",
                            cursor: "pointer"
                        }}
                    />
                    {/* <i
            className="fa fa-light fa-eye"
            onClick={() => {
              navigate(`/panel/viewCandidateForPanel/${row._id}/${row.customerId}`);
            }}
            style={{
              paddingLeft: "10px",
              cursor: "pointer"
            }}
          ></i> */}
                </>
            ),
            style: () => {
                return {
                    font: "Muli",
                    minWidth: "120px",
                    letterSpacing: "0.28px",
                    color: "#3A3A3A",
                    opacity: "2",
                };
            },
        },
    ];

    const scheduleColumns = [
        // {
        //   dataField: 'customerCompany',
        //   text: 'Customer Name',
        //   sort: true,
        // },
        {
            dataField: 'scheduleId',
            text: 'Schedule ID',
            sort: true,
        },
        // {
        //   dataField: 'panelEmailId',
        //   text: 'Panel Email ID',
        //   sort: true,
        // },
        {
            dataField: 'candidateEmailId',
            text: 'Candidate Email ID',
            sort: true,
        },
        {
            dataField: 'coordinatorEmailId',
            text: 'Coordinator Email ID',
            sort: true,
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
        },
        {
            dataField: 'scheduledSlot',
            text: 'Slot',
            sort: true,
            style: {minWidth: '180px'},
            formatter: (cell, row) => (
                <>
                    <span>  {row.scheduledSlot?.slotDate}, {row.scheduledSlot?.slotTime} </span>
                </>
            )
        },
        {
            dataField: "action",
            text: "Actions",
            formatter: (cell, row) => (
                <>
                    <i  title="Edit Candidate"
                        className="fa fa-light fa-eye"
                        onClick={() => {
                            if ((hasAllPermissions || userPermissions.includes('VIEW_SCHEDULED_INTERVIEW'))) {
                                navigate(`/user/viewInterviewSchedule/${row.scheduleId}/${row.customerId}`);
                            }
                        }}
                        style={{
                            paddingLeft: "10px",
                            cursor: "pointer",
                            opacity: !(hasAllPermissions || userPermissions.includes('VIEW_SCHEDULED_INTERVIEW')) ? 0.2 : 1,
                            pointerEvents: !(hasAllPermissions || userPermissions.includes('VIEW_SCHEDULED_INTERVIEW')) ? 'none' : 'auto',
                        }}
                    ></i>
                    {/* <i
            className="fa fa-light fa-pen"
            onClick={() => {
              if ((hasAllPermissions || userPermissions.includes('EDIT_SCHEDULED_INTERVIEW'))) {
                navigate(`/user/editInterviewSchedule/${row.scheduleId}/${row.customerId}`);
              }
            }}
            style={{
              paddingLeft: "10px",
              cursor: "pointer",
              opacity: !(hasAllPermissions || userPermissions.includes('EDIT_SCHEDULED_INTERVIEW')) ? 0.2 : 1,
              pointerEvents: !(hasAllPermissions || userPermissions.includes('EDIT_SCHEDULED_INTERVIEW')) ? 'none' : 'auto',
            }}
          ></i> */}
                </>
            ),
            style: () => {
                return {
                    font: "Muli",
                    minWidth: "120px",
                    letterSpacing: "0.28px",
                    color: "#3A3A3A",
                    opacity: "1",
                };
            },
        }
    ];
    const options = {
        sizePerPage: 5,
        sizePerPageList: [
            {text: '5', value: 5},
            {text: '10', value: 10},
            {text: '15', value: 15},
            {text: '20', value: 20},
            {text: '50', value: 50},
        ],
        hideSizePerPage: false,
        hidePageListOnlyOnePage: false,
        alwaysShowAllBtns: true,
        showTotal: true,
        nextPageText: "Next",
        prePageText: "Prev",
    };
    console.log(panelMetrics);
    if (hasAllPermissions || userPermissions.includes('VIEW_PANEL_DASHBOARD')) {

        return (
            <>
                <div className="row">
                    <div className="col-3">
                        <label><h6>Start Date</h6></label>
                        <input
                            type="date"
                            id="startDate"
                            value={filters.startDate}
                            max={futureDate}
                            className="form-control"
                            onChange={(e) => setFilters({...filters, startDate: e.target.value})}
                            style={{
                                height: "50px",
                                background: "#FFFFFF 0% 0% no-repeat padding-box",
                                border: "1px solid #C7C7C7",
                                borderRadius: "6px",
                                opacity: "1",
                            }}
                            placeholder="Start Date"
                        />
                    </div>
                    <div className="col-3">
                        <label><h6>End Date</h6></label>
                        <input
                            type="date"
                            id="endDate"
                            value={filters.endDate}
                            // min={filters.startDate}
                            max={today}
                            className="form-control"
                            onChange={(e) => setFilters({...filters, endDate: e.target.value})}
                            style={{
                                height: "50px",
                                background: "#FFFFFF 0% 0% no-repeat padding-box",
                                border: "1px solid #C7C7C7",
                                borderRadius: "6px",
                                opacity: "1",
                            }}
                            placeholder="End Date"
                        />
                    </div>
                    <div className="col-2">
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                console.log(filters.startDate, filters.endDate)
                                if (filters.startDate != '' && filters.endDate != '') {
                                    getAllMetrics()
                                } else {
                                    toast.warn('Warning: Invalid Date Range.')
                                }
                            }}
                            style={{
                                height: "50px",
                                width: "150px",
                                background: "#3E67B1 0% 0% no-repeat padding-box",
                                borderRadius: "4px",
                                opacity: "1",
                                marginTop: "30px"
                            }}
                        >
                            Refresh
                        </button>
                    </div>
                </div>
                <div className="d-flex" style={{display: 'flex',
                    marginRight: '24px',
                    marginTop: '20px',
                }}>
                <DashboardCard  title="Interviews"
                               iconClass="fa fa-solid fa-calendar-alt"
                               subHeading1="Scheduled"
                               subHeading2="Canceled"
                               subHeading3="Completed"
                               value1={panelMetrics.interviewsMetrics[0]?.value}
                               value2={panelMetrics.interviewsMetrics[6]?.value}
                               value3={panelMetrics.interviewsMetrics[7]?.value}
                               navigateTo="/panel/interviewSchedules/all"/>
                <div className="col-3"
                     style={{
                         borderRadius: '8px',
                         marginTop: '10px 0px',
                         boxShadow: "0px 3px 6px #00000029",
                         border: "1px solid #F4F4F4",
                         padding: '0px',
                         marginRight: '8px',
                     }}
                ><a href="/panel/interviewSchedules/all">
                    <div style={{
                        backgroundColor: '#EDF3FF', display: 'flex',
                        minHeight: '50px',
                        justifyContent: 'space-between',
                        paddingInline: '16px',
                        alignItems: 'center',
                    }}>
                        Revenue
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                             className="bi bi-arrow-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd"
                                  d="M11.354 8.354a.5.5 0 0 1 0 .707l-4 4a.5.5 0 0 1-.708 0l.707-.707L10.293 9H1.5a.5.5 0 0 1 0-1h8.793l-3.147-3.146a.5.5 0 0 1 .708-.707l4 4a.5.5 0 0 1 0 .707z"/>
                        </svg>
                    </div>
                    <div className="row" style={{
                        backgroundColor: "#FFFFFF",
                        marginInline: '0px',
                        padding: '8px', boxSizing: 'border-box',
                        borderRadius: '0px 0px 10px 10px',
                    }}>
                        <a className="col-4">
                            <p className="card-subheading text-center">Revenue</p>
                            <p className="text-center "
                               style={{
                                   fontWeight: 'bold',
                               }}
                            >{panelMetrics?.revenueMetrics?.totalValue}</p>
                        </a>
                    </div>
                </a>
                </div>
                </div>
                <div className="Interview-Details"
                     style={{
                         background: "#FFFFFF 0% 0% no-repeat padding-box",
                         boxShadow: "0px 3px 6px #00000029",
                         border: "1px solid #E8E8E8",
                         borderRadius: "4px",
                         opacity: 1,
                         margin: "25px 0px",
                     }}>


                    <div className="row">
                        {panelMetrics.interviewsMetrics.map((item, index) => (
                            <div className="col-md-3" key={index}>
                                <div className="claimed d-flex justify-content-center "
                                     style={{
                                         height: "90px",
                                         background: "#FFFFFF 0% 0% no-repeat padding-box",
                                         boxShadow: "0px 3px 6px #DAE1EE33",
                                         border: "1px solid #E8E8E8",
                                         borderRadius: "4px",
                                         opacity: 1,
                                         margin: "20px 0px 0px 0px",
                                         display: "flex", // Make the claimed div a flex container
                                         flexDirection: "column", // Arrange children in a column
                                         alignItems: "center",
                                     }}>
                                    <h6 style={{padding: "7px 0px 0px 10px"}}>{item?.name}</h6>
                                    <div>
                                        <a href={['CLAIMED', 'SCHEDULED'].includes(item.status) ? `/panel/interviewSchedules/${item.status}|${filters.startDate}|${filters.endDate}` : `/panel/closedInterviews/${item.status}|${filters.startDate}|${filters.endDate}`}>
                                            <div
                                                className="col"
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        textAlign: "left",
                                                        font: "normal normal bold 24px/33px Open Sans",
                                                        letterSpacing: "0px",
                                                        color: "#3E67B1",
                                                        opacity: 1,
                                                        cursor: 'pointer'
                                                    }}>
                                                    {item.value}
                                                </p>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="col-md-3" key={'000'}>
                            <div className="claimed"
                                 style={{
                                     height: "90px",
                                     background: "#FFFFFF 0% 0% no-repeat padding-box",
                                     boxShadow: "0px 3px 6px #DAE1EE33",
                                     border: "1px solid #E8E8E8",
                                     borderRadius: "4px",
                                     opacity: 1,
                                     margin: "20px 0px 0px 0px",
                                     display: "flex", // Make the claimed div a flex container
                                     flexDirection: "column", // Arrange children in a column
                                     alignItems: "center",
                                 }}>
                                <h6 style={{padding: "7px 0px 0px 10px"}}>Total Revenue</h6>
                                <div
                                    className="col"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textAlign: "center",
                                    }}
                                >
                                    <p
                                        style={{
                                            textAlign: "left",
                                            font: "normal normal bold 24px/33px Open Sans",
                                            letterSpacing: "0px",
                                            color: "#3E67B1",
                                            opacity: 1
                                        }}>
                                        {panelMetrics?.revenueMetrics?.totalValue}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3" key={'001'}>
                            <div className="claimed"
                                 style={{
                                     height: "90px",
                                     background: "#FFFFFF 0% 0% no-repeat padding-box",
                                     boxShadow: "0px 3px 6px #DAE1EE33",
                                     border: "1px solid #E8E8E8",
                                     borderRadius: "4px",
                                     opacity: 1,
                                     margin: "20px 0px 0px 0px",
                                     display: "flex", // Make the claimed div a flex container
                                     flexDirection: "column", // Arrange children in a column
                                     alignItems: "center",
                                 }}>
                                <h6 style={{padding: "7px 0px 0px 10px"}}>Bonus Amount</h6>
                                <div
                                    className="col"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textAlign: "center",
                                    }}
                                >
                                    <p
                                        style={{
                                            textAlign: "left",
                                            font: "normal normal bold 24px/33px Open Sans",
                                            letterSpacing: "0px",
                                            color: "#3E67B1",
                                            opacity: 1
                                        }}>
                                        {panelMetrics?.revenueMetrics?.bonusAmount}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Box sx={{display: "flex"}}>
                    <Box
                        component="main"
                        style={{backgroundColor: "#F4F6FB", minHeight: "713px"}}
                        sx={{flexGrow: 1, bgcolor: "background.default",}}
                    >
                        <>
                            <Accordion expanded={true}>
                                <AccordionSummary {...ScheduleInterviewAccordionProps}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={3}>
                                            <h5>Scheduled Interviews</h5>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>

                                    <div
                                        className="main-content"
                                        style={{background: "#fff"}}
                                    >
                                        <Box>
                                            <Grid container spacing={0}>
                                                <Grid item xs={3}>
                                                </Grid>
                                                <Grid item xs={9} style={{textAlign: "right"}}>
                                                    <TextField
                                                        placeholder="Search"
                                                        size="small"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchIcon/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        value={scheduleSearch}
                                                        onChange={(e) => setScheduleSearch(e.target.value)}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <div className="custom-table-container">
                                                <BootstrapTable
                                                    hover
                                                    bootstrap4
                                                    keyField="scheduleId"
                                                    data={filteredSchedulesData}
                                                    columns={scheduleColumns}
                                                    pagination={paginationFactory(options)}
                                                    noDataIndication={
                                                        dataLoading ?
                                                            <Box sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                height: '100%',
                                                                width: '100%'
                                                            }}>
                                                                <CircularProgress/>
                                                            </Box>
                                                            :
                                                            <Box sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                height: '100%',
                                                                width: '100%'
                                                            }}>
                                                                <span>No Data Found</span>
                                                            </Box>
                                                    }
                                                />
                                            </div>
                                        </Box>
                                    </div>

                                </AccordionDetails>
                            </Accordion>
                        </>
                        <br/>


                        <Accordion expanded={true}>
                            <AccordionSummary {...ClaimInterviewAccordionProps}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <h5>Claim Interviews</h5>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>

                                <div
                                    className="main-content"
                                    style={{background: "#fff"}}
                                >
                                    <Box>
                                        <Grid container spacing={0}>
                                            <Grid item xs={3}>
                                            </Grid>
                                            <Grid item xs={9} style={{textAlign: "right"}}>
                                                <TextField
                                                    placeholder="Search"
                                                    size="small"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    value={candidateSearch}
                                                    onChange={(e) => setCandidateSearch(e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <div className="custom-table-container1">
                                            <BootstrapTable
                                                hover
                                                bootstrap4
                                                keyField="candidateId"
                                                data={filteredCandidatesData}
                                                columns={candidateColumns}
                                                pagination={paginationFactory(options)}
                                                noDataIndication={
                                                    dataLoading ?
                                                        <Box sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            height: '100%',
                                                            width: '100%'
                                                        }}>
                                                            <CircularProgress/>
                                                        </Box>
                                                        :
                                                        <Box sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            height: '100%',
                                                            width: '100%'
                                                        }}>
                                                            <span>No Data Found</span>
                                                        </Box>
                                                }
                                            />
                                        </div>
                                    </Box>
                                </div>

                            </AccordionDetails>
                        </Accordion>

                        <br/>
                        <>
                            <Accordion expanded={true}>
                                <AccordionSummary {...ClaimedCandidatesAccordionProps}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={3}>
                                            <h5>Claimed Candidates</h5>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>

                                    <div
                                        className="main-content"
                                        style={{background: "#fff"}}
                                    >
                                        <Box>
                                            <Grid container spacing={0}>
                                                <Grid item xs={3}>
                                                </Grid>
                                                <Grid item xs={9} style={{textAlign: "right"}}>
                                                    <TextField
                                                        placeholder="Search"
                                                        size="small"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchIcon/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        value={claimedCandidateSearch}
                                                        onChange={(e) => setClaimedCandidateSearch(e.target.value)}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <div className="custom-table-container1">
                                                <BootstrapTable
                                                    hover
                                                    bootstrap4
                                                    keyField="candidateId"
                                                    data={filteredClaimedCandidatesData}
                                                    columns={claimedCandidateColumns}
                                                    pagination={paginationFactory(options)}
                                                    noDataIndication={
                                                        dataLoading ?
                                                            <Box sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                height: '100%',
                                                                width: '100%'
                                                            }}>
                                                                <CircularProgress/>
                                                            </Box>
                                                            :
                                                            <Box sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                height: '100%',
                                                                width: '100%'
                                                            }}>
                                                                <span>No Data Found</span>
                                                            </Box>
                                                    }
                                                />
                                            </div>
                                        </Box>
                                    </div>

                                </AccordionDetails>
                            </Accordion>
                        </>

                    </Box>
                </Box>
                <br></br>
                <br></br>

                {/* <div
        className="modal fade"
        id="exampleModal-1"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <div className="col">
                <h4>Schedule Slot Details</h4>
              </div>
            </div>
            <div className="modal-body">
              <table className="table" style={{ padding: "5px" }}>
                <thead>
                  <tr style={{ fontWeight: "bold" }}>
                    <th>Schedule Slot Date</th>
                    <th style={{ textAlign: "center" }}>Schedule Slot Time</th>
                    <th style={{ textAlign: "right" }}>Claim</th>
                  </tr>
                </thead>
                <tbody>
                  {popupData.map((item) => (
                    <tr key={item.ssd}>
                      <td>{item.ssd}</td>

                      <td style={{ textAlign: "center" }}>{item.sst}</td>

                      <td style={{ textAlign: "right" }}>
                        <button
                          type="button"
                          className="btn btn-sm btn-primary"
                          style={{ background: "#3E67B1" }}
                        >
                          Claim
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <br />
            <div
              className="row"
              style={{ textAlign: "center", paddingBottom: "30px" }}
            >
              <div className="col">
                <button
                  data-bs-dismiss="modal"
                  className="btn"
                  style={{
                    height: "50px",
                    border: "1px solid #3E67B1",
                    borderRadius: "4px",
                    opacity: 1,
                  }}
                >
                  CLOSE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
       */}
                <br></br>
                <br></br>
            </>
        );

    } else {
        return (
            <>
                <div className="container d-flex justify-content-center align-items-center"
                     style={{marginTop: "100px"}}>
                    <div className="card">
                        <div className="card-body text-center">
                            <h2 className="card-title">
                                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                                Access Denied
                            </h2>
                            <p className="card-text">You do not have the required permissions to access this page.</p>
                            <p className="card-text">Please contact your administrator for assistance.</p>
                        </div>
                    </div>
                </div>

                <br></br>
                <br></br>
            </>
        )
    }
}
