import React, { useRef, useState, useEffect } from "react";
import { useParams, useNavigate  } from "react-router-dom";
import { getSchedule } from "../../service/api";
import {
    Box,
} from '@mui/material';

export default function ViewInterviewSchedule() {
  const { scheduleId, customerId } = useParams();
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');


  const formRef = useRef(null);

  const navigate = useNavigate();

  const [scheduleObj, setScheduleData] = useState({
    scheduleId: scheduleId,
    customerId: customerId,
    candidateId: 0,
    panelId: 0,
    customerEmailId: "",
    customerCompany: "",
    jobName: "",
    candidateName: "",
    candidateEmailId: "",
    panelName: "",
    panelEmailId: "",
    zoomLink: "",
    status: "SCHEDULED",
    scheduledSlot: {}
  });

  useEffect(() => {
    console.log(scheduleId, customerId)
    getSchedule(scheduleId, customerId).then((res) => {
      setScheduleData(res.data);
    });

  }, [scheduleId]);

  if (hasAllPermissions || userPermissions.includes('VIEW_SCHEDULED_INTERVIEW')) {

    return (
      <>
      <Box sx={{ display: "flex", flexDirection: 'column' }}>
        <div>
            <Box
                component="main"
                style={{ backgroundColor: "#F4F6FB" }}
                sx={{ flexGrow: 1, bgcolor: "background.default" }}
              >
              <form ref={formRef}>
              <div className="job-details" style={{ marginTop: "20px" }}>
                <div className="row">

                  <div className="col"
                       style={{display: user.profileType != 'PANELIST' ? 'inline' : 'none'}}
                  >
                    <label>
                      <div className="candidate-view-header2">Schedule ID</div>
                    </label>
                    <p className="custom-paragraph">
                      {scheduleObj.scheduleId || 'No Status'}
                    </p>
                  </div>
                  <div className="col"
                       style={{display: user.profileType != 'PANELIST' ? 'inline' : 'none'}}
                  >
                    <label><div className="candidate-view-header2">Customer Name </div></label>
                    <p className="custom-paragraph">
                      {scheduleObj.customerCompany || 'No Status'}
                    </p>
                  </div>
                  <div className="col">
                    <label><div className="candidate-view-header2">Status </div></label>
                    <p className="custom-paragraph">
                        {scheduleObj.status || 'No Status'}
                    </p>
                  </div>
                  <div className="col"
                       style={{display: user.profileType == 'PANELIST' ? 'inline' : 'none'}}
                  ></div>
                  <div className="col"
                       style={{display: user.profileType == 'PANELIST' ? 'inline' : 'none'}}
                  ></div>
                </div>

                <br></br>

                <div className="row">
                  <div className="col">
                    <label><div className="candidate-view-header2">Job Name </div></label>
                    <p className="custom-paragraph">
                      {scheduleObj.jobName || 'No Status'}
                    </p>
                  </div>
                  <div className="col">
                    <label><div className="candidate-view-header2">Panel Name </div></label>
                    <p className="custom-paragraph">
                      {scheduleObj.panelName || 'No Status'}
                    </p>
                  </div>
                  <div className="col">
                    <label><div className="candidate-view-header2">Candidate Name</div></label>
                    <p className="custom-paragraph">
                      {scheduleObj.candidateName || 'No Status'}
                    </p>
                  </div>
                </div>

                <br></br>

                <div className="row" >
                  <div className="col">
                    <label><div className="candidate-view-header2">Coordinator Email ID </div></label>
                    <p className="custom-paragraph">
                      {scheduleObj.coordinatorEmailId || 'No Status'}
                    </p>
                  </div>
                  <div className="col">
                    <label><div className="candidate-view-header2">Panel Email ID </div></label>
                    <p className="custom-paragraph">
                      {scheduleObj.panelEmailId || 'No Status'}
                    </p>
                  </div>
                  <div className="col">
                    <label><div className="candidate-view-header2">Candidate Email ID</div></label>
                    <p className="custom-paragraph">
                      {scheduleObj.candidateEmailId || 'No Status'}
                    </p>
                  </div>
                </div>

                <br></br>

                <div className="row" >
                  <div className="col">
                    <label><div className="candidate-view-header2">Slot Date</div></label>
                    <p className="custom-paragraph">
                      {scheduleObj.scheduledSlot?.slotDate || 'No slot time selected'}
                    </p>
                  </div>
                  <div className="col">
                    <label><div className="candidate-view-header2">Slot Time </div></label>
                    {/*<input type="text" className="form-control editable"*/}
                    {/*placeholder=""*/}
                    {/*name="slotTime"*/}
                    {/*disabled={true}*/}
                    {/*value={scheduleObj.scheduledSlot?.slotTime}/>*/}
                    <p className="custom-paragraph">
                      {scheduleObj.scheduledSlot?.slotTime || 'No Slot Time Selected'}
                    </p>
                  </div>
                  <div className="col">
                    <label><div className="candidate-view-header2">Slot Timezone </div></label>
                    {/*<input type="text" className="form-control editable"*/}
                    {/*placeholder=""*/}
                    {/*name="timezone"*/}
                    {/*disabled={true}*/}
                    {/*value={scheduleObj.scheduledSlot?.timezone}/>*/}
                    <p className="custom-paragraph">
                      {scheduleObj.scheduledSlot?.timezone || 'No Slot Timezone Selected'}
                    </p>
                  </div>
                </div>
                <br></br>

                <div className="row"
                style={{
                  display: ["ADMIN","SUPER"].includes(user.profileType) ? 'flex': 'none'
                }}
                >
                  <div className="col">
                    <label><div className="candidate-view-header2">Rate Category </div></label>
                    {/*<input type="text" className="form-control editable"*/}
                    {/*placeholder=""*/}
                    {/*name="rateCategory"*/}
                    {/*disabled={true}*/}
                    {/*value={scheduleObj.rateCategory}/>*/}
                    <p className="custom-paragraph">
                      {scheduleObj.rateCategory || 'No Rate Category Selected'}
                    </p>
                  </div>
                  <div className="col">
                    <label><div className="candidate-view-header2">Rate Card </div></label>
                    {/*<input type="text" className="form-control editable"*/}
                    {/*placeholder=""*/}
                    {/*name="rateCard"*/}
                    {/*disabled={true}*/}
                    {/*value={scheduleObj.rateCard}/>*/}
                    <p className="custom-paragraph">
                      {scheduleObj.rateCard || 'No Rate Card Selected'}
                    </p>
                  </div>
                  <div className="col">
                    <label><div className="candidate-view-header2">Bonus Amount </div></label>
                    {/*<input type="text" className="form-control editable"*/}
                    {/*placeholder=""*/}
                    {/*name="bonusAmount"*/}
                    {/*disabled={true}*/}
                    {/*value={scheduleObj.bonusAmount}/>*/}
                    <p className="custom-paragraph">
                      {scheduleObj.bonusAmount || 'No Bonus Amount Selected'}
                    </p>
                  </div>
                </div>

                <br></br>

                <div className="row">
                  <div className="col"
                    style={{
                      display: scheduleObj.status == 'SCHEDULED' ? 'inline' : 'none'
                    }}
                  >
                    <label><div className="candidate-view-header2">Meeting Link </div></label>
                    <br></br>
                    <div className="custom-paragraph">
                      <a href={scheduleObj.zoomLink} target="_blank" rel="noopener noreferrer">
                        Click Here to Join Meeting
                      </a>
                    </div>
                  </div>
                  <div className="col">
                    <label><div className="candidate-view-header2">Created At</div></label>
                    {/*<input type="text" className="form-control editable"*/}
                    {/*placeholder=""*/}
                    {/*name="createdAt"*/}
                    {/*disabled={true}*/}
                    {/*value={scheduleObj.createdAt}/>*/}
                    <p className="custom-paragraph">
                      {scheduleObj.createdAt || 'No Created At'}
                    </p>
                  </div>
                  <div className="col">

                  </div>

                </div>

              </div>

                <br />
                <div className="row justify-content-center">
                  <button
                    className="btn btn-outline-primary me-2"
                    style={{
                      border: "1px solid #3E67B1",
                      borderRadius: " 4px",
                      opacity: "1",
                      width: "96px",
                      height: "50px",
                    }}
                    onClick={() => {
                      if(user.profileType == 'PANELIST') {
                        navigate('/panel/interviewSchedules/all');
                        // if (scheduleObj.status == 'SCHEDULED') {
                        //   navigate('/panel/interviewSchedules/all');
                        // } else {
                        //   navigate('/panel/closedInterviews/all');
                        // }
                      } else if (user.profileType == 'CUSTOMER' || user.profileType == 'CUSTOMER_ADMIN') {
                        navigate('/customer/interviewSchedules/all')
                        // if (scheduleObj.status == 'SCHEDULED') {
                        //   navigate('/customer/interviewSchedules/all')
                        // } else {
                        //   navigate('/customer/closedInterviews/all')
                        // }
                      } else if (user.profileType == 'SUPER' || user.profileType == 'ADMIN') {
                        navigate('/admin/interviewSchedules/all')
                        // if (scheduleObj.status == 'SCHEDULED') {
                        //   navigate('/admin/interviewSchedules/all')
                        // } else {
                        //   navigate('/admin/closedInterviews/all')
                        // }
                      }
                    }}
                  >
                    BACK
                  </button>
                </div>
              </form>
            </Box>
        </div>

      </Box>
      <br></br>
      <br></br>

    </>
    );

  } else {
    return (
      <>
        <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
          <div className="card">
            <div className="card-body text-center">
              <h2 className="card-title">
                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                Access Denied
              </h2>
              <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
};

