import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import './PieChartComponent.css';

// Register the necessary components with Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

const PieChartComponent = () => {
    const data = {
        labels: ['Completed', 'Rescheduled', 'Cancelled', 'Selected', 'Rejected'],
        datasets: [
            {
                data: [500, 150, 150, 50, 50],
                backgroundColor: ['#AD5BFF', '#FF5E99', '#56C2E6', '#4CAF50', '#FF6B6B'],
                hoverBackgroundColor: ['#AD5BFF', '#FF5E99', '#56C2E6', '#4CAF50', '#FF6B6B']
            }
        ]
    };

    const options = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            }
        }
    };

    return (
        <div className="pie-chart-container">
            <div className="pie-chart">
                <Pie data={data} options={options} />
            </div>
            <div className="chart-legend">
                <div className="legend-item">
                    <strong>Uploaded JD's:</strong>
                    <span>1000</span>
                </div>
                <div className="legend-item">
                    <strong>Uploaded Candidates:</strong>
                    <span>1000</span>
                </div>
                <div className="legend-item">
                    <div>
                    <span className="legend-box" style={{ backgroundColor: '#AD5BFF' }}></span>
                    <strong>Completed:</strong>
                </div>
                    <span>500</span>
                </div>
                <div className="legend-item">
                    <div>
                    <span className="legend-box" style={{ backgroundColor: '#FF5E99' }}></span>
                    <strong>Rescheduled:</strong>
                </div>
                    <span>150</span>
                </div>
                <div className="legend-item">
                    <div>
                    <span className="legend-box" style={{ backgroundColor: '#56C2E6' }}></span>
                    <strong>Cancelled:</strong>
                </div>
                    <span>150</span>
                </div>
                <div className="legend-item">
                    <div>
                    <span className="legend-box" style={{ backgroundColor: '#4CAF50' }}></span>
                    <strong>Selected:</strong>
                    </div>
                    <span>50</span>
                </div>
                <div className="legend-item">
                    <div>
                    <span className="legend-box" style={{ backgroundColor: '#FF6B6B' }}></span>
                    <strong>Rejected:</strong>
                    </div>
                    <span>50</span>
                </div>
            </div>
        </div>
    );
};

export default PieChartComponent;
