import React, { useEffect, useState } from "react";
import { useParams, useLocation,useNavigate  } from "react-router-dom";

import Box from "@mui/material/Box";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { createGroup, deleteGroup, getGroups, updateGroup } from "../../service/api";
import { toast } from "react-toastify";
import toastNotifications from "../toastNotifications/toastMessages";
import CircularProgress from '@mui/material/CircularProgress';

export default function Groups() {
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');

  const [groupName, setGroupName] = useState("");
  const [ groupData, setGroupData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const navigate = useNavigate();


  function validateData(data){
    let result = {isValid:true,}
    let required_fields = ['groupName'];
    required_fields.forEach(key=>{
      if(!data[key]){
        result =  {isValid:false,mgs:`Field Missing ${key}`}
      }
    })
    return result;
  }

  const groups_columns = [
    // {
    //   dataField: "sNo",
    //   text: "S.No",
    //   formatter: (cellContent, row, rowIndex) => {
    //     return rowIndex + 1;
    //   },
    //   headerStyle: () => {
    //     return {
    //       font: "  Muli",
    //       letterSpacing: "0.28px",
    //       color: "#3A3A3A",
    //       opacity: "1",
    //     };
    //   },
    //   style: () => {
    //     return {
    //       font: "  Muli",
    //       letterSpacing: "0.28px",
    //       color: "#3A3A3A",
    //       opacity: "1",
    //     };
    //   },
    // },

    {
      dataField: "groupId",
      text: "Group ID",
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          textAlign: "center",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          textAlign: "center",
          opacity: "1",
        };
      },
      sort: true,
    },
    {
      dataField: "groupName",
      text: "Group Name",
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          textAlign: "center",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          textAlign: "center",
          opacity: "1",
        };
      },

      sort: true,
    },

    {
      dataField: "action",
      text: "Actions",
      hidden: user.profileType == 'SUPER' ? false : true,
      formatter: actionFormatter,
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          textAlign: "right",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          textAlign: "right",
          opacity: "1",
        };
      },
    },
  ];

  const [groups_data, setGroupsData] = useState([]);

  useEffect(() => {
    setDataLoading(true);
    getGroups().then((response) => {
      setGroupsData(response.data);
      setDataLoading(false);
    });
  },[]);

  function actionFormatter(row, cell) {
    return (
      <>
        <i
         data-toggle="modal"
         data-target="#groupID"
        onClick={()=>{
          setGroupData(cell)
        }}
        className="fa fa-light fa-pen-to-square"
        style={{ 
          color: "#3E67B1",
          display: user.profileType == 'SUPER' ? 'inline': 'none'
        }} 
        ></i>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <i 
        className="fa fa-light fa-trash-can" 
        style={{ 
          color: "#3E67B1",
          display: user.profileType == 'SUPER' ? 'inline': 'none'
        }} 
        onClick={()=>{
          deleteGroup(cell.groupId).then((res)=>{
            toast.success(res.message);
            getGroups().then((response) => {
              setGroupsData(response.data);
            });
          }).catch((err) => {
            if (err?.response?.data?.message) {
              toast.error(err?.response?.data?.message);
            } else {
              toast.error("An error occurred.!");
            }
          });
        }}></i>
      </>
    );
  }

  const options = {
    sizePerPage: 5,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: false,
    alwaysShowAllBtns: true,
    showTotal: true,
    nextPageText: "Next",
    prePageText: "Prev",
  };

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  if (hasAllPermissions || userPermissions.includes('LIST_GROUPS')) {
    return (
      <>
       <div  className="container-page">
         <div
            className="main-content"
            style={{  background: "#fff", padding: " 0px 20px 20px 20px",borderRadius:"8px" }}
          >
            <>
              <div
                clas="container"
                style={{ marginLeft: "1%", marginRight: "1%" }}
              >
                <div className="row"
                style={{ display: user.profileType == 'SUPER' ? 'flex': 'none',paddingTop:"20px"}}
                >
                  <label>
                    <h6>Group Name</h6>
                  </label>
                  <div className="col-4">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {setGroupName(e.target.value)}}
                      style={{
                        height: "50px",
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        border: "1px solid #C7C7C7",
                        borderRadius: "6px",
                        opacity: "1",
                      }}
                      placeholder="Enter Group Name"
                    />
                  </div>
                  <div className="col-2">
                    <button
                      className="btn btn-primary"
                      onClick={
                        ()=>{
                          const validation = validateData({groupName});
                          if(!validation.isValid){
                            toast.error(validation.mgs)
                            return;
                          }
                          createGroup({groupName}).then((res) => {
                            navigate(0)
                            toast.success(res.message);
                            getGroups().then((response) => {
                              setGroupsData(response.data);
                            });
                          }).catch((err)=>{
                            toast.error(err.error);
                          })
                          
                        }
                    }
                      style={{
                        height: "50px",
                        width: "150px",
                        background: "#3E67B1 0% 0% no-repeat padding-box",
                        borderRadius: "4px",
                        opacity: "1",
                        
                      }}
                    >
                      Create Group
                    </button>
                  </div>
                  <div className="col-6"></div>
                </div>
              </div>
            </>
            <br />
            <>
              <div className="container" style={{marginTop:"-20px"}}>
                <div className="row" >
                  <div className="col">
                    
                    <BootstrapTable
                      className="table"
                      hover
                      bootstrap4
                      keyField="id"
                      data={groups_data}
                      columns={groups_columns}
                      pagination={paginationFactory(options)}
                      filter={filterFactory()}
                      defaultSorted={defaultSorted}
                      noDataIndication={
                        dataLoading ? 
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                          <CircularProgress />
                        </Box> 
                        : 
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                          <span>No Data Found</span>
                        </Box>
                      }
                    />
                  </div>
                </div>
              </div>
            </>
            
          </div>
        </div>
        <>
        <div
          id="groupID"
          className="modal fade bd-example-modal-lg"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Edit Group
                </h5>
              </div>
              <div className="container" style={{ backgroundColor: "white" }}>
                <div className="form-group">
                  <label htmlFor="roleName">Group Name</label>
                  <input
                    id="roleName"
                    className="form-control"
                    type="text"
                    value={groupData.groupName}
                    onChange={(e) =>{
                      e.preventDefault();
                      const newRoleName = e.target.value;
                      setGroupData((prevgroupData) => ({
                        ...prevgroupData,
                        groupName: newRoleName,
                      }));
                    }}
                    style={{
                      height: "50px"
                    }}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">
                  Close
                </button>
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-primary"
                  onClick={() => {
                    updateGroup(groupData.groupId,groupData)
                    .then((res) => {      
                      toast.success(toastNotifications.groups.updateSuccess);
                      getGroups().then((response) => {
                        setGroupsData(response.data);
                      });
                    }).catch(error=>{
                      toast.error(toastNotifications.groups.updateFail);
                    });
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
      </>
    );
  } else {
    return (
      <>
        <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
          <div className="card">
            <div className="card-body text-center">
              <h2 className="card-title">
                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                Access Denied
              </h2>
              <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}

