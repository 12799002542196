import React, { useEffect, useState } from "react";

import {
  getVendorDashboardMetrics
} from "../../service/api";
import { toast } from "react-toastify";

export default function VendorDashboard() {

  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');

  const [metrcisData, setMetricsData] = useState({
    candidatesMetrics: [],
    jobsMetrics: [],
    interviewsMetrics: [],
    ticketsMetrics: []
  });

  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
  });
  const today = new Date().toISOString().slice(0, 10);

  const getAllMetrics = () => {
    getVendorDashboardMetrics(filters).then((response) => {
      setMetricsData(response.data)
    }).catch((err) => {
      console.error(err);
    });
  }

  useEffect(() => {
    getAllMetrics();
  }, []);

  if (hasAllPermissions || userPermissions.includes('VIEW_VENDOR_DASHBOARD')) {
    return (
      <>
      <div className="container"
      style={{margin:"0px",padding:"0px"}}>
        <div className="row">
          <div className="col-3">
            <label> <h6>Start Date</h6> </label>
            <input
              type="date"
              id="startDate"
              value={filters.startDate}
              max={today}
              className="form-control"
              onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
              style={{
                height: "50px",
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                border: "1px solid #C7C7C7",
                borderRadius: "6px",
                opacity: "1",
              }}
              placeholder="Start Date"
            />
          </div>
          <div className="col-3">
            <label> <h6>End Date</h6> </label>
            <input
              type="date"
              id="endDate"
              value={filters.endDate}
              // min={filters.startDate}
              max={today}
              className="form-control"
              onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}
              style={{
                height: "50px",
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                border: "1px solid #C7C7C7",
                borderRadius: "6px",
                opacity: "1",
              }}
              placeholder="End Date"
            />
          </div>
          <div className="col-2">
              <button
                className="btn btn-primary"
                onClick={() => {
                    console.log(filters.startDate, filters.endDate)
                    if (filters.startDate != '' && filters.endDate != '') {
                      getAllMetrics()
                    } else {
                      toast.warn('Warning: Invalid Date Range.')
                    }
                }}
                style={{
                  height: "50px",
                  width: "150px",
                  background: "#3E67B1 0% 0% no-repeat padding-box",
                  borderRadius: "4px",
                  opacity: "1",
                  marginTop: "30px"
                }}
              >
                Refresh
              </button>
              </div>
          </div>
      </div>
        <div className="Interview-Details"
        style={{
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          boxShadow: "0px 3px 6px #00000029",
          border: "1px solid #E8E8E8",
          borderRadius: "4px",
          opacity: 1,
          margin: "20px 0px", padding:"20px"
        }}>
          <h5 > Jobs Involved</h5>
          <div className="row">
            {metrcisData.jobsMetrics.map((item, index) => (
              <div className="col-md-3" key={index}>
                <div className="claimed"
                     style={{
                         height: "90px",
                         background: "#FFFFFF 0% 0% no-repeat padding-box",
                         boxShadow: "0px 3px 6px #DAE1EE33",
                         border: "1px solid #E8E8E8",
                         borderRadius: "4px",
                         opacity: 1,
                         margin: "20px 0px 0px 0px",
                         display: "flex", // Make the claimed div a flex container
                         flexDirection: "column", // Arrange children in a column
                         alignItems: "center",
                     }}>
                  <h6 style={{ padding: "7px 0px 0px 10px" }}>{item?.name}</h6>
                  <a  href={`/user/jobs/${item.status}|${filters.startDate}|${filters.endDate}`}>

                    <div
                      className="col"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <p style={{
                        textAlign: "left",
                        font: "normal normal bold 24px/33px Open Sans",
                        letterSpacing: "0px",
                        color: "#3E67B1",
                        opacity: 1,
                      }}>{item.value}</p>
                    </div>
                  </a>
                </div>
              </div>
            ))}
          </div>
      </div>

      <div className="Interview-Details"
        style={{
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            boxShadow: "0px 3px 6px #00000029",
            border: "1px solid #E8E8E8",
            borderRadius: "4px",
            opacity: 1,
            margin: "20px 0px", padding:"20px"
        }}>
        <h5 >Candidates Uploaded</h5>
        <div className="row">
          {metrcisData.candidatesMetrics.map((item, index) => (
            <div className="col-md-3" key={index}>
              <div className="claimed"
                   style={{
                       height: "90px",
                       background: "#FFFFFF 0% 0% no-repeat padding-box",
                       boxShadow: "0px 3px 6px #DAE1EE33",
                       border: "1px solid #E8E8E8",
                       borderRadius: "4px",
                       opacity: 1,
                       margin: "20px 0px 0px 0px",
                       display: "flex", // Make the claimed div a flex container
                       flexDirection: "column", // Arrange children in a column
                       alignItems: "center",
                   }}>
                <h6 style={{ padding: "7px 0px 0px 10px" }}>{item?.name}</h6>
                <a  href={`/user/candidates/${item.status}|${filters.startDate}|${filters.endDate}`}>

                  <div
                    className="col"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                  <p
                  style={{
                    textAlign: "left",
                    font: "normal normal bold 24px/33px Open Sans",
                    letterSpacing: "0px",
                    color: "#3E67B1",
                    opacity: 1,
                  }}>{item.value}</p>
                </div>
              </a>
            </div>
          </div>
          ))}
        </div>
      </div>

      <br></br>
      <br></br>
      </>
    );
  } else {
    return (
      <>
        <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
          <div className="card">
            <div className="card-body text-center">
              <h2 className="card-title">
                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                Access Denied
              </h2>
              <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}
