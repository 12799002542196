import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  getCandidates
} from "../../service/api";
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from "react-router-dom";
import FilterModal from "./ClaimedFilterModel";
import "./Candidate.css";
import "../../CSS/dashboard.css";


export default function ClaimedCandidates() {
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');

  const [candidatesData, setCandidatesData] = useState([]);
  const [filteredCandidatesData, setFilteredCandidates] = useState([]);
  const [dataLoading, setDataLoading] = useState();
  const navigate = useNavigate();
  const [filters, setFilters] = useState({});
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const handleOpenFilterModal = () => {
    setFilterModalOpen(true);
  };

  const handleCloseFilterModal = () => {
    setFilterModalOpen(false);
  };

  const handleApplyFilters = (newFilters) => {
    const updatedFilters = Object.fromEntries(Object.entries(newFilters).filter(([key, value]) => value !== ""));
    setFilters(updatedFilters);
  };

  const populateCandidates = () => {
    setDataLoading(true);
      getCandidates().then((response) => {
        let claimedCandidates = response.data.filter( e=> e.status == 'CLAIMED');
        setCandidatesData(claimedCandidates);
        setDataLoading(false);
      }).catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    populateCandidates();
  }, []);

  useEffect(() => {
    const filterKeys = Object.keys(filters);
    if (filterKeys.length > 0) {
      const filteredData = candidatesData.filter(job =>
        filterKeys.every(key =>
          String(job[key]).toLowerCase().includes(String(filters[key]).toLowerCase())
        )
      );
      setFilteredCandidates(filteredData);
    } else {
      setFilteredCandidates(candidatesData);
    }
  }, [filters, candidatesData]);


  const candidateColumns = [
    {
      dataField: 'customerCompany',
      text: 'Customer Name',
      sort: true,
    },
    {
      dataField: 'jobName',
      text: 'Job Name',
      sort: true,
      formatter: (cell, row) => (
        <>
          <span> {row.jobId} - {row.jobName} </span>
        </>
      )
    },
    {
      dataField: 'candidateName',
      text: 'Candidate Name',
      sort: true,
      formatter: (cell, row) => (
        <>
          <span> {row.candidateId} - {row.candidateName} </span>
        </>
      )
    },
    {
      dataField: 'panelName',
      text: 'Panel Name',
      sort: true,
      formatter: (cell, row) => (
        <>
          <span> {row.panelId} - {row.panelName} </span>
        </>
      )
    },
    {
      dataField: 'slotDate',
      text: 'Slot',
      sort: true,
      formatter: (cell, row) => (
        <>
        {
          row.slotNumber == 1 ? <span> {row.slotDate1}, {row.slotTime1} </span> : <span> {row.slotDate2}, {row.slotTime2} </span>
        }
        </>
      )
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
    },
    {
      dataField: 'assigneeName',
      text: 'Assignee',
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: (cell, row) => (
        <>
          <i
            onClick={() => {
              if ((hasAllPermissions || userPermissions.includes('APPROVE_PANEL_CLAIMS'))) {
                navigate(`/admin/approvePanelClaims/${row._id}/${row.customerId}`);
              }
            }}
            className="fa fa-light fa-eye"
            style={{
              paddingLeft: "10px",
              cursor: "pointer",
              opacity: !(hasAllPermissions || userPermissions.includes('APPROVE_PANEL_CLAIMS')) ? 0.2 : 1,
              pointerEvents: !(hasAllPermissions || userPermissions.includes('APPROVE_PANEL_CLAIMS')) ? 'none' : 'auto',
            }}
          ></i>
        </>
      ),
      style: () => {
        return {
          font: "Muli",
          minWidth: "120px",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
    },
  ];

  const options = {
    sizePerPage: 5,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '50', value: 50 },
    ],
    hideSizePerPage: false,
    hidePageListOnlyOnePage: false,
    alwaysShowAllBtns: true,
    showTotal: true,
    nextPageText: "Next",
    prePageText: "Prev",
  };

  const filterDict = {
    'customerCompany': 'Customer Company',
    'jobName': 'Job Name',
    'candidateName': 'Candidate Name',
    'primarySkills': 'Primary Skills',
    'assigneeName': 'Assignee Name',
    'status': 'Status'
  }

  if (hasAllPermissions || userPermissions.includes('LIST_CLAIMED_CANDIDATES')) {

    return (
      <div className="container-page" >
        <div className="d-flex justify-content-end pb-2">
                  <button
                    className="filters"
                    variant="contained"
                    color="primary"
                    onClick={handleOpenFilterModal}
                  >
                    <span className="filter-icon">
                      <img src="/Images/Filter.svg" />
                    </span>
                    &nbsp;&nbsp;
                    <span className="filter-text">Filters</span>
                  </button>
            </div>
          <div
            className="main-content"
            style={{  background: "#fff", padding: "10px" }}
          >

            <div className="applied-filters" style={{ display: Object.keys(filters).length > 0 ? 'block': 'none'}}>
              <div className="applied-filters-text">
                <h4>Applied Filters ({Object.keys(filters).length})</h4>
              </div>
              <br></br>
              <div className="applied-filters-list ">
                {Object.keys(filters).map((d, i) => {
                  return (filters[d] != '')? <div>
                  <span className="applied-filter">
                    <label key={i}>{filterDict[d]}:- {filters[d]} &nbsp;{"  "}</label>
                    <i
                      className="fa fa-thin fa-circle-xmark"
                      style={{ color: "#3c77dd" }}
                      onClick={() => {
                        console.log('removeFilter called with:', d, filters);
                        const newFilters = { ...filters };
                        delete newFilters[d];
                        console.log("updatedFilters::", newFilters)
                        setFilters(newFilters);
                        handleApplyFilters(newFilters);
                      }}
                    ></i>
                  </span>
                  </div>: <></>
                })}
              </div>
              <br />
            </div>
      <Box sx={{overflow:"scroll"}}>
        <BootstrapTable
          hover
          bootstrap4
          keyField="candidateId"
          data={filteredCandidatesData}
          columns={candidateColumns}
          pagination={paginationFactory(options)}
          noDataIndication={
            dataLoading ?
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
              <CircularProgress />
            </Box>
            :
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
              <span>No Data Found</span>
            </Box>
          }
        />

        {
        filterModalOpen?<FilterModal
          filters={filters}
          open={filterModalOpen}
          handleClose={handleCloseFilterModal}
          handleApply={handleApplyFilters}
        />: <></>
        }
      </Box>
      </div>
      </div>
    );

  } else {
    return (
      <>
        <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
          <div className="card">
            <div className="card-body text-center">
              <h2 className="card-title">
                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                Access Denied
              </h2>
              <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}
