import React, { useState, useRef, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Row, Col, Button, Container } from "reactstrap";
import CustomModal from "./CustomModal";
import EventForm from "./EventForm"; // A separate component for the event form
import "./Calendar.css";
import useCalendarEvents from "./useCalendarEvents"; // Custom hook for managing events
import { getCustomerCalendarEvents, addCalendarEvents, putCalendarEvents } from "../../service/api";
import moment from "moment";

export default function CustomerCalendar() {
	const user = JSON.parse(localStorage.getItem('user'));
  	const userPermissions = user.permissions.map(e => e.permissionName);
  	const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');

	const [modal, setModal] = useState(false);
	const calendarRef = useRef(null);
	const [initialEvents,setInitialEvents] = useState([]);
	const [toggle, setTogg] = useState(false)
	const { selectedEvent,editModelOpen, handleDateSelect, renderEventContent, handleEventClick, handleEventDrop, handleEventResize,setEditModel, state, setState } = useCalendarEvents(calendarRef, setModal);

	useEffect(()=>{
		setTogg(false);
		getCustomerCalendarEvents().then(data=>{
			if(!data.data) {
				setInitialEvents([]);
			}
			else {
				const initialDates = data.data.map(d=>{
					d.groupId=  Date.now();
					d.id= Date.now();
					d.start = new Date(d.eventStartDate);
					d.end = new Date(d.eventEndDate);
					return d;
				})
				// console.log(`Logging existing calender event's ${JSON.stringify(initialDates)}`)
				setInitialEvents(
					initialDates
				);
			}
			setTogg(true)
		})
	},[])

	if (hasAllPermissions || userPermissions.includes('VIEW_CUSTOMER_CALENDAR')) {

		return (
			<div className="Calendar">
				<Container>
					{/* <Row style={{ marginBottom: 20 }}>
						<Col sm={{ size: 3, offset: 9 }} md={{ size: 3, offset: 9 }} style={{ paddingRight: 15 }}>
							<Button style={{ float: "right" }} color="secondary" onClick={() => setModal(true)}>Add schedule</Button>
						</Col>
					</Row> */}
					<Row>
						<Col md={12}>
							{toggle?<FullCalendar
								ref={calendarRef}
								plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
								headerToolbar={{ 
									left: "prev,today,next", 
									center: "title", 
									right: "dayGridMonth,timeGridWeek,timeGridDay" 
								}}
								initialView="dayGridMonth"
								editable={true}
								selectable={true}
								weekends={true}
								initialEvents={initialEvents}
								select={handleDateSelect}
								eventContent={renderEventContent}
								eventClick={handleEventClick}
								eventDrop={handleEventDrop}
								eventResize={handleEventResize}
							/>:<></>}
						</Col>
					</Row>
				</Container>
				<CustomModal 
					title={state.state === "update" ? "Update Event" : "Add Event"} 
					isOpen={modal} 
					toggle={() => setModal(false)}
					>
					<EventForm state={state} setState={setState} onSave={(data)=> {
						const {
							scheduleId,
							panelEmailId,
							candidateEmailId,
							title,
							eventStartDate,
							eventEndDate,
							meetingLink,
							description
						} = data
						addCalendarEvents( {
							scheduleId,
							panelEmailId,
							candidateEmailId,
							title,
							eventStartDate,
							eventEndDate,
							meetingLink,
							description
						}).then(res => {
							setEditModel(!editModelOpen);
							window.location.reload(false)
						})
					}} />
				</CustomModal>
				{selectedEvent && (
					<CustomModal 
					title={state.state === "update" ? "Update Event Details" : "View Event Details"} 
					isOpen={editModelOpen} 
					toggle={() => setEditModel(!editModelOpen)} 
					>
					<EventForm eventToEdit={selectedEvent} onCancel={() => setEditModel(!editModelOpen)} onSave={(data)=>{
						const {
							scheduleId,
							panelEmailId,
							candidateEmailId,
							title,
							eventId,
							eventStartDate,
							eventEndDate,
							meetingLink,
							description
						} = data;
					
						putCalendarEvents(eventId,{
							scheduleId,
							panelEmailId,
							candidateEmailId,
							title,
							eventId,
							eventStartDate,
							eventEndDate,
							meetingLink,
							description
						}).then(res => {
							setEditModel(!editModelOpen);
							window.location.reload(false)
						})

					}} 
					state={state} 
					setState={setState} 
					/>
					</CustomModal>
			
				)}
			</div>
		);
  
	} else {
	  return (
		<>
		  <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
			<div className="card">
			  <div className="card-body text-center">
				<h2 className="card-title">
				  <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
				  Access Denied
				</h2>
				<p className="card-text">You do not have the required permissions to access this page.</p>
				<p className="card-text">Please contact your administrator for assistance.</p>
			  </div>
			</div>
		  </div>
		</>
	  )
	}
}
