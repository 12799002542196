import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import { Grid, MenuItem, OutlinedInput, Select } from "@mui/material";
import {useNavigate  } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import { createRole, deleteRole, getGroups, getPermissions, getRoles,updateRole } from "../../service/api";
import { toast } from "react-toastify";
import toastNotifications from "../toastNotifications/toastMessages";
import CircularProgress from '@mui/material/CircularProgress';

export default function Roles() {
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');

  const groupIDNameMap = {};
  const permissionIDMap = {};
  const [rowData, setRowData] = useState({
    roleId: 0,
    roleName: "",
    groupId: 0,
    permissions: [],
  });
 
  const navigate = useNavigate();
  const [groupData, setGroupData] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const [permissionData , setPermissionData] = useState([]);
  const [permissionSelect, setPermissionSelect] = useState([]);
  const [groupSelect,  setGroupSelect] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  
  const [roleName, setRoleName] = useState("");
  // const  [groupID, setGroupID] = useState("");
  // const  [permissionsData, setPermissionsData] = useState([]);

  for( let pad of permissionData){
    permissionIDMap[pad.permissionId] = pad;
  }

  function validateData(data){
    let result = {isValid:true,}
    let required_fields = ['groupId', 'roleName', 'permissions'];
    required_fields.forEach(key=>{
      if(!data[key]){
        result =  {isValid:false,mgs:`Field Missing ${key}`}
      }
    })
    return result;
  }

  const editRowCellHandler = (row) => {
    row.permissions = row.permissions.map((permission) => permission.permissionId);
    setRowData(row);
  };
  async function fetchData() {
    try {
      setDataLoading(true);
      let permissionAPI = await getPermissions();
      let groupDataAPI = await getGroups();
      let rolesDataAPI = await getRoles();
      setGroupData(groupDataAPI.data);
      setRolesData(rolesDataAPI.data);
      setPermissionData(permissionAPI.data);
    
      groupDataAPI.data.reduce((acc, curr) => {
        acc[curr.groupId] = curr.groupName;
        return acc;
      }, groupIDNameMap);

      setRolesDataArray(
        rolesDataAPI.data.map((role) => {
          return {
            ...role,
            groupName: groupIDNameMap[role?.groupId],
            permissionsName: role.permissions
              .map((permission) => permission?.permissionName)
              .join(", "),
          };
        })
      );
      setDataLoading(false);
    } catch (error) {
      console.log(error);
    }
  } 
  const [rolesDataArray, setRolesDataArray] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const role_columns = [
    {
      dataField: "roleId",
      text: "Role ID",
      headerStyle: () => {
        return {
          font: "Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
          whiteSpace:"nowrap"
        };
      },
      style: () => {
        return {
          font: "Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
      sort: true,
    },
    {
      dataField: "roleName",
      text: "Role Name",
      headerStyle: () => {
        return {
          font: "Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          textAlign: "center",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          textAlign: "center",
          opacity: "1",
        };
      },
      sort: true,
    },
    {
      dataField: "groupName",
      text: "Group Name",
      headerStyle: () => {
        return {
          font: "Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          textAlign: "center",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          textAlign: "center",
          opacity: "1",
        };
      },
      sort: true,
    },
    {
      dataField: "permissionsName",
      text: "Permissions",
      headerStyle: () => {
        return {
          font: "Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          textAlign: "center",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          textAlign: "center",
          opacity: "1",
        };
      },
      sort: true,
    },
    
    {
      dataField: "action",
      text: "Actions",
      formatter: actionFormatter,
      headerStyle: () => {
        return {
          font: "Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          textAlign: "right",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          textAlign: "right",
          opacity: "1",
        };
      },
    },
  ];

  function actionFormatter(row, cell) {
    return (
      <>
          <i
          onClick={() => editRowCellHandler(cell)}
          style={{
            color: "#3E67B1",
            opacity: "1",
          }}
          className="fa fa-light fa-pen-to-square"
          data-toggle="modal"
          data-target="#rolesID"
        ></i>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <i 
        className="fa fa-light fa-trash-can" 
        style={{ 
          color: "#3E67B1", 
          display: user.profileType == 'SUPER' ? 'inline': 'none' 
        }} 
        onClick={()=>{
          deleteRole(cell.roleId).then((res)=>{
            toast.success(res.message)
            fetchData();
          }).catch((err) => {
            if (err?.response?.data?.message) {
              toast.error(err?.response?.data?.message);
            } else {
              toast.error("An error occurred.!");
            }
          });
        }}
        ></i>
      </>
    );
  }

  const options = {
    sizePerPage: 1,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: false,
    alwaysShowAllBtns: true,
    showTotal: true,
    nextPageText: "Next",
    prePageText: "Prev",
  };

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  if (hasAllPermissions || userPermissions.includes('LIST_ROLES')) {
    return (
      <>
        
            <>
              <div
                clas="container"
                style={{marginBottom:"20px" ,  marginRight: "0px"  }}
              >
                <div className="row">
                <div className="col-4">
                    <label>
                      <h6>Role Name</h6>
                    </label>
                    <br></br>
                    <input
                      onChange={(e) => {
                        setRoleName(e.target.value);  
                      }}
                      type="text"
                      className="form-control"
                      style={{
                        height: "50px",
                        width: "100%",
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        border: "1px solid #C7C7C7",
                        borderRadius: "6px",
                        opacity: "1",
                      }}
                      placeholder="Enter Role Name"
                    />
                  </div>
                  <div className="col-4">
                    <label>
                      <h6>Permissions</h6>
                    </label>
                    <br></br>
                    <Select
                      className="form-control"
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple
                      value={permissionSelect}
                      onChange={(e) => {
                        setPermissionSelect(e.target.value);
                      }}
                      input={<OutlinedInput label="Name" />}
                      MenuProps={{}}
                      style={{
                        width: "100%",
                        height: "50px",
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        border: "1px solid #C7C7C7",
                        borderRadius: "6px",
                        opacity: "1",
                      }}
                    >
                      <option value="" >Select Permissions</option>
                      {permissionData.map((permission) => (
                        <MenuItem
                          key={permission.permissionId}
                          value={permission.permissionId}
                        >
                          {permission.permissionName}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  
                  <div className="col-4">
                    <label>
                      <h6>Group Name</h6>
                    </label>
                    <br></br>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setGroupSelect(e.target.value);
                      }}
                      style={{
                        height: "50px",
                        widts: "100%",
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        border: "1px solid #C7C7C7",
                        borderRadius: "6px",
                        opacity: "1",
                      }}
                    >
                      <option value="">Select Group Name</option>
                      { 
                      groupData.map((group)=>{
                        
                        return (<option  id={group.groupId} value={group.groupId}>{group.groupName}</option>)
                      })} 
                    </select>
                  </div>
</div>
                  <div  style={{ marginTop: "20px" ,display:"flex", justifyContent:"center"}}>
                    <button
                        onClick={() => {
                          let payload = {
                            roleName: roleName,
                            groupId: groupSelect,
                            permissions: permissionSelect.map(d => permissionIDMap[d]).filter(obj => obj !== null)
                          };
                          const validation = validateData(payload)
                          if(!validation.isValid){
                            toast.error(validation.mgs)
                            return;
                          }
                          createRole(payload)
                            .then((res)=>{
                              navigate(0)
                              toast.success(res.message);
                              fetchData();
                            });
                        }}
                        className="btn btn-primary"
                        style={{
                          width: "96px",
                          height: "50px",
                          border: "1px solid #3E67B1",
                          borderRadius: "4px",
                          opacity: "1",
                        }}
                      >
                        Create
                      </button>
                      <button type="button" className="btn btn-secondary" data-dismiss="modal"
                       style={{
                        width: "96px",
                        height: "50px",
                        border: "1px solid #3E67B1",
                        borderRadius: "4px",
                        opacity: "1",
                        backgroundColor:"white",
                        marginLeft:"20px"
                      }}>
                  Cleare
                </button>
                  </div>
                </div>
            </>

            <>
            
                
                  <div  className="container-page">
         <div
            className="main-content"
            style={{  background: "#fff", padding: " 0px 20px 20px 20px" ,borderRadius:"8px"}}
          >
                    <div  className="custom-table-container">
                    <BootstrapTable
                      className="table"
                      hover
                      bootstrap4
                      keyField="id"
                      data={rolesDataArray}
                      columns={role_columns}
                      pagination={paginationFactory(options)}
                      filter={filterFactory()}
                      defaultSorted={defaultSorted}
                      noDataIndication={
                        dataLoading ? 
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                          <CircularProgress />
                        </Box> 
                        : 
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                          <span>No Data Found</span>
                        </Box>
                      }
                    />
                    </div>
                    </div>
                    </div>
                  
          
             
            </>
          
        
        <>
        <div
          id="rolesID"
          className="modal fade bd-example-modal-lg"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Edit Role
                </h5>
              </div>
              <div className="container" style={{ backgroundColor: "white" }}>
                <div className="form-group">
                  <label htmlFor="groupId">Group</label>
                  <select
                    id="groupId"
                    disabled={true}
                    className="form-control"
                    value={rowData.groupId}
                    onChange={(e) =>{
                      e.preventDefault();
                      setRowData((prevRowData) => ({
                        ...prevRowData,
                        groupId: e.target.value,
                      }));
                    }}
                    style={{
                      height: "50px"
                    }}
                  >
                    {groupData.map((group) => (
                      <option id={group.groupId} value={group.groupId}>
                        {group.groupName}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="permission">Permissions</label><br></br>
                  <Select
                    className="form-control"
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={rowData.permissions}
                    onChange={(e) =>{
                      e.preventDefault();
                      const newPermission = e.target.value;
                      setRowData((prevRowData) => ({
                        ...prevRowData,
                        permissions: newPermission,
                      }));
                    }}
                    input={<OutlinedInput label="Name" />}
                    MenuProps={{}}
                    style={{
                      height: "50px"
                    }}
                  >
                  {permissionData.map((permission) => (
                    <MenuItem
                      key={permission.permissionId}
                      value={permission.permissionId}
                    
                    >
                      {permissionIDMap[permission.permissionId].permissionName}
                    </MenuItem>
                  ))}
      
          </Select>
                  
                </div>
                <div className="form-group">
                  <label htmlFor="roleName">Role Name</label>
                  <input
                    id="roleName"
                    className="form-control"
                    type="text"
                    value={rowData.roleName}
                    onChange={(e) =>{
                      e.preventDefault();
                      const newRoleName = e.target.value;
                      setRowData((prevRowData) => ({
                        ...prevRowData,
                        roleName: newRoleName,
                      }));
                    }}
                    style={{
                      height: "50px"
                    }}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">
                  Close
                </button>
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-primary"
                  onClick={() => {
                    let payload = {
                      roleName: rowData.roleName,
                      groupId: rowData.groupId,
                      permissions: rowData.permissions.map((d) =>  {
                        if(d && permissionIDMap[d]) { 
                          return permissionIDMap[d] 
                        }
                      })
                    };
                    updateRole(rowData.roleId, payload).then((res) => {   
                      toast.success(toastNotifications.roles.updateSuccess);    
                      fetchData();
                    }).catch(error=>{
                      toast.error(toastNotifications.roles.updateFail);    
                    });
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
      </>
    );
  } else {
    return (
      <>
        <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
          <div className="card">
            <div className="card-body text-center">
              <h2 className="card-title">
                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                Access Denied
              </h2>
              <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}