import React, { useRef, useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";
import { getCandidate, getFeedbackPDF, getPartialFeedbackPDF } from '../../service/api';

import { toast } from "react-toastify";

export default function FeedbackPDF() {

    const { candidateId } = useParams();
    const [ candidateObj, setCandidateData ] = useState({});

    useEffect(() => {
        getCandidate(candidateId).then((res) => {
          console.log(res.data)
          setCandidateData(res.data);
        });
    }, [candidateId]);


  return (
    <>
      <div
        className="container"
        style={{
          background: "#F6F6F6 0% 0% no-repeat padding-box",
          borderRadius: "4px",
          opacity: "1",
          marginTop: "8px",
          marginBottom: "8px",
        }}
      >
        <div className="row">
          <div className="col">
            <button
              className="btn btn-primary"
              onClick={async (e) => {
                e.preventDefault(true)
                try {
                  let data = await getFeedbackPDF(candidateObj._id);
                  const blobUrl = URL.createObjectURL(data);
                  const link = document.createElement('a');
                  link.href = blobUrl;
                  link.download = `${candidateObj.candidateId}_${candidateObj.candidateName}_feedback.pdf`;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  URL.revokeObjectURL(blobUrl);
                } catch (error) {
                  toast.error('Unable to download Feedback PDF..!')
                }
              }}
            >
              <i className="fa fa-download"></i>{ "  "}Full Download
            </button>
          </div>
          <div className="col">
            <button
              className="btn btn-primary"
              style={{float: 'right'}}
              onClick={async (e) => {
                e.preventDefault(true)
                try {
                  let data = await getPartialFeedbackPDF(candidateObj._id);
                  const blobUrl = URL.createObjectURL(data);
                  const link = document.createElement('a');
                  link.href = blobUrl;
                  link.download = `${candidateObj.candidateId}_${candidateObj.candidateName}_partial_feedback.pdf`;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  URL.revokeObjectURL(blobUrl);
                } catch (error) {
                  toast.error('Unable to download Partial Feedback PDF..!')
                }
              }}
            >
              <i className="fa fa-download"></i>{ "  "} Partial Download
            </button>
          </div>
        </div>
      </div>
      <br></br>
      <div
        className="container feedbackContent"
        style={{
          background: "#F6F6F6 0% 0% no-repeat padding-box",
          borderRadius: "4px",
          opacity: "1",
          marginTop: "8px",
        }}
      >
        
        <div className="row" style={{ padding: "2%", paddingBottom: "0%" }}>
          <div
            className="col-3 d-flex align-items-center"
            style={{ color: "#3E67B1" }}
          >
            <Typography variant="h6" sx={{ my: 2 }}>
              <img style={{width: '200px'}} src="/Images/panls-logo-white.png" alt="Logo"/>
            </Typography>
          </div>
          <h4
            className="col-5 text-center"
            style={{
              marginTop: "11px",
            }}
          >
            Evaluation and Analysis Report
          </h4>
          <div
            className="col-4"
            style={{
              textAlign: "left",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {candidateObj.candidateName}
            <br />
            {candidateObj.jobName} ({candidateObj.currentCountry})
            <br />
            {candidateObj.candidateEmailId} | {candidateObj.contactNumber}
          </div>
        </div>
        
        {/* divider */}
        <div
          style={{
            borderTop: `2px solid #A9BAD9`, // Adjust the width and style as needed
            width: "100%", // Adjust the width based on your layout
            marginTop: "10px",
          }}
        ></div>
        
        {/* Overall Feedback */}
        <h4 style={{ margin: "19px 0px 12px 0px" }}>Overall Feedback</h4>

        <div
          className="overall-feedback"
          style={{
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            borderRadius: "4px",
            opacity: 1,
            marginTop: "12px",
          }}
        >
          <div className="row" style={{ padding: "1%" }}>
            <div className="col-4">
              <div className="d-flex align-items-center">
                <i
                  className="fa fa-solid fa-circle-check fa-lg mr-2"
                  style={{ color: "#23B96F" }}
                ></i>
                &nbsp;&nbsp;
                <div>
                  <label style={{width: "200px"}} htmlFor="exampleInput">Overall Feedback</label>
                  <h5 id="exampleInput" style={{ color: "#23B96F" }}>
                    {candidateObj.overallFeedback}
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-6">
              <label htmlFor="exampleInput">Recommendations</label>
              <h6 id="exampleInput">
                {candidateObj.recommendations}
              </h6>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        {/* Skill Assessment */}
        <h4 style={{ margin: "19px 0px 12px 0px" }}>Skill Assessment</h4>
        
        <div
          className="container"
          style={{
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            borderRadius: "4px",
            opacity: 1,
          }}
        >
          { 
            candidateObj?.dynamicSkills ? candidateObj.dynamicSkills.map(each => {
              return (
                <div key={each.candidateId} className="row" style={{ paddingLeft: "3%", paddingRight: "3%" }}>
                  <div className="col-2 d-flex align-items-center">
                    <h5>{each.skillTopic}</h5>
                  </div>
                  <div className="col-9" style={{ padding: "1%" }}>
                    <div className="row">
                      <div className="col-3">
                        <label htmlFor="exampleInput" style={{ color: "#908686" }}>
                          Expected Profeciency
                        </label>
                        <h6 id="exampleInput">{each.expectedProficiency}</h6>
                      </div>
                      <div className="col-3">
                        <label htmlFor="exampleInput" style={{ color: "#908686" }}>
                          Possess Profeciency
                        </label>
                        <h6 id="exampleInput">{each.possessProficiency}</h6>
                      </div>
                      <div className="col-3">
                        <label htmlFor="exampleInput" style={{ color: "#908686" }}>
                          Rating
                        </label>
                        <h6 id="exampleInput">
                          <Rating name="read-only" value={each.rating} readOnly />
                        </h6>
                      </div>
                      <div className="col-3">
                        <label htmlFor="exampleInput" style={{ color: "#908686" }}>
                          Remarks/Comments
                        </label>
                        <h6 id="exampleInput">
                          {each.comments}
                        </h6>
                      </div>
                    </div>
                    {/* <div className="row" style={{ marginTop: "3px" }}>
                      <div className="col">
                        <label htmlFor="exampleInput" style={{ color: "#908686" }}>
                          Remarks/Comments
                        </label>
                        <h6 id="exampleInput">
                          {each.comments}
                        </h6>
                      </div>
                    </div> */}
                  </div>
                </div>
              );
            }): null
          }
        </div>
        
        <br></br>
        <br></br>

        {/* Standard Competency Description */}
        <h4 style={{ margin: "12px 0px 12px 0px" }}>
          Standard Competency Description
        </h4>
        <div
          className="container"
          style={{
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            borderRadius: "4px",
            opacity: 1,
            padding: "1%",
          }}
        >
          <div className="row" style={{ marginTop: "10px", color: "#989898" }}>
            <div className="col-6"
            style={{
              paddingLeft: "50px",
            }}
            >Topic</div>
            <div className="col-2" 
              style={{ textAlign: "left" }}
            >
              Rating
            </div>
            <div
              className="col-4"
              style={{
                paddingLeft: "0px",
              }}
            >
              Remarks/Comments
            </div>
          </div>
          { 
            candidateObj?.standardSkills ? candidateObj?.standardSkills.map(each => {
              return (
                <div key={each.candidateId} className="row" style={{ paddingLeft: "3%", paddingRight: "3%", marginTop: "10px" }}>
                  <div className="col-6">
                    {each.skillTopic}
                  </div>
                  <div className="col-2" style={{ textAlign: "left" }}>
                    <Rating name="read-only" value={each.rating} readOnly />
                  </div>
                  <div className="col-4">
                    {each.comments}
                  </div>
                </div>
              );
            }): null
          }
          
          <br></br>
          <br></br>
        </div>

        <br></br>
        <br></br>

        {/* Recorded Meeting Link */}
        <h4 style={{ margin: "12px 0px 12px 0px" }}>
          Recorded Meeting
        </h4> 
        
        <div
          className="container"
          style={{
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            borderRadius: "4px",
            opacity: 1,
            padding: "1%",
          }}
        >
          <a href={candidateObj.recordedVideoUrl} target="_blank" rel="noopener noreferrer">
                <img 
                  src="/Images/video_thumbnail.png" 
                  alt="Video Thumbnail" 
                  style={{
                    width: "50%",
                    height: "300px",
                    marginLeft: "22%"
                  }}
                />
            </a>
        </div>
        
      </div>
      <br></br>
      <br></br>
      <br></br>
    </>
  );
}