import "./App.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer } from "react-toastify";

import AdminPage from "./Components/Admin/Admin";
import PanelPage from "./Components/Panel/Panel";
import CustomerPage from "./Components/Customer/Customer";
import UserPage from "./Components/User/User";

import Dashboard from "./Components/Dashboard/Dashboard";
import PanelDashboard from "./Components/Dashboard/PanelDashboard";
import CustomerDashboard from "./Components/Dashboard/CustomerDashboard";
import VendorDashboard from "./Components/Dashboard/VendorDashboard";
import EditProfile from "./Components/EditProfile";
import EditUser from "./Components/EditUser";
import ForgotPassword from "./Components/Authentication/ForgotPassword";
import ChangePassword from "./Components/Authentication/ChangePassword";
import LandingPage from "./Components/LandingPage";
// import Login from "./Components/Authentication/Login";
import LoginPage from "./Components/Authentication/LoginPage";
import Permissions from "./Components/Authorization/Permissions";
import ResetPassword from "./Components/Authentication/ResetPassword";
import Users from "./Components/Users";
import Panels from "./Components/Panels";
import Vendors from "./Components/Vendors";
import Customers from "./Components/Customers";
import CustomerAdmins from "./Components/CustomerAdmins";
import RequireAuth from "./Components/RequireAuth";
import UpdateTicket from "./Components/UpdateTicket/UpdateTicket";

import CustomerRequestForm from "./Components/CustomerRequests/CustomerRequestForm";
import CustomerRequests from "./Components/CustomerRequests/CustomerRequests";
import CustomerTickets from "./Components/CustomerRequests/CustomerTickets";
import EditCustomerTicket from "./Components/CustomerRequests/EditCustomerTicket";
import DeliveryUnits from "./Components/DeliveryUnits/DeliveryUnits";
import EditDeliveryUnit from "./Components/DeliveryUnits/EditDeliveryUnit";
import AddDeliveryUnit from "./Components/DeliveryUnits/AddDeliveryUnit";

import Groups from "./Components/Authorization/Groups";
import Roles from "./Components/Authorization/Roles";
import Tickets from "./Components/Tickets";
import AddUser from "./Components/AddUser";
import Countries from "./Components/MasterData/Countries";
import States from "./Components/MasterData/States";
import Cities from "./Components/MasterData/Cities";
import Universities from "./Components/MasterData/Universities";
import Skills from "./Components/MasterData/Skills";
import SkillFamilies from "./Components/MasterData/SkillFamilies";
import SkillMappings from "./Components/MasterData/SkillMappings";
import Designations from "./Components/MasterData/Designations";
import ProfileTypes from "./Components/MasterData/ProfileTypes";
import PanelOffer from "./Components/PanelOffer";
import SlotAvailability from "./Components/SlotAvailiability/SlotAvailability";

import Calendar from "./Components/Calendar/Calendar";
import AdminCalendar from "./Components/Calendar/AdminCalendar";
import CustomerCalendar from "./Components/Calendar/CustomerCalendar";

import Jobs from "./Components/Job/Jobs";
import AddJob from "./Components/Job/AddJob";
import EditJob from "./Components/Job/EditJob";
import ViewJob from "./Components/Job/ViewJob";

import Candidates from "./Components/Candidate/Candidates";
import AddCandidate from "./Components/Candidate/AddCandidate";
import AddCandidate2 from "./Components/Candidate/AddCandidate2";
import EditCandidate from "./Components/Candidate/EditCandidate";
import ViewCandidate from "./Components/Candidate/ViewCandidate";
import ViewCandidateForPanel from "./Components/Candidate/ViewCandidateForPanel";

import ClaimedCandidates from "./Components/Candidate/ClaimedCandidates";
import ApprovePanelClaims from "./Components/Candidate/ApprovePanelClaims";

import InterviewSchedules from "./Components/Interviews/InterviewSchedules";
import PanelSchedules from "./Components/Interviews/PanelSchedules";
import CustomerSchedules from "./Components/Interviews/CustomerSchedules";
import EditInterviewSchedule from "./Components/Interviews/EditInterviewSchedule";
import ViewInterviewSchedule from "./Components/Interviews/ViewInterviewSchedule";
import CompletedInterviews from "./Components/Interviews/CompletedInterviews";
import CompletedPanelSchedules from "./Components/Interviews/CompletedPanelSchedules";
import CompletedCustomerSchedules from "./Components/Interviews/CompletedCustomerSchedules";

import FeedbackPDF from "./Components/Candidate/FeedbackPDF";
import PanelistInvoice from "./Components/Invoice/PanelistInvoice";
import ListPanelist from "./Components/Invoice/ListPanelist";
import {useEffect} from "react";
import CandidateStatus from "./Components/Candidate/CandidateStatus";
import CustomerReports from "./Components/Reports/CustomerReports";
import AdminInterviews from "./Components/Interviews/AdminInterviews";
import PanelistInvoices from "./Components/Invoice/PanelistInvoices";
import Invoice from "./Components/Invoice/Invoice";

import PanelSearch from "./Components/PanelSearch/PanelSearch";
import PanelSearchDetails from "./Components/PanelSearch/PanelSearchDetails";

function App() {
  useEffect(() => {
    window.document.body.style.zoom = "90%";
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/register" element={<LandingPage />} />
          {/* <Route path="/login" element={<Login />} /> */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/panelOffer/:userHash" element={<PanelOffer/>}/>
          <Route
            path="/admin/*"
            element={
              <RequireAuth >
                <AdminPage />
              </RequireAuth>
            }
          >
            <Route path="" element={<Navigate to="dashboard" />} />
            <Route path="dashboard" element={<Dashboard />} requiredPermissions="VIEW_ADMIN_DASHBOARD" />

            <Route path="groups" element={<Groups />} requiredPermissions="LIST_GROUPS"  />
            <Route path="permissions" element={<Permissions />} requiredPermissions="LIST_PERMISSIONS"  />
            <Route path="roles" element={<Roles />} requiredPermissions="LIST_ROLES"  />

            <Route path="countries" element={<Countries />} requiredPermissions="LIST_MASTER_DATA" />
            <Route path="states" element={<States />} requiredPermissions="LIST_MASTER_DATA" />
            <Route path="cities" element={<Cities />} requiredPermissions="LIST_MASTER_DATA" />
            <Route path="universities" element={<Universities />} requiredPermissions="LIST_MASTER_DATA" />
            <Route path="skills" element={<Skills />} requiredPermissions="LIST_MASTER_DATA" />
            <Route path="skillFamilies" element={<SkillFamilies />} requiredPermissions="LIST_MASTER_DATA" />
            <Route path="skillMappings" element={<SkillMappings />} requiredPermissions="LIST_MASTER_DATA" />
            <Route path="designations" element={<Designations />} requiredPermissions="LIST_MASTER_DATA" />
            <Route path="profileTypes" element={<ProfileTypes />} requiredPermissions="LIST_MASTER_DATA" />

            <Route path="tickets/:filterOptions" element={<Tickets />} requiredPermissions="LIST_TICKETS" />
            <Route path="updateTicket/:ticketId" element={<UpdateTicket />} requiredPermissions="EDIT_TICKET" />

            <Route path="customerTickets" element={<CustomerTickets />} requiredPermissions="LIST_CUSTOMER_TICKETS" />
            <Route path="editCustomerTicket/:cusReqId" element={<EditCustomerTicket />} requiredPermissions="EDIT_CUSTOMER_TICKET" />

            <Route path="users" element={<Users />} requiredPermissions="LIST_IPAANEL_USERS" />
            <Route path="panels" element={<Panels />} requiredPermissions="LIST_PANELS" />
            <Route path="vendors" element={<Vendors />} requiredPermissions="LIST_VENDORS" />
            <Route path="customers" element={<Customers />} requiredPermissions="LIST_CUSTOMERS" />
            <Route path="customerAdmins" element={<CustomerAdmins />} requiredPermissions="LIST_CUSTOMER_ADMINS" />
            <Route path="addUser" element={<AddUser />} requiredPermissions="CREATE_USER" />
            <Route path="editUser/:profileType/:userMongoId" element={<EditUser />} requiredPermissions="EDIT_USER" />

            <Route path="calendar" element={<AdminCalendar />} requiredPermissions="VIEW_ADMIN_CALENDAR" />
            <Route path="interviewSchedules/:filterOptions" element={<InterviewSchedules />} requiredPermissions="LIST_ADMIN_SCHEDULES" />
            <Route path="Interviews/:filterOptions" element={<AdminInterviews />} requiredPermissions="LIST_ADMIN_SCHEDULES" />
            <Route path="approvePanelClaims/:candidateId/:customerId" element={<ApprovePanelClaims />} requiredPermissions="APPROVE_PANEL_CLAIMS" />
            <Route path="downloadPDF/:candidateId" element={<FeedbackPDF />} requiredPermissions="DOWNLOAD_FEEDBACK" />
            <Route path="adminInvoice" element={<PanelistInvoices/>} />
            <Route path="panelSearch" element={<PanelSearch/>} />
            <Route path="panelSearchDetails/:panelEmailId" element={<PanelSearchDetails/>} />

          </Route>

          <Route
            path="/user/*"
            element={
              <RequireAuth >
                <UserPage />
              </RequireAuth>
            }
          >
            {/* <Route path="adminInvoice" element={<AdminInvoice />} />
            <Route path="customerInvoice" element={<CustomerInvoice />} /> */}

            <Route path="changePassword" element={<ChangePassword />}  />
            <Route path="editProfile/:profileType/:userMongoId" element={<EditProfile />} />

            <Route path="vendorDashboard" element={<VendorDashboard />} requiredPermissions="VENDOR_DASHBOARD" />

            <Route path="jobs/:filterOptions" element={<Jobs />} requiredPermissions="LIST_JOBS" />
            <Route path="addJob" element={<AddJob />} requiredPermissions="CREATE_JOB" />
            <Route path="editJob/:jobId/:customerId" element={<EditJob />} requiredPermissions="EDIT_JOB" />
            <Route path="viewJob/:jobId/:customerId" element={<ViewJob />} requiredPermissions="VIEW_JOB" />

            <Route path="candidates/:filterOptions" element={<Candidates />} requiredPermissions="LIST_CANDIDATES" />
            <Route path="addCandidate" element={<AddCandidate2 />} requiredPermissions="CREATE_CANDIDATE" />
            <Route path="editCandidate/:candidateId/:customerId" element={<EditCandidate />} requiredPermissions="EDIT_CANDIDATE" />
            <Route path="viewCandidate/:candidateId/:customerId" element={<ViewCandidate />} requiredPermissions="VIEW_CANDIDATE" />

            <Route path="claimedCandidates" element={<ClaimedCandidates />} requiredPermissions="LIST_CLAIMED_CANDIDATES" />
            <Route path="editInterviewSchedule/:scheduleId/:customerId" element={<EditInterviewSchedule />} requiredPermissions="EDIT_SCHEDULED_INTERVIEW" />
            <Route path="viewInterviewSchedule/:scheduleId/:customerId" element={<ViewInterviewSchedule />} requiredPermissions="VIEW_SCHEDULED_INTERVIEW" />
          </Route>

          <Route
            path="/panel/*"
            element={
              <RequireAuth >
                <PanelPage />
              </RequireAuth>
            }
          >

            <Route path="" element={<Navigate to="panelDashboard" />} />
            <Route path="panelDashboard" element={<PanelDashboard />} requiredPermissions="VIEW_PANEL_DASHBOARD" />
            <Route path="slotAvailability" element={<SlotAvailability />} requiredPermissions="VIEW_SLOT_AVAILABILITY" />
            <Route path="calendar" element={<Calendar />} requiredPermissions="VIEW_PANEL_CALENDAR" />
            <Route path="interviewSchedules/:filterOptions" element={<PanelSchedules />} requiredPermissions="LIST_PANEL_SCHEDULES" />
            <Route path="closedInterviews/:filterOptions" element={<CompletedPanelSchedules />} requiredPermissions="LIST_PANEL_SCHEDULES" />
            <Route path="viewCandidateForPanel/:candidateId/:customerId" element={<ViewCandidateForPanel />} requiredPermissions="VIEW_CANDIDATE_FOR_PANEL" />
            <Route path="PanelInvoice/:panelEmailId" element={<PanelistInvoice/>} />
            <Route path="invoice/" element={<Invoice/>}/>
          </Route>
          <Route
            path="/customer/*"
            element={
              <RequireAuth >
                <CustomerPage />
              </RequireAuth>
            }
          >
            <Route path="" element={<Navigate to="customerDashboard" />} />
            <Route path="customerDashboard" element={<CustomerDashboard />} requiredPermissions="VIEW_CUSTOMER_DASHBOARD" />
            <Route path="calendar" element={<CustomerCalendar />} requiredPermissions="VIEW_CUSTOMER_CALENDAR" />
            <Route path="interviewSchedules/:filterOptions" element={<CustomerSchedules />} requiredPermissions="LIST_CUSTOMER_SCHEDULES" />
            <Route path="closedInterviews/:filterOptions" element={<CompletedCustomerSchedules />} requiredPermissions="LIST_CUSTOMER_SCHEDULES" />
            <Route path="customerRequests" element={<CustomerRequests />} requiredPermissions="LIST_CUSTOMER_REQUESTS" />
            <Route path="customerRequestForm" element={<CustomerRequestForm />} requiredPermissions="CREATE_CUSTOMER_REQUEST" />
            <Route path="deliveryUnits" element={<DeliveryUnits />} requiredPermissions="LIST_DELIVERY_UNITS" />
            <Route path="addDeliveryUnit" element={<AddDeliveryUnit />} requiredPermissions="CREATE_DELIVERY_UNIT" />
            <Route path="editDeliveryUnit/:delUnitId" element={<EditDeliveryUnit />} requiredPermissions="EDIT_DELIVERY_UNIT" />
            <Route path="candidateStatus" element={<CandidateStatus />} requiredPermissions="LIST_CUSTOMER_SCHEDULES" />
            <Route path="reports" element={<CustomerReports />}/>
            </Route>

        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
