import React, { useRef, useState, useEffect } from "react";
import { useParams, useLocation,useNavigate  } from "react-router-dom";
import { getDeliveryUnit, updateDeliveryUnit } from "../../service/api";
import { Grid, TextField, Checkbox } from "@mui/material";
import { toast } from "react-toastify";
import { 
    Box
} from '@mui/material';

const EditDeliveryUnit = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');
  
  const { delUnitId } = useParams();
  const formRef = useRef(null);
  const [delUnitData, setBusUnitData] = useState({});

  const navigate = useNavigate();

  function validateDeliveryUnitData(data){
    let result = {isValid:true,}
    let keyLabels = {
      'deliveryUnit': "Delivery Unit",
      'accountName': "Account Name",
      'lineOfBusiness': "Line Of Business",
      'vertical': "Vertical",
      'customerId': "Customer Company",
    };
    
    let required_fields = [
      'deliveryUnit',
      'accountName',
      'lineOfBusiness',
      'vertical',
      'customerId',
    ];
    required_fields.forEach(key=>{
      if(!data[key]){
        result =  {isValid:false,mgs:`Field Missing ${keyLabels[key]}`}
      }
    })
    return result;
  }

  useEffect(() => {
    getDeliveryUnit(delUnitId).then((res) => {
      setBusUnitData(res.data);
    });
  }, [delUnitId]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    navigate(-1);
  };

  if (hasAllPermissions || userPermissions.includes('EDIT_DELIVERY_UNIT')) {

    return (
      <>
      <Box sx={{ display: "flex", flexDirection: 'column' }}>
        <div>
            <Box
                component="main"
                style={{ backgroundColor: "#F4F6FB" }}
                sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
              >
              <form ref={formRef} onSubmit={handleFormSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <label htmlFor="delUnitCode">DU Code</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter DU Code"
                        name="delUnitCode"
                        disabled={true}
                        defaultValue={delUnitData.delUnitCode}
                        style={{
                          height: "50px",
                          background: "#FFFFFF 0% 0% no-repeat padding-box",
                          border: "1px solid #C7C7C7",
                          borderRadius: "6px",
                          opacity: "1",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label htmlFor="customerCompany">Company Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Company Name"
                        name="customerCompany"
                        disabled={true}
                        defaultValue={delUnitData.customerCompany}
                        style={{
                          height: "50px",
                          background: "#FFFFFF 0% 0% no-repeat padding-box",
                          border: "1px solid #C7C7C7",
                          borderRadius: "6px",
                          opacity: "1",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label htmlFor="vertical">Vertical</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Vertical"
                        name="vertical"
                        defaultValue={delUnitData.vertical}
                        style={{
                          height: "50px",
                          background: "#FFFFFF 0% 0% no-repeat padding-box",
                          border: "1px solid #C7C7C7",
                          borderRadius: "6px",
                          opacity: "1",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label htmlFor="lineOfBusiness">Line Of Business</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter LOB"
                        name="lineOfBusiness"
                        defaultValue={delUnitData.lineOfBusiness}
                        style={{
                          height: "50px",
                          background: "#FFFFFF 0% 0% no-repeat padding-box",
                          border: "1px solid #C7C7C7",
                          borderRadius: "6px",
                          opacity: "1",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label htmlFor="accountName">Account Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Account Name"
                        name="accountName"
                        defaultValue={delUnitData.accountName}
                        style={{
                          height: "50px",
                          background: "#FFFFFF 0% 0% no-repeat padding-box",
                          border: "1px solid #C7C7C7",
                          borderRadius: "6px",
                          opacity: "1",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label htmlFor="name">Delivery Unit Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Delivery Unit"
                        name="deliveryUnit"
                        defaultValue={delUnitData.deliveryUnit}
                        style={{
                          height: "50px",
                          background: "#FFFFFF 0% 0% no-repeat padding-box",
                          border: "1px solid #C7C7C7",
                          borderRadius: "6px",
                          opacity: "1",
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <label htmlFor="remarks">Remarks</label>
                      <TextField
                        placeholder="Enter Remarks"
                        name="remarks"
                        defaultValue={delUnitData?.remarks || ''}
                        multiline
                        rows={2}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                <br />

              <div className="row justify-content-center">
                <button
                  className="btn btn-outline-primary me-2"
                  style={{
                    border: "1px solid #3E67B1",
                    borderRadius: " 4px",
                    opacity: "1",
                    width: "96px",
                    height: "50px",
                  }}
                  onClick={() => {
                    navigate('/customer/deliveryUnits')
                  }}
                >
                  BACK
                </button>
                <button
                  onClick={() => {
                    const fd= new FormData(formRef.current);
                    const formDataJson ={}
                    Array.from(fd.entries()).reduce((a,b)=>{
                      a[b[0]] = b[1];
                      return a
                    }, formDataJson)
                    let updatedObj = {...delUnitData, ...formDataJson}
                    
                    const validation = validateDeliveryUnitData(updatedObj);
                    if(!validation.isValid){
                      toast.error(validation.mgs)
                      return;
                    }
                    
                    updateDeliveryUnit(delUnitId, updatedObj).then((res) => {
                      toast.success('Updated the Delivery Unit.!');
                      navigate('/customer/deliveryUnits');
                    }).catch(err=>{
                      toast.error('Unable to update Delivery Unit, Please try again.!');
                    });
                  }}
                  type="button"
                  className="btn btn-primary"
                  style={{
                    border: "1px solid #3E67B1",
                    borderRadius: " 4px",
                    opacity: "1",
                    width: "200px",
                    height: "50px",
                    display: (hasAllPermissions || userPermissions.includes('EDIT_DELIVERY_UNIT')) ? 'inline' : 'none'
                  }}
                >
                  Update Delivery Unit
                </button>
              </div>
              </form>
            </Box>
        </div>

      </Box>
      <br></br>
      <br></br>
      
    </>
    );

  } else {
    return (
      <>
        <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
          <div className="card">
            <div className="card-body text-center">
              <h2 className="card-title">
                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                Access Denied
              </h2>
              <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
};

export default EditDeliveryUnit;
        