import React from 'react';

export default function DashboardCard(
    {
        title, subHeading1, subHeading2,
        subHeading3, value1, value2, value3,
        // status,startDate,endDate
        navigateTo,iconClass
    }) {

    return (
        <div className="col-3"
             style={{
                 borderRadius: '8px',
                 marginTop: '10px 0px',
                 boxShadow: "0px 3px 6px #00000029",
                 border: "1px solid #F4F4F4",
                 padding: '0px',
                 marginRight: '8px',
             }}
        ><a href={`${navigateTo}`}>
            <div style={{
                backgroundColor: '#EDF3FF', display: 'flex',
                minHeight: '50px',
                justifyContent: 'space-between',
                paddingInline: '16px',
                alignItems: 'center',
            }}>
                {
                    title === 'Interviews' ? <img src="/interviews.svg" alt="interviews"/> :
                        title === 'Jobs' ? <img src="/jobs.svg" alt="jobs"/> :
                            title === 'Candidates' ? <img src="/candidate.svg" alt="candidates"/> :
                                title === 'Candidate Status' ? <img src="/candidatestatus.svg" alt="candidatestatus"/> :
                                    null
                }
                {title}
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                     className="bi bi-arrow-right" viewBox="0 0 16 16">
                    <path fillRule="evenodd"
                          d="M11.354 8.354a.5.5 0 0 1 0 .707l-4 4a.5.5 0 0 1-.708 0l.707-.707L10.293 9H1.5a.5.5 0 0 1 0-1h8.793l-3.147-3.146a.5.5 0 0 1 .708-.707l4 4a.5.5 0 0 1 0 .707z"/>
                </svg>

            </div>
        </a>
            <div className="row" style={{
                backgroundColor: "#FFFFFF",
                marginInline: '0px',
                padding: '8px', boxSizing: 'border-box',
                borderRadius: '0px 0px 10px 10px',
            }}>
                <a className="col-4"
                   href={`${navigateTo}?dashboardFilter=${subHeading1}`}
                    style={{
                    borderRight: '1px solid #00000029',
                    display: subHeading1 ? 'block' : 'none',
                }}>
                    <p className="card-subheading text-center">{subHeading1}</p>
                    <p className="text-center "
                       style={{
                           fontWeight: 'bold',
                       }}
                    >{value1}</p>
                </a>
                <a className={subHeading1 ? "col-4" : "col-6"}
                    href = {`${navigateTo}?dashboardFilter=${subHeading2}`}
                     style={{
                         borderRight: '1px solid #00000029',
                     }}
                >
                    <p className="card-subheading text-center">{subHeading2}</p>
                    <p className="text-center"
                       style={{
                           fontWeight: 'bold',
                       }}
                    >{value2}</p>
                </a>
                <a className={subHeading1 ? "col-4" : "col-6"}
                    href = {`${navigateTo}?dashboardFilter=${subHeading3}`}
                >
                    <p className="card-subheading text-center">{subHeading3}</p>
                    <p className="text-center"
                       style={{
                           fontWeight: 'bold',
                       }}
                    >{value3}</p>
                </a>
            </div>
        </div>

    );
}
