import { axiosInstance,updateToken } from "./http";

getData();

function getData() {
    axiosInstance.get('/health').then((response) => {
        console.log(response);
    }).catch((error) => {
        console.log(error);
    });
}

export const getAdminDashboardMetrics = async (filters={ "startDate" : "",  "endDate": "" }) => {
  const response = await axiosInstance.get(`/dashboard/adminMetrics/${JSON.stringify(filters)}`);
  return response.data;
};


export const getCustomerDashboardMetrics = async (filters={ "startDate" : "",  "endDate": "" }) => {
  const response = await axiosInstance.get(`/dashboard/customerMetrics/${JSON.stringify(filters)}`);
  return response.data;
};


export const getVendorDashboardMetrics = async (filters={ "startDate" : "",  "endDate": "" }) => {
  const response = await axiosInstance.get(`/dashboard/vendorMetrics/${JSON.stringify(filters)}`);
  return response.data;
};



export const getPanelDashboardMetrics = async (panelEmailId,filters={ "startDate" : "",  "endDate": "" }) => {
  const response = await axiosInstance.get(`/dashboard/panelMetrics/${panelEmailId}/${JSON.stringify(filters)}`);
  return response.data;
};


export const getGroups = async () => {
  const response = await axiosInstance.get('/groups');
  return response.data;
};

export const getGroupById = async (groupId) => {
  const response = await axiosInstance.get(`/group/${groupId}`);
  return response.data;
};

export const createGroup = async (group) => {
  const response = await axiosInstance.post('/groups', group);
  return response.data;
};

export const updateGroup = async (groupId, group) => {
  const response = await axiosInstance.put(`/group/${groupId}`, group);
  return response.data;
};

export const deleteGroup = async (groupId) => {
  const response = await axiosInstance.delete(`/group/${groupId}`);
  return response.data;
};

export const getPermissions = async () => {
  const response = await axiosInstance.get('/permissions');
  return response.data;
};

export const getPermissionById = async (permissionId) => {
  const response = await axiosInstance.get(`/permission/${permissionId}`);
  return response.data;
};

export const createPermission = async (permission) => {
  const response = await axiosInstance.post('/permissions', permission);
  return response.data;
};

export const updatePermission = async (permissionId, permission) => {
  const response = await axiosInstance.put(`/permission/${permissionId}`, permission);
  return response.data;
};

export const deletePermission = async (permissionId) => {
  const response = await axiosInstance.delete(`/permission/${permissionId}`);
  return response.data;
};

export const getRoles = async () => {
  const response = await axiosInstance.get('/roles');
  return response.data;
};

export const getRoleById = async (roleId) => {
  const response = await axiosInstance.get(`/role/${roleId}`);
  return response.data;
};

export const createRole = async (role) => {
  const response = await axiosInstance.post('/roles', role);
  return response.data;
};

export const updateRole = async (roleId, role) => {
  const response = await axiosInstance.put(`/role/${roleId}`, role);
  return response.data;
};

export const deleteRole = async (roleId) => {
  const response = await axiosInstance.delete(`/role/${roleId}`);
  return response.data;
};


export const getCountries = async () => {
  const response = await axiosInstance.get('/countries');
  return response.data;
};

export const getCountryById = async (countryId) => {
  const response = await axiosInstance.get(`/country/${countryId}`);
  return response.data;
};

export const createCountry = async (country) => {
  const response = await axiosInstance.post('/countries', country);
  return response.data;
};

export const updateCountry = async (countryId, country) => {
  const response = await axiosInstance.put(`/country/${countryId}`, country);
  return response.data;
};

export const deleteCountry = async (countryId) => {
  const response = await axiosInstance.delete(`/country/${countryId}`);
  return response.data;
};


export const getStates = async () => {
  const response = await axiosInstance.get('/states');
  return response.data;
};

export const getStateById = async (stateId) => {
  const response = await axiosInstance.get(`/state/${stateId}`);
  return response.data;
};

export const createState = async (state) => {
  const response = await axiosInstance.post('/states', state);
  return response.data;
};

export const updateState = async (stateId, state) => {
  const response = await axiosInstance.put(`/state/${stateId}`, state);
  return response.data;
};

export const deleteState = async (stateId) => {
  const response = await axiosInstance.delete(`/state/${stateId}`);
  return response.data;
};

export const getStatesByCountryCode = async (countryCode) => {
  try {
    const response = await axiosInstance.get(`/states/countryCode/${countryCode}`);
    return response.data;

  } catch (error) {
    return {"data":[]};
  }
};


export const getCities = async () => {
  const response = await axiosInstance.get('/cities');
  return response.data;
};

export const getCityById = async (cityId) => {
  const response = await axiosInstance.get(`/city/${cityId}`);
  return response.data;
};

export const createCity = async (city) => {
  const response = await axiosInstance.post('/cities', city);
  return response.data;
};

export const updateCity = async (cityId, city) => {
    const response = await axiosInstance.put(`/city/${cityId}`, city);
    return response.data;
};

export const deleteCity = async (cityId) => {
    const response = await axiosInstance.delete(`/city/${cityId}`);
    return response.data;
  };

  export const getCitiesByStateCode = async (countryCode, stateCode) => {
    try {
    const response = await axiosInstance.get(`/cities/stateCode/${stateCode}`);
    return response.data;
    } catch (error) {
     return {"data":[]};
    }
  };

  export const getUniversities = async () => {
    const response = await axiosInstance.get('/universities');
    return response.data;
  };

  export const getUniversityById = async (universityId) => {
    const response = await axiosInstance.get(`/university/${universityId}`);
    return response.data;
  };

  export const createUniversity = async (university) => {
    const response = await axiosInstance.post('/universities', university);
    return response.data;
  };

  export const updateUniversity = async (universityId, university) => {
    const response = await axiosInstance.put(`/university/${universityId}`, university);
    return response.data;
  };

  export const deleteUniversity = async (universityId) => {
    const response = await axiosInstance.delete(`/university/${universityId}`);
    return response.data;
  };


  export const getProfileTypes = async () => {
    const response = await axiosInstance.get('/profileTypes');
    return response.data;
  };

  export const getProfileTypeById = async (profileTypeId) => {
    const response = await axiosInstance.get(`/profileType/${profileTypeId}`);
    return response.data;
  };

  export const createProfileType = async (profileType) => {
    const response = await axiosInstance.post('/profileTypes', profileType);
    return response.data;
  };

  export const updateProfileType = async (profileTypeId, profileType) => {
    const response = await axiosInstance.put(`/profileType/${profileTypeId}`, profileType);
    return response.data;
  };

  export const deleteProfileType = async (profileTypeId) => {
    const response = await axiosInstance.delete(`/profileType/${profileTypeId}`);
    return response.data;
  };

  export const getSkills = async () => {
    const response = await axiosInstance.get('/skills');
    return response.data;
  };

  export const getSkillById = async (skillId) => {
    const response = await axiosInstance.get(`/skill/${skillId}`);
    return response.data;
  };

  export const createSkill = async (skill) => {
    const response = await axiosInstance.post('/skills', skill);
    return response.data;
  };

  export const updateSkill = async (skillId, skill) => {
    const response = await axiosInstance.put(`/skill/${skillId}`, skill);
    return response.data;
  };

  export const deleteSkill = async (skillId) => {
    const response = await axiosInstance.delete(`/skill/${skillId}`);
    return response.data;
  };


  // export const getSkillFamilies = async () => {
  //   const response = await axiosInstance.get('/skillFamilies');
  //   return response.data;
  // };

  export const getSkillFamilyById = async (skillFamilyId) => {
    const response = await axiosInstance.get(`/skillFamily/${skillFamilyId}`);
    return response.data;
  };

  export const createSkillFamily = async (skillFamily) => {
    const response = await axiosInstance.post('/skillFamilies', skillFamily);
    return response.data;
  };

  export const updateSkillFamily = async (skillFamilyId, skillFamily) => {
    const response = await axiosInstance.put(`/skillFamily/${skillFamilyId}`, skillFamily);
    return response.data;
  };

  export const deleteSkillFamily = async (skillFamilyId) => {
    const response = await axiosInstance.delete(`/skillFamily/${skillFamilyId}`);
    return response.data;
  };

  export const getSkillMappings = async () => {
    const cachedSkills = localStorage.getItem('skillMappings');
    if (cachedSkills) {
        console.log("Fetching skillMappings from localStorage");
        return JSON.parse(cachedSkills);
    }
    console.log("Fetching skillMappings from server");
    const response = await axiosInstance.get('/skillMappings');
    const skillMappings = response.data;
    localStorage.setItem('skillMappings', JSON.stringify(skillMappings));
    return skillMappings;
  };

  export const getAllSkills = async () => {
    let skillMappings = localStorage.getItem('skillMappings');
    if (!skillMappings) {
        console.log("Fetching skillMappings from server");
        const response = await axiosInstance.get('/skillMappings');
        skillMappings = response.data;
        localStorage.setItem('skillMappings', JSON.stringify(skillMappings));
    } else {
        console.log("Fetching skillMappings from localStorage");
        skillMappings = JSON.parse(skillMappings);
    }

    if (!Array.isArray(skillMappings?.data)) {
        console.error("Invalid skillMappings data:", skillMappings);
        return [];
    }

    let skills = skillMappings?.data.map(e => {return { 'skill' : e.skill }});
    return {data: skills};
  };

  export const getSkillFamilies = async () => {
    const cachedSkills = localStorage.getItem('skillFamilies');
    if (cachedSkills) {
        console.log("Fetching skillFamilies from localStorage");
        return JSON.parse(cachedSkills);
    }
    console.log("Fetching skillFamilies from server");
    const response = await axiosInstance.get('/skillMappings/skillFamilies');
    const skillFamilies = response.data;
    skillFamilies.data = skillFamilies?.data.sort((a, b) => a.skillFamily.localeCompare(b.skillFamily));
    localStorage.setItem('skillFamilies', JSON.stringify(skillFamilies));
    return skillFamilies;
  };

  export const getDomains = async () => {
    return {
      data: [
        {'label': 'Aerospace', 'value': 'Aerospace'},
        {'label': 'Chemical', 'value': 'Chemical'},
        {'label': 'Computers', 'value': 'Computers'},
        {'label': 'Construction', 'value': 'Construction'},
        {'label': 'Defense', 'value': 'Defense'},
        {'label': 'Education', 'value': 'Education'},
        {'label': 'Energy', 'value': 'Energy'},
        {'label': 'Entertainment', 'value': 'Entertainment'},
        {'label': 'Banking', 'value': 'Banking'},
        {'label': 'Insurance', 'value': 'Insurance'},
        {'label': 'Capital Markets', 'value': 'Capital Markets'},
        {'label': 'Health Care', 'value': 'Health Care'},
        {'label': 'Life Sciences', 'value': 'Life Sciences'},
        {'label': 'Hospitality', 'value': 'Hospitality'},
        {'label': 'Information', 'value': 'Information'},
        {'label': 'Manufacturing', 'value': 'Manufacturing'},
        {'label': 'Mass Media', 'value': 'Mass Media'},
        {'label': 'Telecommunications', 'value': 'Telecommunications'},
        {'label': 'Transport', 'value': 'Transport'},
        {'label': 'Retail', 'value': 'Retail'}
      ]
    }
  }

  export const getSkillsBySkillFamily = async (skillFamily) => {
    let skillMappings = localStorage.getItem('skillMappings');
    if (!skillMappings) {
        console.log("Fetching skillMappings from server");
        const response = await axiosInstance.get('/skillMappings');
        skillMappings = response.data;
        localStorage.setItem('skillMappings', JSON.stringify(skillMappings));
    } else {
        console.log("Fetching skillMappings from localStorage");
        skillMappings = JSON.parse(skillMappings);
    }

    if (!Array.isArray(skillMappings?.data)) {
        console.error("Invalid skillMappings data:", skillMappings);
        return [];
    }

    let skills = skillMappings?.data.filter(e => e.skillFamily === skillFamily);
    return {data: skills};
  };

  export const createSkillMapping = async (skillMapping) => {
    const response = await axiosInstance.post('/skillMappings', skillMapping);
    return response.data;
  };

  export const updateSkillMapping = async (skillMappingId, skillMapping) => {
    const response = await axiosInstance.put(`/skillMapping/${skillMappingId}`, skillMapping);
    return response.data;
  };

  export const deleteSkillMapping = async (skillMappingId) => {
    const response = await axiosInstance.delete(`/skillMapping/${skillMappingId}`);
    return response.data;
  };



  export const getDesignations = async () => {
    const response = await axiosInstance.get('/designations');
    return response.data;
  };

  export const getDesignationById = async (designationId) => {
    const response = await axiosInstance.get(`/designation/${designationId}`);
    return response.data;
  };

  export const createDesignation = async (designation) => {
    const response = await axiosInstance.post('/designations', designation);
    return response.data;
  };

  export const updateDesignation = async (designationId, designation) => {
    const response = await axiosInstance.put(`/designation/${designationId}`, designation);
    return response.data;
  };

  export const deleteDesignation = async (designationId) => {
    const response = await axiosInstance.delete(`/designation/${designationId}`);
    return response.data;
  };

  export const getListOfTimezones = () => {
    return [
      { value: 'America/New_York(UTC-5)', label: 'America/New_York (EST)' },
      { value: 'America/Chicago(UTC-6)', label: 'America/Chicago (CST)' },
      { value: 'America/Denver(UTC-7)', label: 'America/Denver (MST)' },
      { value: 'America/Los_Angeles(UTC-8)', label: 'America/Los_Angeles (PST)' },
      { value: 'Europe/London(UTC+0)', label: 'Europe/London (GMT)' },
      { value: 'Asia/Dubai(UTC+4)', label: 'Asia/Dubai (GST)' },
      { value: 'Asia/Kolkata(UTC+5:30)', label: 'Asia/Kolkata (IST)' },
      { value: 'Asia/Singapore(UTC+8)', label: 'Asia/Singapore (SGT)' },
      { value: 'Australia/Perth(UTC+8)', label: 'Australia/Perth (AWST)' },
      { value: 'Australia/Adelaide(UTC+9:30)', label: 'Australia/Adelaide (ACST)' },
      { value: 'Australia/Sydney(UTC+10)', label: 'Australia/Sydney (AEST)' },
      { value: 'Australia/Melbourne(UTC+10)', label: 'Australia/Melbourne (AEST)' },
      { value: 'Australia/Brisbane(UTC+10)', label: 'Australia/Brisbane (AEST)' }
    ];
  };

  export const getTimezones = async () => {
    const response = await axiosInstance.get('/timezones');
    return response.data;
  };

  export const getTimezoneById = async (timezoneId) => {
    const response = await axiosInstance.get(`/timezone/${timezoneId}`);
    return response.data;
  };

  export const createTimezone = async (timezone) => {
    const response = await axiosInstance.post('/timezones', timezone);
    return response.data;
  };

  export const updateTimezone = async (timezoneId, timezone) => {
    const response = await axiosInstance.put(`/timezone/${timezoneId}`, timezone);
    return response.data;
  };

  export const deleteTimezone = async (timezoneId) => {
    const response = await axiosInstance.delete(`/timezone/${timezoneId}`);
    return response.data;
  };

  export const getUsers = async () => {
    const response = await axiosInstance.get('/users');
    return response.data;
  };

  export const getPanels = async () => {
    const response = await axiosInstance.get('/panels');
    return response.data;
  };

  export const getPanel = async (panelId) => {
    const response = await axiosInstance.get(`/panel/${panelId}`);
    return response.data;
  };

  export const getPanelsList = async (jobId) => {
    const response = await axiosInstance.get(`/panelsList/${jobId}`);
    return response.data;
  };

  export const getCustomers = async () => {
    const response = await axiosInstance.get('/customers');
    return response.data;
  };

  export const getCustomerCompanies = async () => {
    const response = await axiosInstance.get('/customerCompanies');
    return response.data;
  };


  export const getVendors = async () => {
    const response = await axiosInstance.get('/vendors');
    return response.data;
  };

  export const getIpaanelUsers = async () => {
    const response = await axiosInstance.get('/ipaanelUsers');
    return response.data;
  };

  export const getAssignedUsers = async () => {
    const response = await axiosInstance.get('/assignedUsers');
    return response.data;
  };

  export const getUserById = async (userMongoId, profileType) => {
    const response = await axiosInstance.get(`/user/${profileType}/${userMongoId}`);
    return response.data;
  };

  export const createUser = async (user) => {
    const response = await axiosInstance.post('/users', user);
    return response.data;
  };

  export const updateUser = async (userMongoId, profileType, user) => {
    const response = await axiosInstance.put(`/user/${profileType}/${userMongoId}`, user);
    return response.data;
  };

  export const deleteUser = async (userId, profileType) => {
    const response = await axiosInstance.delete(`/user/${profileType}/${userId}`);
    return response.data;
  }

  export const loginUser = async (loginData) => {
    const response = await axiosInstance.post('/auth/login', loginData);
    const user = response.data.data;
    localStorage.setItem('token', user.token);
    localStorage.setItem('user', JSON.stringify(user));
    updateToken();
    return response.data;
  };

  export const forgotPasswordAPI = async (emailId, profileType) => {
    const response = await axiosInstance.post('/auth/password/forgot', {emailId:emailId, profileType:profileType});
    return response.data;
  };

  export const resetPassword = async (resetData) => {
    const response = await axiosInstance.post('/auth/password/reset', resetData);
    return response.data;
  };

  export const updatePassword = async (changePasswordData) => {
    const response = await axiosInstance.post('/users/changePassword', changePasswordData);
    return response.data;
  };
  // create a new endpoint for post /panelContacts
  export const getPanelContacts = async () => {
    const response = await axiosInstance.get('/panelContacts');
    return response.data;
  };
  // get panelContacts by emailId
  export const getPanelContactByEmail = async (emailId) => {
    const response = await axiosInstance.get(`/panelContact/${emailId}`);
    return response.data;
  };

  export const postPanelContacts = async (panelContact) => {
    const response = await axiosInstance.post('/panelContacts', panelContact);
    return response.data;
  };

  // update panelContacts by emailId
  export const updatePanelContacts = async (panelContact) => {
    const response = await axiosInstance.put(`/panelContact/${panelContact.emailId}`, panelContact);
    return response.data;
  };

  // get panelContacts by emailId
  export const getCustomerContactByEmail = async (emailId) => {
    const response = await axiosInstance.get(`/customerContact/${emailId}`);
    return response.data;
  };

  export const postCustomerContacts = async (panelContact) => {
    const response = await axiosInstance.post('/customerContacts', panelContact);
    return response.data;
  };

  // call new endpoint for /tickets to get tickets
  export const getTickets = async () => {
    const response = await axiosInstance.get('/tickets');
    return response.data;
  };
  // update tickets by ticketId
  export const updateTickets = async (ticketId,ticket) => {
    const response = await axiosInstance.put(`/ticket/${ticketId}`, ticket);
    return response.data;
  };

  // delete ticket by ticketId
  export const deleteTicket = async (ticketId) => {
    const response = await axiosInstance.delete(`/ticket/${ticketId}`);
    return response.data;
  }

  export const getTicketByID = async (ticketId) => {
    const response = await axiosInstance.get(`/ticket/${ticketId}`);
    return response.data;
  };

  // post tickets
  export const postTickets = async (ticket) => {
    const { emailId, purpose,otp} = ticket;
    const response = await axiosInstance.post('/tickets', { emailId, purpose,otp} );
    return response.data;
  };

export const getTicketDetails= async (ticketHash)=>{
  const response = await axiosInstance.get(`/ticketDetails/${ticketHash}`);
  return response.data;
}

export const putTicketDetails = async (ticketHash, obj)=>{
  const response = await axiosInstance.put(`/ticketStatus/${ticketHash}`, obj);
  return response.data;
}

export const getPanelDocumentById = async (resumeDocId) => {
  let response = await axiosInstance.get(`/documents/panel/${resumeDocId}`, { responseType: 'blob' });
  return response.data;
}


export const getAllPanelSlots = async () => {
  const response = await axiosInstance.get(`/allPanelSlots`);
  return response.data.data;
}

export const updatePanelSlots = async (slotObj) => {
  const response = await axiosInstance.post(`/panelSlots`, slotObj);
  return response.data;
}


export const getUserSlots = async () => {
  const response = await axiosInstance.get(`/panelSlots`);
  return response.data.data;
}

// write a new api call for calling api/v1/calendarEvents
export const getCalendarEvents = async () => {
  const response = await axiosInstance.get('/panelCalendarEvents');
  return response.data;
}

export const getCustomerCalendarEvents = async () => {
  const response = await axiosInstance.get('/customerCalendarEvents');
  return response.data;
}

export const getAllCalendarEvents = async () => {
  const response = await axiosInstance.get('/adminCalendarEvents');
  return response.data;
}

// write a new api call post calender event
export const putCalendarEvents = async (eventId,calendarEvent) => {
  const response = await axiosInstance.put(`/calendarEvent/${eventId}`, calendarEvent);
  return response.data;
}
// write a post request
export const addCalendarEvents = async (calendarEvent) => {
  calendarEvent['scheduleId'] = new Date().getTime();
  const response = await axiosInstance.post('/calendarEvents', calendarEvent);
  return response.data;
}

export const deleteCalendarEvent = async (eventId) => {
  const response = await axiosInstance.delete(`/calendarEvent/${eventId}`);
  return response.data;
}

export const getAllCustomerRequests = async () => {
  const response = await axiosInstance.get(`/allCustomerRequests`);
  return response.data.data;
}

export const createCustomerRequest = async (CustomerRequestObj) => {
  const response = await axiosInstance.post('/customerRequests', CustomerRequestObj);
  return response.data;
}

export const getCustomerRequests = async () => {
  const response = await axiosInstance.get(`/customerRequests`);
  return response.data.data;
}

export const getCustomerRequest = async (custReqId) => {
  const response = await axiosInstance.get(`/customerRequest/${custReqId}`);
  return response.data;
}

export const updateCustomerRequest = async (custReqId, customerRequest) => {
  const response = await axiosInstance.put(`/customerRequest/${custReqId}`, customerRequest);
  return response.data;
}

export const getJobs = async () => {
  const response = await axiosInstance.get('/jobs');
  return response.data;
}

export const getJobsInfo = async () => {
  const response = await axiosInstance.get('/jobsInfo');
  return response.data;
}


export const getJob = async (jobId) => {
  const response = await axiosInstance.get(`/job/${jobId}`);
  return response.data;
}

export const postJob = async (jobData) => {
  const response = await axiosInstance.post(`/jobs`,jobData);
  return response.data;
}

export const uploadJobs = async (jobsData) => {
  const response = await axiosInstance.post(`/uploadJobs`,jobsData);
  return response.data;
}

export const updateJob = async (jobId, jobData) => {
  const response = await axiosInstance.put(`/job/${jobId}`,jobData);
  return response.data;
}

export const deleteJob = async (jobId) => {
  const response = await axiosInstance.delete(`/job/${jobId}`);
  return response.data;
}

export const updateJobAssignee = async (jobId, jobData) => {
  const response = await axiosInstance.put(`/job/updateAssignee/${jobId}`, jobData);
  return response.data;
}


export const createDeliveryUnit = async (deliveryUnitObj) => {
  const response = await axiosInstance.post('/deliveryUnits', deliveryUnitObj);
  return response.data;
}

export const getCustomerDeliveryUnits = async (customerId) => {
  const response = await axiosInstance.get(`/customerDeliveryUnits/${customerId}`);
  return response.data.data;
}

export const getDeliveryUnits = async () => {
  const response = await axiosInstance.get(`/deliveryUnits`);
  return response.data.data;
}

export const getDeliveryUnit = async (delUnitId) => {
  const response = await axiosInstance.get(`/deliveryUnit/${delUnitId}`);
  return response.data;
}

export const updateDeliveryUnit = async (delUnitId, deliveryUnit) => {
  const response = await axiosInstance.put(`/deliveryUnit/${delUnitId}`, deliveryUnit);
  return response.data;
}


export const getSkillAssessments = async (jobId) => {
  const response = await axiosInstance.get(`/skillAssessments/${jobId}`);
  return response.data;
}

export const updateSkillAssessments = async (jobId, skillAssessment) => {
  const response = await axiosInstance.put(`/skillAssessments/${jobId}`, skillAssessment);
  return response.data;
}

export const updatePanelsInJob = async (addPanelObj) => {
  const response = await axiosInstance.post(`/job/updatePanels`, addPanelObj);
  return response.data;
}

export const requestPanels = async (requestPanelsObj) => {
  const response = await axiosInstance.post(`/job/requestPanels`, requestPanelsObj);
  return response.data;
}

export const removePanelFromJob = async (removePanelObj) => {
  const response = await axiosInstance.post(`/job/removePanel`, removePanelObj);
  return response.data;
}

export const getVendorsForCustomer = async (customerId) => {
  const response = await axiosInstance.get(`/customerVendors/${customerId}`);
  return response.data;
}

export const updateVendorsInJob = async (addVendorObj) => {
  const response = await axiosInstance.post(`/job/updateVendors`, addVendorObj);
  return response.data;
}

export const removeVendorFromJob = async (removeVendorObj) => {
  const response = await axiosInstance.post(`/job/removeVendor`, removeVendorObj);
  return response.data;
}


export const getCandidates = async () => {
  const response = await axiosInstance.get('/candidates');
  return response.data;
}


export const getApprovedCandidates = async () => {
  const response = await axiosInstance.get('/approvedCandidates');
  return response.data;
}

export const getPanelClaimedCandidates = async () => {
  const response = await axiosInstance.get('/panelClaimedCandidates');
  return response.data;
}

export const getCustomerCandidates = async (customerId) => {
  const response = await axiosInstance.get(`/customerCandidates/${customerId}`);
  return response.data;
}

export const getPanelCandidates = async (panelId) => {
  const response = await axiosInstance.get(`/panelCandidates/${panelId}`);
  return response.data;
}

export const getCandidate = async (candidateId) => {
  const response = await axiosInstance.get(`/candidate/${candidateId}`);
  return response.data;
}

export const postCandidate = async (candidateData) => {
  const response = await axiosInstance.post(`/candidates`, candidateData);
  return response.data;
}

export const uploadCandidateDocument = async (documentName, candidateDocument) => {
  const response = await axiosInstance.put(`/document/candidate/${documentName}`, candidateDocument);
  return response.data;
}

export const getCandidateDocumentById = async (documentName) => {
  let response = await axiosInstance.get(`/document/candidate/${documentName}`, { responseType: 'blob' });
  return response.data;
}

export const deleteDocument = async (candidateId, documentId) => {
  const response = await axiosInstance.delete(`/document/candidate/${candidateId}/${documentId}`);
  return response.data;
}


export const uploadResumesToServer = async (resumesZipObj) => {
  const response = await axiosInstance.post(`/resumes/uploadZip`, resumesZipObj);
  return response.data;
}

export const uploadCandidates = async (candidatesData) => {
  const response = await axiosInstance.post(`/uploadCandidates`,candidatesData);
  return response.data;
}

export const updateCandidate = async (candidateId, candidateData) => {
  const response = await axiosInstance.put(`/candidate/${candidateId}`,candidateData);
  return response.data;
}

export const updateBonusAmount = async (candidateId, candidateData) => {
  const response = await axiosInstance.put(`/candidateBonusAmount/${candidateId}`, candidateData);
  return response.data;
}

export const deleteCandidate = async (candidateId) => {
  const response = await axiosInstance.delete(`/candidate/${candidateId}`);
  return response.data;
}

export const updateSkillsForCandidate = async (candidateId, skillsData) => {
  const response = await axiosInstance.put(`/candidate/skills/${candidateId}`, skillsData);
  return response.data;
}


export const claimCandidateByPanel = async (candidateId, panelData) => {
  const response = await axiosInstance.put(`/candidate/panelInfo/${candidateId}`, panelData);
  return response.data;
}


export const scheduleInterview = async (candidateId, meetingLink) => {
  const response = await axiosInstance.put(`/candidate/scheduleInterview/${candidateId}`, {'meetingLink': meetingLink});
  return response.data;
}

export const getInterviewSchedules = async () => {
  const response = await axiosInstance.get('/interviewSchedules');
  return response.data;
}

export const getPanelSchedules = async () => {
  const response = await axiosInstance.get('/panelSchedules');
  return response.data;
}

export const getCustomerSchedules = async () => {
  const response = await axiosInstance.get('/customerSchedules');
  return response.data;
}

export const getSchedule = async (scheduleId, customerId) => {
  const response = await axiosInstance.get(`/interviewSchedule/${customerId}/${scheduleId}`);
  return response.data;
}

export const updateSchedule = async (scheduleId, customerId, scheduleData) => {
  const response = await axiosInstance.put(`/interviewSchedule/${customerId}/${scheduleId}`,scheduleData);
  return response.data;
}

export const getFeedbackPDF = async (candidateId) => {
  let response = await axiosInstance.get(`/candidateFeedback/${candidateId}`, { responseType: 'blob' });
  return response.data;
}


export const getPartialFeedbackPDF = async (candidateId) => {
  let response = await axiosInstance.get(`/candidatePartialFeedback/${candidateId}`, { responseType: 'blob' });
  return response.data;
}

export const getPanelInvoices = async () => {
  const response = await axiosInstance.post("/invoice/admin/allPanelSummary")
  return response.data;
}
export const getPanelInvoicesByPanel = async (payload) => {
  const response = await axiosInstance.post("/invoice/panelDetailSummary", payload);
  console.log(response.data)
  return response.data;
}

export const getPanelSearchList = async () => {
  const response = await axiosInstance.get(`/admin/panelSearchSummary`);
  return response.data;
};

export const getPanelSearchDetails = async (panelEmailId) => {
  const response = await axiosInstance.get(`/admin/panelSlots/${panelEmailId}`);
  return response.data;
};

export default getData;
