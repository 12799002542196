import React, { useRef, useState, useEffect } from "react";
import { TextField, InputAdornment, IconButton, MenuItem } from "@mui/material";
import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MailLockIcon from '@mui/icons-material/MailLock';
import { useNavigate } from "react-router-dom";
import { updatePassword } from "../../service/api";
import { toast } from "react-toastify";
import toastNotifications from '../toastNotifications/toastMessages';

export default function ChangePassword() {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const [emailId, setEmailId] = useState("");
  const [profileType, setProfileType] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const profileTypes = [
    { value: 'PANELIST', label: 'PANELIST'},
    { value: 'CUSTOMER', label: 'CUSTOMER'},
    { value: 'CUSTOMER_ADMIN', label: 'CUSTOMER_ADMIN'},
    { value: 'VENDOR', label: 'VENDOR'},
    { value: 'ADMIN', label: 'ADMIN'}
  ]

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    setEmailId(user.emailId);
    if (user.profileType ==='SUPER' || user.profileType ==='ADMIN') {
      setProfileType('ADMIN');
    } else {
      setProfileType(user.profileType);
    }
  }, []);

  return (
    <>
      <section className="vh-100 gradient-custom">
        <div className="row d-flex justify-content-center align-items-center ">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="card  text-white" style={{ borderRadius: "1em" }}>
              <div className="card-body p-5 text-center">
                {/* <img
                  src="/Images/iPannel.png"
                  style={{ height: "50%", width: "30%" }}
                  onClick={() => {
                    navigate('/');
                  }}
                /> */}
                <div className="mb-md-5 mt-md-4 pb-10">
                <form ref={formRef}>
                  <TextField
                    placeholder="emailId"
                    disabled={true}
                    value={emailId}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <MailLockIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <br />
                  <br />

                  <TextField
                    select
                    disabled={true}
                    value={profileType}
                    placeholder="profileType"
                    style={{ textAlign: "initial"}}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <PersonOutlineRoundedIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  >
                    {profileTypes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <br />
                  <br />
                  <TextField
                    type="password"
                    placeholder="Old Password"
                    value={oldPassword}
                    onChange={(e) => {
                      e.preventDefault(); 
                      setOldPassword(e.target.value)
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <LockOutlinedIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <br />
                  <br />
                  <TextField
                    type="password"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => {
                      e.preventDefault(); 
                      setNewPassword(e.target.value)
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <LockOutlinedIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  </form>
                </div>
                <button 
                className="btn btn-primary btn-lg px-5" 
                onClick={() => {
                  updatePassword({emailId, profileType, oldPassword, newPassword}).then((res) => {
                    toast.success(toastNotifications.changePassword.updateSuccess);
                  }).catch(err=>{
                    toast.error(toastNotifications.changePassword.updateFail);
                  });
                }}
                type="button"
                >
                  Submit
                </button>
                &nbsp;
                <button className="btn btn-primary btn-lg px-5" onClick={() => { navigate(-1) }}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
