import React, {useEffect, useState} from "react";
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    ListItemButton,
} from "@mui/material";
import {Link, useLocation, useNavigate, useRouteMatch} from "react-router-dom";

import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {createTheme, styled, ThemeProvider} from "@mui/material/styles";

const drawerWidth = 275;
const theme = createTheme({
    typography: {
        fontFamily: '"Open sans", "Regular"', fontSize: 14, margin: 0, padding: 0, lineSpacing: 36,
    }, palette: {
        text: {
            primary: '#3A3A3A',
        },
    },
});
const StyledDrawer = styled(Drawer)(({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    '& .ipanelScroll': {
        overflowY: 'auto',
    },
    [theme.breakpoints.down('md')]: {
        '& .ipanelScroll': {
            overflowY: 'visible', // or auto, scroll, hidden based on your requirement
        },
    },
}));
const StyledList = styled(List)(({theme}) => ({
    overflowY: 'auto',
}));
const SideNav = () => {
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem("user"));
    const [usersOpen, setUsersOpen] = React.useState(false);
    const [authOpen, setAuthOpen] = React.useState(false);
    const [dataOpen, setDataOpen] = React.useState(false);
    const location = useLocation();

    const [activeLink, setActiveLink] = useState(null);
    useEffect(() => {
        setActiveLink(location.pathname);
    }, [location.pathname]);
    const handleUsersClick = () => {
        setUsersOpen(!usersOpen);
    };
    const handleAuthClick = () => {
        setAuthOpen(!authOpen);
    };
    const handleDataClick = () => {
        setDataOpen(!dataOpen);
    };

    return (

        <ThemeProvider theme={theme}>
            <StyledDrawer
                variant="permanent"
                anchor="left"
            >
            <StyledList>
                <Drawer
                    classes={{
                        paper: "ipanelScroll",
                    }}
                    variant="permanent"
                    anchor="left"

                >
                    <List>
                        <div className="text-center">
                            <img
                                src="/panls-logo-wht(2).png"
                                width="80%"
                                style={{
                                    marginBottom: "20px",
                                    marginTop: "10px",
                                    paddingInline: "auto",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    navigate("/");
                                }}
                            />
                        </div>


                        {/* ADMIN ROUTES */}
                        {/* Dashboard */}
                        <ListItemButton
                            key="101"
                            component={Link}
                            to="/admin/dashboard"
                            style={{
                                display: ["SUPER", "ADMIN"].includes(user.profileType)
                                    ? "flex"
                                    : "none",
                                backgroundColor: activeLink === "/admin/dashboard" ? "#3E67B1" : "inherit",
                                color: activeLink === "/admin/dashboard" ? "white" : "inherit",
                                margin: "0px 10px",
                                borderRadius: "4px"
                            }}
                        >
                            <ListItemIcon style={{minWidth: "30px"}}>
                                <i className="fa fa-solid fa-tachometer-alt"
                                   style={{color: activeLink === "/admin/dashboard" ? "white" : "inherit"}}
                                ></i>
                            </ListItemIcon>
                            <ListItemText>Dashboard</ListItemText>
                        </ListItemButton>

                        <ListItemButton
                            key="201"
                            component={Link}
                            to="/customer/customerDashboard"
                            style={{
                                display: ["CUSTOMER", "CUSTOMER_ADMIN"].includes(user.profileType)
                                    ? "flex"
                                    : "none",
                                backgroundColor: activeLink === "/customer/customerDashboard" ? "#3E67B1" : "inherit",
                                color: activeLink === "/customer/customerDashboard" ? "white" : "inherit",
                                borderRadius: "4px",
                                margin: "0px 10px"
                            }}
                        >
                            <ListItemIcon style={{minWidth: "30px"}}>
                                <i className="fa fa-solid fa-tachometer-alt"
                                   style={{color: activeLink === "/customer/customerDashboard" ? "white" : "inherit"}}
                                ></i>
                            </ListItemIcon>
                            <ListItemText>Dashboard</ListItemText>
                        </ListItemButton>

                        <ListItemButton
                            key="201V"
                            component={Link}
                            to="/user/vendorDashboard"
                            style={{
                                display: ["VENDOR"].includes(user.profileType) ? "flex" : "none",
                                backgroundColor: activeLink === "/user/vendorDashboard" ? "#3E67B1" : "inherit",
                                color: activeLink === "/user/vendorDashboard" ? "white" : "inherit",
                                borderRadius: "4px",
                                margin: "0px 10px"
                            }}
                        >
                            <ListItemIcon style={{minWidth: "30px"}}>
                                <i className="fa fa-solid fa-tachometer-alt"
                                   style={{color: activeLink === "/user/vendorDashboard" ? "white" : "inherit"}}
                                ></i>
                            </ListItemIcon>
                            <ListItemText>Dashboard</ListItemText>
                        </ListItemButton>

                        <ListItemButton
                            key="301"
                            component={Link}
                            to="/panel/panelDashboard"
                            style={{
                                display: ["PANELIST"].includes(user.profileType) ? "flex" : "none",
                                backgroundColor: activeLink === "/panel/panelDashboard" ? "#3E67B1" : "inherit",
                                color: activeLink === "/panel/panelDashboard" ? "white" : "inherit",
                                borderRadius: "4px",
                                margin: "0px 10px"
                            }}
                        >
                            <ListItemIcon style={{minWidth: "30px"}}>
                                <i className="fa fa-solid fa-tachometer-alt"
                                   style={{color: activeLink === "/panel/panelDashboard" ? "white" : "inherit"}}
                                ></i>
                            </ListItemIcon>
                            <ListItemText>Panel Dashboard</ListItemText>
                        </ListItemButton>

                        {/* Tickets */}
                        <ListItemButton
                            key="102"
                            component={Link}
                            to="/admin/tickets/all"
                            style={{
                                display: ["SUPER", "ADMIN"].includes(user.profileType)
                                    ? "flex"
                                    : "none",
                                backgroundColor: activeLink === "/admin/tickets/all" ? "#3E67B1" : "inherit",
                                color: activeLink === "/admin/tickets/all" ? "white" : "inherit",
                                borderRadius: "4px",
                                margin: "0px 10px"
                            }}
                        >
                            <ListItemIcon style={{minWidth: "30px"}}>
                                <i className="fa fa-solid fa-ticket-alt"
                                   style={{color: activeLink === "/admin/tickets/all" ? "white" : "inherit"}}
                                ></i>
                            </ListItemIcon>
                            <ListItemText>Tickets</ListItemText>
                        </ListItemButton>

                        <ListItemButton
                            key="103"
                            component={Link}
                            to="/admin/customerTickets"
                            style={{
                                display: ["SUPER", "ADMIN"].includes(user.profileType)
                                    ? "flex"
                                    : "none",
                                backgroundColor: activeLink === "/admin/customerTickets" ? "#3E67B1" : "inherit",
                                color: activeLink === "/admin/customerTickets" ? "white" : "inherit",
                                borderRadius: "4px",
                                margin: "0px 10px"
                            }}
                        >
                            <ListItemIcon style={{minWidth: "30px"}}>
                                <i className="fa fa-solid fa-ticket"
                                   style={{color: activeLink === "/admin/customerTickets" ? "white" : "inherit"}}
                                ></i>
                            </ListItemIcon>
                            <ListItemText>Customer Tickets</ListItemText>
                        </ListItemButton>

                        <ListItemButton
                            key="104"
                            component={Link}
                            to="/admin/calendar"
                            style={{
                                display: ["SUPER", "ADMIN"].includes(user.profileType)
                                    ? "flex"
                                    : "none",
                                backgroundColor: activeLink === "/admin/calendar" ? "#3E67B1" : "inherit",
                                color: activeLink === "/admin/calendar" ? "white" : "inherit",
                                borderRadius: "4px",
                                margin: "0px 10px"
                            }}
                        >
                            <ListItemIcon style={{minWidth: "30px"}}>
                                <i className="fa fa-solid fa-calendar-days"
                                   style={{color: activeLink === "/admin/calendar" ? "white" : "inherit"}}
                                ></i>
                            </ListItemIcon>
                            <ListItemText>Admin Calendar</ListItemText>
                        </ListItemButton>

                        <ListItemButton
                            key="105"
                            component={Link}
                            to="/user/jobs/all"
                            style={{
                                display: [
                                    "SUPER",
                                    "ADMIN",
                                    "CUSTOMER",
                                    "CUSTOMER_ADMIN",
                                    "VENDOR",
                                ].includes(user.profileType)
                                    ? "flex"
                                    : "none",
                                backgroundColor: activeLink === "/user/jobs/all" ? "#3E67B1" : "inherit",
                                color: activeLink === "/user/jobs/all" ? "white" : "inherit",
                                borderRadius: "4px",
                                margin: "0px 10px"
                            }}
                        >
                            <ListItemIcon style={{minWidth: "30px"}}>
                                <i className="fa fa-solid fa-bullhorn"
                                   style={{color:activeLink === "/user/jobs/all" ? "white" : "inherit"}}

                                ></i>
                            </ListItemIcon>
                            <ListItemText>Jobs</ListItemText>
                        </ListItemButton>

                        <ListItemButton
                            key="106"
                            component={Link}
                            to="/user/candidates/all"
                            style={{
                                display: [
                                    "SUPER",
                                    "ADMIN",
                                    "CUSTOMER",
                                    "CUSTOMER_ADMIN",
                                    "VENDOR",
                                ].includes(user.profileType)
                                    ? "flex"
                                    : "none",
                                backgroundColor: activeLink === "/user/candidates/all" ? "#3E67B1" : "inherit",
                                color: activeLink === "/user/candidates/all" ? "white" : "inherit",
                                borderRadius: "4px",
                                margin: "0px 10px"
                            }}
                        >
                            <ListItemIcon style={{minWidth: "30px"}}>
                                <i className="fa fa-solid fa-user-circle"
                                   style={{color: activeLink === "/user/candidates/all" ? "white" : "inherit"}}
                                ></i>
                            </ListItemIcon>
                            <ListItemText>Candidates</ListItemText>
                        </ListItemButton>
                        <ListItemButton
                            key="106"
                            component={Link}
                            to="/customer/candidateStatus"
                            style={{
                                display: [
                                    "SUPER",
                                    "ADMIN",
                                    "CUSTOMER",
                                    "CUSTOMER_ADMIN",
                                    "VENDOR",
                                ].includes(user.profileType)
                                    ? "flex"
                                    : "none",
                                backgroundColor: activeLink === "/customer/candidateStatus" ? "#3E67B1" : "inherit",
                                color: activeLink === "/customer/candidateStatus" ? "white" : "inherit",
                                borderRadius: "4px",
                                margin: "0px 10px"
                            }}
                        >
                            <ListItemIcon style={{minWidth: "30px"}}>
                                <i className="fa fa-solid fa-user-circle"
                                   style={{color: activeLink === "/customer/candidateStatus" ? "white" : "inherit"}}
                                ></i>
                            </ListItemIcon>
                            <ListItemText>Candidates Status</ListItemText>
                        </ListItemButton>

                        <ListItemButton
                            key="107"
                            component={Link}
                            to="/user/claimedCandidates"
                            style={{
                                display: ["SUPER", "ADMIN"].includes(user.profileType)
                                    ? "flex"
                                    : "none",
                                backgroundColor: activeLink === "/user/claimedCandidates" ? "#3E67B1" : "inherit",
                                color: activeLink === "/user/claimedCandidates" ? "white" : "inherit",
                                borderRadius: "4px",
                                margin: "0px 10px"
                            }}
                        >
                            <ListItemIcon style={{minWidth: "30px"}}>
                                <i className="fa fa-solid fa-circle-check"
                                   style={{color: activeLink === "/user/claimedCandidates" ? "white" : "inherit"}}
                                ></i>
                            </ListItemIcon>
                            <ListItemText>Approve Panel Claims</ListItemText>
                        </ListItemButton>

                        {/*<ListItemButton*/}
                        {/*    key="108"*/}
                        {/*    component={Link}*/}
                        {/*    to="/admin/interviewSchedules/all"*/}
                        {/*    style={{*/}
                        {/*        display: ["SUPER", "ADMIN"].includes(user.profileType)*/}
                        {/*            ? "flex"*/}
                        {/*            : "none",*/}
                        {/*        backgroundColor: activeLink === "/admin/interviewSchedules/all" ? "#3E67B1" : "inherit",*/}
                        {/*        color: activeLink === "/admin/interviewSchedules/all" ? "white" : "inherit",*/}
                        {/*        borderRadius: "4px",*/}
                        {/*        margin: "0px 10px"*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <ListItemIcon style={{minWidth: "30px"}}>*/}
                        {/*        <i className="fa fa-solid fa-book-open"*/}
                        {/*           style={{color: activeLink === "/admin/interviewSchedules/all" ? "white" : "inherit"}}*/}

                        {/*        ></i>*/}
                        {/*    /!*    svg images*!/*/}
                        {/*    </ListItemIcon>*/}
                        {/*    <ListItemText>Scheduled Interviews</ListItemText>*/}
                        {/*</ListItemButton>*/}

                        <ListItemButton
                            key="109"
                            component={Link}
                            to="/admin/Interviews/all"
                            style={{
                                display: ["SUPER", "ADMIN"].includes(user.profileType)
                                    ? "flex"
                                    : "none",
                                backgroundColor: activeLink === "/admin/Interviews/all" ? "#3E67B1" : "inherit",
                                color: activeLink === "/admin/Interviews/all" ? "white" : "inherit",
                                borderRadius: "4px",
                                margin: "0px 10px"

                            }}

                        >
                            <ListItemIcon style={{minWidth: "30px"}}>
                                <i className="fa fa-solid fa-book"
                                   style={{color: activeLink === "/admin/Interviews/all" ? "white" : "inherit"}}
                                ></i>
                            </ListItemIcon>
                            <ListItemText> Interviews</ListItemText>
                        </ListItemButton>

                        {/* Users */}
                        <ListItemButton
                            key="1091"
                            onClick={handleUsersClick}
                            style={{
                                display: ["SUPER", "ADMIN"].includes(user.profileType)
                                    ? "flex"
                                    : "none",
                                marginInline: "10px",
                                backgroundColor: usersOpen ? "#EDEDED" : "initial", // Change color here
                            }}
                        >
                            <ListItemIcon style={{minWidth: "30px"}}>
                                <i className="fa fa-solid fa-users"></i>
                            </ListItemIcon>
                            <ListItemText primary="Users"/>
                            {usersOpen ? <ExpandLess/> : <ExpandMore/>}
                        </ListItemButton>
                        <Collapse
                            in={usersOpen}
                            timeout="auto"
                            unmountOnExit
                            style={{
                                display: ["SUPER", "ADMIN"].includes(user.profileType)
                                    ? "flex"
                                    : "none",
                                paddingLeft: "10px",
                                backgroundColor: usersOpen ? "#EDEDED" : "initial", // Change color here
                                borderRadius: "4px",
                                margin: "0px 10px"
                            }}
                        >
                            <List component="div" disablePadding>
                                <ListItemButton
                                    key="Users"
                                    component={Link}
                                    to="/admin/users"
                                    style={{
                                        borderRadius: "4px",
                                        backgroundColor: activeLink === "/admin/users" ? "#3E67B1" : "inherit",
                                        color: activeLink === "/admin/users" ? "white" : "inherit",
                                        margin: "0px 10px"
                                    }}
                                >
                                    <ListItemIcon style={{minWidth: "30px"}}>
                                        <i className="fa fa-solid fa-users"
                                           style={{color: activeLink === "/admin/users" ? "white" : "inherit",}}
                                        ></i>
                                    </ListItemIcon>
                                    <ListItemText>Admins</ListItemText>
                                </ListItemButton>
                                <ListItemButton
                                    key="Panels"
                                    component={Link}
                                    to="/admin/panels"
                                    style={{
                                        borderRadius: "4px",
                                        backgroundColor: activeLink === "/admin/panels" ? "#3E67B1" : "inherit",
                                        color: activeLink === "/admin/panels" ? "white" : "inherit",
                                        margin: "0px 10px"
                                    }}
                                >
                                    <ListItemIcon style={{minWidth: "30px"}}>
                                        <i className="fa fa-solid fa-user"
                                           style={{color: activeLink === "/admin/panels" ? "white" : "inherit"}}
                                        ></i>
                                    </ListItemIcon>
                                    <ListItemText>Panels</ListItemText>
                                </ListItemButton>
                                <ListItemButton

                                    key="Customers"
                                    component={Link}
                                    to="/admin/customers"
                                    style={{
                                        borderRadius: "4px",
                                        backgroundColor: activeLink === "/admin/customers" ? "#3E67B1" : "inherit",
                                        color: activeLink === "/admin/customers" ? "white" : "inherit",
                                        marginInline: "10px"
                                    }}
                                >
                                    <ListItemIcon style={{minWidth: "30px"}}>
                                        <i className="fa fa-solid fa-user-group"
                                           style={{color: activeLink === "/admin/customers" ? "white" : "inherit"}}
                                        ></i>
                                    </ListItemIcon>
                                    <ListItemText>Customers</ListItemText>
                                </ListItemButton>
                                <ListItemButton
                                    key="CustomerCoordinators"
                                    component={Link}
                                    to="/admin/customerAdmins"
                                    style={{
                                        borderRadius: "4px",
                                        backgroundColor: activeLink === "/admin/customerAdmins" ? "#3E67B1" : "inherit",
                                        color: activeLink === "/admin/customerAdmins" ? "white" : "inherit",
                                        margin: "0px 10px"
                                    }}
                                >
                                    <ListItemIcon style={{minWidth: "30px"}}>
                                        <i className="fa fa-solid fa-user-group"
                                           style={{color: activeLink === "/admin/customerAdmins" ? "white" : "inherit"}}
                                        ></i>
                                    </ListItemIcon>
                                    <ListItemText>Customer Admins</ListItemText>
                                </ListItemButton>
                                <ListItemButton
                                    key="Vendors"
                                    component={Link}
                                    to="/admin/vendors"
                                    style={{
                                        borderRadius: "4px",
                                        backgroundColor: activeLink === "/admin/vendors" ? "#3E67B1" : "inherit",
                                        color: activeLink === "/admin/vendors" ? "white" : "inherit",
                                        margin: "0px 10px"
                                    }}
                                >
                                    <ListItemIcon style={{minWidth: "30px"}}>
                                        <i className="fa fa-solid fa-user-secret"
                                           style={{color: activeLink === "/admin/vendors" ? "white" : "inherit"}}
                                        ></i>
                                    </ListItemIcon>
                                    <ListItemText>Vendors</ListItemText>
                                </ListItemButton>
                            </List>
                        </Collapse>

                        {/* Authorization */}
                        <ListItemButton
                            key="110"
                            onClick={handleAuthClick}
                            style={{
                                display: ["SUPER", "ADMIN"].includes(user.profileType)
                                    ? "flex"
                                    : "none",
                                backgroundColor: authOpen ? "#EDEDED" : "initial",
                                margin: "0px 10px"
                            }}
                        >
                            <ListItemIcon style={{minWidth: "30px"}}>
                                <i className="fa fa-solid fa-shield"></i>
                            </ListItemIcon>
                            <ListItemText primary="Authorization"/>
                            {authOpen ? <ExpandLess/> : <ExpandMore/>}
                        </ListItemButton>
                        <Collapse
                            in={authOpen}
                            timeout="auto"
                            unmountOnExit
                            style={{
                                display: ["SUPER", "ADMIN"].includes(user.profileType)
                                    ? "flex"
                                    : "none",
                                backgroundColor: authOpen ? "#EDEDED" : "initial",
                                margin: "0px 10px"
                            }}
                        >
                            <List component="div" disablePadding>
                                <ListItemButton
                                    key="Groups"
                                    component={Link}
                                    to="/admin/groups"
                                    style={{
                                        borderRadius: "4px",
                                        backgroundColor: activeLink === "/admin/groups" ? "#3E67B1" : "inherit",
                                        color: activeLink === "/admin/groups" ? "white" : "inherit",
                                        margin: "0px 10px"  // Change color here
                                    }}
                                >
                                    <ListItemIcon style={{minWidth: "30px"}}>
                                        <i className="fa fa-solid fa-users"
                                           style={{color: activeLink === "/admin/groups" ? "white" : "inherit"}}
                                        ></i>
                                    </ListItemIcon>
                                    <ListItemText>Groups</ListItemText>
                                </ListItemButton>
                                <ListItemButton
                                    key="Roles"
                                    component={Link}
                                    to="/admin/roles"
                                    style={{
                                        borderRadius: "4px",
                                        backgroundColor: activeLink === "/admin/roles" ? "#3E67B1" : "inherit",
                                        color: activeLink === "/admin/roles" ? "white" : "inherit",
                                        margin: "0px 10px"
                                    }}
                                >
                                    <ListItemIcon style={{minWidth: "30px"}}>
                                        <i className="fa fa-solid fa-shield"
                                           style={{color: activeLink === "/admin/roles" ? "white" : "inherit"}}
                                        ></i>
                                    </ListItemIcon>
                                    <ListItemText>Roles</ListItemText>
                                </ListItemButton>
                                <ListItemButton
                                    key="Permissions"
                                    component={Link}
                                    to="/admin/permissions"
                                    style={{
                                        borderRadius: "4px",
                                        backgroundColor: activeLink === "/admin/permissions" ? "#3E67B1" : "inherit",
                                        color: activeLink === "/admin/permissions" ? "white" : "inherit",
                                        margin: "0px 10px"
                                    }}
                                >
                                    <ListItemIcon style={{minWidth: "30px"}}>
                                        <i className="fa fa-solid fa-user-secret"
                                           style={{color: activeLink === "/admin/permissions" ? "white" : "inherit"}}
                                        ></i>
                                    </ListItemIcon>
                                    <ListItemText>Permissions</ListItemText>
                                </ListItemButton>
                            </List>
                        </Collapse>

                        {/* Master Data */}
                        <ListItemButton
                            key="111"
                            onClick={handleDataClick}
                            style={{
                                display: ["SUPER"].includes(user.profileType)
                                    ? "flex"
                                    : "none",
                                backgroundColor: dataOpen ? "#EDEDED" : "initial",
                                margin: "0px 10px"
                            }}
                        >
                            <ListItemIcon style={{minWidth: "30px"}}>
                                <i className="fa fa-solid fa-database"></i>
                            </ListItemIcon>
                            <ListItemText primary="Master Data"/>
                            {dataOpen ? <ExpandLess/> : <ExpandMore/>}
                        </ListItemButton>
                        <Collapse
                            in={dataOpen}
                            timeout="auto"
                            unmountOnExit
                            style={{
                                display: ["SUPER", "ADMIN"].includes(user.profileType)
                                    ? "flex"
                                    : "none",
                                backgroundColor: dataOpen ? "#EDEDED" : "initial", // Change color here
                                margin: "0px 10px"
                            }}
                        >
                            <List component="div" disablePadding>
                                <ListItemButton
                                    key="Countries"
                                    component={Link}
                                    to="/admin/countries"
                                    style={{
                                        borderRadius: "4px",
                                        backgroundColor: activeLink === "/admin/countries" ? "#3E67B1" : "inherit",
                                        color: activeLink === "/admin/countries" ? "white" : "inherit",
                                        margin: "0px 10px"
                                    }}
                                >
                                    <ListItemIcon style={{minWidth: "30px"}}>
                                        <i className="fa fa-solid fa-flag"
                                           style={{color: activeLink === "/admin/countries" ? "white" : "inherit"}}
                                        ></i>
                                    </ListItemIcon>
                                    <ListItemText>Countries</ListItemText>
                                </ListItemButton>
                                <ListItemButton
                                    key="States"
                                    component={Link}
                                    to="/admin/states"
                                    style={{
                                        borderRadius: "4px",
                                        backgroundColor: activeLink === "/admin/states" ? "#3E67B1" : "inherit",
                                        color: activeLink === "/admin/states" ? "white" : "inherit",
                                        margin: "0px 10px"
                                    }}
                                >
                                    <ListItemIcon style={{minWidth: "30px"}}>
                                        <i className="fa fa-solid fa-map-marker"
                                           style={{color: activeLink === "/admin/states" ? "white" : "inherit"}}
                                        ></i>
                                    </ListItemIcon>
                                    <ListItemText>States</ListItemText>
                                </ListItemButton>
                                <ListItemButton
                                    key="Cities"
                                    component={Link}
                                    to="/admin/cities"
                                    style={{
                                        borderRadius: "4px",
                                        backgroundColor: activeLink === "/admin/cities" ? "#3E67B1" : "inherit",
                                        color: activeLink === "/admin/cities" ? "white" : "inherit",
                                        margin: "0px 10px"
                                    }}
                                >
                                    <ListItemIcon style={{minWidth: "30px"}}>
                                        <i className="fa fa-solid fa-city"
                                           style={{color: activeLink === "/admin/cities" ? "white" : "inherit"}}
                                        ></i>
                                    </ListItemIcon>
                                    <ListItemText>Cities</ListItemText>
                                </ListItemButton>
                                {/* <ListItemButton
                                    key="Universities"
                                    component={Link}
                                    to="/admin/universities"
                                    style={{
                                        borderRadius: "4px",
                                        backgroundColor: activeLink === "/admin/universities" ? "#3E67B1" : "inherit",
                                        color: activeLink === "/admin/universities" ? "white" : "inherit",
                                        margin: "0px 10px"
                                    }}
                                >
                                    <ListItemIcon style={{minWidth: "40px"}}>
                                        <i className="fa fa-solid fa-university"
                                           style={{color: activeLink === "/admin/universities" ? "white" : "inherit"}}
                                        ></i>
                                    </ListItemIcon>
                                    <ListItemText>Universities</ListItemText>
                                </ListItemButton> */}

                                <ListItemButton
                                    key="SkillMappings"
                                    component={Link}
                                    to="/admin/skillMappings"
                                    style={{
                                        borderRadius: "4px",
                                        backgroundColor: activeLink === "/admin/skillMappings" ? "#3E67B1" : "inherit",
                                        color: activeLink === "/admin/skillMappings" ? "white" : "inherit",
                                        margin: "0px 10px"
                                    }}
                                >
                                    <ListItemIcon style={{minWidth: "30px"}}>
                                        <i className="fa fa-solid fa-th"
                                           style={{color: activeLink === "/admin/skillMappings" ? "white" : "inherit"}}
                                        ></i>
                                    </ListItemIcon>
                                    <ListItemText>Skill Mappings</ListItemText>
                                </ListItemButton>
                                {/* <ListItemButton
                                    key="Designations"
                                    component={Link}
                                    to="/admin/designations"
                                    style={{
                                        borderRadius: "4px",
                                        backgroundColor: activeLink === "/admin/designations" ? "#3E67B1" : "inherit",
                                        color: activeLink === "/admin/designations" ? "white" : "inherit",
                                        margin: "0px 10px"
                                    }}

                                >
                                    <ListItemIcon style={{minWidth: "40px"}}>
                                        <i className="fa fa-solid fa-graduation-cap"
                                           style={{color: activeLink === "/admin/designations" ? "white" : "inherit"}}
                                        ></i>
                                    </ListItemIcon>
                                    <ListItemText>Designations</ListItemText>
                                </ListItemButton> */}
                                <ListItemButton
                                    key="ProfileTypes"
                                    component={Link}
                                    to="/admin/profileTypes"

                                    style={{
                                        borderRadius: "4px",
                                        backgroundColor: activeLink === "/admin/profileTypes" ? "#3E67B1" : "inherit",
                                        color: activeLink === "/admin/profileTypes" ? "white" : "inherit",
                                        margin: "0px 10px"
                                    }}
                                >
                                    <ListItemIcon style={{minWidth: "30px"}}>
                                        <i className="fa fa-solid fa-user"
                                           style={{color: activeLink === "/admin/profileTypes" ? "white" : "inherit"}}
                                        ></i>
                                    </ListItemIcon>
                                    <ListItemText>ProfileTypes</ListItemText>
                                </ListItemButton>
                            </List>
                        </Collapse>
                        <ListItemButton
                            key="112"
                            component={Link}
                            to="/admin/adminInvoice"
                            style={{
                                display: ["SUPER", "ADMIN"].includes(user.profileType)
                                    ? "flex"
                                    : "none",
                                backgroundColor: activeLink === "/admin/adminInvoice" ? "#3E67B1" : "inherit",
                                color: activeLink === "/admin/adminInvoice" ? "white" : "inherit",
                                borderRadius: "4px",
                                margin: "0px 10px"
                            }}
                        >
                            <ListItemIcon style={{minWidth: "30px"}}>
                                <i className="fa fa-solid fa-file-invoice"
                                      style={{color: activeLink === "/admin/adminInvoice" ? "white" : "inherit"}}
                                ></i>
                            </ListItemIcon>
                            <ListItemText>Admin Invoice</ListItemText>
                        </ListItemButton>


                        <ListItemButton
                        key="113"
                        component={Link}
                        to="/admin/panelSearch"
                        style={{
                            display: ["SUPER", "ADMIN"].includes(user.profileType)
                                ? "flex"
                                : "none",
                            backgroundColor: activeLink === "/admin/panelSearch" ? "#3E67B1" : "inherit",
                            color: activeLink === "/admin/panelSearch" ? "white" : "inherit",
                            borderRadius: "4px",
                            margin: "0px 10px"
                        }}
                        >
                        <ListItemIcon style={{minWidth: "30px"}}>
                            <i className="fa fa-solid fa-search"
                                style={{color: activeLink === "/admin/panelSearch" ? "white" : "inherit"}}
                            ></i>
                        </ListItemIcon>
                        <ListItemText>Panel Search</ListItemText>
                        </ListItemButton>

                        {/* CUSTOMER ROUTES */}
                        <ListItemButton
                            key="202"
                            component={Link}
                            to="/customer/deliveryUnits"
                            style={{
                                display: ["SUPER", "ADMIN", "CUSTOMER", "CUSTOMER_ADMIN"].includes(
                                    user.profileType
                                )
                                    ? "flex"
                                    : "none",
                                backgroundColor: activeLink === "/customer/deliveryUnits" ? "#3E67B1" : "inherit",
                                color: activeLink === "/customer/deliveryUnits" ? "white" : "inherit",
                                borderRadius: "4px",
                                margin: "0px 10px"
                            }}
                        >
                            <ListItemIcon style={{minWidth: "30px"}}>
                                <i className="fa fa-solid fa-building"
                                   style={{color: activeLink === "/customer/deliveryUnits" ? "white" : "inherit"}}
                                ></i>
                            </ListItemIcon>
                            <ListItemText>Delivery Units</ListItemText>
                        </ListItemButton>

                        <ListItemButton
                            key="203"
                            component={Link}
                            to="/customer/customerRequests"
                            style={{
                                display: ["CUSTOMER", "CUSTOMER_ADMIN"].includes(user.profileType)
                                    ? "flex"
                                    : "none",
                                backgroundColor: activeLink === "/customer/customerRequests" ? "#3E67B1" : "inherit",
                                color: activeLink === "/customer/customerRequests" ? "white" : "inherit",
                                borderRadius: "4px",
                                margin: "0px 10px"
                            }}
                        >
                            <ListItemIcon style={{minWidth: "30px"}}>
                                <i className="fa fa-solid fa-list"
                                   style={{color: activeLink === "/customer/customerRequests" ? "white" : "inherit"}}
                                ></i>
                            </ListItemIcon>
                            <ListItemText>Requests</ListItemText>
                        </ListItemButton>

                        <ListItemButton
                            key="204"
                            component={Link}
                            to="/customer/calendar"
                            style={{
                                display: ["CUSTOMER", "CUSTOMER_ADMIN"].includes(user.profileType)
                                    ? "flex"
                                    : "none",
                                backgroundColor: activeLink === "/customer/calendar" ? "#3E67B1" : "inherit",
                                color: activeLink === "/customer/calendar" ? "white" : "inherit",
                                borderRadius: "4px",
                                margin: "0px 10px"
                            }}
                        >
                            <ListItemIcon style={{minWidth: "30px"}}>
                                <i className="fa fa-solid fa-calendar-days"
                                   style={{color: activeLink === "/customer/calendar" ? "white" : "inherit"}}
                                ></i>
                            </ListItemIcon>
                            <ListItemText>Calendar</ListItemText>
                        </ListItemButton>

                        <ListItemButton
                            key="205"
                            component={Link}
                            to="/customer/interviewSchedules/all"
                            style={{
                                display: ["CUSTOMER", "CUSTOMER_ADMIN"].includes(user.profileType)
                                    ? "flex"
                                    : "none",
                                backgroundColor: activeLink === "/customer/interviewSchedules/all" ? "#3E67B1" : "inherit",
                                color: activeLink === "/customer/interviewSchedules/all" ? "white" : "inherit",
                                borderRadius: "4px",
                                margin: "0px 10px"
                            }}
                        >
                            <ListItemIcon style={{minWidth: "30px"}}>
                                <i className="fa fa-solid fa-book-open"
                                   style={{color: activeLink === "/customer/interviewSchedules/all" ? "white" : "inherit"}}
                                ></i>
                            </ListItemIcon>
                            <ListItemText>Interviews</ListItemText>
                        </ListItemButton>

                        {/*<ListItemButton*/}
                        {/*    key="206"*/}
                        {/*    component={Link}*/}
                        {/*    to="/customer/closedInterviews/all"*/}
                        {/*    style={{*/}
                        {/*        display: ["CUSTOMER", "CUSTOMER_ADMIN"].includes(user.profileType)*/}
                        {/*            ? "flex"*/}
                        {/*            : "none",*/}
                        {/*        backgroundColor: activeLink === "/customer/closedInterviews/all" ? "#3E67B1" : "inherit",*/}
                        {/*        color: activeLink === "/customer/closedInterviews/all" ? "white" : "inherit",*/}
                        {/*        borderRadius: "4px",*/}
                        {/*        margin: "0px 10px"*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <ListItemIcon style={{minWidth: "30px"}}>*/}
                        {/*        <i className="fa fa-solid fa-book"*/}
                        {/*           style={{color: activeLink === "/customer/closedInterviews/all" ? "white" : "inherit"}}*/}
                        {/*        ></i>*/}
                        {/*    </ListItemIcon>*/}
                        {/*    <ListItemText>Closed Interviews</ListItemText>*/}
                        {/*</ListItemButton>*/}

                        {/* PANEL ROUTES */}
                        <ListItemButton
                            key="302"
                            component={Link}
                            to="/panel/slotAvailability"
                            style={{
                                display: ["PANELIST"].includes(user.profileType) ? "flex" : "none",
                                backgroundColor: activeLink === "/panel/slotAvailability" ? "#3E67B1" : "inherit",
                                color: activeLink === "/panel/slotAvailability" ? "white" : "inherit",
                                borderRadius: "4px",
                                margin: "0px 10px"
                            }}
                        >
                            <ListItemIcon style={{minWidth: "30px"}}>
                                <i className="fa fa-solid fa-calendar"
                                   style={{color: activeLink === "/panel/slotAvailability" ? "white" : "inherit"}}
                                ></i>
                            </ListItemIcon>
                            <ListItemText>Slot Availability</ListItemText>
                        </ListItemButton>

                        <ListItemButton
                            key="303"
                            component={Link}
                            to="/panel/calendar"
                            style={{
                                display: ["PANELIST"].includes(user.profileType) ? "flex" : "none",
                                backgroundColor: activeLink === "/panel/calendar" ? "#3E67B1" : "inherit",
                                color: activeLink === "/panel/calendar" ? "white" : "inherit",
                                borderRadius: "4px",
                                margin: "0px 10px"
                            }}
                        >
                            <ListItemIcon style={{minWidth: "30px"}}>
                                <i className="fa fa-solid fa-calendar-days"
                                   style={{color: activeLink === "/panel/calendar" ? "white" : "inherit"}}
                                ></i>
                            </ListItemIcon>
                            <ListItemText>Panel Calendar</ListItemText>
                        </ListItemButton>

                        <ListItemButton
                            key="304"
                            component={Link}
                            to="/panel/interviewSchedules/all"
                            style={{
                                display: ["PANELIST"].includes(user.profileType) ? "flex" : "none",
                                backgroundColor: activeLink === "/panel/interviewSchedules/all" ? "#3E67B1" : "inherit",
                                color: activeLink === "/panel/interviewSchedules/all" ? "white" : "inherit",
                                borderRadius: "4px",
                                margin: "0px 10px"
                            }}
                        >
                            <ListItemIcon style={{minWidth: "30px"}}>
                                <i className="fa fa-solid fa-book-open"
                                style={{color: activeLink === "/panel/interviewSchedules/all" ? "white" : "inherit"}}
                                ></i>
                            </ListItemIcon>
                            <ListItemText>Scheduled Interviews</ListItemText>
                        </ListItemButton>

                        <ListItemButton
                            key="305"
                            component={Link}
                            to="/panel/closedInterviews/all"
                            style={{
                                display: ["PANELIST"].includes(user.profileType) ? "flex" : "none",
                                backgroundColor: activeLink === "/panel/closedInterviews/all" ? "#3E67B1" : "inherit",
                                color: activeLink === "/panel/closedInterviews/all" ? "white" : "inherit",
                                borderRadius: "4px",
                                margin: "0px 10px"
                            }}
                        >
                            <ListItemIcon style={{minWidth: "30px"}}>
                                <i className="fa fa-solid fa-book"
                                   style={{color: activeLink === "/panel/closedInterviews/all" ? "white" : "inherit"}}
                                ></i>
                            </ListItemIcon>
                            <ListItemText>Closed Interviews</ListItemText>
                        </ListItemButton>
                        <ListItemButton
                            key="305"
                            component={Link}
                            to="/panel/invoice/"
                            style={{
                                display: ["PANELIST"].includes(user.profileType) ? "flex" : "none",
                                backgroundColor: activeLink === "/panel/closedInterviews/all" ? "#3E67B1" : "inherit",
                                color: activeLink === "/panel/closedInterviews/all" ? "white" : "inherit",
                                borderRadius: "4px",
                                margin: "0px 10px"
                            }}
                        >
                            <ListItemIcon style={{minWidth: "30px"}}>
                                <i className="fa fa-solid fa-book"
                                   style={{color: activeLink === "/panel/closedInterviews/all" ? "white" : "inherit"}}
                                ></i>
                            </ListItemIcon>
                            <ListItemText>Invoices</ListItemText>
                        </ListItemButton>
                        {/* <ListItemButton
                            key="306"
                            component={Link}
                            to="/panel/PanelInvoice"
                            style={{
                                display: ["PANELIST"].includes(user.profileType) ? "flex" : "none",
                                backgroundColor: activeLink === "/panel/PanelInvoice" ? "#3E67B1" : "inherit",
                                color: activeLink === "/panel/PanelInvoice" ? "white" : "inherit",
                                borderRadius: "4px",
                                margin: "0px 10px"
                            }}
                        >
                            <ListItemIcon style={{minWidth: "30px"}}>
                                <i className="fa fa-solid fa-file-invoice"
                                      style={{color: activeLink === "/panel/PanelInvoice" ? "white" : "inherit"}}
                                ></i>
                            </ListItemIcon>
                            <ListItemText>Panel Invoice</ListItemText>
                        </ListItemButton> */}
                    </List>
                </Drawer>
            </StyledList>
            </StyledDrawer>
        </ThemeProvider>

                );
                };
export default SideNav;
