import { useState, useEffect } from 'react';

export default function useCalendarEvents(calendarRef, setModal) {
  // const initialEvents = events;
  const [initialEvents, setInitialEvents] = useState([]);
  
  const [selectedEvent,setSelectedEvent] = useState(null);
  const [editModelOpen,setEditModel] = useState(false);

  const [state, setState] = useState({
    // Initial state
  });

  const handleDateSelect = (selectInfo) => {
    // Code to handle date selection
  };

  const renderEventContent = (eventInfo) => {
    // Code to render event content
  };

  const handleEventClick = (clickInfo) => {
    // const {groupId,title,start,end, description, scheduleId, panelEmailId} = clickInfo.event.extendedProps
    // console.log({groupId,title,start,end})
    setSelectedEvent({...clickInfo.event.extendedProps,title:clickInfo.event.title})
    setEditModel(true)
    // Code to handle event click
  };

  const handleEventDrop = (dropInfo) => {
    // Code to handle event drop
  };

  const handleEventResize = (resizeInfo) => {
    // Code to handle event resize
  };

  // Other logic related to calendar events
  // ...

  return {
    selectedEvent,
    initialEvents,
    editModelOpen,
    setEditModel,
    handleDateSelect,
    renderEventContent,
    handleEventClick,
    handleEventDrop,
    handleEventResize,
    setInitialEvents,
    state,
    setState
  };
}
