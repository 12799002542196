import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import "../CSS/navBar.css";
import "../CSS/stats.css";
import "../CSS/what-is-ipaanel.css";
import "../CSS/what-client-says.css";
import "../CSS/contact-us.css";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Stepper, Step, StepLabel } from "@mui/material";
import Modal from "@mui/material/Modal";
import ContactForm from "./TalkToUsForms/ContactForm";
import Customer from "./TalkToUsForms/Customer";


import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';


const CircleStepIcon = (props) => {
  const { completed } = props;

  const circleStyle = {
    color: completed ? "#1976d2" : "#d6d6d6",
  };

  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiStepIcon-root Mui-completed css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root"
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 50 50"
      width="12"
      height="12"
      style={circleStyle}
    >
      <circle cx="24" cy="24" r="22" fill="currentColor"></circle>
    </svg>
  );
};



const drawerWidth = 240;
const navItems = ['Home', 'About us', 'Services', 'Why iPaanel', 'Contact Us'];


export default function LandingPage(props) {
  const [purpose, setPurpose] = useState("CUSTOMER");
  const [submit, setSubmit] = useState(false);

  const handleCloseSubmit = () => {
    setSubmit(false);
  };

  let navigate = useNavigate();
  function loginPage() {
    navigate("/login");
  }
  function purposeSaver(event) {
    setPurpose(event.target.value);
  }

  function handleContinueToSubmit() {
    setSubmit(true);
  }


  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }} >
      <Typography variant="h6" sx={{ my: 2 }}>
      <img src="/Images/ipannel-logo.jpeg" alt="Logo"/>
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
         <Button
                  type="button"
                  className="btn btn-primary me-3"
                  onClick={loginPage}
                >
                  SIGN IN
                </Button>
      </List>
    </Box>
  );

  const container_nav = window !== undefined ? () => window().document.body : undefined;

  return (
    <div style={{ background: "#f4f6fb" }}>
      <>
        {/* refer navBar.css for styling  */}

        {/* <nav className="navbar  navbar-expand-lg navbar-light bg-light">
          <div className="container">
            <div className="collapse navbar-collapse" id="navbarButtonsExample">
              <img
                src="/Images/ipannel-logo.jpeg"
                height="100%"
                width="1000%"
                alt="IPannel Logo"
              />
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item"></li>
              </ul>
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item"></li>
              </ul>

              <ul
                className="navbar-nav me-auto mb-2 mb-lg-0"
                style={{ fontWeight: "bold" }}
              >
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    About Us
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Services
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Why IPannel
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Contact Us
                  </a>
                </li>
              </ul>

              <div className="d-flex align-items-center">
                <button
                  type="button"
                  className="btn btn-primary me-3"
                  onClick={loginPage}
                >
                  SIGN IN
                </button>
              </div>
            </div>
          </div>
        </nav> */}

    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav" style={{background:'#FFFFFF 0% 0% no-repeat padding-box'}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon style={{color:'#3A3A3A'}}/>
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            <img src="/Images/ipannel-logo.jpeg" alt="Logo"/>
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' ,textAlign:'right' }} }>
            {navItems.map((item) => (
              <Button key={item} sx={{ color: '#3A3A3A' }}>
                {item}&nbsp;&nbsp;
              </Button>
            ))}
            <Button
                  type="button"
                  className="btn btn-primary me-3"
                  onClick={loginPage}
                >
                  SIGN IN
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container_nav}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', minWidth: drawerWidth },
           
          }}
        >
          
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ p: 3 }}>
     
       
      </Box>
    </Box>
       
      </>
      <br />
      <>
        <nav>
          <h1 style={{ textAlign: "center" }}>
            iPaanel transforms your way of talent quest
            <br /> Easy, Effective & Efficient.
          </h1>

          <p style={{ textAlign: "center" }}>
            An on-demand insight driven platform designed to enhance interview
            experience.
          </p>
          <center>
          <div className="button-container">
            <button className="btn btn-outline-primary"
            onClick={() => {
              setPurpose('PANELIST')
              document.getElementById("talk2usDiv").scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest"
              });
            }}>
              WANT TO BE A PANELIST
            </button>
            &nbsp; &nbsp;
            <button className="btn btn-primary"
            onClick={() => {
              setPurpose('CUSTOMER')
              document.getElementById("talk2usDiv").scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest"
              });
            }}
            >CONTACT FOR DEMO</button>
            </div>
          </center>
        </nav>
      </>
      <>
        <br />
        <img src="/Images/Mask_Group_42@2x.png" width="100%" />
      </>

      {/* <>
        <div className="stats-container">
          <div className="stats-item">
            <p className="stats-count">3,00,000</p>
            <p className="stats-text">Skilled People</p>
          </div>
          <div className="stats-item">
            <p className="stats-count">4000+</p>
            <p className="stats-text">Happy Clients</p>
          </div>
          <div className="stats-item">
            <p className="stats-count">2,00,000</p>
            <p className="stats-text">Hires</p>
          </div>
          <div className="stats-item">
            <p className="stats-count">1,00,000</p>
            <p className="stats-text">Live Interviews</p>
          </div>
          <div className="stats-item">
            <p className="stats-count">1,00,000</p>
            <p className="stats-text">Assessments</p>
          </div>
        </div>
      </> */}

      <div style={{  backgroundColor: "#FFFFFF",padding:'20px' }}>
        {/* refer what-is-ipaanel.css */}

        <center style={{ padding: "2%" }}>
          <h2>What is iPannel?</h2>
          <p>
            This is an interviewing platform solution combining highly
            proficient interviewers,
            <br /> automated interviewing processes, and robust reporting.
          </p>
        </center>

<div className="container" style={{padding:'0px 20px 20px 20px'}}>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <img
                src="/Images/what-is-iPannel.jpeg"
                alt="image"
                height="100%"
                width="100%"
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <br />
              <p className="OurMission">Our Mission</p>
              <br />
              <p className="OurMissionText">
                Our mission is to revolutionise the interview process by
                providing AI enabled, efficient, and personalised Interview as a
                Service platform that empowers and creates hiring experience
                through enhanced communication, collaboration, and feedback.
              </p>
              <br />

              <p className="OurVision">Our Vision</p>
              <br />
              <p className="OurVisionText">
                Our vision is to create a world where the interview process is
                seamless, efficient, and transparent. We envision a future where
                every candidate and employer is matched with the perfect job
                opportunity and fastest way.
              </p>
            </div>
        </div>
        </div>

      </div>

      <>
        <div style={{ backgroundColor: "#3E67B1",marginBottom:'10px' }}>
          <div className="container">
            <div className="row">
              <div
                className="col"
                style={{
                  justifyContent: "center",
                  marginTop: "60px",
                  color: "white",
                }}
              >
                <h2>
                  Best Qulaity
                  <br /> Services for your
                  <br /> Business
                </h2>
              </div>
              <div className="col-10">
                <div className="container-lg my-3">
                  <div
                    id="myCarousel"
                    className="carousel carousel-dark slide"
                    data-bs-ride="carousel"
                  >
                    <ol className="carousel-indicators" style={{color:'#FFFFFF'}}>
                      <li
                        data-bs-target="#myCarousel"
                        data-bs-slide-to="0"
                        className="active"
                      ></li>
                      <li
                        data-bs-target="#myCarousel"
                        data-bs-slide-to="1"
                      ></li>
                      <li
                        data-bs-target="#myCarousel"
                        data-bs-slide-to="2"
                      ></li>
                    </ol>

                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <div className="row">
                          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="card">
                              <div className="card-body">
                              <h5 className="card-title">
                                  <i className="fa fa-brands fa-apple"></i> You
                                  Source, We Interview
                                </h5>
                                <p className="card-text-1">
                                  This on-demand service helps the candidates
                                  take the interviews at their preferred timings
                                  without any dependency.
                                </p>
                                <a href="#" className="primary">
                                  READ MORE
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="card">
                              <div className="card-body">
                              <h5 className="card-title">
                                  <i className="fa fa-brands fa-apple"></i> You
                                  Source, We Interview
                                </h5>
                                <p className="card-text-1">
                                  This on-demand service helps the candidates
                                  take the interviews at their preferred timings
                                  without any dependency.
                                </p>
                                <a href="#" className="primary">
                                  READ MORE
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="card">
                              <div className="card-body">
                              <h5 className="card-title">
                                  <i className="fa fa-brands fa-apple"></i> You
                                  Source, We Interview
                                </h5>
                                <p className="card-text-1">
                                  This on-demand service helps the candidates
                                  take the interviews at their preferred timings
                                  without any dependency.
                                </p>
                                <a href="#" className="primary">
                                  READ MORE
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="row">
                          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="card">
                              <div className="card-body">
                                <h5 className="card-title">
                                  <i className="fa fa-brands fa-apple"></i> You
                                  Source, We Interview
                                </h5>
                                <p className="card-text-1">
                                  This on-demand service helps the candidates
                                  take the interviews at their preferred timings
                                  without any dependency.
                                </p>
                                <a href="#" className="primary">
                                  READ MORE
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="card">
                              <div className="card-body">
                                <h5 className="card-title">
                                  <i className="fa fa-brands fa-apple"></i> You
                                  Source, We Interview
                                </h5>
                                <p className="card-text-1">
                                  This on-demand service helps the candidates
                                  take the interviews at their preferred timings
                                  without any dependency.
                                </p>
                                <a href="#" className="primary">
                                  READ MORE
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="card">
                              <div className="card-body">
                                <h5 className="card-title">
                                  <i className="fa fa-brands fa-apple"></i> You
                                  Source, We Interview
                                </h5>
                                <p className="card-text-1">
                                  This on-demand service helps the candidates
                                  take the interviews at their preferred timings
                                  without any dependency.
                                </p>
                                <a href="#" className="primary">
                                  READ MORE
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="row">
                          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="card">
                              <div className="card-body">
                                <h5 className="card-title">
                                  <i className="fa fa-brands fa-apple"></i> You
                                  Source, We Interview
                                </h5>
                                <p className="card-text-1">
                                  This on-demand service helps the candidates
                                  take the interviews at their preferred timings
                                  without any dependency.
                                </p>
                                <a href="#" className="primary">
                                  READ MORE
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="card">
                              <div className="card-body">
                                <h5 className="card-title">
                                  <i className="fa fa-brands fa-apple"></i> You
                                  Source, We Interview
                                </h5>
                                <p className="card-text-1">
                                  This on-demand service helps the candidates
                                  take the interviews at their preferred timings
                                  without any dependency.
                                </p>
                                <a href="#" className="primary">
                                  READ MORE
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="card">
                              <div className="card-body">
                                <h5 className="card-title">
                                  <i className="fa fa-brands fa-apple"></i> You
                                  Source, We Interview
                                </h5>
                                <p className="card-text-1">
                                  This on-demand service helps the candidates
                                  take the interviews at their preferred timings
                                  without any dependency.
                                </p>
                                <a href="#" className="primary">
                                  READ MORE
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
     
      <>
        <div style={{ backgroundColor: "white" }}>
          <div className="row" style={{textAlign:'center'}}>
            <div className="col">
          
            <h2>For Whom?</h2>
            <p>
              This on-demand service helps the candidates take the interviews at
              their preferred timings
              <br />
              without any dependency on interpersonal communication.
            </p>
     
            </div>
          </div>
         
          <br />
          <div
            className="container"
            style={{ textAlign:'center'}}
          >
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 mb-4" >
                <img src="/Images/global-inhouse.jpeg" />
                <p className="text-under-image" style={{ fontWeight: "bold" }}>
                  Global In-house Centers /Captive
                </p>
                <p>
                  Global In-house Centers (GICs) or Captive Centers face unique
                  challenges when it comes to hiring and managing their talent
                  pool. These centers are typically located in geographically
                  dispersed locations and often operate within a highly
                  competitive and dynamic business environment.
                </p>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 mb-4" >
                <img src="/Images/consult-service-provider.jpeg" />
                <p className="text-under-image" style={{ fontWeight: "bold" }}>
                  Consulting Service Providers
                </p>
                <p>
                  Consulting and contracting service providers often face tight
                  timelines and resource constraints to accommodate changing
                  client requirements, making it difficult to manage the
                  interviewing process in-house.
                </p>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 mb-4" >
                <img src="/Images/IT-Services.jpeg" />
                <p className="text-under-image" style={{ fontWeight: "bold" }}>
                  IT Services Companies
                </p>
                <p>
                  An interview service can bring in expertise in identifying the
                  best talent, asking the right questions, and conducting a
                  thorough screening process. This can result in better hiring
                  decisions for the IT services companies, reducing the risk of
                  making costly hiring mistakes.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                <img src="/Images/product-development.jpeg" />
                <p className="text-under-image" style={{ fontWeight: "bold" }}>
                  Product Development companies
                </p>
                <p>
                  Product development companies may have changing hiring needs,
                  with spikes in demand for talent during certain stages of
                  product development. An interview service can provide
                  flexibility in terms of the timing of the interviews, allowing
                  product development companies to scale their hiring process up
                  or down as needed.
                </p>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 mb-4" >
                <img src="/Images/Startups.jpeg" />
                <p className="text-under-image" style={{ fontWeight: "bold" }}>
                  Startups
                </p>
                <p>
                  Conducting interviews in-house may prove to be a challenge due
                  to the availability of the required expertise. Our services
                  can provide an alternative solution that minimizes
                  distractions from the core focus area.
                </p>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 mb-4"></div>
            </div>
          </div>
        </div>
      </>

      <>
        {/* <div className="container">
          <br />
          <div className="row" style={{ textAlign: "center" }}>
            <div className="col">
              <h3>How iPannel Works</h3>
              <p>
                Our Enterprise upskilling solution combines immersive hands-on
                learning.
              </p>
            </div>
          </div>
          <br />
          <div className="row" style={{ textAlign: "center" }}>
            <div className="col-md-6 " style={{ marginTop: "10%" }}>
              <h5 style={{ fontWeight: "bold", textAlign: "left" }}>
                Input your Job description
              </h5>
              <p style={{ textAlign: "left" }}>
                Our platform enables you to create a job requirement and
                leverage our capabilities for scheduling and tracking technical
                interviews.
              </p>
            </div>

            <div className="col-md-6">
              <img
                src="/Images/how-ipannel-works-1.0.jpeg"
                width="90%"
                height="100%"
              />
            </div>
          </div>
          <br />
          <br />
          <br />

          <div className="row" style={{ textAlign: "center" }}>
            <div className="col-md-6">
              <img
                src="/Images/how-ipannel-works-2.0.jpeg"
                width="90%"
                height="100%"
              />
            </div>
            <div className="col-md-6 " style={{ marginTop: "10%" }}>
              <h5 style={{ fontWeight: "bold", textAlign: "left" }}>
                Update candidate availability
              </h5>
              <p style={{ textAlign: "left" }}>
                The portal will allow you to select available time slots of the
                candidate.
              </p>
            </div>
          </div>
          <br />
          <br />
          <br />
          <div className="row" style={{ textAlign: "center" }}>
            <div className="col-md-6 " style={{ marginTop: "10%" }}>
              <h5 style={{ fontWeight: "bold", textAlign: "left" }}>
                Technical experts and candidate's availability mapping
              </h5>
              <p style={{ textAlign: "left" }}>
                Using an uberization method, our AI technology maps panellists
                according to their skills and candidate availability
              </p>
            </div>

            <div className="col-md-6">
              <img
                src="/Images/how-ipannel-works-3.0.jpeg"
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <br />
          <br />
          <br />
          <div className="row" style={{ textAlign: "center" }}>
            <div className="col-md-6">
              <img
                src="/Images/how-ipannel-works-1.jpeg"
                width="100%"
                height="100%"
              />
            </div>
            <div className="col-md-6 " style={{ marginTop: "10%" }}>
              <h5 style={{ fontWeight: "bold", textAlign: "left" }}>
                Scheduling and Notifying
              </h5>
              <p style={{ textAlign: "left" }}>
                Our platform schedules the interview between the technical
                expert and candidate, and sends notifications once the details
                are confirmed.
              </p>
            </div>
          </div>
          <br />
          <br />
          <br />
          <div className="row" style={{ textAlign: "center" }}>
            <div className="col-md-6" style={{ marginTop: "10%" }}>
              <h5 style={{ fontWeight: "bold", textAlign: "left" }}>
                Structured Feedback
              </h5>
              <p style={{ textAlign: "left" }}>
                We offer a structured feedback report and video recording to
                support your hiring decisions.
              </p>
            </div>
            <div className="col-md-6 ">
              <img
                src="/Images/how-ipannel-works-2.jpeg"
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <br />
          <br />
          <br />
          <div className="row" style={{ textAlign: "center" }}>
            <div className="col-md-6">
              <img
                src="/Images/how-ipannel-works-3.jpeg"
                width="100%"
                height="100%"
              />
            </div>
            <div className="col-md-6 " style={{ marginTop: "10%" }}>
              <h5 style={{ fontWeight: "bold", textAlign: "left" }}>
                Metrics and reports
              </h5>
              <p style={{ textAlign: "left" }}>
                Our platform provides a dashboard and detailed metrics on
                parameters such as the number of interviews, selection ratio,
                and billing data.
              </p>
            </div>
          </div>
        </div> */}
        <div className="container how-ipannel-works" style={{marginTop:'10px'}}>
          <div className="row">
            <div className="col text-center">
              <h3>How iPannel Works</h3>
              <p>
                Our Enterprise upskilling solution combines immersive hands-on
                learning.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-5">
              <div>
                <h5 className="font-weight-bold text-left">
                  Input your Job description
                </h5>
                <p className="text-left">
                  Our platform enables you to create a job requirement and
                  leverage our capabilities for scheduling and tracking
                  technical interviews.
                </p>
              </div>
              <div>
                <img
                  src="/Images/how-ipannel-works-2.0.jpeg"
                  width="90%"
                  height="100%"
                />
              </div>
              <div>
                <h5 className="font-weight-bold text-left">
                  Technical experts and candidate's availability mapping
                </h5>
                <p className="text-left">
                  Using an uberization method, our AI technology maps panellists
                  according to their skills and candidate availability
                </p>
              </div>
              <div>
                <img
                  src="/Images/how-ipannel-works-1.jpeg"
                  width="100%"
                  height="100%"
                />
              </div>
              <div>
                <h5 className="font-weight-bold text-left">Structured Feedback</h5>
                <p className="text-left">
                  We offer a structured feedback report and video recording to
                  support your hiring decisions.
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="stepper-container">
                <Stepper
                  activeStep={2}
                  orientation="vertical"
                  className="full-height-stepper"
                >
                  <Step key={0}>
                    <StepLabel StepIconComponent={CircleStepIcon}></StepLabel>
                  </Step>
                  <Step key={1}>
                    <StepLabel StepIconComponent={CircleStepIcon}></StepLabel>
                  </Step>
                  <Step key={2}>
                    <StepLabel StepIconComponent={CircleStepIcon}></StepLabel>
                  </Step>
                </Stepper>
              </div>
            </div>
            <div className="col-md-5">
              <div>
                <img
                  src="/Images/how-ipannel-works-1.0.jpeg"
                  width="90%"
                  height="100%"
                />
              </div>
              <div>
                <h5 className="font-weight-bold text-left">
                  Update candidate availability
                </h5>
                <p className="text-left">
                  The portal will allow you to select available time slots of
                  the candidate.
                </p>
              </div>
              <div>
                <img
                  src="/Images/how-ipannel-works-3.0.jpeg"
                  width="100%"
                  height="100%"
                />
              </div>
              <div>
                <h5 className="font-weight-bold text-left">
                  Scheduling and Notifying
                </h5>
                <p className="text-left">
                  Our platform schedules the interview between the technical
                  expert and candidate, and sends notifications once the details
                  are confirmed.
                </p>
              </div>
              <div>
                <img
                  src="/Images/how-ipannel-works-2.jpeg"
                  width="100%"
                  height="100%"
                />
              </div>
              <div>
                <h5 className="font-weight-bold text-left">Metrics and reports</h5>
                <p className="text-left">
                  Our platform provides a dashboard and detailed metrics on
                  parameters such as the number of interviews, selection ratio,
                  and billing data.
                </p>
              </div>
            </div>
          </div>
        </div>

        <br />
      </>

      <>
        <div style={{ backgroundColor: "white" }}>
          <br />
          <h2 style={{ textAlign: "center" }}>What Client Says</h2>
          <br />
          <div
            id="carouselExampleControls"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="cards-wrapper">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-text">
                        The certification took me from a Project
                        <br /> Manager to a Senior Position with a good
                        <br /> hike. All credit to the informative and <br />
                        easy-to-understand online <br />
                        study material!
                      </h5>
                      <br />
                      <div
                        style={{ textAlign: "center" }}
                        className="small-ratings"
                      >
                        <i className="fa fa-star rating-color"></i>
                        <i className="fa fa-star rating-color"></i>
                        <i className="fa fa-star rating-color"></i>
                        <i className="fa fa-star  rating-color"></i>
                        <i className="fa fa-star  rating-color"></i>
                      </div>
                      <br />
                      <div className="card-footer bg-transparent ">
                        <div style={{ display: "flex" }}>
                          <img
                            src="/Images/michael-dam-mEZ3PoFGs_k-unspla_nw.png"
                            style={{
                              width: "50px",
                              height: "60px",
                            }}
                            alt="profile"
                          />
                          <p style={{ float: "right", marginLeft: "10px" }}>
                            <span style={{ fontWeight: "bold" }}>
                              Garima Khera
                            </span>
                            <br />
                            Project Manager, Wipro1
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card d-none d-md-block">
                    <div className="card-body">
                      <h5 className="card-text">
                        The certification took me from a Project
                        <br /> Manager to a Senior Position with a good <br />
                        hike. All credit to the informative and <br />{" "}
                        easy-to-understand online <br /> study material!
                      </h5>
                      <br />
                      <div
                        style={{ textAlign: "center" }}
                        className="small-ratings"
                      >
                        <i className="fa fa-star rating-color"></i>
                        <i className="fa fa-star rating-color"></i>
                        <i className="fa fa-star rating-color"></i>
                        <i className="fa fa-star  rating-color"></i>
                        <i className="fa fa-star  rating-color"></i>
                      </div>
                      <br />
                      <div className="card-footer bg-transparent ">
                        <div style={{ display: "flex" }}>
                          <img
                            src="/Images/michael-dam-mEZ3PoFGs_k-unspla_nw.png"
                            style={{
                              width: "50px",
                              height: "60px",
                            }}
                            alt="profile"
                          />
                          <p style={{ float: "right", marginLeft: "10px" }}>
                            <span style={{ fontWeight: "bold" }}>
                              Garima Khera
                            </span>
                            <br />
                            Project Manager, Wipro2
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card d-none d-md-block">
                    <div className="card-body">
                      <h5 className="card-text">
                        The certification took me from a Project
                        <br /> Manager to a Senior Position with a good <br />
                        hike. All credit to the informative and <br />{" "}
                        easy-to-understand online <br /> study material!
                      </h5>
                      <br />
                      <div
                        style={{ textAlign: "center" }}
                        className="small-ratings"
                      >
                        <i className="fa fa-star rating-color"></i>
                        <i className="fa fa-star rating-color"></i>
                        <i className="fa fa-star rating-color"></i>
                        <i className="fa fa-star  rating-color"></i>
                        <i className="fa fa-star  rating-color"></i>
                      </div>
                      <br />
                      <div className="card-footer bg-transparent ">
                        <div style={{ display: "flex" }}>
                          <img
                            src="/Images/michael-dam-mEZ3PoFGs_k-unspla_nw.png"
                            style={{
                              width: "50px",
                              height: "60px",
                            }}
                            alt="profile"
                          />
                          <p style={{ float: "right", marginLeft: "10px" }}>
                            <span style={{ fontWeight: "bold" }}>
                              Garima Khera
                            </span>
                            <br />
                            Project Manager, Wipro3
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="cards-wrapper">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-text">
                        The certification took me from a Project
                        <br /> Manager to a Senior Position with a good <br />
                        hike. All credit to the informative and <br />{" "}
                        easy-to-understand online <br /> study material!
                      </h5>
                      <br />
                      <div
                        style={{ textAlign: "center" }}
                        className="small-ratings"
                      >
                        <i className="fa fa-star rating-color"></i>
                        <i className="fa fa-star rating-color"></i>
                        <i className="fa fa-star rating-color"></i>
                        <i className="fa fa-star  rating-color"></i>
                        <i className="fa fa-star  rating-color"></i>
                      </div>
                      <br />
                      <div className="card-footer bg-transparent ">
                        <div style={{ display: "flex" }}>
                          <img
                            src="/Images/michael-dam-mEZ3PoFGs_k-unspla_nw.png"
                            style={{
                              width: "50px",
                              height: "60px",
                            }}
                            alt="profile"
                          />
                          <p style={{ float: "right", marginLeft: "10px" }}>
                            <span style={{ fontWeight: "bold" }}>
                              Garima Khera
                            </span>
                            <br />
                            Project Manager, Wipro4
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card d-none d-md-block">
                    <div className="card-body">
                      <h5 className="card-text">
                        The certification took me from a Project
                        <br /> Manager to a Senior Position with a good <br />
                        hike. All credit to the informative and <br />{" "}
                        easy-to-understand online <br /> study material!
                      </h5>
                      <br />
                      <div
                        style={{ textAlign: "center" }}
                        className="small-ratings"
                      >
                        <i className="fa fa-star rating-color"></i>
                        <i className="fa fa-star rating-color"></i>
                        <i className="fa fa-star rating-color"></i>
                        <i className="fa fa-star  rating-color"></i>
                        <i className="fa fa-star  rating-color"></i>
                      </div>
                      <br />
                      <div className="card-footer bg-transparent ">
                        <div style={{ display: "flex" }}>
                          <img
                            src="/Images/michael-dam-mEZ3PoFGs_k-unspla_nw.png"
                            style={{
                              width: "50px",
                              height: "60px",
                            }}
                            alt="profile"
                          />
                          <p style={{ float: "right", marginLeft: "10px" }}>
                            <span style={{ fontWeight: "bold" }}>
                              Garima Khera
                            </span>
                            <br />
                            Project Manager, Wipro5
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card d-none d-md-block">
                    <div className="card-body">
                      <h5 className="card-text">
                        The certification took me from a Project
                        <br /> Manager to a Senior Position with a good <br />
                        hike. All credit to the informative and <br />{" "}
                        easy-to-understand online <br /> study material!
                      </h5>
                      <br />
                      <div
                        style={{ textAlign: "center" }}
                        className="small-ratings"
                      >
                        <i className="fa fa-star rating-color"></i>
                        <i className="fa fa-star rating-color"></i>
                        <i className="fa fa-star rating-color"></i>
                        <i className="fa fa-star  rating-color"></i>
                        <i className="fa fa-star  rating-color"></i>
                      </div>
                      <br />
                      <div className="card-footer bg-transparent ">
                        <div style={{ display: "flex" }}>
                          <img
                            src="/Images/michael-dam-mEZ3PoFGs_k-unspla_nw.png"
                            style={{
                              width: "50px",
                              height: "60px",
                            }}
                            alt="profile"
                          />
                          <p style={{ float: "right", marginLeft: "10px" }}>
                            <span style={{ fontWeight: "bold" }}>
                              Garima Khera
                            </span>
                            <br />
                            Project Manager, Wipro6
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="carousel-item">
                <div className="cards-wrapper">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-text">
                        The certification took me from a Project
                        <br /> Manager to a Senior Position with a good <br />
                        hike. All credit to the informative and <br />{" "}
                        easy-to-understand online <br /> study material!
                      </h5>
                      <br />
                      <div
                        style={{ textAlign: "center" }}
                        className="small-ratings"
                      >
                        <i className="fa fa-star rating-color"></i>
                        <i className="fa fa-star rating-color"></i>
                        <i className="fa fa-star rating-color"></i>
                        <i className="fa fa-star  rating-color"></i>
                        <i className="fa fa-star  rating-color"></i>
                      </div>
                      <br />
                      <div className="card-footer bg-transparent ">
                        <div style={{ display: "flex" }}>
                          <img
                            src="/Images/michael-dam-mEZ3PoFGs_k-unspla_nw.png"
                            style={{
                              width: "50px",
                              height: "60px",
                            }}
                            alt="profile"
                          />
                          <p style={{ float: "right", marginLeft: "10px" }}>
                            <span style={{ fontWeight: "bold" }}>
                              Garima Khera
                            </span>
                            <br />
                            Project Manager, Wipro7
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card d-none d-md-block">
                    <div className="card-body">
                      <h5 className="card-text">
                        The certification took me from a Project
                        <br /> Manager to a Senior Position with a good <br />
                        hike. All credit to the informative and <br />{" "}
                        easy-to-understand online <br /> study material!
                      </h5>{" "}
                      <br />
                      <div
                        style={{ textAlign: "center" }}
                        className="small-ratings"
                      >
                        <i className="fa fa-star rating-color"></i>
                        <i className="fa fa-star rating-color"></i>
                        <i className="fa fa-star rating-color"></i>
                        <i className="fa fa-star  rating-color"></i>
                        <i className="fa fa-star  rating-color"></i>
                      </div>
                      <br />
                      <div className="card-footer bg-transparent ">
                        <div style={{ display: "flex" }}>
                          <img
                            src="/Images/michael-dam-mEZ3PoFGs_k-unspla_nw.png"
                            style={{
                              width: "50px",
                              height: "60px",
                            }}
                            alt="profile"
                          />
                          <p style={{ float: "right", marginLeft: "10px" }}>
                            <span style={{ fontWeight: "bold" }}>
                              Garima Khera
                            </span>
                            <br />
                            Project Manager, Wipro8
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card d-none d-md-block">
                    <div className="card-body">
                      <h5 className="card-text">
                        The certification took me from a Project
                        <br /> Manager to a Senior Position with a good <br />
                        hike. All credit to the informative and <br />{" "}
                        easy-to-understand online <br /> study material!
                      </h5>
                      <br />
                      <div
                        style={{ textAlign: "center" }}
                        className="small-ratings"
                      >
                        <i className="fa fa-star rating-color"></i>
                        <i className="fa fa-star rating-color"></i>
                        <i className="fa fa-star rating-color"></i>
                        <i className="fa fa-star  rating-color"></i>
                        <i className="fa fa-star  rating-color"></i>
                      </div>
                      <br />
                      <div className="card-footer bg-transparent ">
                        <div style={{ display: "flex" }}>
                          <img
                            src="/Images/michael-dam-mEZ3PoFGs_k-unspla_nw.png"
                            style={{
                              width: "50px",
                              height: "60px",
                            }}
                            alt="profile"
                          />
                          <p style={{ float: "right", marginLeft: "10px" }}>
                            <span style={{ fontWeight: "bold" }}>
                              Garima Khera
                            </span>
                            <br />
                            Project Manager, Wipro9
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />

            <a
              className="carousel-control-prev"
              href="#carouselExampleControls"
              role="button"
              data-slide="prev"
              style={{ backgroundColor: "white" }}
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
                style={{ backgroundColor: "white" }}
              ></span>
              <span className="sr-only" style={{ backgroundColor: "white" }}>
                Previous
              </span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleControls"
              role="button"
              data-slide="next"
              style={{ backgroundColor: "white" }}
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
                style={{ backgroundColor: "white" }}
              ></span>
              <span className="sr-only" style={{ backgroundColor: "white" }}>
                Next
              </span>
            </a>
          </div>
        </div>
      </>
      <>
        <div
          style={{
            background: "#f4f6fb",
            alignContent: "center",
            margin: "5%",
          }}
        >
          <h1 style={{ textAlign: "left" }}>Frequently Asked Questions</h1>
          <br />
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                1. How does iPaanel take care of Hiring Company's Branding?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                We pay utmost importance to our client’s brand value. All the
                emails communications to the candidate are sent with client’s
                branding providing candidate an excellent experience is one of
                the key product objectives.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <br />
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>
                2. How does iPaanel take care of Hiring Company's Branding?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                We pay utmost importance to our client’s brand value. All the
                emails communications to the candidate are sent with client’s
                branding providing candidate an excellent experience is one of
                the key product objectives.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <br />
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>
                3. How does iPaanel take care of Hiring Company's Branding?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                We pay utmost importance to our client’s brand value. All the
                emails communications to the candidate are sent with client’s
                branding providing candidate an excellent experience is one of
                the key product objectives.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <br />
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>
                4. How does iPaanel take care of Hiring Company's Branding?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                We pay utmost importance to our client’s brand value. All the
                emails communications to the candidate are sent with client’s
                branding providing candidate an excellent experience is one of
                the key product objectives.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <br />
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>
                5. How does iPaanel take care of Hiring Company's Branding?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                We pay utmost importance to our client’s brand value. All the
                emails communications to the candidate are sent with client’s
                branding providing candidate an excellent experience is one of
                the key product objectives.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </>

      <>
      <div id="talk2usDiv">
        {purpose == "CUSTOMER" ? (
         <Customer purpose={purpose} purposeSaver={purposeSaver}/>
        ):(
      <ContactForm purpose={purpose} purposeSaver={purposeSaver}/>
        )  }
      </div>
      </>
      {submit ? (
        <div>
          <Modal
            open={submit}
            onClose={() => handleCloseSubmit()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <Box sx={style1}> */}
            <>
              <div className="container" style={{ backgroundColor: "white" }}>
                <br />
                <div className="row" style={{ textAlign: "center" }}>
                  <div className="col">
                    <h3>Additional Details</h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <label>
                      <h6>Company Name</h6>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Company Name"
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <label>
                      <h6>Experience*</h6>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Experience"
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <label>
                      <h6>Primary Skills*</h6>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Primary Skills"
                    />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <label>
                      <h6>Secondary Skills</h6>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Secondary Skills"
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <label>
                      <h6>Talk to you on*</h6>
                    </label>
                    <input
                      className="form-control"
                      placeholder="Select Availability Date for Demo"
                      type="date"
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <label>
                      <h6>Talk to you at*</h6>
                    </label>
                    <select
                      className="form-control-lg-8"
                      style={{ width: "100%", height: "50%" }}
                    >
                      <option value="Select Time">Select Time</option>
                      <option value="volvo">10:00</option>
                      <option value="saab">11:00</option>
                    </select>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <label>
                      <h6>LinkedIn Profile</h6>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter LinkedIn Profile"
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <label>
                      <h6>Reffered By</h6>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Reffered By"
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <label>
                      <h6>Referee Mobile Number</h6>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Referee Mobile Number"
                    />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <label>
                      <h6>Referee Email ID</h6>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Referee Email ID"
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <label>
                      <h6>Upload Sheet*</h6>
                    </label>
                    <input className="form-control" type="file"></input>
                    <i>Supported files are pdf, jpg, png,</i>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-4"></div>
                </div>
                <br />
                <div className="row" style={{ textAlign: "center" }}>
                  <div className="col">
                    <button className="btn btn-outline-primary">CLOSE</button>{" "}
                    &nbsp;
                    <button className="btn btn-primary">SUBMIT</button>
                  </div>
                </div>
                <br />
              </div>
            </>
            {/* </Box> */}
          </Modal>
        </div>
      ) : (
        <></>
      )}

      <>
        <div className="container-my-footer" style={{ background: "#3A3A3A" }}>
          <footer className="text-center text-lg-start border border-white mt-xl-5 pt-4">
            <div className="container p-4">
              <div className="row">
                <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                  <h5 className="text-uppercase mb-4 text-white">Company</h5>

                  <ul className="list-unstyled mb-4">
                    <li>
                      <a href="#!" className="text-white">
                        Home
                      </a>
                    </li>
                    <li>
                      <a href="#!" className="text-white">
                        About Us
                      </a>
                    </li>
                    <li>
                      <a href="#!" className="text-white">
                        Services
                      </a>
                    </li>
                    <li>
                      <a href="#!" className="text-white">
                        Why Ipaanel
                      </a>
                    </li>
                    <li>
                      <a href="#!" className="text-white">
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                  <h5 className="text-uppercase mb-4 text-white">Address</h5>

                  <p className="text-white">
                    Ipaanel
                    <br />
                    A Unit of Karpaga Assessment App
                    <br /> Matrix Services Pvt. Ltd, SBT Towers, 2nd Floor,
                    <br />
                    Smaran Building, No. 4/2, Millers road,
                    <br />
                    Banglore - 560 052.
                  </p>
                </div>

                <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                  <h5 className="text-uppercase mb-4 text-white">Contact</h5>

                  <ul className="list-unstyled">
                    <li>
                      <a href="#!" className="text-white">
                        Email: info@ipaanel.com
                      </a>
                    </li>
                    <li>
                      <a href="#!" className="text-white">
                        Phone: 9663397508
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                  <h5 className="text-uppercase mb-4 text-white">Follow us</h5>

                  <div>
                    <a href="#!" className="text-white me-4">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="#!" className="text-white me-4">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href="#!" className="text-white me-4">
                      <i className="fab fa-google"></i>
                    </a>
                    <a href="#!" className="text-white me-4">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a href="#!" className="text-white me-4">
                      <i className="fab fa-linkedin"></i>
                    </a>
                    <a href="#!" className="text-white me-4">
                      <i className="fab fa-github"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <hr className="hr" />
            <section className="p-3 pt-0">
              <div className="row d-flex align-items-center">
                <div className="col-md-7 col-lg-8 text-center text-md-start">
                  <div className="p-3 text-white">Copyright © 2023 iPaanel</div>
                </div>

                <div className="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">
                  <a href="#!" className="text-white me-4">
                    Terms & Conditions
                  </a>
                  <a href="#!" className="text-white me-4">
                    Privacy Policy
                  </a>
                  <a href="#!" className="text-white me-4">
                    Privacy Legal
                  </a>
                </div>
              </div>
            </section>
          </footer>
        </div>
      </>
    </div>
  );
}
