import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  getInterviewSchedules, getAssignedUsers
} from "../../service/api";
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useParams } from "react-router-dom";
import FilterModal from "./FilterModel";
import "../Candidate/Candidate.css";
import "../../CSS/dashboard.css";
import { toast } from "react-toastify";
import Papa from 'papaparse';

function filterDataByDateRange(data, startDate, endDate) {
  const filteredData = data.filter((item) => {
      const itemDate = new Date(item.createdAt).toISOString().split('T')[0];
      return itemDate >= startDate && itemDate <= endDate;
  });
  return filteredData;
}

export default function InterviewSchedules() {
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');

  const { filterOptions } = useParams();
  const [searchStatus, setSearchStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [schedulesData, setSchedulesData] = useState([]);
  const [filteredSchedulesData, setFilteredSchedules] = useState([]);
  const [dataLoading, setDataLoading] = useState();
  const [filters, setFilters] = useState({});
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpenFilterModal = () => {
    setFilterModalOpen(true);
  };

  const handleCloseFilterModal = () => {
    setFilterModalOpen(false);
  };

  const handleApplyFilters = (newFilters) => {
    if (['all', 'CREATED','SCHEDULED'].includes(newFilters.status) ) {
      newFilters.status = '';
    }
    const updatedFilters = Object.fromEntries(Object.entries(newFilters).filter(([key, value]) => value !== ""));
    setFilters(updatedFilters);
  };

  const populateSchedules = () => {
    setDataLoading(true);
    getAssignedUsers().then((res) => {
      const userDict = res?.data.reduce((acc, { userId, firstName, lastName }) => {
        acc[userId] = `${firstName} ${lastName}`;
        return acc;
      }, {});

      getInterviewSchedules().then((response) => {
        let schedules = response?.data?.filter(e => ['SCHEDULED'].includes(e.status));

        if (startDate != '' && endDate != '') {
          schedules = filterDataByDateRange(schedules, startDate, endDate);
        }
        schedules.forEach((e) => {
          e.slot = `${e.scheduledSlot?.slotDate}, ${e.scheduledSlot?.slotTime}`;
          e.assigneeFullName = userDict[e.coordinatorId];
        });
        setSchedulesData(schedules.reverse());
        setDataLoading(false);
      }).catch((err) => {
        console.error(err);
      });

    });

  }

  useEffect(() => {
    if (filterOptions) {
      let [status, startDate, endDate] = filterOptions.split('|')
      console.log(status, startDate, endDate)
      setSearchStatus(status);
      setStartDate(startDate || '');
      setEndDate(endDate || '');
    }
    populateSchedules();
    handleApplyFilters({'status': searchStatus})
  }, [searchStatus]);

  useEffect(() => {
    const filterKeys = Object.keys(filters);
    if (filterKeys.length > 0) {
      const filteredData = schedulesData.filter(job =>
          filterKeys.every(key => {
            if (['SCHEDULED'].includes(filters[key])) {
              return String(job[key]).toLowerCase() == String(filters[key]).toLowerCase();
            } else {
              return String(job[key]).toLowerCase().includes(String(filters[key]).toLowerCase())
            }
          }
        )
      );
      setFilteredSchedules(filteredData);
    } else {
      setFilteredSchedules(schedulesData);
    }
  }, [filters, schedulesData]);

  const scheduleColumns = [
    {
      dataField: 'customerId',
      text: 'Customer ID',
      sort: true,
    },
    {
      dataField: 'customerCompany',
      text: 'Customer Name',
      sort: true,
    },
    {
      dataField: 'jobId',
      text: 'Job ID',
      sort: true,
    },
    {
      dataField: 'jobName',
      text: 'Job Name',
      sort: true,
    },
    // {
    //   dataField: 'scheduleId',
    //   text: 'Schedule ID',
    //   sort: true,
    // },
    // {
    //   dataField: 'panelEmailId',
    //   text: 'Panel Email ID',
    //   sort: true,
    // },
    // {
    //   dataField: 'candidateEmailId',
    //   text: 'Candidate Email ID',
    //   sort: true,
    // },
    // {
    //   dataField: 'coordinatorEmailId',
    //   text: 'Coordinator Email ID',
    //   sort: true,
    // },
    {
      dataField: 'panelName',
      text: 'Panel Name',
      sort: true,
    },
    {
      dataField: 'candidateName',
      text: 'Candidate Name',
      sort: true,
    },
    {
      dataField: 'assigneeFullName',
      text: 'Coordinator Name',
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
    },
    {
      dataField: 'slot',
      text: 'Slot',
      sort: true,
      style: { minWidth: '180px'},
      // formatter: (cell, row) => (
      //   <>
      //     <span>  {row.scheduledSlot?.slotDate},  {row.scheduledSlot?.slotTime} </span>
      //   </>
      // )
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: (cell, row) => (
        <>
          <i title="View Schedule"
            className="fa fa-light fa-eye"
            onClick={() => {
              if ((hasAllPermissions || userPermissions.includes('VIEW_SCHEDULED_INTERVIEW'))) {
                navigate(`/user/viewInterviewSchedule/${row.scheduleId}/${row.customerId}`);
              }
            }}
            style={{
              paddingLeft: "10px",
              cursor: "pointer",
              opacity: !(hasAllPermissions || userPermissions.includes('VIEW_SCHEDULED_INTERVIEW')) ? 0.2 : 1,
              pointerEvents: !(hasAllPermissions || userPermissions.includes('VIEW_SCHEDULED_INTERVIEW')) ? 'none' : 'auto',
            }}
          ></i>
          <i title="Edit Schedule"
            className="fa fa-light fa-pen"
            onClick={() => {
              if ((hasAllPermissions || userPermissions.includes('EDIT_SCHEDULED_INTERVIEW'))) {
                navigate(`/user/editInterviewSchedule/${row.scheduleId}/${row.customerId}`);
              }
            }}
            style={{
              paddingLeft: "10px",
              cursor: "pointer",
              opacity: !(hasAllPermissions || userPermissions.includes('EDIT_SCHEDULED_INTERVIEW')) ? 0.2 : 1,
              pointerEvents: !(hasAllPermissions || userPermissions.includes('EDIT_SCHEDULED_INTERVIEW')) ? 'none' : 'auto',
            }}
          ></i>
        </>
      ),
      style: () => {
        return {
          font: "Muli",
          minWidth: "120px",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
    }
  ];
  const options = {
    sizePerPage: 10,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '50', value: 50 },
    ],
    hideSizePerPage: false,
    hidePageListOnlyOnePage: false,
    alwaysShowAllBtns: true,
    showTotal: true,
    nextPageText: "Next",
    prePageText: "Prev",
  };
  const today = new Date().toISOString().slice(0, 10);
  const downloadInterviews = (filteredData) => {
    const modifiedData = filteredData.map(interview => ({
      'Customer Name': interview.customerCompany,
      'Customer Email ID': interview.customerEmailId,
      'Job ID': interview.jobId,
      'Job Name': interview.jobName,
      'Skill Family': interview.skillFamily,
      'Primary Skills': interview.primarySkills,
      'Candidate ID': interview.candidateId,
      'Candidate Name': interview.candidateName,
      'Candidate Email ID': interview.candidateEmailId,
      'Panel Name': interview.panelName,
      'Panel Email ID': interview.panelEmailId,
      'Coordinator Name': interview.coordinatorName,
      'Coordinator Email ID': interview.coordinatorEmailId,
      'Rate Category': interview.rateCategory,
      'Rate Card': interview.rateCard,
      'Date': interview.scheduledSlot.slotDate,
      'Time': interview.scheduledSlot.slotTime,
      'Timezone': interview.scheduledSlot.timezone,
      'Status': interview.status,
    }));
    const csv = Papa.unparse(modifiedData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Scheduled Interviews.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  }
  const filterDict = {
    'customerCompany': 'Customer Company',
    'scheduleId': 'Schedule ID',
    'slot': 'Slot',
    'status': 'Status',
    'panelEmailId': 'Panel Email ID',
    'candidateEmailId': 'Candidate Email ID',
    'coordinatorEmailId': 'Coordinator Email ID',
    'panelName': 'Panel Name',
    'candidateName': 'Candidate Name',
    'coordinatorName': 'Coordinator Name',
  }
  if (hasAllPermissions || userPermissions.includes('LIST_ADMIN_SCHEDULES')) {
    return (
      <div className="container-page">
        <div
          className="main-content shadow-radius"
          >
            <div className="row">
              <div className="col-3">
                  <label><h6>Start Date</h6></label>
                  <input
                      type="date"
                      id="startDate"
                      value={startDate}
                      max={today}
                      className="form-control"
                      onChange={(e) => setStartDate(e.target.value)}
                      style={{
                          height: "50px",
                          background: "#FFFFFF 0% 0% no-repeat padding-box",
                          border: "1px solid #C7C7C7",
                          borderRadius: "6px",
                          opacity: "1",
                      }}
                      placeholder="Start Date"
                  />
              </div>
              <div className="col-3">
                  <label><h6>End Date</h6></label>
                  <input
                      type="date"
                      id="endDate"
                      value={endDate}
                      // min={startDate}
                      max={today}
                      className="form-control"
                      onChange={(e) => setEndDate(e.target.value)}
                      style={{
                          height: "50px",
                          background: "#FFFFFF 0% 0% no-repeat padding-box",
                          border: "1px solid #C7C7C7",
                          borderRadius: "6px",
                          opacity: "1",
                      }}
                      placeholder="End Date"
                  />
              </div>
              <div className="col-2">
                  <button
                      className="btn btn-primary"
                      onClick={() => {
                          console.log(startDate, endDate)
                          if (startDate != '' && endDate != '') {
                            const filteredData = filterDataByDateRange(filteredSchedulesData, startDate, endDate)
                            setSchedulesData(filteredData.reverse())
                            downloadInterviews(filteredData.reverse())
                          } else {
                            setSchedulesData(filteredSchedulesData.reverse())
                            downloadInterviews(filteredSchedulesData.reverse())
                          }
                      }}
                      style={{
                          height: "50px",
                          width: "150px",
                          background: "#3E67B1 0% 0% no-repeat padding-box",
                          borderRadius: "4px",
                          opacity: "1",
                          marginTop: "30px"
                      }}
                  >
                    Download
                  </button>
              </div>
              <div className="col-4">
                <div>
                    <div  style={{textAlign: "right"}}>
                      <button
                          className="filters"
                          variant="contained"
                          color="primary"
                          onClick={handleOpenFilterModal}
                          style={{
                            height: "50px",
                            borderRadius: "4px",
                            opacity: "1",
                            marginTop: "30px"
                          }}
                      >
                        &nbsp;
                        <span className="filter-icon">
                      <img src="/Images/Filter.svg"/>
                    </span>
                        &nbsp;&nbsp;
                        <span className="filter-text">Filters</span>
                      </button>

                  </div>
                </div>

              </div>

            </div>

          <br></br>

          <div className="applied-filters" style={{display: Object.keys(filters).length > 0 ? 'block' : 'none'}}>
            <div className="applied-filters-text">
              <h4>Applied Filters ({Object.keys(filters).length})</h4>
            </div>
            <br></br>
            <div className="applied-filters-list ">
              {Object.keys(filters).map((d, i) => {
                return (filters[d] != '') ? <div>
                    <span className="applied-filter">
                      <label key={i}>{filterDict[d]}:- {filters[d]} &nbsp;{"  "}</label>
                      <i
                          className="fa fa-thin fa-circle-xmark"
                        style={{ color: "#3c77dd" }}
                        onClick={() => {
                          console.log('removeFilter called with:', d, filters);
                          const newFilters = { ...filters };
                          delete newFilters[d];
                          console.log("updatedFilters::", newFilters)
                          setFilters(newFilters);
                          handleApplyFilters(newFilters);
                        }}
                      ></i>
                    </span>
                    </div>: <></>
                  })}
                </div>
              <br />
            </div>

          <div className="custom-table-container">
            <BootstrapTable
              hover
              bootstrap4
              keyField="scheduleId"
              data={filteredSchedulesData}
              columns={scheduleColumns}
              pagination={paginationFactory(options)}
              noDataIndication={
                dataLoading ?
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                  <CircularProgress />
                </Box>
                :
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                  <span>No Data Found</span>
                </Box>
              }
            />

            {
            filterModalOpen?<FilterModal
              filters={filters}
              open={filterModalOpen}
              handleClose={handleCloseFilterModal}
              handleApply={handleApplyFilters}
            />: <></>
            }
          </div>
        </div>
      </div>
    );

  } else {
    return (
      <>
        <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
          <div className="card">
            <div className="card-body text-center">
              <h2 className="card-title">
                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                Access Denied
              </h2>
              <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}

