import React, { useRef, useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {  Tab, Tabs } from "react-bootstrap";

import "./Candidate.css";
import "./viewCandidate.css";
import {
  getJobs, getIpaanelUsers,getPanelsList, getCandidate,
  getCountries, getCustomers, getCandidateDocumentById,
} from '../../service/api';

import { toast } from "react-toastify";

export default function ViewCandidate() {
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');

  const { candidateId, customerId } = useParams();
  const [lpChecklistTabKey, setLPChecklistTabKey] = useState('home')
  const changeLPChecklistTab = (key) => {
      if (key !== lpChecklistTabKey) {
        setLPChecklistTabKey(key)
      }
  }
  const [jobs, setJobs] = useState([]);

  const [countries, setCountries] = useState([]);
  // const [states, setStates] = useState([]);
  // const [cities, setCities] = useState([]);
  // const [skillFamilies, setSkillFamilies] = useState([]);
  const [customersData, setCustomers] = useState([]);
  const navigate = useNavigate();
  // const [skillFamilyData, setSkillFamiliesData] = useState([]);
  // const [skillFamilyDict, setSkillFamiliesDict] = useState({});
  const [candidateResume, setCandidateResume] = useState(null);
  const [candidateResumeName, setCandidateResumeName] = useState('Choose a file');
  const [users, setUsers] = useState([]);
  const [panels, setPanels] = useState([]);
  const [dynamicSkills, setDynamicSkills] = useState([]);
  const [standardSkills, setStandardSkills] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [uploadDocument, setUploadDocument] = useState(null);
  const [uploadDocumentName, setUploadDocumentName] = useState('');

  const formRef = useRef(null);


  const [candidateObj, setCandidateData] = useState({
    customerId: customerId,
    custCoordinatorId: 0,
    jobId: 0,
    custCoordinatorName: '',
    custCoordinatorEmailId: '',
    customerCompany: '',
    candidateId: candidateId,
    candidateName: '',
    candidateEmailId: '',
    candidateResumeId: '',
    contactNumber: '',
    alternateContanctNumber: '',
    highestQualification: '',
    course: '',
    institute: '',
    isCurrentlyEmployed: '',
    currentCompany: '',
    currentRole: '',
    currentCountry: 'IND',
    currentState: '',
    currentCity: '',
    totalExperience: '',
    primarySkills: '',
    secondarySkills: '',
    skillFamily: '',
    socialProfile: '',
    slotDate1: '',
    slotTime1: '',
    timezone1: '',
    slotDate2: '',
    slotTime2: '',
    timezone2: '',
    dynamicSkills: [],
    standardSkills: [],
    documents: [],
    overallFeedback: '',
    recommendations: '',
    remarks: '',
    status: '',
    interviewDuration: '',
  });

  useEffect(() => {
    getIpaanelUsers().then((res) => {
      setUsers(res.data);
    });

    getJobs().then((res) => {
      const jobs = (res.data).map( job => {
        return {
          'jobId': parseInt(job.jobId),
          'jobName': job.jobName
        }
      });
      setJobs(jobs);
    });

    getCountries().then((res) => {
      setCountries(res.data);
    });
    getCustomers().then((res) => {
      const customers = (res.data).filter( e => e.profileType === 'CUSTOMER').map( cust => {
          return {
            'customerId': parseInt(cust.userId),
            'companyName': cust.companyName
          }
      });
      setCustomers(customers);
    });

  }, []);

  useEffect(() => {
    getCandidate(candidateId).then((res) => {
      getPanelsList(res.data.jobId).then((panels) => {
        setPanels(panels.data);
      });
      setCandidateData(res.data);
      setCandidateResumeName(res.data.candidateResumeId);
      setDynamicSkills(res.data.dynamicSkills);
      setStandardSkills(res.data.standardSkills);
      setDocuments(res.data.documents);
    });
  }, [candidateId]);

  const handleResumeDocumentChange = async (e) => {
    const selectedFile = e.target.files[0];
    setCandidateResume(selectedFile);
    setCandidateResumeName(selectedFile ? selectedFile.name : candidateResumeName);
  }

  if (hasAllPermissions || userPermissions.includes('VIEW_CANDIDATE')) {

    return (
      <>
        <div className="container-page">
          <div className="main-content" >


            <form ref={formRef}>
              <Tabs
                defaultActiveKey="home"
                className="mb-3 mt-2"
                onSelect={(e) => { if (e !== lpChecklistTabKey) { changeLPChecklistTab(e)}}}
              >
                <Tab eventKey="home" title="Candidate Details">
                  <div className="candidate-details" style={{ marginTop: "20px" }}>

                    <div className="row">
                      <div className="col container-heading">
                        <h6>Candidate Details</h6>
                      </div>
                    </div>

                    <div className="row">

                      <div className="col">
                        <label>
                          <div
                              className="candidate-view-header2"
                          >Customer Name </div>
                        </label>
                        <p className="custom-paragraph">
                          {customersData.find(c => c.customerId === candidateObj.customerId)?.companyName || 'No Customer Selected'}
                        </p>
                      </div>

                      <div className="col">
                        <label>
                          <div className="candidate-view-header2">Candidate ID</div>
                        </label>
                        <p className="custom-paragraph">
                          {candidateObj.candidateId || 'No Candidate ID'}
                        </p>
                      </div>
                      <div className="col">
                        <label>
                          <div className="candidate-view-header2">Status</div>
                        </label>
                        <p className="custom-paragraph">
                          {candidateObj.status || 'No Status'}
                        </p>
                      </div>
                    </div>
                    <br></br>
                    <div className="row">
                      <div className="col">
                        <label>
                          <div className="candidate-view-header2">Job Name </div>
                        </label>
                        <p className="custom-paragraph">
                          {jobs.find(job => job.jobId === candidateObj.jobId)?.jobName || 'No Job Selected'}
                        </p>
                      </div>
                      <div className="col">
                        <label>
                          <div className="candidate-view-header2">Candidate Name</div>
                        </label>
                        <p className="custom-paragraph">
                          {candidateObj.candidateName || 'No Candidate Name'}
                        </p>
                      </div>
                      <div className="col">
                        <label>
                          <div className="candidate-view-header2">Candidate Email ID</div>
                        </label>
                        <p className="custom-paragraph">
                          {candidateObj.candidateEmailId || 'No Email ID'}
                        </p>
                      </div>
                    </div>

                    <br></br>

                    <div className="row">
                      <div className="col">
                        <label>
                          <div className="candidate-view-header2">Country</div>
                        </label>
                        <p className="custom-paragraph">
                          {countries.find(country => country.countryCode === candidateObj.currentCountry)?.countryName || 'No Country Selected'}
                        </p>
                      </div>
                      <div className="col">
                        <label>
                          <div className="candidate-view-header2">Primary Skills</div>
                        </label>
                        <p className="custom-paragraph">
                          {candidateObj.primarySkills || 'No Primary Skills'}
                        </p>
                      </div>
                      <div className="col">
                        <label>
                          <div className="candidate-view-header2">Secondary Skills</div>
                        </label>
                        <p className="custom-paragraph">
                          {candidateObj.secondarySkills || 'No Secondary Skills'}
                        </p>
                      </div>
                    </div>

                    <br></br>

                    <div className="row">
                      <div className="col">
                        <label>
                          <div className="candidate-view-header2">Contact Number</div>
                        </label>
                        <p className="custom-paragraph">
                          {candidateObj.contactNumber || 'No Contact Number'}
                        </p>
                      </div>
                      <div className="col">
                        <label>
                          <div className="candidate-view-header2">Alternate Contact Number</div>
                        </label>
                        <p className="custom-paragraph">
                          {candidateObj.alternateContanctNumber || 'No Alternate Contact Number'}
                        </p>
                      </div>

                      <div className="col">
                        <label>
                          <div className="candidate-view-header2">Interview Duration</div>
                        </label>
                        <p className="custom-paragraph">
                          {candidateObj.interviewDuration || 'No Interview Duration'}
                        </p>
                      </div>
                    </div>

                  </div>

                  <div className="candidate-details"
                       style={{
                         marginTop: "20px", minHeight: "100px",
                         display: ["ADMIN", "SUPER"].includes(user.profileType) ? 'block' : 'none'
                       }}>
                    <div className="row">
                      <div className="col container-heading"><h6>Rate Card Details</h6></div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label>
                          <div className="candidate-view-header2">Rate Category</div>
                        </label>
                        <p className="custom-paragraph">
                          {candidateObj.rateCategory || 'No Rate Category'}
                        </p>
                      </div>

                      <div className="col">
                        <label>
                          <div className="candidate-view-header2">Rate Card </div>
                        </label>
                        <p className="custom-paragraph">
                          {candidateObj.rateCard || 'No Rate Card'}
                        </p>
                      </div>

                      <div className="col">
                        <label>
                          <div className="candidate-view-header2">Is Bonus Applicable </div>
                        </label>
                        <p className="custom-paragraph">
                          {candidateObj.isBonusApplicable ? 'Yes' : 'No'}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="candidate-details" style={{marginTop: "20px", minHeight: "200px"}}>
                  <div className="row">
                      <div className="col container-heading"><h6>Candidate Slots</h6></div>
                    </div>
                    <div className="availability-slot">
                      <div className="row">
                        <div className="col container-heading">Availability Slot 1</div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <label>
                            <div className="candidate-view-header2">Date</div>
                          </label>
                          <p className="custom-paragraph">
                            {candidateObj.slotDate1 || 'No Date Selected'}
                          </p>
                        </div>

                        <div className="col">
                          <label>
                            <div className="candidate-view-header2">Time</div>
                          </label>
                          <p className="custom-paragraph">
                            {candidateObj.slotTime1 || 'No Time Selected'}
                          </p>
                        </div>
                        <div className="col">
                          <label>
                            <div className="candidate-view-header2">Timezone</div>
                          </label>
                          <p className="custom-paragraph">
                            {candidateObj.timezone1 || 'No Timezone Selected'}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="availability-slot">
                      <div className="row">
                        <div className="col container-heading">Availability Slot 2</div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <label>
                            <div className="candidate-view-header2">Date </div>
                          </label>
                          <p className="custom-paragraph">
                            {candidateObj.slotDate2 || 'No Date Selected'}
                          </p>
                        </div>

                        <div className="col">
                          <label>
                            <div className="candidate-view-header2">Time</div>
                          </label>
                          <p className="custom-paragraph">
                            {candidateObj.slotTime2 || 'No Time Selected'}
                          </p>
                        </div>

                        <div className="col">
                          <label>
                          <div className="candidate-view-header2">Timezone</div>
                          </label>
                          <p className="custom-paragraph">
                            {candidateObj.timezone2 || 'No Timezone Selected'}
                          </p>
                        </div>
                      </div>
                    </div>


                  </div>

                  <div className="candidate-details" style={{ marginTop: "20px", minHeight:"100px" }}>
                    <div className="row">
                      <div className="col container-heading">
                        <h5>Education Details</h5></div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label>
                          <div className="candidate-view-header2">Highest Qualification</div>
                        </label>
                        <p className="custom-paragraph">
                          {candidateObj.highestQualification || 'No Highest Qualification'}
                        </p>
                      </div>

                      <div className="col">
                        <label>
                          <div className="candidate-view-header2">Course </div>
                        </label>
                        <p className="custom-paragraph">
                          {candidateObj.course || 'No Course'}
                        </p>
                      </div>

                      <div className="col">
                        <label>
                          <div className="candidate-view-header2">Institute </div>
                        </label>
                        <p className="custom-paragraph">
                          {candidateObj.institute || 'No Institute'}
                        </p>
                      </div>
                    </div>

                  </div>

                  <div className="candidate-details" style={{ marginTop: "20px", minHeight:"240px" }}>
                    <div className="row">
                      <div className="col container-heading"><h5>Employment Details</h5></div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label>
                          <div className="candidate-view-header2">Are you currently Employed? </div>
                        </label>
                        <p className="custom-paragraph">
                          {candidateObj.isCurrentlyEmployed || 'No Selection'}
                        </p>
                      </div>

                      <div className="col">
                        <label>
                          <div className="candidate-view-header2">Total Experience </div>
                        </label>
                        <p className="custom-paragraph">
                          {candidateObj.totalExperience || 'No Total Experience'}
                        </p>
                      </div>

                      <div className="col">
                        <label>
                          <div className="candidate-view-header2">Relevant Experience </div>
                        </label>
                        <p className="custom-paragraph">
                          {candidateObj.relevantExperience || 'No Relevant Experience'}
                        </p>
                      </div>
                    </div>

                    <br></br>

                    <div className="row">
                      <div className="col">
                        <label>
                          <div className="candidate-view-header2">Current Company</div>
                        </label>
                        <p className="custom-paragraph">
                          {candidateObj.currentCompany || 'No Current Company'}
                        </p>
                      </div>

                      <div className="col">
                        <label>
                          <div className="candidate-view-header2">Current Role/Designation</div>
                        </label>
                        <p className="custom-paragraph">
                          {candidateObj.currentRole || 'No Current Role'}
                        </p>
                      </div>
                      <div className="col">
                      </div>
                    </div>
                  </div>

                  <div className="candidate-details" style={{ marginTop: "20px", minHeight:"100px" }}>
                    <div className="row">
                      <div className="col container-heading"><h5>Social Profile Link</h5></div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label>
                          <div className="candidate-view-header2">Social Profile</div>
                        </label>
                        <p className="custom-paragraph">
                          {candidateObj.socialProfile || 'No Social Profile'}
                        </p>
                      </div>
                      <div className="col">

                      </div>
                    </div>

                  </div>

                  <div className="comments-remarks" style={{marginTop: "20px", padding: "20px"}}>
                    <div className="row">
                      <div className="col container-heading"><h5>Upload Candidate Resume </h5></div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label
                          for="candidateResume"
                          style={{
                            height: "50px",
                            width: "600px",
                            background: "#EEF4FF 0% 0% no-repeat padding-box",
                            borderRadius: "4px",
                            opacity: 1,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                        <span
                          style={{
                            width: "600px",
                            height: "50px",
                            background: "#EEF4FF 0% 0% no-repeat padding-box",
                            borderRadius: "4px",
                            opacity: 1,
                            display: "flex",
                            alignItems: "center",
                            alignContent: "center",
                          }}
                        >
                          <img src="/Images/pdfIcon.svg" style={{paddingLeft: '20px'}} />
                          <span style={{marginLeft: '2%'}}>{candidateResumeName}</span>
                          <span>
                            <i className="fa-solid fa-download"
                              style={{
                                cursor: 'pointer',
                                paddingLeft: '20px'
                              }}
                              onClick={async (e) => {
                                e.preventDefault(true)
                                console.log("Clicked", candidateObj.candidateResumeId)
                                let documentId = candidateObj.candidateResumeId;
                                try {
                                  let data = await getCandidateDocumentById(documentId);
                                  const blobUrl = URL.createObjectURL(data);
                                  const link = document.createElement('a');
                                  link.href = blobUrl;
                                  link.download = documentId;
                                  document.body.appendChild(link);
                                  link.click();
                                  document.body.removeChild(link);
                                  URL.revokeObjectURL(blobUrl);
                                } catch (error) {
                                  toast.error('Unable to download Document..!')
                                }
                              }}
                              >
                              </i>
                            </span>
                        </span>
                        <input
                          style={{ display: "none" }}
                          id="candidateResume"
                          type="file"
                          name="candidateResume"
                          disabled={true}
                          accept=".pdf"
                          onChange={handleResumeDocumentChange}
                        />
                      </label>
                      <i>
                      Only PDF/DOCX Files Supported with Size lessthan 500KB
                      </i>
                    </div>
                  </div>
                  </div>

                  <div className="comments-remarks" style={{ marginTop: "20px", padding: "20px" }} >
                    <div className="row">
                      <div className="col">
                        <label>
                          <div className="candidate-view-header2">Assignee</div>
                        </label>
                        <p className="custom-paragraph">
                          {users.find(user => user.userId === candidateObj.assigneeId)?.firstName || 'No Assignee Selected'}
                        </p>
                      </div>

                      <div className="col">
                        <label>
                          <div className="candidate-view-header2">Assignee Email ID</div>
                        </label>
                        <p className="custom-paragraph">
                          {candidateObj.assigneeEmailId || 'No Email ID'}
                        </p>
                      </div>

                      <div className="col">
                      </div>

                    </div>
                    <br></br>

                    <div className="row">
                      <div className="col">
                        <label>
                          <div className="candidate-view-header2">Panel</div>
                        </label>
                        <p className="custom-paragraph">
                          {panels.find(panel => panel.userId === candidateObj.panelId)?.firstName || 'No Panel Selected'}
                        </p>
                      </div>

                      <div className="col">
                        <label>
                          <div className="candidate-view-header2">Panel Email ID</div>
                        </label>
                        <p className="custom-paragraph">
                          {candidateObj.panelEmailId || 'No Email ID'}
                        </p>
                      </div>

                      <div className="col">
                      </div>

                    </div>
                    <br></br>

                    <div className="row">
                      <div className="col-10">
                        <label>
                          <div className="candidate-view-header2">Remarks</div>
                        </label>
                        <p className="custom-paragraph">
                          {candidateObj.remarks || 'No Remarks'}
                        </p>
                      </div>
                    </div>
                    <div className="row" style={{marginTop: '20px', textAlign: 'center', paddingBottom: '5px'}}>
                    <div className="col" style={{ textAlign: 'center' }}>
                        <button
                          type="button"
                          className="btn btn-outline-primary me-2"
                          style={{
                            border: '1px solid #3E67B1',
                            borderRadius: ' 4px',
                            opacity: '1',
                            width: '96px',
                            height: '50px',
                          }}
                          onClick={()=>{
                            navigate(-1);
                          }}
                        >
                          BACK
                        </button>

                      </div>
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="start" title="Assessment Skills">
                  <div className="comments-remarks" style={{ margin: "20px", padding: "20px" }} >
                    <div className="row">
                      <div className="col text-left"><div className="candidate-view-header2">Skill Assessment</div></div>
                    </div>
                    <table className="table">
                      <thead>
                        <tr>
                          <th >Topic/Technology</th>
                          <th >Expected Profeciency</th>
                          <th>Possess Profeciency</th>
                          <th style={{textAlign:'left'}}>Rating</th>
                          <th style={{textAlign:'left'}}>Remarks/Comments</th>
                        </tr>
                      </thead>
                      <tbody>
                      {dynamicSkills.map((cell) => (
                        <tr key={cell.skillIndex}>
                          <td className="col-2">
                            <input
                              className="form-control editable"
                              type="text"
                              disabled={true}
                              onChange={(event) => {
                                setDynamicSkills((prev) => {
                                  const updatedSkills = [...prev];
                                  updatedSkills[cell.skillIndex].skillTopic = event.target.value;
                                  return updatedSkills;
                                });
                              }}
                              style={{ height: '30px', width: "100%" }}
                              name="skillTopic"
                              value={cell.skillTopic}
                            />
                          </td>
                          <td className="col-2">
                            <select
                              className="form-control-lg-8 drop-down"
                              disabled={true}
                              onChange={(event) => {
                                setDynamicSkills((prev) => {
                                  const updatedSkills = [...prev];
                                  updatedSkills[cell.skillIndex].expectedProficiency = event.target.value;
                                  return updatedSkills;
                                });
                              }}
                              name="expectedProficiency"
                              value={cell.expectedProficiency}
                              style={{height: '30px', borderRadius:'5px', width: "100%"}}
                              >
                              <option key={1} value='BEGINNER'>BEGINNER</option>
                              <option key={2} value='AVERAGE'>AVERAGE</option>
                              <option key={3} value='INTERMEDIATE'>INTERMEDIATE</option>
                              <option key={4} value='GOOD'>GOOD</option>
                              <option key={5} value='ADVANCED'>ADVANCED</option>
                            </select>

                          </td>
                          <td className="col-2">
                            <select
                              className="form-control-lg-8 drop-down"
                              onChange={(event) => {
                                setDynamicSkills((prev) => {
                                  const updatedSkills = [...prev];
                                  updatedSkills[cell.skillIndex].possessProficiency = event.target.value;
                                  return updatedSkills;
                                });
                              }}
                              name="possessProficiency"
                              disabled={true}
                              value={cell.possessProficiency}
                              style={{height: '30px', borderRadius:'5px', width: "100%"}}
                              >
                              <option key={0} value=''>Select</option>
                              <option key={1} value='BEGINNER'>BEGINNER</option>
                              <option key={2} value='AVERAGE'>AVERAGE</option>
                              <option key={3} value='INTERMEDIATE'>INTERMEDIATE</option>
                              <option key={4} value='GOOD'>GOOD</option>
                              <option key={5} value='ADVANCED'>ADVANCED</option>
                            </select>
                          </td>
                          <td className="col-2">
                            <select
                              className="form-control-lg-8 drop-down"
                              onChange={(event) => {
                                setDynamicSkills((prev) => {
                                  const updatedSkills = [...prev];
                                  updatedSkills[cell.skillIndex].rating = event.target.value;
                                  return updatedSkills;
                                });
                              }}
                              name="rating"
                              value={cell.rating}
                              disabled={true}
                              style={{height: '30px', borderRadius:'5px', width: "100%"}}
                              >
                              <option key={0} value=''>Select</option>
                              <option key={1} value='1'>&#9733; &#9734; &#9734; &#9734; &#9734;</option>
                              <option key={2} value='2'>&#9733; &#9733; &#9734; &#9734; &#9734;</option>
                              <option key={3} value='3'>&#9733; &#9733; &#9733; &#9734; &#9734;</option>
                              <option key={4} value='4'>&#9733; &#9733; &#9733; &#9733; &#9734;</option>
                              <option key={5} value='5'>&#9733; &#9733; &#9733; &#9733; &#9733;</option>
                            </select>
                          </td>
                          <td className="col-4">
                            <input
                              className="form-control editable"
                              type="text"
                              onChange={(event) => {
                                const { value } = event.target;
                                setDynamicSkills((prev) => {
                                  const updatedSkills = prev.map((skill) => {
                                    if (skill.skillIndex === cell.skillIndex) {
                                      return { ...skill, comments: value };
                                    }
                                    return skill;
                                  });
                                  return updatedSkills;
                                });
                              }}
                              style={{ height: '30px', width: "100%" }}
                              name="comments"
                              disabled={true}
                              value={cell.comments}
                            />
                          </td>

                        </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="comments-remarks" style={{ margin: "20px", padding: "20px" }} >
                    <div className="row">
                      <div className="col text-left"><div className="candidate-view-header2">Standard Competency Description</div></div>
                    </div>
                    <table className="table">
                      <thead>
                        <tr>
                          <th >Topic/Skill</th>
                          <th style={{textAlign:'left'}}>Rating</th>
                          <th style={{textAlign:'left'}}>Remarks/Comments</th>
                        </tr>
                      </thead>
                      <tbody>
                      {standardSkills.map((cell) => (
                        <tr key={cell.skillIndex}>
                          <td className="col-6">
                            <span>
                            {cell.skillTopic}
                            </span>
                          </td>
                          <td className="col-2">
                          <select
                            className="form-control-lg-8 drop-down"
                            onChange={(event) => {
                              setStandardSkills((prev) => {
                                const updatedSkills = [...prev];
                                updatedSkills[cell.skillIndex].rating = event.target.value;
                                return updatedSkills;
                              });
                            }}
                            name="rating"
                            disabled={true}
                            value={cell.rating}
                            style={{height: '30px', borderRadius:'5px', width: "100%"}}
                            >
                            <option key={0} value=''>Select</option>
                            <option key={1} value='1'>&#9733; &#9734; &#9734; &#9734; &#9734;</option>
                            <option key={2} value='2'>&#9733; &#9733; &#9734; &#9734; &#9734;</option>
                            <option key={3} value='3'>&#9733; &#9733; &#9733; &#9734; &#9734;</option>
                            <option key={4} value='4'>&#9733; &#9733; &#9733; &#9733; &#9734;</option>
                            <option key={5} value='5'>&#9733; &#9733; &#9733; &#9733; &#9733;</option>
                          </select>
                          </td>
                          <td className="col-4">
                            <input
                              className="form-control editable"
                              type="text"
                              onChange={(event) => {
                                const { value } = event.target;
                                setStandardSkills((prev) => {
                                  const updatedSkills = prev.map((skill) => {
                                    if (skill.skillIndex === cell.skillIndex) {
                                      return { ...skill, comments: value };
                                    }
                                    return skill;
                                  });
                                  return updatedSkills;
                                });
                              }}
                              style={{ height: '30px', width: "100%" }}
                              name="comments"
                              disabled={true}
                              value={cell.comments}
                            />
                          </td>
                        </tr>

                      ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="comments-remarks" style={{ margin: "20px", padding: "20px" }} >
                    <div className="row">
                      <div className="col-4">
                        <label>
                          <h6>Overall Feedback</h6>
                        </label>
                        <select
                          className="form-control-lg drop-down"
                          style={{ width: "100%", height: "50%" }}
                          onChange={(event) => {
                            setCandidateData((prevCandidateData) => ({
                              ...prevCandidateData,
                              overallFeedback: event.target.value
                            }));
                          }}
                          name="overallFeedback"
                          disabled={true}
                          value={candidateObj.overallFeedback}
                        >
                          <option value="">Select </option>
                          <option value="SELECTED">SELECTED</option>
                          <option value="NEAR MATCH">NEAR MATCH</option>
                          <option value="TRAINABLE">TRAINABLE</option>
                          <option value="HOLD">HOLD</option>
                          <option value="REJECTED">REJECTED</option>
                        </select>
                      </div>
                      <div className="col-8" >
                        <label>
                          <div className="candidate-view-header2">Recommendations</div>
                        </label>
                        <input
                          type="text"
                          className="form-control editable"
                          placeholder="Enter Recommendations"
                          onChange={(event) => {
                            setCandidateData((prevCandidateData) => ({
                              ...prevCandidateData,
                              recommendations: event.target.value
                            }));
                          }}
                          name="recommendations"
                          disabled={true}
                          value={candidateObj.recommendations}
                        />
                      </div>

                    </div>
                    <br></br>

                    <div className="row">
                      <div className="col-6" >
                        <label>
                          <di className="candidate-view-header2">Recorded Meeting Link</di>
                        </label>
                        {
                          candidateObj.recordedVideoUrl != "" ?
                          <div>
                            <a href={candidateObj.recordedVideoUrl} target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-file-video" style={{marginLeft: "0px"}}></i>{" "} Click Here to View Recorded Meeting
                            </a>
                          </div> :
                          <div>
                            Not Available
                          </div>
                        }
                      </div>
                    </div>
                  </div>

                  <div className="comments-remarks" style={{ margin: "20px", padding: "20px" }} >

                    <div className="row">
                      <table className="table">
                        <thead>
                          <tr>
                            <th >Document Name</th>
                            <th style={{textAlign:'left'}}>Document Link</th>
                            <th style={{textAlign:'left'}}> Actions </th>
                          </tr>
                        </thead>
                        <tbody>
                        {documents.map((cell) => (
                          <tr key={cell.skillIndex}>
                            <td className="col-4">
                              <span>
                              {cell.documentName}
                              </span>
                            </td>
                            <td className="col-4">
                              <span>
                              {cell.documentId}
                              </span>
                            </td>
                            <td className="col-4">
                              <span>
                                <i className="fa-solid fa-download"
                                style={{
                                  cursor: 'pointer'
                                }}
                                onClick={async (e) => {
                                  e.preventDefault(true)
                                  console.log("Clicked", cell.documentId)
                                  let documentId = cell.documentId;
                                  try {
                                    let data = await getCandidateDocumentById(documentId);
                                    const blobUrl = URL.createObjectURL(data);
                                    const link = document.createElement('a');
                                    link.href = blobUrl;
                                    link.download = documentId;
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);
                                    URL.revokeObjectURL(blobUrl);
                                  } catch (error) {
                                    toast.error('Unable to download Document..!')
                                  }
                                }}
                                >
                                </i>
                              </span>

                            </td>

                          </tr>
                        ))}
                      </tbody>
                    </table>
                    </div>
                  </div>

                  <div className="comments-remarks" style={{ margin: "20px", padding: "20px" }} >

                    <div className="row" style={{ marginTop: '20px', textAlign: 'center', paddingBottom: '5px' }}>
                      <div className="col" style={{ textAlign: 'center' }}>
                        <button
                          type="button"
                          className="btn btn-outline-primary me-2"
                          style={{
                            border: '1px solid #3E67B1',
                            borderRadius: ' 4px',
                            opacity: '1',
                            width: '96px',
                            height: '50px',
                          }}
                          onClick={()=>{
                            navigate(-1);
                          }}
                        >
                          BACK
                        </button>
                      </div>
                    </div>
                  </div>


                  <br></br>
                  <br></br>

                </Tab>

              </Tabs>
            </form>

          </div>

        </div>
      </>
    );

  } else {
    return (
      <>
        <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
          <div className="card">
            <div className="card-body text-center">
              <h2 className="card-title">
                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                Access Denied
              </h2>
              <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}
