import React, { useRef, useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import Box from "@mui/material/Box";

import "./Job.css";
import {
  getCustomerDeliveryUnits, getCountries, getSkillFamilies,
  getCustomers,
  getJob, getIpaanelUsers,
  getSkillAssessments

} from '../../service/api';

import moment from 'moment-timezone';

export default function ViewJob() {
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');

  const { jobId, customerId } = useParams();
  const [delUnits, setDelUnits] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countriesDict, setCountriesDict] = useState({});

  const [skillFamilies, setSkillFamilies] = useState([]);
  // const [vendorsData, setVendors] = useState([]);
  const [customersData, setCustomers] = useState([]);
  const [customerCoordinatorsData, setCustomerCoordinators] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersDict, setUsersDict] = useState({});

  const formRef = useRef(null);
  const navigate = useNavigate();

  const [jobObj, setJobData] = useState({
    customerId: 0,
    custCoordinatorId: 0,
    vendorId: 0,
    delUnitId: 0,
    custCoordinatorName: '',
    custCoordinatorEmailId: '',
    customerCompany: '',
    deliveryUnit: '',
    vertical: '',
    lineOfBusiness: '',
    accountName: '',

    jobName: '',
    jobDescription: '',
    positionsRequired: 0,
    keyResponsibilities: '',
    rateCategory: '',
    rateCard: '',
    isBonusApplicable: false,

    skillFamily: '',
    primarySkills: '',
    secondarySkills: '',
    interviewDuration: '30 Mins',
    requiredExperience: 1,
    shiftType: 'Hybrid',
    shiftTimings: '09:00 - 18:00',
    country: "IND",

    sourcing: 'External',
    panels: [],
    vendors: [],
    inviteSendToCandidates: true,
    remarks: '',
    assigneeName: '',
    assigneeEmailId: '',
    assigneeId: 0

  });

  let [skillData, setSkillData] = useState([]);

  const panelDataColumns = [
    {
      dataField: "emailId",
      text: "Panel Email ID",
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },

      sort: true,
    },
    {
      dataField: "firstName",
      text: "Panel Name",
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },

      sort: true,
    },
    {
      dataField: "primarySkills",
      text: "Primary Skills",
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },

      sort: true,
    },
    {
      dataField: "slots",
      text: "Panel Slots",
      formatter: (cell, row) => {
        return row.slots.map((slot) => {
          let slotDate = moment.utc(slot, 'YYYY-MM-DD HH:mm');
          let slotInUserTimezone = slotDate.clone().tz(moment.tz.guess());
          let currentDate = moment();
          if (!slotInUserTimezone.isBefore(currentDate)) {
            return (<>
              <span className="panel-slots-display">
                <label key={slot} style={{margin: '5px'}}>{slotInUserTimezone.format('YYYY-MM-DD HH:mm')}</label>
              </span>
            </>)
          } else {
            return null;
          }
        })
      },
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },

      sort: true,
    },
    // {
    //   dataField: "action",
    //   text: "Actions",
    //   formatter: panelActionFormatter,
    //   headerStyle: () => {
    //     return {
    //       font: "  Muli",
    //       letterSpacing: "0.28px",
    //       color: "#3A3A3A",
    //       textAlign: "right",
    //       opacity: "1",
    //     };
    //   },
    //   style: () => {
    //     return {
    //       font: "  Muli",
    //       letterSpacing: "0.28px",
    //       color: "#3A3A3A",
    //       textAlign: "right",
    //       opacity: "1",
    //     };
    //   },
    // },
  ];

  const vendorDataColumns = [
    {
      dataField: "emailId",
      text: "Vendor Email ID",
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },

      sort: true,
    },
    {
      dataField: "firstName",
      text: "Vendor Name",
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },

      sort: true,
    },
    // {
    //   dataField: "action",
    //   text: "Actions",
    //   formatter: vendorActionFormatter,
    //   headerStyle: () => {
    //     return {
    //       font: "  Muli",
    //       letterSpacing: "0.28px",
    //       color: "#3A3A3A",
    //       textAlign: "right",
    //       opacity: "1",
    //     };
    //   },
    //   style: () => {
    //     return {
    //       font: "  Muli",
    //       letterSpacing: "0.28px",
    //       color: "#3A3A3A",
    //       textAlign: "right",
    //       opacity: "1",
    //     };
    //   },
    // },
  ];

  const skillDataColumns = [
    {
      dataField: "skillTopic",
      text: "Skill Topic",
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },

      sort: true,
    },
    {
      dataField: "expectedProficiency",
      text: "Expected Proficiency",
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },

      sort: true,
    },
  ];

  useEffect(() => {
    getIpaanelUsers().then((res) => {
      setUsers(res.data);
      const dict = res?.data.reduce((acc, obj) => {
        acc[obj.userId] = obj.firstName + ' ' + obj.lastName;
        return acc;
      }, {});
      setUsersDict(dict);
    });

    getCountries().then((res) => {
      setCountries(res.data);
      const dict = res?.data.reduce((acc, obj) => {
        acc[obj.countryCode] = obj.countryName;
        return acc;
      }, {});
      setCountriesDict(dict);
    });
    getSkillFamilies().then((res) => {
      setSkillFamilies(res.data);
    });
    getCustomers().then((res) => {
      const customers = (res.data).filter( e => e.profileType === 'CUSTOMER').map( cust => {
          return {
            'customerId': parseInt(cust.userId),
            'companyName': cust.companyName
          }
      });
      setCustomers(customers);
      const customerCoordinators = (res.data).filter( e => e.profileType === 'CUSTOMER_ADMIN').map( cust => {
        return {
          'customerId': parseInt(cust.ticketId),
          'userId': parseInt(cust.userId),
          'name': `${cust.firstName} ${cust.lastName}`,
          'emailId': cust.emailId
        }
      });
      setCustomerCoordinators(customerCoordinators);

    });
    getSkillAssessments(jobId).then((res) => {
      setSkillData(res.data.skillsToAssess);
    })


  }, []);

  useEffect(() => {
    getJob(jobId).then((res) => {
      setJobData(res.data);
      getCustomerDeliveryUnits(parseInt(customerId)).then((data) => {
        const delUnits = (data).map( d => {
          return {
            'customerId': jobObj.customerId,
            'delUnitId': d.delUnitId,
            'deliveryUnit': d.deliveryUnit,
            'vertical': d.vertical,
            'lineOfBusiness': d.lineOfBusiness,
            'accountName': d.accountName,
          }
        });
        setDelUnits(delUnits)
      });
      getSkillAssessments(jobId).then((res) => {
        setSkillData(res.data.skillsToAssess);
      })
    });
  }, [jobId]);

  const refreshSkillData = async () => {
    getSkillAssessments(jobId).then((res) => {
      setSkillData(res.data.skillsToAssess);
    })
  }


  const updateCustomerCoordinators = async (customerId) => {
    getCustomers().then((res) => {
      const customerCoordinators = (res.data).filter(
        e => e.ticketId === customerId && e.profileType === 'CUSTOMER_ADMIN'
      ).map( cust => {
        return {
          'customerId': parseInt(cust.ticketId),
          'userId': parseInt(cust.userId),
          'name': `${cust.firstName} ${cust.lastName}`,
          'emailId': cust.emailId
        }
      });
      setCustomerCoordinators(customerCoordinators);
    });
  }

  const updateDeliveryUnits = async (customerId) => {
    getCustomerDeliveryUnits(customerId).then((data) => {
      const delUnits = (data).map( d => {
        return {
          'customerId': customerId,
          'delUnitId': d.delUnitId,
          'deliveryUnit': d.deliveryUnit,
          'vertical': d.vertical,
          'lineOfBusiness': d.lineOfBusiness,
          'accountName': d.accountName,
        }
      });
      setDelUnits(delUnits)
    });
  }


  const rateCategoryDict = {
    ratesInIndia: 'Rates In India',
    ratesInUS: 'Rates In US',
    ratesInternal: 'Rates Internal',
    ratesExternal: 'Rates External',
  }

  const rateCardDict = {
    highDemand: 'High Demand',
    niche: 'Niche',
    legacy: 'Legacy',
    leadership: 'Leadership',
    others: 'Others',
  }


  if (hasAllPermissions || userPermissions.includes('VIEW_JOB')) {

    return (
      <>
        <div className="container-page">
          <div className="main-content" >
            <form ref={formRef} style={{ marginTop: '54px' }}>
              <div className="job-details" style={{ marginTop: "20px" }}>
                <div className="row" >
                  <div className="col">
                    <label>
                      <div className="job-view-header">Job ID </div>
                      </label>
                    <p className="custom-para">
                   {jobObj.jobId || 'No Job ID'}
                    </p>
                  </div>
                  <div className="col">
                  <label>
                      <div className="job-view-header">Job Name </div>
                      </label>
                        <p className="custom-para"
                    onChange={(event) => {
                      setJobData((prevJobData) => ({
                        ...prevJobData,
                        jobName: event.target.value,
                      }));
                    }}>
                    {jobObj.jobName ||'No Job Name'}
                      </p>
                  </div>
                  <div className="col">
                  <label><div className="job-view-header">Status </div></label>
                    <p
                     className="custom-para"
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          status: event.target.value
                        }));
                      }}
                      >
                      {jobObj.status ||'No Status'}

              </p>
                  </div>
                </div>

                <br></br>

                <div className="row" >
                  <div className="col">
                    <label><div className="job-view-header">No Of Positions </div></label>
                    <p className="custom-para"
                     onChange={(event) => {
                      setJobData((prevJobData) => ({
                        ...prevJobData,
                        positionsRequired: event.target.value,
                      }));
                    }}
                    >
                    {jobObj.positionsRequired ||'No Position Required'}
                    </p>
                  </div>
                  <div className="col">
                    <label><div className="job-view-header">Job Description</div></label>
                    <p  className="custom-para"
                    onChange={(event) => {
                      setJobData((prevJobData) => ({
                        ...prevJobData,
                        jobDescription: event.target.value,
                      }));
                    }}
                    >
                    {jobObj.jobDescription || 'No Jb Description'}
                    </p>
                  </div>
                  <div className="col">
                    <label><div className="job-view-header">Key Responsibilities </div></label>
                    <p className="custom-para"
                    onChange={(event) => {
                      setJobData((prevJobData) => ({
                        ...prevJobData,
                        keyResponsibilities: event.target.value,
                      }));
                    }}
                    >
                    {jobObj.keyResponsibilities || 'No Key Responsibilities'}
                    </p>
                  </div>
                </div>

                <br></br>

                <div className="row">
                  <div className="col">
                    <label><div className="job-view-header" >Skill Family </div></label>
                    <p
                      className="custom-para"
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          skillFamily: event.target.value
                        }));
                      }}
                      >
                      {jobObj.skillFamily ||'No SkillFamily'}


                    </p>
                  </div>
                  <div className="col">
                    <label><div className="job-view-header">Primary Skills </div></label>
                    <p className="custom-para"
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          primarySkills: event.target.value,
                        }));
                      }}
                      >
                      {jobObj.primarySkills||'No PrimarySkills'}
                       </p>
                  </div>
                  <div className="col">
                    <label><div className="job-view-header">Secondary Skills</div></label>
                    <p className="custom-para"
                    onChange={(event) => {
                      setJobData((prevJobData) => ({
                        ...prevJobData,
                        secondarySkills: event.target.value,
                      }));
                    }}
                    >
                    {jobObj.secondarySkills || 'No SecondarySkills'}
                    </p>
                  </div>
                </div>

                <br></br>

                <div className="row">
                  <div className="col">
                    <label><div className="job-view-header">Minimum Experience Required </div></label>
                    <p className="custom-para"
                    onChange={(event) => {
                      setJobData((prevJobData) => ({
                        ...prevJobData,
                        requiredExperience: event.target.value,
                      }));
                    }}
                  >
                    {jobObj.requiredExperience || 'No RequiredExperience'}
                    </p>
                  </div>
                  <div className="col">
                    <label><div className="job-view-header">Work/Shift Type </div></label>
                    <p
                      className="custom-para"
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          shiftType: event.target.value,
                        }));
                      }}
                      >
                  {jobObj.shiftType || 'No ShiftType'}

                    </p>
                  </div>
                  <div className="col">
                    <label><div className="job-view-header">Work/Shift Timings </div></label>
                    <p
                      className="custom-para"
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          shiftTimings: event.target.value,
                        }));
                      }}
                      >
                    {jobObj.shiftTimings || ' No ShiftTimings'}

                    </p>
                  </div>
                </div>

                <br></br>

                <div className="row">
                  <div className="col">
                    <label><div className="job-view-header">Country </div></label>
                    <p
                      className="custom-para"
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          country: event.target.value,
                        }));
                      }}

                    >
                      {countriesDict[jobObj.country] ||'No Country Selected'}
                    </p>
                  </div>
                  <div className="col">
                  <label><div className="job-view-header">Interview Duration </div></label>
                    <p
                      className="custom-para"
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          interviewDuration: event.target.value,
                        }));
                      }}

>
{jobObj.interviewDuration || 'No InterviewDuration'}

                    </p>
                  </div>
                  <div className="col">

                  </div>
                </div>
              </div>

              <div className="job-details"
                style={{
                  marginTop: "20px", minHeight:"140px",
                  display: ["ADMIN","SUPER"].includes(user.profileType) ? 'block': 'none'
                }}
              >
                <div className="row">
                  <div className="col">
                    <label><div className="job-view-header">Rate Category </div></label>
                    <p
                      className="custom-para"
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          rateCategory: event.target.value,
                        }));
                      }}
                      >
                    {rateCategoryDict[jobObj.rateCategory] || 'No RateCategory'}
                    </p>
                  </div>

                  <div className="col">
                    <label><div className="job-view-header">Rate Card </div></label>
                    <p
                      className="custom-para"
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          rateCard: event.target.value,
                        }));
                      }}
                      >
                    {rateCardDict[jobObj.rateCard] ||'No RateCard'}
                    </p>
                  </div>

                  <div className="col">
                    <label><div className="job-view-header">Is Bonus Applicable </div></label>
                    <p
                      className="custom-para"
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          isBonusApplicable: event.target.value,
                        }));
                      }}
                      >
                {jobObj.isBonusApplicable ?'Yes':'No'}

                    </p>
                  </div>
                </div>
              </div>

              <div className="job-details" style={{ marginTop: "20px", minHeight:"240px" }}>
                <div className="row">
                <div className="col">
                  <label>
                    <div className="job-view-header">Customer </div>
                  </label>
                  <p
                    className="custom-para"
                    onChange={(event) => {
                      setJobData((prevJobData) => ({
                        ...prevJobData,
                        customerId: parseInt(event.target.value),
                      }));
                      updateCustomerCoordinators(parseInt(event.target.value));
                      updateDeliveryUnits(parseInt(event.target.value));
                    }}
                  >
                  {jobObj.customerCompany || 'NA'}


                    </p>
                    </div>

                  <div className="col">
                    <label><div className="job-view-header">Customer Admin Name </div></label>
                    <p
                    className="custom-para"
                    onChange={(event) => {
                      let coordinator = customerCoordinatorsData.filter(e => e.userId == event.target.value)[0];
                      setJobData((prevJobData) => ({
                        ...prevJobData,
                        custCoordinatorId: coordinator.userId,
                        custCoordinatorName: coordinator.name,
                        custCoordinatorEmailId: coordinator.emailId,
                      }));
                    }}
                  >
                    {jobObj.custCoordinatorName || 'NA'}

                    </p>
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    <label><div className="job-view-header">Customer Admin Email ID</div></label>
                    <p
                      className="custom-para"
                    >
                      {jobObj.custCoordinatorEmailId ||'No Customer Admin Email ID'}
                  </p>
                </div>

                <br></br>

                  <div className="col">
                    <label><div className="job-view-header">Sourcing </div></label>
                    <p
                      className="custom-para"
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          sourcing: event.target.value,
                        }));
                      }}
                      >
                  {jobObj.sourcing || 'NA'}

                    </p>
                  </div>
</div>
                  <div className="col">
                    <label><div className="job-view-header">Invite to be sent to the candidate</div></label>
                    <p
                      className="custom-para"
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          inviteSendToCandidates: event.target.value,
                        }));
                      }}
                      >
                      {jobObj.inviteSendToCandidates ? 'Yes':'No'}

                    </p>
                  </div>
                  <div className="col"></div>

              </div>

              <div className="job-details" style={{ marginTop: "20px", minHeight:"240px" }}>
                <div className="row">
                  <div className="col">
                    <label><div className="job-view-header">Delivery Unit </div></label>
                    <p
                      className="custom-para"
                      onChange={(event) => {
                        let delUnit = delUnits.filter(e => e.delUnitId == event.target.value)[0];
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          delUnitId: delUnit.delUnitId,
                          deliveryUnit: delUnit.deliveryUnit,
                          vertical: delUnit.vertical,
                          lineOfBusiness: delUnit.lineOfBusiness,
                          accountName: delUnit.accountName,
                        }));
                      }}
                      >
                      {jobObj.deliveryUnit || 'NA'}

                    </p>
                  </div>
                  <div className="col">
                    <label><div className="job-view-header">Vertical</div></label>
                    <p className="custom-para"
                    >
                      {jobObj.vertical ||'NA'}
                      </p>
                  </div>
                  <div className="col">
                    <label><div className="job-view-header">Line Of Business</div></label>
                    <p className="custom-para">
                    {jobObj.lineOfBusiness ||'NA'}
               </p>
                  </div>
                </div>

                <br></br>

                <div className="row">
                  <div className="col">
                    <label><div className="job-view-header">Account Name</div></label>
                    <p className="custom-para">{jobObj.accountName ||'NA'}
                    </p>
                  </div>
                  <div className="col">
                  </div>
                  <div className="col">
                  </div>
                </div>
              </div>



              <div className="comments-remarks" style={{ margin: "20px 0px", padding: "20px" }} >
                <div className="row">
                  <div className="col">
                    <label><div className="job-view-header">Assignee</div></label>
                    <p
                      className="custom-para"
                      onChange={(event) => {
                        let assignee = users.filter((d) => d.userId == event.target.value)[0];
                        console.log(event.target.value, assignee)
                        if (assignee) {
                          setJobData((prevJobData) => ({
                            ...prevJobData,
                            assigneeId: event.target.value,
                            assigneeName: assignee?.username,
                            assigneeEmailId: assignee?.emailId,
                          }));
                        } else {
                          setJobData((prevJobData) => ({
                            ...prevJobData,
                            assigneeId: null,
                            assigneeName: '',
                            assigneeEmailId: '',
                          }));
                        }
                      }}
                    >
                    {
                      usersDict[jobObj.assigneeId] || 'Not Assigned'
                    }
                    </p>
                  </div>
                  <div className="col">
                    <label><div className="job-view-header">Assignee Email ID</div></label>
                    <p  className="custom-para">
                    {jobObj.assigneeEmailId ||'No Assignee Email ID'}

                    </p>

                  </div>
                  <div className="col">

                  </div>

                </div>
                <br></br>

                <div className="row">
                  <label><div className="job-view-header">Remarks</div></label>
                  <div className="col-10">
                    <p
                      className="custom-para "
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          remarks: event.target.value,
                        }));
                      }}
                      >
                      {jobObj.remarks||'No Remarks'}
                    </p>
                  </div>
                </div>
                <div className="row" style={{ marginTop: '20px', textAlign: 'center', paddingBottom: '5px' }}>
                  <div className="col" style={{ textAlign: 'center' }}>
                    <button
                      type="reset"
                      className="btn btn-primary"
                      style={{
                        border: '1px solid #3E67B1',
                        borderRadius: ' 4px',
                        opacity: '1',
                        width: '96px',
                        height: '50px',
                      }}
                      onClick={()=>{
                        navigate(-1);
                      }}
                    >
                      BACK
                    </button>
                  </div>
                </div>
              </div>

              <div className="job-details" style={{
                marginTop: "20px", minHeight: '200px' ,
                display: ["ADMIN","SUPER"].includes(user.profileType) ? 'block': 'none'
                }}>
                <label>
                  <h5>Panels </h5>
                </label>
                <br></br>
                <div className="row">
                  <div className="col">
                    <BootstrapTable
                      className="table"
                      hover
                      bootstrap4
                      keyField="userId"
                      data={jobObj.panels || []}
                      columns={panelDataColumns}
                      pagination={paginationFactory({
                        sizePerPage: 5,
                        sizePerPageList: [
                          { text: '5', value: 5 },
                          { text: '10', value: 10 },
                          { text: '15', value: 15 },
                          { text: '20', value: 20 },
                          { text: '50', value: 50 },
                        ],
                        hideSizePerPage: false,
                        hidePageListOnlyOnePage: false,
                        alwaysShowAllBtns: true,
                        showTotal: true,
                        nextPageText: "Next",
                        prePageText: "Prev",
                      })}
                      filter={filterFactory()}
                      noDataIndication={
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                          <span>No Data Found</span>
                        </Box>
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="job-details" style={{ marginTop: "20px", minHeight: '200px' }}>
                <label>
                  <h5>Vendors</h5>
                </label>
                <br></br>
                <div className="row">
                  <div className="col">
                    <BootstrapTable
                      className="table"
                      hover
                      bootstrap4
                      keyField="userId"
                      data={jobObj.vendors || []}
                      columns={vendorDataColumns}
                      pagination={paginationFactory({
                        sizePerPage: 5,
                        sizePerPageList: [
                          { text: '5', value: 5 },
                          { text: '10', value: 10 },
                          { text: '15', value: 15 },
                          { text: '20', value: 20 },
                          { text: '50', value: 50 },
                        ],
                        hideSizePerPage: false,
                        hidePageListOnlyOnePage: false,
                        alwaysShowAllBtns: true,
                        showTotal: true,
                        nextPageText: "Next",
                        prePageText: "Prev",
                      })}
                      filter={filterFactory()}
                      noDataIndication={
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                          <span>No Data Found</span>
                        </Box>
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="job-details" style={{ marginTop: "20px", minHeight: '200px' }}>
                <label>
                  <h5>Skill Assessment</h5>
                </label>
                <br></br>
                <div className="row">
                  <div className="col">
                    <BootstrapTable
                      className="table"
                      hover
                      bootstrap4
                      keyField="skillTopic"
                      data={skillData}
                      columns={skillDataColumns}
                      pagination={paginationFactory({
                        sizePerPage: 5,
                        sizePerPageList: [
                          { text: '5', value: 5 },
                          { text: '10', value: 10 },
                          { text: '15', value: 15 },
                          { text: '20', value: 20 },
                          { text: '50', value: 50 },
                        ],
                        hideSizePerPage: false,
                        hidePageListOnlyOnePage: false,
                        alwaysShowAllBtns: true,
                        showTotal: true,
                        nextPageText: "Next",
                        prePageText: "Prev",
                      })}
                      filter={filterFactory()}
                      noDataIndication={
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                          <span>No Data Found</span>
                        </Box>
                      }
                    />
                  </div>
                </div>

              </div>
            </form>
          </div>
        </div>

      </>
    );

  } else {
    return (
      <>
        <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
          <div className="card">
            <div className="card-body text-center">
              <h2 className="card-title">
                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                Access Denied
              </h2>
              <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}
