import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { createCountry, deleteCountry, getCountries, updateCountry } from "../../service/api";
import CircularProgress from '@mui/material/CircularProgress';

export default function Countries() {
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');
  
  const [ countryName, setCountryName] = React.useState("");
  const [ countryCode, setCountryCode] = React.useState("");
  const [ countryData, setCountryData] = React.useState([])
  const [dataLoading, setDataLoading] = useState(false);
  
  const countryColumns = [
    {
      dataField: "countryId",
      text: "CountryId",
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },

      sort: true,
    },
    {
      dataField: "countryName",
      text: "Country Name",
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },

      sort: true,
    },
    {
      dataField: "countryCode",
      text: "Country Code",
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },

      sort: true,
    },

    {
      dataField: "action",
      text: "Actions",
      formatter: actionFormatter,
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
    },
  ];

  const [countriesData, setCountriesData] = React.useState([]);

  useEffect(() => {
    setDataLoading(true);
    getCountries().then((response) => {
      setCountriesData(response.data);
      setDataLoading(false);
    });
  },[]);

  function actionFormatter(row, cell) {
    return (
      <>
        <i style={{ color: "#3E67B1" }}
         data-toggle="modal"
         data-target="#countryID"
        onClick={()=>{
          setCountryData(cell)
        }}
        className="fa fa-light fa-pen-to-square"></i>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <i style={{ color: "#3E67B1" }} className="fa fa-light fa-trash-can" onClick={()=>{
          deleteCountry(cell.countryId).then((res)=>{
            setDataLoading(true);
            getCountries().then((response) => {
              setCountriesData(response.data);
              setDataLoading(false);
            });
          })
        }}></i>
      </>
    );
  }

  const options = {
    sizePerPage: 5,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '50', value: 50 },
    ],
    hideSizePerPage: false,
    hidePageListOnlyOnePage: false,
    alwaysShowAllBtns: true,
    showTotal: true,
    nextPageText: "Next",
    prePageText: "Prev",
  };

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];


  if (hasAllPermissions || userPermissions.includes('LIST_MASTER_DATA')) {

    return (
      <>
        <Box sx={{ display: "flex" }}>

          <Box component="main"
            style={{ backgroundColor: "#F4F6FB", minHeight: "713px" }}
            sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
          >
            <>
              <div clas="container" style={{ marginLeft: "1%", marginRight: "1%" }} >
                <div className="row col-12">
                  <div className="col-4">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {setCountryName(e.target.value)}}
                      style={{
                        height: "50px",
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        border: "1px solid #C7C7C7",
                        borderRadius: "6px",
                        opacity: "1",
                      }}
                      placeholder="Enter Country Name"
                    />
                  </div>
                  <div className="col-3">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {setCountryCode(e.target.value)}}
                      style={{
                        height: "50px",
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        border: "1px solid #C7C7C7",
                        borderRadius: "6px",
                        opacity: "1",
                      }}
                      placeholder="Enter Country Code"
                    />
                  </div>
                  <div className="col-3">
                    <button
                      className="btn btn-primary"
                      onClick={
                        ()=>{
                          createCountry({countryName, countryCode}).then((res)=>{
                            setDataLoading(true);
                            getCountries().then((response) => {
                              setCountriesData(response.data);
                              setDataLoading(false);
                            });
                          }).catch((err)=>{})
                          
                        }
                      }
                      style={{
                        height: "50px",
                        background: "#3E67B1 0% 0% no-repeat padding-box",
                        borderRadius: "4px",
                        opacity: "1"
                      }}
                    >
                      Create Country
                    </button>
                  </div>
                  <div className="col-2"></div>
                </div>
              </div>
            </>
            <br />
            <>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <BootstrapTable
                      className="table"
                      hover
                      bootstrap4
                      keyField="id"
                      data={countriesData}
                      columns={countryColumns}
                      pagination={paginationFactory(options)}
                      filter={filterFactory()}
                      defaultSorted={defaultSorted}
                      noDataIndication={
                        dataLoading ? 
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                          <CircularProgress />
                        </Box> 
                        : 
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                          <span>No Data Found</span>
                        </Box>
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          </Box>
        </Box>
        <>
        <div
          id="countryID"
          className="modal fade bd-example-modal-lg"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          style={{
            marginLeft:"10%", marginTop: "5%"
          }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Edit Country
                </h5>
              </div>
              <div className="container" style={{ backgroundColor: "white" }}>
                <div className="form-group">
                  <label htmlFor="countryName">Country Name</label>
                  <input
                    id="countryName"
                    className="form-control"
                    type="text"
                    value={countryData.countryName}
                    onChange={(e) =>{
                      e.preventDefault();
                      const newCountryName = e.target.value;
                      setCountryData((prevcountryData) => ({
                        ...prevcountryData,
                        countryName: newCountryName,
                      }));
                    }}
                    style={{
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: "1px solid #C7C7C7",
                      borderRadius: "6px",
                      opacity: "1",
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="countryCode">Country Code</label>
                  <input
                    id="countryCode"
                    className="form-control"
                    type="text"
                    value={countryData.countryCode}
                    onChange={(e) =>{
                      e.preventDefault();
                      const newCountryCode = e.target.value;
                      setCountryData((prevcountryData) => ({
                        ...prevcountryData,
                        countryCode: newCountryCode,
                      }));
                    }}
                    style={{
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: "1px solid #C7C7C7",
                      borderRadius: "6px",
                      opacity: "1",
                    }}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">
                  Close
                </button>
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-primary"
                  onClick={() => {
                    updateCountry(countryData.countryId,countryData)
                    .then((res) => {      
                      getCountries().then((response) => {
                        setCountriesData(response.data);
                      });
                    });
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
      </>
    );
  
  } else {
    return (
      <>
        <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
          <div className="card">
            <div className="card-body text-center">
              <h2 className="card-title">
                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                Access Denied
              </h2>
              <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}

