import React from "react";

const InvoiceHelperView = (props) => {
    const { header, summary, highDemand, rateCard } = props;
    console.log(header);

    return (
        <div className="shadow-radius" style={{ marginBottom: "20px" }}>
            <div className="main-header">{header}</div>
            <div className="row row_style"
                 style={{
                     border: "1px solid #E8E8E8",
                     borderRadius: "6px",
                     padding: "10px",
                     margin: "0px 0px 20px 0px",
                     marginBottom: "20px"
                 }}
            >
                <div className="main-header">Interview Summary</div>
                <div className="col-2 custom-paragraph d-flex justify-content-center">Completed</div>
                <div className="col-2 custom-paragraph d-flex justify-content-center">Rescheduled</div>
                <div className="col-2 custom-paragraph d-flex justify-content-center">Canceled</div>
                <div className="col-2 custom-paragraph d-flex justify-content-center">Panel No Show</div>
                <div className="col-2 custom-paragraph d-flex justify-content-center">Candidate No Show</div>
                <div className="col-2 custom-paragraph d-flex justify-content-center">Technical Glitch</div>
                <div className="row row_style mt-2">
                    <div className="col-2 d-flex justify-content-center">{summary.COMPLETED}</div>
                    <div className="col-2 d-flex justify-content-center">{summary.RESCHEDULED}</div>
                    <div className="col-2 d-flex justify-content-center">{summary.CANCELED}</div>
                    <div className="col-2 d-flex justify-content-center">{summary["PANEL NO SHOW"]}</div>
                    <div className="col-2 d-flex justify-content-center">{summary["CANDIDATE NO SHOW"]}</div>
                    <div className="col-2 d-flex justify-content-center">{summary["PANEL TECHNICAL GLITCH"] + summary["CANDIDATE TECHNICAL GLITCH"]}</div>
                </div>
            </div>

            <div className="row row_style">
                <div className="main-header ">High Demand Skills</div>
                <div className="col-3">
                    <div className="custom-paragraph d-flex justify-content-center">Status</div>
                </div>
                <div className="col-3">
                    <div className="custom-paragraph d-flex justify-content-center">No. of Interviews</div>
                </div>
                <div className="col-3">
                    <div className="custom-paragraph d-flex justify-content-center">Price per Interview</div>
                </div>
                <div className="col-3">
                    <div className="custom-paragraph d-flex justify-content-center">Amount</div>
                </div>
                <div className="row " style={{ marginTop: "20px" }}></div>
                {highDemand.map(skill => (
                    <div className="row row_style" key={skill.status}>
                        <div className="col-3">
                            <div className="d-flex justify-content-center">{skill.status}</div>
                        </div>
                        <div className="col-3">
                            <div className="d-flex justify-content-center">{skill.count}</div>
                        </div>
                        <div className="col-3">
                            <div className="d-flex justify-content-center">{skill.pricePerInterview}</div>
                        </div>
                        <div className="col-3">
                            <div className="d-flex justify-content-center">{skill.amount}</div>
                        </div>
                    </div>
                ))}
                <div className="row">
                    <div className="col-9"></div>
                    <div className="col-3"
                         style={{
                             fontSize: "16px",
                             fontWeight: "bold",
                             display: "flex",
                                justifyContent: "center",
                         }}>
                        Amount {highDemand.reduce((acc, skill) => acc + skill.amount, 0).toFixed(2)}
                    </div>
                </div>
            </div>

            <div className="row" style={{
                border: "1px solid #E8E8E8",
                borderRadius: "6px",
                padding: "20px",
                margin: "0px 0px 20px 0px"
            }}>
                <div className="main-header">Rate Card</div>
                <div className="col-2">
                    <div className="custom-paragraph d-flex justify-content-center">Category</div>
                </div>
                <div className="col-2">
                    <div className="custom-paragraph d-flex justify-content-center">Price for Completed</div>
                </div>
                <div className="col-2">
                    <div className="custom-paragraph d-flex justify-content-center">Cancelled</div>
                </div>
                <div className="col-2">
                    <div className="custom-paragraph d-flex justify-content-center">Candidate No Show</div>
                </div>
                <div className="col-2">
                    <div className="custom-paragraph d-flex justify-content-center">Panel No Show</div>
                </div>
                <div className="col-2">
                    <div className="custom-paragraph d-flex justify-content-center">Technical Glitch</div>
                </div>
                <div className="mt-4"></div>
                {rateCard.map(category => (
                    <div className="row row_style" key={category.category}>
                        <div className="col-2">
                            <div className="d-flex justify-content-center">{category.category}</div>
                        </div>
                        <div className="col-2">
                            <div className="d-flex justify-content-center">{category.completed}</div>
                        </div>
                        <div className="col-2">
                            <div className="d-flex justify-content-center"> {category.cancelled}</div>
                        </div>
                        <div className="col-2">
                            <div className="d-flex justify-content-center">{category.candidateNoShow}</div>
                        </div>
                        <div className="col-2">
                            <div className="d-flex justify-content-center">{category.panelNoShow}</div>
                        </div>
                        <div className="col-2">
                            <div className="d-flex justify-content-center">{category.panelTechnicalGlitch}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default InvoiceHelperView;
