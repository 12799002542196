import React, { useState } from "react";
import { TextField, InputAdornment, IconButton, MenuItem } from "@mui/material";
import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";
import { forgotPasswordAPI } from "../../service/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function ForgotPassword() {
  const navigator = useNavigate();
  const [emailId, setEmailId] = useState("");
  const [profileType, setProfileType] = useState("ADMIN");

  const profileTypes = [
    { value: "PANELIST", label: "PANELIST" },
    { value: "CUSTOMER", label: "CUSTOMER" },
    { value: "CUSTOMER_ADMIN", label: "CUSTOMER_ADMIN" },
    { value: "VENDOR", label: "VENDOR" },
    { value: "ADMIN", label: "ADMIN" },
  ];

  function forgotPasswordHandler() {
    if (emailId === "" || profileType === "") {
      toast.error("Please provide Email ID");
      return;
    }

    forgotPasswordAPI(emailId, profileType)
      .then((res) => {
        toast.success("Please check your Email for Reset Password Link");
        navigator("/login");
      })
      .catch((err) => {
        console.error(err);
        toast.error(`Error: User not exists.!`);
      });
  }

  return (
    <>
      <section className="vh-100 gradient-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="card text-white" style={{ borderRadius: "1em" }}>
              <div className="card-body p-5 d-flex flex-column align-items-center">
                <img
                  src="/Images/blue_logo.svg"
                  style={{ height: "50%", width: "30%" }}
                  onClick={() => {
                    navigator("/");
                  }}
                />

                <div className="mb-md-5 mt-md-4 pb-10">
                  <TextField
                    placeholder="Enter Email"
                    value={emailId}
                    onChange={(e) => setEmailId(e.target.value)}
                    style={{
                      width: "350px",
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      opacity: 1,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <PersonOutlineRoundedIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <br />
                  <br />
                  <TextField
                    select
                    value={profileType}
                    placeholder="profileType"
                    onChange={(e) => setProfileType(e.target.value)}
                    style={{
                      width: "350px",
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      opacity: 1,
                    }}
                  >
                    {profileTypes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                <button
                  className="btn btn-primary btn-lg px-5"
                  onClick={forgotPasswordHandler}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
