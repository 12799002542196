import React, { useState, useEffect } from "react";
import {Link, useParams} from "react-router-dom";
import { getPanelInvoicesByPanel } from "../../service/api";
import InvoiceHelperView from "./InvoiceHelerView";
import users from "../Users";
const Invoice = () => {
    const [panelDetailSummary, setPanelDetailSummary] = useState(null);
    const user = JSON.parse(localStorage.getItem("user"));
    const panelEmailId = user.emailId;
    console.log(panelEmailId)
    useEffect(() => {
        const fetchPanelInvoices = async () => {
            const payload = {
                panelEmailId: panelEmailId,
                startDate: '2023-06-01',
                endDate: new Date().toISOString().split('T')[0] // Current date in 'YYYY-MM-DD' format
            };
            const data = await getPanelInvoicesByPanel(payload);
            setPanelDetailSummary(data);
        };

        fetchPanelInvoices();
    }, [panelEmailId]);

    if (!panelDetailSummary) {
        return <div>Loading...</div>;
    }

    const ratesInIndia = panelDetailSummary.data.ratesInIndia;
    console.log("panelDetailSummary", panelDetailSummary);
    console.log("ratesInIndia", ratesInIndia);
    const summary = ratesInIndia.summary;
    console.log("summary", summary);
    const highDemand = ratesInIndia.highDemand;
    console.log("highDemand", highDemand);
    const rateCard = ratesInIndia.rateCard;
    console.log("rateCard", rateCard);

    return (
        <div>
            <div className="shadow-radius" style={{ marginBottom: "20px" }}>
                <div className="main-header">Panelist Details</div>
                <div className="row">
                    <div className="col-4">
                        <div className="candidate-view-header2">Panelist Email</div>
                        <div className="custom-paragraph">{panelEmailId}</div>
                    </div>
                    <div className="col-4">
                        <div className="candidate-view-header2">Invoice From Month</div>
                        <div className="custom-paragraph">June 2023</div>
                    </div>
                    <div className="col-4" >
                        <div className="candidate-view-header2">Invoice To Month</div>
                        <div className="custom-paragraph">{new Date().toLocaleString('default', { month: 'long', year: 'numeric' })}</div>
                    </div>
                </div>
            </div>

            <InvoiceHelperView header="Rates In India" summary={summary} highDemand={highDemand} rateCard={rateCard} />
            {/*<InvoiceHelperView header="OffStore Location" />*/}

            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginRight: "20px",
                    marginBottom: "20px"
                }}
            >
                <Link className="btn btn-primary" style={{ marginRight: "20px" }} to="/admin/adminInvoice" >
                    Back
                </Link>
                <button className="btn btn-primary">Export Invoice</button>
            </div>
        </div>
    );
};

export default Invoice;
