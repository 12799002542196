import React from "react";

import Box from "@mui/material/Box";
import SideNav from "../SideNav";
import Header from "../Header";
 import { Outlet } from "react-router-dom";

export default function UserPage() {
  return (
      <div className="row" style={{overflowX: "hidden",margin: "0px", padding: "0px"}}>
          <div className="col" style={{maxWidth: "216px", overflowX: "hidden", }}>
              <SideNav/>
          </div>
          <div className="col "
               style={{overflowX: "hidden", backgroundColor: "#F4F6FB", margin: "0px", padding: "0px 20px"}}>
              <Header/>
              <Outlet/>
          </div>
      </div>
  )
}
