import React, { useRef, useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {  Tab, Tabs } from "react-bootstrap";

import "./Candidate.css";

import { 
  getJobsInfo, getCandidate, claimCandidateByPanel,
  getCountries, deleteDocument, getCandidateDocumentById,
  uploadCandidateDocument, updateSkillsForCandidate
} from '../../service/api';

import { toast } from "react-toastify";
const MAX_RESUME_FILE_SIZE = 5 * 100 * 1024 ; // 500 KB


export default function ViewCandidateForPanel() {
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');
  
  const { candidateId, customerId } = useParams();
  const [ isInterviewCompleted, setIsInterviewCompleted ] = useState(false);

  const [lpChecklistTabKey, setLPChecklistTabKey] = useState('home')
  const changeLPChecklistTab = (key) => {
      if (key !== lpChecklistTabKey) {
        setLPChecklistTabKey(key)
      }
  }
  const [jobs, setJobs] = useState([]);
  
  const [countries, setCountries] = useState([]);
  const navigate = useNavigate();
  const [candidateResume, setCandidateResume] = useState(null);
  const [candidateResumeName, setCandidateResumeName] = useState('Choose a file');
  // const [users, setUsers] = useState([]);
  // const [panels, setPanels] = useState([]);
  const [dynamicSkills, setDynamicSkills] = useState([]);
  const [standardSkills, setStandardSkills] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [uploadDocument, setUploadDocument] = useState(null);
  const [uploadDocumentName, setUploadDocumentName] = useState('');
  
  const formRef = useRef(null);

  const areAllKeysNotEmpty = (obj) => Object.values(obj).every((value) => value !== '');


  function validateFeedback(dynamicSkills, standardSkills, overallFeedback, recommendations){
    if ([null, ''].includes(overallFeedback)) {
      return false;
    }
    if ([null, ''].includes(recommendations)) {
      return false;
    }

    const isDynamicSkillsValid = dynamicSkills.every((skill) => areAllKeysNotEmpty(skill));
    if (!isDynamicSkillsValid) {
      return false;
    }

    const isStandardSkillsValid = standardSkills.every((skill) => areAllKeysNotEmpty(skill));
    if (!isStandardSkillsValid) {
      return false;
    }

    return true;
  }

  const [candidateObj, setCandidateData] = useState({
    customerId: customerId,
    custCoordinatorId: 0,
    jobId: 0,
    custCoordinatorName: '',
    custCoordinatorEmailId: '',
    customerCompany: '',
    candidateId: candidateId,
    candidateName: '',
    candidateEmailId: '',
    candidateResumeId: '',
    contactNumber: '',
    alternateContanctNumber: '',
    highestQualification: '',
    course: '',
    institute: '',
    isCurrentlyEmployed: '',
    currentCompany: '',
    currentRole: '',
    currentCountry: 'IND',
    currentState: '',
    currentCity: '',
    totalExperience: '',
    primarySkills: '',
    secondarySkills: '',
    skillFamily: '',
    socialProfile: '',
    slotDate1: '',
    slotTime1: '',
    timezone1: '',
    slotDate2: '',
    slotTime2: '',
    timezone2: '',
    dynamicSkills: [],
    standardSkills: [],
    documents: [],
    overallFeedback: '',
    recommendations: '',
    remarks: '',
    status: '',
    interviewDuration: '',
  });

  useEffect(() => {
    getJobsInfo().then((res) => {
      const jobs = (res.data).map( job => {
        return {
          'jobId': parseInt(job.jobId), 
          'jobName': job.jobName 
        }
      });
      setJobs(jobs);
    });

    getCountries().then((res) => {
      setCountries(res.data);
    });
    
  }, []);

  useEffect(() => {
    getCandidate(candidateId).then((res) => {
      setCandidateData(res.data);
      setCandidateResumeName(res.data.candidateResumeId);
      setDynamicSkills(res.data.dynamicSkills);
      setStandardSkills(res.data.standardSkills);
      setDocuments(res.data.documents);

      // disable if already feedback provided
      setIsInterviewCompleted(Boolean(res.data.overallFeedback)); 
    });
  }, [candidateId, isInterviewCompleted]);
  
  const handleResumeDocumentChange = async (e) => {
    const selectedFile = e.target.files[0];
    setCandidateResume(selectedFile);
    setCandidateResumeName(selectedFile ? selectedFile.name : candidateResumeName);
  }
  
  const handleUploadDocument = async () => {
    try {
      if (uploadDocument) {
          const ext = uploadDocument?.name?.split('.').pop();
          let docName = `${candidateObj.jobId}_${candidateObj.candidateId}_${uploadDocumentName.trim()}.${ext}`;
          const formData = new FormData();
          formData.append('candidateDocument', uploadDocument);
          await uploadCandidateDocument(docName, formData);
          getCandidate(candidateId).then((res) => {
            setDocuments(res.data.documents);
            setUploadDocument(null);
            setUploadDocumentName('');
          });
          toast.success('Candidate document has been uploaded.!');
        } else {
          toast.error('No document selected to upload.!')
        }
    } catch (error) {
      console.error('Error while uploading document:', error);
      toast.error(error.response.data.message);
    }
  };

  
  if (hasAllPermissions || userPermissions.includes('VIEW_CANDIDATE_FOR_PANEL')) {
    return (
      <>
        <div className="container-page">

          <div 
            style={{ 
              marginRight: "20px", 
              marginBottom: '20px',
              display: (["PANELIST"].includes(user.profileType) && candidateObj.status == 'APPROVED') ? 'block': 'None',
            }}
            
            >
                <div className="row">
                  <div className="col">
                  </div>
                  <div className="col" style={{ textAlign: "right" }}>
                    <button
                    onClick={() => {
                      claimCandidateByPanel(candidateId, {
                        panelId: user.userId,
                        panelEmail: user.emailId,
                        slot: {
                          slotDate: candidateObj.slotDate1,
                          slotTime: candidateObj.slotTime1,
                          timezone: candidateObj.timezone1
                        },
                        slotNumber: 1
                      }).then((res) => {
                        if (res.message) {
                          toast.success(`Candidate assigned to ${user.emailId}`);
                          navigate('/panel/panelDashboard');
                        } else if (res.warning) {
                          toast.warning(res.warning);
                        } else {
                          toast.error(res.error);
                        }
                      }).catch((error) => {
                        console.log(error)
                        toast.error(error?.response?.data?.error);
                      })
                    }}
                    type="button"
                    className="btn btn-primary"
                    style={{
                      border: '1px solid #3E67B1',
                      borderRadius: ' 4px',
                      opacity: '1',
                      width: '196px',
                      height: '50px',
                    }}
                    >
                      CLAIM SLOT 1
                    </button>
                    &nbsp;&nbsp;

                    <button
                    onClick={() => {
                      claimCandidateByPanel(candidateId, {
                        panelId: user.userId,
                        panelEmail: user.emailId,
                        slot: {
                          slotDate: candidateObj.slotDate2,
                          slotTime: candidateObj.slotTime2,
                          timezone: candidateObj.timezone2
                        },
                        slotNumber: 2
                      }).then((res) => {
                        if (res.message) {
                          toast.success(`Candidate assigned to ${user.emailId}`);
                          navigate('/panel/panelDashboard');
                        } else if (res.warning) {
                          toast.warning(res.warning);
                        } else {
                          toast.error(res.error);
                        }
                      }).catch((error) => {
                        console.log(error)
                        toast.error(error?.response?.data?.message);
                      })
                    }}
                    type="button"
                    className="btn btn-warning"
                    style={{
                      border: '1px solid #3E67B1',
                      borderRadius: ' 4px',
                      opacity: '1',
                      width: '196px',
                      height: '50px',
                    }}
                    >
                      CLAIM SLOT 2
                    </button>
                    
                  </div>
                </div>
          </div>

          <div className="main-content" style={{ }}>
            {/* SignleForm */}
            <form ref={formRef}>
              <Tabs 
                defaultActiveKey="home" 
                className="mb-3 mt-2" 
                style={{marginLeft: '20px'}}
                onSelect={(e) => { if (e !== lpChecklistTabKey) { changeLPChecklistTab(e)}}}
              >
                <Tab eventKey="home" title="Candidate Details">
                  <div className="candidate-details" style={{ marginTop: "20px", minHeight: "200px" }}>
                    
                    <div className="row">
                      <div className="col container-heading"><h6>Candidate Details</h6></div>
                    </div>

                    <div className="row">
                      
                      <div className="col">
                        <label><h6>Candidate ID *</h6></label>
                        <input className="form-control editable" 
                          placeholder="CandidateId" name="candidateId" 
                          disabled={true}
                          value={candidateObj.candidateId} />
                      </div>
                      <div className="col">
                        <label><h6>Assignee Email ID</h6></label>
                        <input type="text" className="form-control editable" 
                        placeholder="" name="assigneeEmailId" 
                        disabled={true}
                        value={candidateObj.assigneeEmailId}/>
                      </div>
                      <div className="col">
                        <label><h6>Panel Email ID</h6></label>
                        <input type="text" className="form-control editable" 
                        placeholder="" name="panelEmailId" 
                        disabled={true}
                        value={candidateObj.panelEmailId}/>
                      </div>
                    </div>
                    <br></br>
                    <div className="row" >
                      <div className="col">
                        <label><h6>Job Name *</h6></label>
                        <select 
                          className="form-control-lg-8 drop-down" 
                          style={{ width: "100%", height: "50%" }} 
                          name="jobId"
                          onChange={(event) => {
                            setCandidateData((prevCandidateData) => ({
                              ...prevCandidateData,
                              jobId: event.target.value
                            }));
                          }}
                          value={candidateObj.jobId}
                          disabled={true}
                          >
                          <option value="">Select Job</option>
                            {jobs.map((job) => (
                              <option
                                key={job.jobId}
                                value={job.jobId}
                              >
                              {job.jobId} - {job.jobName}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col">
                        <label><h6>Candidate Name *</h6></label>
                        <input className="form-control editable" 
                          placeholder="Enter Candidate name" name="candidateName" 
                          onChange={(event) => {
                            setCandidateData((prevCandidateData) => ({
                              ...prevCandidateData,
                              candidateName: event.target.value,
                            }));
                          }}
                          disabled={true}
                          value={candidateObj.candidateName} />
                      </div>
                      <div className="col">
                        <label><h6>Candidate Email ID *</h6></label>
                        <input className="form-control editable" 
                          placeholder="Enter Candidate Email ID" name="candidateEmailId" 
                          onChange={(event) => {
                            setCandidateData((prevCandidateData) => ({
                              ...prevCandidateData,
                              candidateEmailId: event.target.value,
                            }));
                          }}
                          disabled={true}
                          value={candidateObj.candidateEmailId} />
                      </div>
                    </div>

                    <br></br>
                    

                  <div className="row">
                    <div className="col">
                      <label><h6>Country *</h6></label>
                        <select 
                          className="form-control-lg-8 drop-down" 
                          style={{ width: "100%", height: "50%" }}   
                          name="currentCountry"
                          disabled={true}
                          value={candidateObj.currentCountry}
                          onChange={(event) => {
                            setCandidateData((prevCandidateData) => ({
                              ...prevCandidateData,
                              currentCountry: event.target.value,
                            }));
                          }}
                        >
                          <option value="">Select Country</option>
                            {countries.map((country) => (
                              <option
                                key={country.countryCode}
                                value={country.countryCode}
                              >
                                {country.countryName}
                              </option>
                            ))}
                        </select>
                  </div>
                  <div className="col">
                    <label><h6>Primary Skills *</h6></label>
                    <input className="form-control editable" 
                      placeholder="Enter Primary Skills" name="primarySkills" 
                      onChange={(event) => {
                        setCandidateData((prevCandidateData) => ({
                          ...prevCandidateData,
                          primarySkills: event.target.value,
                        }));
                      }}
                      disabled={true}
                      value={candidateObj.primarySkills} />
                  </div>
                  <div className="col">
                    <label><h6>Secondary Skills</h6></label>
                    <input className="form-control editable" 
                    placeholder="Enter Secondary Skills" name="secondarySkills" 
                    disabled={true}
                    value={candidateObj.secondarySkills}/>
                  </div>
                </div>

                <br></br>
                  <div className="row">
                    <div className="col">
                      <label><h6>Interview Duration *</h6></label>
                      <select 
                        className="form-control-lg-8 drop-down" 
                        style={{ width: "100%", height: "50%" }}  
                        name="interviewDuration"
                        onChange={(event) => {
                          setCandidateData((prevCandidateData) => ({
                            ...prevCandidateData,
                            interviewDuration: event.target.value,
                          }));
                        }}
                        disabled={true}
                        value={candidateObj.interviewDuration}
                        >
                        <option key={0} value={'30 Mins'}>{'30 Mins'}</option>
                        <option key={1} value={'60 Mins'}>{'60 Mins'}</option>
                      </select>
                  </div>
                  <div className="col">
                    
                  </div>
                  <div className="col">
                    
                  </div>
                </div>


                </div>

                {/* <div className="candidate-details" style={{ marginTop: "20px", minHeight:"100px" }}>
                    <div className="row">
                      <div className="col container-heading"><h6>Rate Card Details</h6></div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label><h6>Rate Category *</h6></label>
                        <select 
                          className="form-control-lg-8 drop-down" 
                          style={{ width: "100%", height: "50%" }} 
                          onChange={(event) => {
                            setCandidateData((prevCandidateData) => ({
                              ...prevCandidateData,
                              rateCategory: event.target.value,
                            }));
                          }}
                          name="rateCategory"
                          disabled={true}
                          value={candidateObj.rateCategory}
                          >
                          <option key={0} value={'ratesInIndia'}>{'Rates In India'}</option>
                          <option key={1} value={'ratesInUS'}>{'Rates In US'}</option>
                          <option key={2} value={'ratesInternal'}>{'Rates Internal'}</option>
                          <option key={3} value={'ratesExternal'}>{'Rates External'}</option>
                        </select>
                      </div>

                      <div className="col">
                        <label><h6>Rate Card *</h6></label>
                        <select 
                          className="form-control-lg-8 drop-down" 
                          style={{ width: "100%", height: "50%" }} 
                          onChange={(event) => {
                            setCandidateData((prevCandidateData) => ({
                              ...prevCandidateData,
                              rateCard: event.target.value,
                            }));
                          }}
                          name="rateCard"
                          disabled={true}
                          value={candidateObj.rateCard}
                          >
                          <option key={0} value={'highDemand'}>{'High Demand'}</option>
                          <option key={1} value={'niche'}>{'Niche'}</option>
                          <option key={2} value={'legacy'}>{'Legacy'}</option>
                          <option key={3} value={'leadership'}>{'Leadership'}</option>
                          <option key={4} value={'others'}>{'Others'}</option>
                        </select>
                      </div>

                      <div className="col">
                        <label><h6>Is Bonus Applicable *</h6></label>
                        <select 
                          className="form-control-lg-8 drop-down" 
                          style={{ width: "100%", height: "50%" }}  
                          name="isBonusApplicable"
                          disabled={true}
                          onChange={(event) => {
                            setCandidateData((prevCandidateData) => ({
                              ...prevCandidateData,
                              isBonusApplicable: event.target.value,
                            }));
                          }}
                          value={candidateObj.isBonusApplicable}
                          >
                          <option key={0} value={true}>Yes</option>
                          <option key={1} value={false}>No</option>
                        </select>
                      </div>
                    </div>
                </div> */}

                  <div className="candidate-details" style={{ 
                    marginTop: "20px", minHeight:"200px",
                    display: candidateObj.slotNumber == 0 ? 'block': 'none'
                  }}>
                    <div className="row">
                      <div className="col container-heading"><h6>Candidate Slots</h6></div>
                    </div>
                    <div className="availability-slot" >
                      <div className="row" >
                        <div className="col container-heading">Availability Slot 1</div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <label>
                            <h6>Date *</h6>
                          </label>
                          <input
                            type="date"
                            className="form-control editable "
                            placeholder="Select Date"
                            name="slotDate1" 
                            disabled={true}
                            value={candidateObj.slotDate1}
                          />
                        </div>
                        <div className="col">
                          <label>
                            <h6>Time *</h6>
                          </label>
                          <input
                            type="text"
                            className="form-control editable "
                            placeholder="Select Time"
                            name="slotTime1" 
                            disabled={true}
                            value={candidateObj.slotTime1}
                          />
                        </div>
                        
                        <div className="col">
                          <label>
                            <h6>Timezone *</h6>
                          </label>
                          <input
                            type="text"
                            className="form-control editable "
                            placeholder="Select Timezone"
                            name="timezone1" 
                            disabled={true}
                            value={candidateObj.timezone1}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="availability-slot" >
                      <div className="row">
                        <div className="col container-heading"
                        >Availability Slot 2</div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <label>
                            <h6>Date *</h6>
                          </label>
                          <input
                            type="date"
                            className="form-control editable "
                            placeholder="Select Date"
                            name="slotDate2" 
                            disabled={true}
                            value={candidateObj.slotDate2}
                          />
                        </div>
                        <div className="col">
                          <label>
                            <h6>Time *</h6>
                          </label>
                          <input
                            type="text"
                            className="form-control editable "
                            placeholder="Select Time"
                            name="slotTime2" 
                            disabled={true}
                            value={candidateObj.slotTime2}
                          />
                        </div>
                        
                        <div className="col">
                          <label>
                            <h6>Timezone *</h6>
                          </label>
                          <input
                            type="text"
                            className="form-control editable "
                            placeholder="Select Timezone"
                            name="timezone2" 
                            disabled={true}
                            value={candidateObj.timezone2}
                          />
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className="candidate-details" style={{ 
                    marginTop: "20px", minHeight:"200px",
                    display: candidateObj.slotNumber > 0 ? 'block': 'none'
                  }}>
                    <div className="row">
                      <div className="col container-heading"><h6>Selected Slot</h6></div>
                    </div>
                    <div className="availability-slot" 
                      style={{display: candidateObj.slotNumber == 1 ? 'block': 'none'}}
                    >
                      <div className="row">
                        <div className="col">
                          <label>
                            <h6>Date *</h6>
                          </label>
                          <input
                            type="date"
                            className="form-control editable "
                            placeholder="Select Date"
                            name="slotDate1" 
                            disabled={true}
                            value={candidateObj.slotDate1}
                          />
                        </div>
                        <div className="col">
                          <label>
                            <h6>Time *</h6>
                          </label>
                          <input
                            type="text"
                            className="form-control editable "
                            placeholder="Select Time"
                            name="slotTime1" 
                            disabled={true}
                            value={candidateObj.slotTime1}
                          />
                        </div>
                        
                        <div className="col">
                          <label>
                            <h6>Timezone *</h6>
                          </label>
                          <input
                            type="text"
                            className="form-control editable "
                            placeholder="Select Timezone"
                            name="timezone1" 
                            disabled={true}
                            value={candidateObj.timezone1}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="availability-slot" 
                      style={{display: candidateObj.slotNumber == 2 ? 'block': 'none'}}
                    >
                      <div className="row">
                        <div className="col">
                          <label>
                            <h6>Date *</h6>
                          </label>
                          <input
                            type="date"
                            className="form-control editable "
                            placeholder="Select Date"
                            name="slotDate2" 
                            disabled={true}
                            value={candidateObj.slotDate2}
                          />
                        </div>
                        <div className="col">
                          <label>
                            <h6>Time *</h6>
                          </label>
                          <input
                            type="text"
                            className="form-control editable "
                            placeholder="Select Time"
                            name="slotTime2" 
                            disabled={true}
                            value={candidateObj.slotTime2}
                          />
                        </div>
                        
                        <div className="col">
                          <label>
                            <h6>Timezone *</h6>
                          </label>
                          <input
                            type="text"
                            className="form-control editable "
                            placeholder="Select Timezone"
                            name="timezone2" 
                            disabled={true}
                            value={candidateObj.timezone2}
                          />
                        </div>
                      </div>
                    </div>

                  </div>


                  <div className="candidate-details" style={{ marginTop: "20px", minHeight:"100px" }}>
                    <div className="row">
                      <div className="col container-heading"><h6>Education Details</h6></div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label><h6>Highest Qualification </h6></label>
                        <input className="form-control editable" 
                        name="highestQualification" 
                        disabled={true}
                        value={candidateObj.highestQualification}/>
                      </div>
                      <div className="col">
                        <label><h6>Course </h6></label>
                        <input className="form-control editable" 
                        placeholder="Ex: Computers Engineering" 
                        name="course" 
                        disabled={true}
                        value={candidateObj.course}/>
                      </div>
                      <div className="col">
                        <label><h6>Institute </h6></label>
                        <input className="form-control editable" 
                        placeholder="Enter Institute Name" 
                        
                        name="institute" 
                        disabled={true}
                        value={candidateObj.institute}/>
                      </div>
                    </div>

                  </div>

                  <div className="candidate-details" style={{ marginTop: "20px", minHeight:"100px" }}>
                    <div className="row">
                      <div className="col container-heading"><h6>Employment Details</h6></div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label><h6>Are you currently Employed? </h6></label>
                        <select 
                          className="form-control-lg-8 drop-down" 
                          style={{ width: "100%", height: "50%" }}  
                          name="isCurrentlyEmployed"
                          disabled={true}
                          value={candidateObj.isCurrentlyEmployed}
                          >
                          <option value="">Select</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div className="col">
                        <label><h6>Total Experience </h6></label>
                        <input className="form-control editable" 
                        placeholder="Ex: 5 years 2 Months" 
                        name="totalExperience" 
                        disabled={true}
                        value={candidateObj.totalExperience}/>
                      </div>
                      <div className="col">
                        <label><h6>Relevant Experience </h6></label>
                        <input className="form-control editable" 
                        placeholder="Ex: 5 years 2 Months" 
                        name="relevantExperience" 
                        disabled={true}
                        value={candidateObj.relevantExperience}/>
                      </div>
                    </div>

                    {/* <br></br>

                    <div className="row">
                      <div className="col">
                        <label><h6>Current Company</h6></label>
                        <input className="form-control editable" 
                        placeholder="Enter Current Company" 
                        name="currentCompany" 
                        disabled={true}
                        value={candidateObj.currentCompany}/>
                      </div>
                      <div className="col">
                        <label><h6>Current Role/Designation</h6></label>
                        <input className="form-control editable" 
                        placeholder="Enter Current Role" 
                        name="currentRole" 
                        disabled={true}
                        value={candidateObj.currentRole}/>
                      </div>
                      <div className="col">
                      </div>
                    </div>

                    <br></br>

                    <div className="row">
                      <div className="col">
                        <label><h6>Social Profile Link</h6></label>
                        <input className="form-control editable" 
                        placeholder="Ex: http://linkedin.in/your-name" 
                        name="socialProfile" 
                        disabled={true}
                        value={candidateObj.socialProfile}/>
                      </div>
                      <div className="col">
                        
                      </div>
                    </div> */}
                  </div>


                  <div className="comments-remarks" style={{ margin: "20px", padding: "20px" }} >
                  <div className="row">
                      <div className="col container-heading"><h6>Candidate Resume *</h6></div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label
                          for="candidateResume"
                          style={{
                            height: "50px",
                            width: "600px",
                            background: "#EEF4FF 0% 0% no-repeat padding-box",
                            borderRadius: "4px",
                            opacity: 1,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                        <span
                          style={{
                            width: "600px",
                            height: "50px",
                            background: "#EEF4FF 0% 0% no-repeat padding-box",
                            borderRadius: "4px",
                            opacity: 1,
                            display: "flex",
                            alignItems: "center",
                            alignContent: "center",
                          }}
                        >
                          <img src="/Images/pdfIcon.svg" style={{paddingLeft: '20px'}} /> 
                          {/* <span style={{paddingLeft: '10px'}}> {"  "} </span> */}
                          {/* <span style={{ color: "#3E67B1", paddingLeft: '10px', paddingRight: '10px' }}> Browse </span> */}
                          <span style={{marginLeft: '5%'}}>{candidateResumeName}</span>
                          <span>
                            <i className="fa-solid fa-download"
                              style={{
                                cursor: 'pointer',
                                paddingLeft: '20px'
                              }}
                              onClick={async (e) => {
                                e.preventDefault(true)
                                console.log("Clicked", candidateObj.candidateResumeId)
                                let documentId = candidateObj.candidateResumeId;
                                try {
                                  let data = await getCandidateDocumentById(documentId);
                                  const blobUrl = URL.createObjectURL(data);
                                  const link = document.createElement('a');
                                  link.href = blobUrl;
                                  link.download = documentId;
                                  document.body.appendChild(link);
                                  link.click();
                                  document.body.removeChild(link);
                                  URL.revokeObjectURL(blobUrl);
                                } catch (error) {
                                  toast.error('Unable to download Document..!')
                                }
                              }}
                              >
                              </i>
                            </span>
                          
                        </span>
                        <input
                          style={{ display: "none" }}
                          id="candidateResume"
                          type="file"
                          name="candidateResume"
                          disabled={true}
                          accept=".pdf,.docx" 
                          onChange={handleResumeDocumentChange}
                        />
                      </label>
                      {/* <i>
                      Only PDF Files Supported with Size lessthan 100KB
                      </i> */}
                    </div>
                  </div>
                  </div>

                  <div className="comments-remarks" style={{ margin: "20px", padding: "20px" }} >
                  

                    
                    <div className="row" style={{ marginTop: '20px', textAlign: 'center', paddingBottom: '5px' }}>
                      <div className="col" style={{ textAlign: 'center' }}>
                        <button
                          type="button"
                          className="btn btn-outline-primary me-2"
                          style={{
                            border: '1px solid #3E67B1',
                            borderRadius: ' 4px',
                            opacity: '1',
                            width: '96px',
                            height: '50px',
                          }}
                          onClick={()=>{
                            navigate(-1);
                          }}
                        >
                          BACK
                        </button>
                        
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <br></br>
                </Tab>
                
                <Tab eventKey="start" title="Assessment Skills">
                  <div className="comments-remarks" style={{ margin: "20px", padding: "20px" }} >
                    <div className="row">
                      <div className="col text-left"><h6>Skill Assessment</h6></div>
                    </div>
                    <table className="table">
                      <thead>
                        <tr>
                          <th >Topic/Technology</th>
                          <th >Expected Profeciency</th>
                          <th>Possess Profeciency *</th>
                          <th style={{textAlign:'left'}}>Rating *</th>
                          <th style={{textAlign:'left'}}>Remarks/Comments *</th>
                        </tr>
                      </thead>
                      <tbody>
                      {dynamicSkills.map((cell) => (
                        <tr key={cell.skillIndex}>
                          <td className="col-2">
                            <input
                              className="form-control editable"
                              type="text"
                              disabled={true}
                              onChange={(event) => {
                                setDynamicSkills((prev) => {
                                  const updatedSkills = [...prev];
                                  updatedSkills[cell.skillIndex].skillTopic = event.target.value;
                                  return updatedSkills;
                                });
                              }}
                              style={{ height: '30px', width: "100%" }}
                              name="skillTopic"
                              value={cell.skillTopic}
                            />
                          </td>
                          <td className="col-2"> 
                            <select 
                              className="form-control-lg-8 drop-down" 
                              disabled={true}
                              onChange={(event) => {
                                setDynamicSkills((prev) => {
                                  const updatedSkills = [...prev];
                                  updatedSkills[cell.skillIndex].expectedProficiency = event.target.value;
                                  return updatedSkills;
                                });
                              }}
                              name="expectedProficiency"
                              value={cell.expectedProficiency}
                              style={{height: '30px', borderRadius:'5px', width: "100%"}}
                              >
                              <option key={0} value=''>Select</option>
                              <option key={1} value='BEGINNER'>BEGINNER</option>
                              <option key={2} value='AVERAGE'>AVERAGE</option>
                              <option key={3} value='INTERMEDIATE'>INTERMEDIATE</option>
                              <option key={4} value='GOOD'>GOOD</option>
                              <option key={5} value='ADVANCED'>ADVANCED</option>
                            </select>
                          
                          </td>
                          <td className="col-2">
                            <select 
                              className="form-control-lg-8 drop-down" 
                              onChange={(event) => {
                                setDynamicSkills((prev) => {
                                  const updatedSkills = [...prev];
                                  updatedSkills[cell.skillIndex].possessProficiency = event.target.value;
                                  return updatedSkills;
                                });
                              }}
                              name="possessProficiency"
                              disabled={isInterviewCompleted}
                              value={cell.possessProficiency}
                              style={{height: '30px', borderRadius:'5px', width: "100%"}}
                              >
                              <option key={0} value=''>Select</option>
                              <option key={1} value='BEGINNER'>BEGINNER</option>
                              <option key={2} value='AVERAGE'>AVERAGE</option>
                              <option key={3} value='INTERMEDIATE'>INTERMEDIATE</option>
                              <option key={4} value='GOOD'>GOOD</option>
                              <option key={5} value='ADVANCED'>ADVANCED</option>
                            </select>
                          </td>
                          <td className="col-2">
                            <select 
                              className="form-control-lg-8 drop-down" 
                              onChange={(event) => {
                                setDynamicSkills((prev) => {
                                  const updatedSkills = [...prev];
                                  updatedSkills[cell.skillIndex].rating = event.target.value;
                                  return updatedSkills;
                                });
                              }}
                              name="rating"
                              value={cell.rating}
                              disabled={isInterviewCompleted}
                              style={{height: '30px', borderRadius:'5px', width: "100%"}}
                              >
                              <option key={0} value=''>Select</option>
                              <option key={1} value='1'>&#9733; &#9734; &#9734; &#9734; &#9734;</option>
                              <option key={2} value='2'>&#9733; &#9733; &#9734; &#9734; &#9734;</option>
                              <option key={3} value='3'>&#9733; &#9733; &#9733; &#9734; &#9734;</option>
                              <option key={4} value='4'>&#9733; &#9733; &#9733; &#9733; &#9734;</option>
                              <option key={5} value='5'>&#9733; &#9733; &#9733; &#9733; &#9733;</option>
                            </select>
                          </td>
                          <td className="col-4">
                            <input
                              className="form-control editable"
                              type="text"
                              onChange={(event) => {
                                const { value } = event.target;
                                setDynamicSkills((prev) => {
                                  const updatedSkills = prev.map((skill) => {
                                    if (skill.skillIndex === cell.skillIndex) {
                                      return { ...skill, comments: value };
                                    }
                                    return skill;
                                  });
                                  return updatedSkills;
                                });
                              }}
                              style={{ height: '30px', width: "100%" }}
                              name="comments"
                              disabled={isInterviewCompleted}
                              value={cell.comments}
                            />
                          </td>
                        
                        </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="comments-remarks" style={{ margin: "20px", padding: "20px" }} >
                    <div className="row">
                      <div className="col text-left"><h6>Standard Competency Description</h6></div>
                    </div>
                    <table className="table">
                      <thead>
                        <tr>
                          <th >Topic/Skill</th>
                          <th style={{textAlign:'left'}}>Rating *</th>
                          <th style={{textAlign:'left'}}>Remarks/Comments *</th>
                        </tr>
                      </thead>
                      <tbody>
                      {standardSkills.map((cell) => (
                        <tr key={cell.skillIndex}>
                          <td className="col-6">
                            <span>
                            {cell.skillTopic}
                            </span>
                          </td>
                          <td className="col-2">
                          <select 
                            className="form-control-lg-8 drop-down" 
                            onChange={(event) => {
                              setStandardSkills((prev) => {
                                const updatedSkills = [...prev];
                                updatedSkills[cell.skillIndex].rating = event.target.value;
                                return updatedSkills;
                              });
                            }}
                            name="rating"
                            disabled={isInterviewCompleted}
                            value={cell.rating}
                            style={{height: '30px', borderRadius:'5px', width: "100%"}}
                            >
                            <option key={0} value=''>Select</option>
                            <option key={1} value='1'>&#9733; &#9734; &#9734; &#9734; &#9734;</option>
                            <option key={2} value='2'>&#9733; &#9733; &#9734; &#9734; &#9734;</option>
                            <option key={3} value='3'>&#9733; &#9733; &#9733; &#9734; &#9734;</option>
                            <option key={4} value='4'>&#9733; &#9733; &#9733; &#9733; &#9734;</option>
                            <option key={5} value='5'>&#9733; &#9733; &#9733; &#9733; &#9733;</option>
                          </select>
                          </td>
                          <td className="col-4">
                            <input
                              className="form-control editable"
                              type="text"
                              onChange={(event) => {
                                const { value } = event.target;
                                setStandardSkills((prev) => {
                                  const updatedSkills = prev.map((skill) => {
                                    if (skill.skillIndex === cell.skillIndex) {
                                      return { ...skill, comments: value };
                                    }
                                    return skill;
                                  });
                                  return updatedSkills;
                                });
                              }}
                              style={{ height: '30px', width: "100%" }}
                              name="comments"
                              disabled={isInterviewCompleted}
                              value={cell.comments}
                            />
                          </td>
                        </tr>

                      ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="comments-remarks" style={{ margin: "20px", padding: "20px" }} >
                    <div className="row"
                    style={{ display: isInterviewCompleted ? 'none' : 'flex' }}
                    >
                      <div className="col-5">
                        <label>
                          <h6>Upload Documents</h6>
                        </label>
                        <label
                          for="uploadDocument"
                          style={{
                            height: "50px",
                            width: "100%",
                            background: "#EEF4FF 0% 0% no-repeat padding-box",
                            borderRadius: "4px",
                            opacity: 1,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              height: "50px",
                              width: "100%",
                              background: "#EEF4FF 0% 0% no-repeat padding-box",
                              borderRadius: "4px",
                              opacity: 1,
                              display: "flex",
                              alignItems: "center",
                              alignContent: "center",
                            }}
                          >
                            <img src="/Images/pdfIcon.svg" style={{paddingLeft: '20px'}} /> 
                            <span style={{ color: "#3E67B1", paddingLeft: '10px', paddingRight: '10px' }}> Browse </span>
                            <span style={{marginLeft: '10px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',  maxWidth: '50%' }}> {uploadDocument?.name}</span>
                          
                          </span>
                          <input
                            style={{ display: "none" }}
                            id="uploadDocument"
                            type="file"
                            name="uploadDocument"
                            accept=".pdf,.jpg,.png,.docx" 
                            onChange={(e) => {
                              const documentFile = e.target.files[0];
                              if (documentFile.size > MAX_RESUME_FILE_SIZE) {
                                toast.error('File size exceeds the maximum allowed size of 500 KB.');
                                setUploadDocument(null);
                              } else {
                                setUploadDocument(documentFile);
                              }
                            }}
                          />
                        </label>
                        <span>Size must be lessthan 500KB </span>
                      </div>
                      <div className="col-4">
                        <label>
                          <h6>Document Name *</h6>
                        </label>
                        <input
                          type="text"
                          className="form-control editable"
                          placeholder="Enter Document Name"
                          onChange={(event) => {
                            let value = (event.target.value).replace(/[._@-]/g, '');
                            setUploadDocumentName(value);
                          }}
                          name="uploadDocumentName"
                          value={uploadDocumentName}
                        />

                      </div>
                      <div className="col-3">
                        <button
                          onClick={() => {
                            if (uploadDocumentName == '') {
                              toast.error('Document Name missing')
                              return;
                            }
                            if (!uploadDocument) {
                              toast.error('Document missing')
                              return;
                            }
                            handleUploadDocument()
                          }}
                          type="button"
                          className="btn btn-primary"
                          style={{
                            border: '1px solid #3E67B1',
                            borderRadius: ' 4px',
                            opacity: '1',
                            width: '130px',
                            height: '50px',
                            marginTop: '30px',
                            display: candidateObj.status == 'APPROVED' ? 'none': 'block'
                          }}
                        >
                          UPLOAD
                        </button>

                      </div>
                    </div>
                    <div className="row">
                      <table className="table">
                        <thead>
                          <tr>
                            <th >Document Name</th>
                            <th style={{textAlign:'left'}}>Document Link</th>
                            <th style={{textAlign:'left'}}> Actions </th>
                          </tr>
                        </thead>
                        <tbody>
                        {documents.map((cell) => (
                          <tr key={cell.skillIndex}>
                            <td className="col-4">
                              <span>
                              {cell.documentName}
                              </span>
                            </td>
                            <td className="col-4">
                              <span>
                              {cell.documentId}
                              </span>
                            </td>
                            <td className="col-4">
                              <span>
                                <i className="fa-solid fa-download"
                                style={{
                                  cursor: 'pointer'
                                }}
                                onClick={async (e) => {
                                  e.preventDefault(true)
                                  console.log("Clicked", cell.documentId)
                                  let documentId = cell.documentId;
                                  try {
                                    let data = await getCandidateDocumentById(documentId);
                                    const blobUrl = URL.createObjectURL(data);
                                    const link = document.createElement('a');
                                    link.href = blobUrl;
                                    link.download = documentId;
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);
                                    URL.revokeObjectURL(blobUrl);
                                  } catch (error) {
                                    toast.error('Unable to download Document..!', error)
                                  }
                                }}
                                >
                                </i>
                              </span>
                              <span>
                                <i className="fa-solid fa-trash-can"
                                style={{
                                  cursor: 'pointer',
                                  marginLeft: '20px',
                                  display: cell.documentName == 'Resume' ? 'none': 'inline'
                                }}
                                onClick={async (e) => {
                                  e.preventDefault(true)
                                  deleteDocument(candidateId, cell.documentId).then((res) => {
                                    toast.success('Deleted the Document.')
                                    setDocuments(res.data.documents)
                                  }).catch((err) => {
                                    console.log(err);
                                    toast.error('Unable to delete Document.')
                                  })
                                }}
                                >
                                </i>
                              </span>
                            </td>
                            
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    </div>
                  </div>

                  <div className="comments-remarks" style={{ margin: "20px", padding: "20px" }} >
                    <div className="row">
                      <div className="col-4">
                        <label>
                          <h6>Overall Feedback *</h6>
                        </label>
                        <select
                          className="form-control-lg drop-down"
                          style={{ width: "100%", height: "50%" }}
                          onChange={(event) => {
                            setCandidateData((prevCandidateData) => ({
                              ...prevCandidateData,
                              overallFeedback: event.target.value
                            }));
                          }}
                          name="overallFeedback"
                          disabled={isInterviewCompleted}
                          value={candidateObj.overallFeedback}
                        >
                          <option value="">Select </option>
                          <option value="SELECTED">SELECTED</option>
                          <option value="NEAR MATCH">NEAR MATCH</option>
                          <option value="TRAINABLE">TRAINABLE</option>
                          <option value="HOLD">HOLD</option>
                          <option value="REJECTED">REJECTED</option>
                        </select>
                      </div>
                      <div className="col-8" >
                        <label>
                          <h6>Recommendations *</h6>
                        </label>
                        <input
                          type="text"
                          className="form-control editable"
                          placeholder="Enter Recommendations"
                          onChange={(event) => {
                            setCandidateData((prevCandidateData) => ({
                              ...prevCandidateData,
                              recommendations: event.target.value
                            }));
                          }}
                          name="recommendations"
                          disabled={isInterviewCompleted}
                          value={candidateObj.recommendations}
                        />
                      </div>

                    </div>

                    
                    <div className="row" style={{ 
                      marginTop: '20px', textAlign: 'center', paddingBottom: '5px',
                      }}>
                      <div className="col" style={{ 
                        textAlign: 'center'
                        }}>
                        <button
                          type="button"
                          className="btn btn-outline-primary me-2"
                          style={{
                            border: '1px solid #3E67B1',
                            borderRadius: ' 4px',
                            opacity: '1',
                            width: '96px',
                            height: '50px',
                          }}
                          onClick={()=>{
                            navigate('/panel/panelDashboard');
                          }}
                        >
                          BACK
                        </button>
                        <button
                          onClick={() => {
                            if (!candidateObj.panelEmailId) {
                              toast.error('Candidate is not claimed.!');
                              return;
                            }
                            if (!user.emailId) {
                              toast.error('Invalid User Email ID.!')
                              return;
                            }
                            if(candidateObj.panelEmailId == user.emailId) {
                              let isValid = validateFeedback(
                                dynamicSkills, 
                                standardSkills, 
                                candidateObj['overallFeedback'], 
                                candidateObj['recommendations']
                              );
                              if (isValid) {
                                updateSkillsForCandidate(candidateId, { 
                                  dynamicSkills, 
                                  standardSkills, 
                                  overallFeedback: candidateObj['overallFeedback'],
                                  recommendations: candidateObj['recommendations'],
                                }).then((res) => {
                                  toast.success(`Assessment Feedback has been updated.!`);
                                  setCandidateData(res.data);
                                  setDynamicSkills(res.data.dynamicSkills);
                                  setStandardSkills(res.data.standardSkills);
                                  navigate('/panel/panelDashboard');
                                }).catch((err)=> {
                                  console.error(err);
                                  toast.error(`Unable to update Assessment Skills.!`);
                                  return;
                                })
                              } else {
                                toast.error('All fields are Mandatory.!');
                                return;
                              }
                            } else {
                              toast.error('Feedback should be provided by Claimed Panel.!')
                              return;
                            }
                          }}
                          type="button"
                          className="btn btn-primary"
                          style={{
                            border: '1px solid #3E67B1',
                            borderRadius: ' 4px',
                            opacity: '1',
                            width: '96px',
                            height: '50px',
                            display: (isInterviewCompleted || candidateObj.status == 'APPROVED') ? 'none': 'block'
                          }}
                        >
                          UPDATE
                        </button>
                      </div>
                    </div>
                    <br></br>

                  </div>

                  


                  <br></br>
                  <br></br>

                </Tab>

              </Tabs>
            </form>
            
          </div>

        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
          <div className="card">
            <div className="card-body text-center">
              <h2 className="card-title">
                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                Access Denied
              </h2>
              <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}
