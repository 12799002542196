import React, { useState } from "react";
import { postCustomerContacts, postTickets } from "../../service/api";
import { generate } from "rxjs";
import { event } from "jquery";
import { toast } from "react-toastify";
// import toastNotifications from "../toastNotifications/toastMessages";

const InputField = ({
  label,
  type,
  placeholder,
  value,
  onChange,
  disabled,
}) => (
  <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
    <label>
      <h6>{label}</h6>
    </label>
    <input
      type={type}
      className="form-control"
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      onChange={onChange}
      style={{ width: "100%", height: "50px" }}
    />
  </div>
);

const SelectField = ({ label, options, value, onChange }) => (
  <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
    <label>
      <h6>{label}</h6>
    </label>
    <select
      value={value}
      onChange={onChange}
      className="form-select"
      style={{ width: "100%", height: "50px", cursor: "pointer" }}
    >
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

export default function Customer(props) {
  function validateCustomerData(data) {
    let result = { isValid: true };
    let required_fields = [
      "purpose",
      "name",
      "companyName",
      "gender",
      "emailId",
      "phoneNumber",
      "description",
      "country",
    ];
    required_fields.forEach((key) => {
      if (!data[key]) {
        result = { isValid: false, mgs: `Field Missing ${key}` };
      }
    });
    return result;
  }

  function resetCustomerForm(data) {
    setFormValues({
      purpose: "CUSTOMER",
      name: "",
      emailId: "",
      companyName: "",
      companySize: "",
      gender: "", // Updated from "designation"
      country: "",
      phoneNumber: "",
      otp: "",
      companyUrl: "",
      availabilityDate: "",
      slotAvailability: "",
      description: "",
    });
  }

  function generateOTPHandler(event) {
    event.preventDefault();
    const {
      purpose,
      name,
      gender,
      emailId,
      phoneNumber,
      country,
      companyName,
      companySize,
      companyUrl,
      slotDate,
      slotTime,
      description,
    } = formValues;

    const payload = {
      purpose,
      name,
      gender,
      emailId: formValues.emailId,
      phoneNumber: formValues.phoneNumber,
      country,
      companyName,
      companySize,
      companyUrl,
      slotDate: formValues.availabilityDate,
      slotTime: formValues.slotAvailability,
      description,
    };

    const validation = validateCustomerData(payload);
    if (!validation.isValid) {
      toast.error(validation.mgs);
      return;
    }

    postCustomerContacts(payload)
      .then((data) => {
        setOTP(true);
      })
      .catch((error) => {
        setOTP(false);
      });
  }
  const [isOTPGenerated, setOTP] = useState(false);
  const [formValues, setFormValues] = useState({
    purpose: "CUSTOMER",
    name: "",
    emailId: "",
    companyName: "",
    companySize: "",
    gender: "",
    country: "",
    phoneNumber: "",
    otp: "",
    companyUrl: "",
    availabilityDate: "",
    slotAvailability: "",
    description: "",
  });

  const handleChange = (event, field) => {
    if (field && field === "purpose") {
      props.purposeSaver(event);
    }

    const value = event.target.value;

    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleSubmit = (event) => {
    const { emailId, purpose, otp } = formValues;
    if (!otp) {
      alert(`Please enter OTP`);
    } else {
      let otpVal = +otp;
      postTickets({ emailId: emailId, purpose, otp: otpVal })
        .then((data) => {
          toast.success(
            "Ticket has been Created, Our Support team will contact you soon.!"
          );
          resetCustomerForm();
        })
        .catch((err) => {
          console.error(err);
          toast.error(err.error);
          setOTP(false);
        });
    }
  };

  const sectionStyle = { marginTop: "1rem", marginBottom: "1rem" };

  return (
    <div
      className="container"
      style={{ backgroundColor: "white", padding: "1rem" }}
    >
      <div className="row" style={{ ...sectionStyle, textAlign: "center" }}>
        <div className="col">
          <h3>Talk to us Today</h3>
        </div>
      </div>
      <form>
        <div className="row" style={sectionStyle}>
          <SelectField
            label="Purpose"
            value={props.purpose}
            onChange={(event) => handleChange(event, "purpose")}
            options={[
              { label: "Panelist", value: "PANELIST" },
              { label: "Customer", value: "CUSTOMER" },
            ]}
            style={{ width: "100%", height: "50px" }}
          />
          <InputField
            label="Name"
            type="text"
            placeholder="Enter Name"
            value={formValues.name}
            onChange={(event) => handleChange(event, "name")}
            style={{ width: "100%", height: "50px" }}
          />
          <InputField
            label="Email"
            type="text"
            placeholder="Enter Email"
            value={formValues.emailId}
            onChange={(event) => handleChange(event, "emailId")}
            style={{ width: "100%", height: "50px" }}
          />
        </div>
        <div className="row" style={sectionStyle}>
          <InputField
            label="Company Name"
            type="text"
            placeholder="Enter Company Name"
            value={formValues.companyName}
            onChange={(event) => handleChange(event, "companyName")}
            style={{ width: "100%", height: "50px" }}
          />
          <SelectField
            label="Company Size"
            value={formValues.companySize}
            onChange={(event) => handleChange(event, "companySize")}
            options={[
              { label: "Select Company Size", value: "Select Purpose" },
              { label: "100-500", value: "100-500" },
              { label: "500-1000", value: "500-1000" },
            ]}
            style={{ width: "100%", height: "50px" }}
          />
          <InputField
            label="Company URL"
            type="text"
            placeholder="Enter Company URL"
            value={formValues.companyUrl}
            onChange={(event) => handleChange(event, "companyUrl")}
            style={{ width: "100%", height: "50px" }}
          />
        </div>
        <div className="row" style={sectionStyle}>
          <SelectField
            label="Country"
            value={formValues.country}
            onChange={(event) => handleChange(event, "country")}
            options={[
              { label: "Select Country", value: "Select Purpose" },
              { label: "India", value: "india" },
              { label: "Canada", value: "canada" },
            ]}
            style={{ width: "100%", height: "50px" }}
          />
          <InputField
            label="Mobile No"
            type="tel"
            placeholder="Enter Mobile No"
            value={formValues.phoneNumber}
            onChange={(event) => handleChange(event, "phoneNumber")}
            style={{ width: "100%", height: "50px" }}
          />
          {/* <div className="col"></div> */}
          <SelectField
            label="Gender" // Updated from "Designation"
            value={formValues.gender} // Updated from "designation"
            onChange={(event) => handleChange(event, "gender")} // Updated from "designation"
            options={[
              // Updated options for gender
              { label: "Select Gender", value: "Select Purpose" },
              { label: "Male", value: "male" },
              { label: "Female", value: "female" },
            ]}
            style={{ width: "100%", height: "50px" }}
          />
        </div>
        <div className="row" style={sectionStyle}>
          <InputField
            label="Availability Date For Demo"
            type="date"
            placeholder="Select Availability Date for Demo"
            value={formValues.availabilityDate}
            onChange={(event) => handleChange(event, "availabilityDate")}
            style={{ width: "100%", height: "50px" }}
          />
          <SelectField
            label="Slot Availability Time"
            value={formValues.slotAvailability}
            onChange={(event) => handleChange(event, "slotAvailability")}
            options={[
              { label: "Select Slot Time", value: "Select Slot Time" },
              { label: "00:00 AM", value: "00:00 AM" },
              { label: "01:00 AM", value: "01:00 AM" },
              { label: "02:00 AM", value: "02:00 AM" },
              { label: "03:00 AM", value: "03:00 AM" },
              { label: "04:00 AM", value: "04:00 AM" },
              { label: "05:00 AM", value: "05:00 AM" },
              { label: "06:00 AM", value: "06:00 AM" },
              { label: "07:00 AM", value: "07:00 AM" },
              { label: "08:00 AM", value: "08:00 AM" },
              { label: "09:00 AM", value: "09:00 AM" },
              { label: "10:00 AM", value: "10:00 AM" },
              { label: "11:00 AM", value: "11:00 AM" },
              { label: "12:00 PM", value: "12:00 PM" },
              { label: "13:00 PM", value: "13:00 PM" },
              { label: "14:00 PM", value: "14:00 PM" },
              { label: "15:00 PM", value: "15:00 PM" },
              { label: "16:00 PM", value: "16:00 PM" },
              { label: "17:00 PM", value: "17:00 PM" },
              { label: "18:00 PM", value: "18:00 PM" },
              { label: "19:00 PM", value: "19:00 PM" },
              { label: "20:00 PM", value: "20:00 PM" },
              { label: "21:00 PM", value: "21:00 PM" },
              { label: "22:00 PM", value: "22:00 PM" },
              { label: "23:00 PM", value: "23:00 PM" },
            ]}
            style={{ width: "100%", height: "50px" }}
          />
          <div className="col">
            <label>
              <h6>Description</h6>
            </label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              placeholder="Enter Description"
              style={{ width: "100%", height: "50px" }}
              value={formValues.description}
              onChange={(event) => handleChange(event, "description")}
            />
          </div>
        </div>
        <div className="row" style={sectionStyle}>
          <div className="col"></div>
          <InputField
            label="OTP"
            type="text"
            placeholder="Enter OTP"
            value={formValues.otp}
            disabled={!isOTPGenerated}
            onChange={(event) => handleChange(event, "otp")}
            style={{ width: "100%", height: "50px" }}
          />
          <div className="col"></div>
        </div>
        <div className="row" style={{ ...sectionStyle, textAlign: "center" }}>
          <div className="col">
            {!isOTPGenerated ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={(event) => generateOTPHandler(event)}
              >
                generate OTP
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary"
                onClick={(event) => handleSubmit(event)}
              >
                SUBMIT
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
