import React, { useEffect, useState } from "react";

import {
  getCustomerDashboardMetrics
} from "../../service/api";
import { toast } from "react-toastify";
import "./dashboard.css";
import DashboardCard from "../../helpers/DashboardCard";
import InterviewSchedules from "../Interviews/InterviewSchedules";
import CustomerSchedules from "../Interviews/CustomerSchedules";
export default function CustomerDashboard() {

  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');

  const [metrcisData, setMetricsData] = useState({
    feedbackMetrics: [],
    candidatesMetrics: [],
    jobsMetrics: [],
    interviewsMetrics: [],
    ticketsMetrics: []
  });

  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
  });
  const today = new Date().toISOString().slice(0, 10);

  const getAllMetrics = () => {
    getCustomerDashboardMetrics(filters).then((response) => {
      console.log(response?.data)
      setMetricsData(response?.data)
    }).catch((err) => {
      console.error(err);
    });
  }

  useEffect(() => {
    getAllMetrics();
  }, []);

  if (hasAllPermissions || userPermissions.includes('VIEW_CUSTOMER_DASHBOARD')) {
    return (
      <div style={{backgroundColor: "#F4F6FB"}}>
        <div className="row">
          <div className="col-3">
            <label> <h6>Start Date</h6> </label>
            <input
              type="date"
              id="startDate"
              value={filters.startDate}
              max={today}
              className="form-control"
              onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
              style={{
                height: "50px",
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                border: "1px solid #C7C7C7",
                borderRadius: "6px",
                opacity: "1",
              }}
              placeholder="Start Date"
            />
          </div>
          <div className="col-3">
            <label> <h6>End Date</h6> </label>
            <input
              type="date"
              id="endDate"
              value={filters.endDate}
              // min={filters.startDate}
              max={today}
              className="form-control"
              onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}
              style={{
                height: "50px",
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                border: "1px solid #C7C7C7",
                borderRadius: "6px",
                opacity: "1",
              }}
              placeholder="End Date"
            />
          </div>
          <div className="col-2">
              <button
                className="btn btn-primary"
                onClick={() => {
                    console.log(filters.startDate, filters.endDate)
                    if (filters.startDate != '' && filters.endDate != '') {
                      getAllMetrics()
                    } else {
                      toast.warn('Warning: Invalid Date Range.')
                    }
                }}
                style={{
                  height: "50px",
                  width: "150px",
                  background: "#3E67B1 0% 0% no-repeat padding-box",
                  borderRadius: "4px",
                  opacity: "1",
                  marginTop: "30px"
                }}
              >
                Refresh
              </button>
              </div>
          </div>
          <div className="d-flex" style={{display: 'flex',
          marginRight: '24px',
              marginTop: '20px',
          }}>
              <DashboardCard title="Interviews"
                  iconClass="fa fa-solid fa-calendar-alt"
                  subHeading1="Scheduled"
                  subHeading2="Completed"
                  subHeading3="Cancelled"
                  value1={metrcisData.interviewsMetrics[0]?.value}
                  value2={metrcisData.interviewsMetrics[3]?.value}
                  value3={metrcisData.interviewsMetrics[2]?.value}
                  // status ="SCHEDULED" startDate={filters.startDate}
                  // endDate={filters.endDate}
                  navigateTo="/customer/interviewSchedules/all"

                  />
              <DashboardCard
                  iconClass="fa fa-solid fa-bullhorn"
                  title="Jobs"
                  subHeading1="Created"
                  subHeading2="In Progress"
                  subHeading3="Approved"
                  value1={metrcisData.jobsMetrics[0]?.value}
                  value2={metrcisData.jobsMetrics[2]?.value}
                  value3={metrcisData.jobsMetrics[3]?.value}
                  // status ="CREATED" startDate={filters.startDate}
                  // endDate={filters.endDate}
                  navigateTo="/user/jobs/all"

              />
              <DashboardCard title="Candidates"
                  // subHeading1="Scheduled"
                             iconClass="fa fa-solid fa-user-circle"
                             subHeading2="Feedback Provided"
                             subHeading3="Feedback Pending"
                  // value1={metrcisData.candidatesMetrics[0]?.value}
                             value2={metrcisData.candidatesMetrics[8]?.value}
                             value3={metrcisData.candidatesMetrics[7]?.value}
                  // status ="FEEDBACK_PROVIDED" startDate={filters.startDate}
                  // endDate={filters.endDate}
                             navigateTo="/user/candidates/all"
              />
              <DashboardCard title="Candidate Status"
                             iconClass="fa fa-solid fa-user-circle"
                             subHeading1="Selected"
                             subHeading2="Rejected"
                             subHeading3="Near Match"
                  // wrong values
                             value1={metrcisData.feedbackMetrics[0]?.value}
                             value2={metrcisData.feedbackMetrics[1]?.value}
                             value3={metrcisData.feedbackMetrics[2]?.value}
                  // status ="SELECTED" startDate={filters.startDate}
                  // endDate={filters.endDate}
                             navigateTo="/customer/candidateStatus"
              />

          </div>
          <br></br>
          <CustomerSchedules/>
      </div>
    );
  } else {
      return (
          <>
              <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
          <div className="card">
            <div className="card-body text-center">
              <h2 className="card-title">
                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                Access Denied
              </h2>
              <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}
