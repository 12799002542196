import React, { useEffect, useRef, useState } from "react";
import {
  postPanelContacts,
  postTickets,
  updatePanelContacts,
} from "../../service/api";
import reactFromBuilder from "../../utils/formBuilder";
import { getCountries } from "../../service/api";
import ExtraDetailsModel from "./ExtraDetailsModel";
import { toast } from "react-toastify";
import toastNotifications from "../toastNotifications/toastMessages";
import { useLocation, useNavigate } from "react-router-dom";

export default function ContactForm(props) {
  const modalRef = useRef(null);
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const formRef = useRef(null);
  const [isOTPGenerated, setOTPGenerated] = useState(false);
  // const [otpInput, setOTPInput] = useState("");
  // move to pop up

  useEffect(() => {
    getCountries().then((res) => {});
  }, []);

  function validatePanelData(data) {
    let result = { isValid: true };
    let required_fields = [
      "purpose",
      "name",
      "emailId",
      "phoneNumber",
      "country",
    ];
    required_fields.forEach((key) => {
      if (!data[key]) {
        result = { isValid: false, mgs: `Field Missing ${key}` };
      }
    });
    return result;
  }

  const extraDetailsCB = (data, extraDetails) => {
    const formData = new FormData(extraDetails.current);
    const emailId = formData.get("emailId");
    const otpInput = data.get("otp");
    data["emailId"] = emailId;
    return updatePanelContacts(data)
      .then((res) => {
        postTickets({
          emailId,
          purpose: "PANELIST",
          otp: +otpInput,
        })
          .then((res) => {
            toast.success(toastNotifications.contactForm.ticketCreatedSuccess);
            navigate(0);
          })
          .catch((err) => {
            toast.error(err.response.data.error);
          });
      })
      .catch((err) => {
        toast.error(err.response.data.error);
      });
  };
  extraDetailsCB.bind(modalRef);

  const onSubmitHandler = (event) => {
    modalRef.current.click();
    setIsSubmitted(true);
  };

  const handlePurposeChange = (event) => {
    props.purposeSaver(event);
  };

  const generateOTPHandler = () => {
    const formData = new FormData(formRef.current);
    const data = {
      purpose: formData.get("purpose"),
      name: formData.get("name"),
      gender: formData.get("gender"),
      emailId: formData.get("emailId"),
      phoneNumber: formData.get("phoneNumber"),
      description: formData.get("description"),
      country: formData.get("country"),
      companyName: "",
      experience: "",
      primarySkills: "",
      secondarySkills: "",
      slotDate: "",
      slotTime: "",
      linkedinProfile: "",
      referredBy: "",
      refereeMobile: "",
      refereeEmail: "",
    };

    const validation = validatePanelData(data);
    if (!validation.isValid) {
      toast.error(validation.mgs);
      return;
    }

    postPanelContacts(data).then((res) => {
      modalRef.current.click();
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const formData = new FormData(event.target);

    const data = {
      purpose: formData.get("purpose"),
      name: formData.get("name"),
      gender: formData.get("gender"),
      emailId: formData.get("emailId"),
      phoneNumber: formData.get("phoneNumber"),
      description: formData.get("description"),
      country: formData.get("country"),
    };
    postPanelContacts(data).then((res) => {
      postTickets(data).then((res2) => {
        toast.success("Panel Contact created successfully.!");
      });
    });
  }

  return (
    <>
      <div className="container" style={{ backgroundColor: "white" }}>
        <br />
        <div className="row" style={{ textAlign: "center" }}>
          <div className="col">
            <h3>Talk to us Today</h3>
          </div>
        </div>
        <br />
        <form ref={formRef} name="contactForm">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <label>
                <h6>Purpose *</h6>
              </label>
              <select
                className="form-select"
                placeholder="Select Purpose"
                style={{ width: "100%", height: "50px", cursor: "pointer" }}
                name="purpose"
                value={props.purpose}
                onChange={(event) => {
                  handlePurposeChange(event);
                }}
              >
                {/* <option value="Select Purpose">Select purpose</option> */}
                <option value="PANELIST">PANELIST</option>
                <option value="CUSTOMER">CUSTOMER</option>
              </select>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <label>
                <h6>Name *</h6>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Name"
                name="name"
                style={{ width: "100%", height: "50px" }}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <label>
                <h6>Gender</h6>
              </label>
              <select
                className="form-select"
                style={{ width: "100%", height: "50px", cursor: "pointer" }}
                name="gender"
              >
                <option value="Select Gender">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <label>
                <h6>Country *</h6>
              </label>
              <select
                className="form-select"
                style={{ width: "100%", height: "50px", cursor: "pointer" }}
                name="country"
              >
                <option value="Select Purpose">Select Country</option>
                <option value="India">INDIA</option>
                <option value="US">US</option>
              </select>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <label>
                <h6>Email ID *</h6>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Email ID"
                name="emailId"
                style={{ width: "100%", height: "50px" }}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <label>
                <h6>Mobile No *</h6>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Mobile No"
                name="phoneNumber"
                style={{ width: "100%", height: "50px" }}
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <label>
                <h6>Description</h6>
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                placeholder="Enter Description"
                name="description"
                style={{ width: "100%", height: "100px" }}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4"></div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4"></div>
          </div>
          <br />
          <div className="row" style={{ textAlign: "center" }}>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              {true ? (
                <button
                  type="button"
                  disabled={false}
                  style={{
                    height: "50px",
                    background: "#3E67B1 0% 0% no-repeat padding-box",
                    borderRadius: "4px",
                    opacity: "1",
                  }}
                  className="btn btn-primary"
                  onClick={() => generateOTPHandler()}
                >
                  Generate OTP and CONTINUE
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  type="button"
                  style={{
                    height: "50px",
                    background: "#3E67B1 0% 0% no-repeat padding-box",
                    borderRadius: "4px",
                    opacity: "1",
                  }}
                  onClick={() => generateOTPHandler()}
                >
                  Generate OTP
                </button>
              )}
            </div>
          </div>
          <span
            hidden={true}
            ref={modalRef}
            data-toggle="modal"
            data-target="#extra_details_modal"
          ></span>
          <br />
        </form>
      </div>
      <ExtraDetailsModel
        modelClick={extraDetailsCB}
        extraDetails={formRef}
        modelID="extra_details_modal"
      />
    </>
  );
}
