import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { TextField, InputAdornment, Button, Switch } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { getDeliveryUnits } from '../../service/api';
import { toast } from "react-toastify";
import toastNotifications from "../toastNotifications/toastMessages";
import CircularProgress from '@mui/material/CircularProgress';

const DeliveryUnits = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');

  const navigate = useNavigate();
  const [deliveryUnitsData, setDeliveryUnitsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    setDataLoading(true);
    getDeliveryUnits().then((res) => {
      let delUnits = res.sort((a, b) => (b.customerId - a.customerId) || (b.delUnitId - a.delUnitId));
      setDeliveryUnitsData(delUnits);
      setFilteredData(delUnits);
      setDataLoading(false);
    });
  }, []);

  useEffect(() => {
    setFilteredData(
      deliveryUnitsData.filter((bu) =>
        Object.values(bu).some(
          (value) => {
            if(value) {
              return value.toString().toLowerCase().includes(search.toLowerCase())
            } else {
              return false;
            }
          }
        )
      )
    );
  }, [search, deliveryUnitsData]);

  const deliveryUnitsColumns = [
  
    {
      dataField: 'customerId',
      text: 'Customer ID',
      sort: true,
    },
    {
      dataField: 'customerCompany',
      text: 'Company Name',
      sort: true,
    },
    {
      dataField: 'delUnitId',
      text: 'Delivery Unit ID',
      sort: true,
    },
    {
      dataField: 'deliveryUnit',
      text: 'Delivery Unit',
      sort: true,
    },
    
    {
      dataField: 'vertical',
      text: 'Vertical/Domain',
      sort: true,
    },
    {
      dataField: 'lineOfBusiness',
      text: 'Line Of Business',
      sort: true,
    },
    {
      dataField: 'accountName',
      text: 'Account Name',
      sort: true,
    },

    {
      dataField: 'action',
      text: 'Actions',
      style: { width: '120px' },
      sort: true,
      formatter: (cell, row) => (
        <>
        <i
          title="Edit Delivery Unit"
          onClick={() => editDeliveryUnit(row.delUnitId)}
          className="fa fa-light fa-pen-to-square"
          style={{
            color: '#3E67B1',
            opacity: !(hasAllPermissions || userPermissions.includes('EDIT_DELIVERY_UNIT')) ? 0.2 : 1,
            pointerEvents: !(hasAllPermissions || userPermissions.includes('EDIT_DELIVERY_UNIT')) ? 'none' : 'auto',
          }}
        ></i>
        </>
      ),
    }
  ];

  const newDeliveryUnit = () => {
    if ((hasAllPermissions || userPermissions.includes('CREATE_DELIVERY_UNIT'))) {
      navigate(`/customer/addDeliveryUnit`);
    }
  }

  const editDeliveryUnit = (delUnitId) => {
    if ((hasAllPermissions || userPermissions.includes('EDIT_DELIVERY_UNIT'))) {
      navigate(`/customer/editDeliveryUnit/${delUnitId}`);
    }
  }

  const options = {
    sizePerPage: 5,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '50', value: 50 },
    ],
    hideSizePerPage: false,
    hidePageListOnlyOnePage: false,
    alwaysShowAllBtns: true,
    showTotal: true,
    nextPageText: "Next",
    prePageText: "Prev",
  };

  if (hasAllPermissions || userPermissions.includes('LIST_DELIVERY_UNITS')) {

    return (
      <div  className="container-page">
             <div style={{ padding: "0px", marginBottom: "0px"}}>

          <TextField
            placeholder="Search"
            size="small"
            style={{
              background: "#FCFCFC 0% 0% no-repeat padding-box",
              boxShadow: " 0px 3px 6px #3E67B133",
              borderRadius: "4px",
              opacity: "1",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <button
          onClick={newDeliveryUnit}
          className="btn btn-primary"
          style={{
            height: "50px",
            background: "#3E67B1 0% 0% no-repeat padding-box",
            borderRadius: "4px",
            opacity: "1",
            marginLeft: "20px",
            float: "right"
          }}
          >
          Create Delivery Unit
        </button>
          </div>
          <br/>
          <div
            className="main-content"
            style={{  background: "#fff", padding: "20px" }}
          >
          <div className="custom-table-container">
          <BootstrapTable
            hover
            bootstrap4
            keyField="id"
            data={filteredData}
            columns={deliveryUnitsColumns}
            pagination={paginationFactory(options)}
            filter={filterFactory()}
            noDataIndication={
              dataLoading ?
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',  width: '100%' }}>
                    <CircularProgress />
                  </Box>
                  :
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',  width: '100%' }}>
                    <span>No Data Found</span>
                  </Box>
            }
          />
        
        </div>
        </div>
      </div>
    );

  } else {
    return (
      <>
        <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
          <div className="card">
            <div className="card-body text-center">
              <h2 className="card-title">
                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                Access Denied
              </h2>
              <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }

};

export default DeliveryUnits;
