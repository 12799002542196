import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  getCustomerSchedules, getAssignedUsers, getCustomerDashboardMetrics
} from "../../service/api";
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import FilterModal from "./FilterModel";
import "../Candidate/Candidate.css";
import "../../CSS/dashboard.css";

function filterDataByDateRange(data, startDate, endDate) {
  let filteredData = data.filter((item) => {
      const itemDate = new Date(item.createdAt).toISOString().split('T')[0];
      return itemDate >= startDate && itemDate <= endDate;
  });
  filteredData = filteredData.sort((a, b) => b.scheduleId - a.scheduleId);
  return filteredData;
}
export default function CustomerSchedules() {
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dashboardFilter = queryParams.get('dashboardFilter');
  const { filterOptions } = useParams();
  const [searchStatus, setSearchStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [schedulesData, setSchedulesData] = useState([]);
  const [filteredSchedulesData, setFilteredSchedules] = useState([]);
  const [dataLoading, setDataLoading] = useState();
  const [filters, setFilters] = useState({});
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const navigate = useNavigate();
  const [metrcisData, setMetricsData] = useState({
    interviewsMetrics: []
  });
  const [activeCard, setActiveCard] = useState('');
  const getAllMetrics = () => {
    getCustomerDashboardMetrics(filters).then((response) => {
      setMetricsData(response.data)
      console.log(response.data)
    }).catch((err) => {
      console.error(err);
    });
  }

  const handleOpenFilterModal = () => {
    setFilterModalOpen(true);
  };

  const handleCloseFilterModal = () => {
    setFilterModalOpen(false);
  };
  const handleApplyFilters = (newFilters) => {
    if (['all', 'CREATED','SCHEDULED'].includes(newFilters.status) ) {
      newFilters.status = '';
    }
    const updatedFilters = Object.fromEntries(Object.entries(newFilters).filter(([key, value]) => value !== ""));
    setFilters(updatedFilters);
  };
  const populateSchedules = () => {
    getAssignedUsers().then((res) => {
      const userDict = res?.data.reduce((acc, { userId, firstName, lastName }) => {
        acc[userId] = `${firstName} ${lastName}`;
        return acc;
      }, {});

      setDataLoading(true); // Move setDataLoading(true) here

      getCustomerSchedules().then((response) => {

        let schedules = response?.data;

        if(window.location.pathname === '/customer/customerDashboard'){
            schedules = schedules.filter((schedule) => schedule.status === 'SCHEDULED');
        }
        if (startDate != '' && endDate != '') {
          schedules = filterDataByDateRange(schedules, startDate, endDate);
        }
        schedules.forEach((e) => {
          e.slot = `${e.scheduledSlot?.slotDate}, ${e.scheduledSlot?.slotTime}`;
          e.assigneeFullName = userDict[e.coordinatorId];
        });
        console.log(schedules);
        setSchedulesData(schedules);
        setDataLoading(false);
      }).catch((err) => {
        console.error(err);
        setDataLoading(false); // Ensure to set loading to false even if an error occurs
      });
    }).catch((err) => {
      console.error(err);
      setDataLoading(false); // Ensure to set loading to false even if an error occurs
    });
  };

  useEffect(() => {
    if (filterOptions) {
      let [status, startDate, endDate] = filterOptions.split('|')
      console.log(status, startDate, endDate)
      setSearchStatus(status);
      setStartDate(startDate || '');
      setEndDate(endDate || '');
    }
    populateSchedules();
    handleApplyFilters({'status': searchStatus})
  }, [searchStatus]);

  useEffect(() => {
    const filterKeys = Object.keys(filters);
    if (filterKeys.length > 0) {
      const filteredData = schedulesData.filter(job =>
        filterKeys.every(key =>
          String(job[key]).toLowerCase().includes(String(filters[key]).toLowerCase())
        )
      );
      setFilteredSchedules(filteredData);
    } else {
      setFilteredSchedules(schedulesData);
    }
  }, [filters, schedulesData]);

  useEffect(() => {
    getAllMetrics();
    console.log('dashboardFilter:', dashboardFilter);
    if (dashboardFilter) {
      const filteredData = schedulesData.filter(candidate => candidate.status === dashboardFilter.toUpperCase());
      console.log('filteredData:', filteredData)
      setActiveCard(dashboardFilter.toUpperCase());
      setFilteredSchedules(filteredData);
    } else {
      setFilteredSchedules(schedulesData);
    }
  }, [dashboardFilter, schedulesData]);


  const scheduleColumns = [
    {
      dataField: 'customerId',
      text: 'Customer ID',
      sort: true,
    },
    {
      dataField: 'customerCompany',
      text: 'Customer Name',
      sort: true,
    },
    {
      dataField: 'jobId',
      text: 'Job ID',

    },
    {
      dataField: 'jobName',
      text: 'Job Name',

    },
    {
      dataField: 'candidateName',
      text: 'Candidate Name',
      sort: true,
    },
    {
      dataField: 'assigneeFullName',
      text: 'Coordinator Name',
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
    },
    {
      dataField: 'slot',
      text: 'Slot',
      sort: true,
      style: { minWidth: '180px'},
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: (cell, row) => (
        <>
          <i
            title="View Schedule"
            className="fa fa-light fa-eye"
            onClick={() => {
              if ((hasAllPermissions || userPermissions.includes('VIEW_SCHEDULED_INTERVIEW'))) {
                navigate(`/user/viewInterviewSchedule/${row.scheduleId}/${row.customerId}`);
              }
            }}
            style={{
              cursor: "pointer",
              opacity: !(hasAllPermissions || userPermissions.includes('VIEW_SCHEDULED_INTERVIEW')) ? 0.2 : 1,
              pointerEvents: !(hasAllPermissions || userPermissions.includes('VIEW_SCHEDULED_INTERVIEW')) ? 'none' : 'auto',
            }}
          ></i>
          {/* <i
            className="fa fa-light fa-pen"
            onClick={() => {
              if ((hasAllPermissions || userPermissions.includes('EDIT_SCHEDULED_INTERVIEW'))) {
                navigate(`/user/editInterviewSchedule/${row.scheduleId}/${row.customerId}`);
              }
            }}
            style={{
              paddingLeft: "10px",
              cursor: "pointer",
              opacity: !(hasAllPermissions || userPermissions.includes('EDIT_SCHEDULED_INTERVIEW')) ? 0.2 : 1,
              pointerEvents: !(hasAllPermissions || userPermissions.includes('EDIT_SCHEDULED_INTERVIEW')) ? 'none' : 'auto',
            }}
          ></i> */}
        </>
      ),
      style: () => {
        return {
          font: "Muli",
          minWidth: "120px",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
    }
  ];

  const options = {
    sizePerPage: 10,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '50', value: 50 },
    ],
    hideSizePerPage: false,
    hidePageListOnlyOnePage: false,
    alwaysShowAllBtns: true,
    showTotal: true,
    nextPageText: "Next",
    prePageText: "Prev",
  };

  const filterDict = {
    'customerCompany': 'Customer Company',
    'scheduleId': 'Schedule ID',
    'slot': 'Slot',
    'status': 'Status',
    'panelEmailId': 'Panel Email ID',
    'candidateEmailId': 'Candidate Email ID',
    'coordinatorEmailId': 'Coordinator Email ID',
    'panelName': 'Panel Name',
    'candidateName': 'Candidate Name',
    'coordinatorName': 'Coordinator Name',
  }
  const filterBasedOnCards = (status) => {

    const filteredData = schedulesData.filter(candidate => candidate.status === status);
    setActiveCard(status);
    setFilteredSchedules(filteredData); // Update filtered schedules based on the selected card status
  };

  if (hasAllPermissions || userPermissions.includes('LIST_CUSTOMER_SCHEDULES')) {
    return (
        <div className="container-page">
          <div className="row"
               style={{
                 display: window.location.pathname === '/customer/customerDashboard' ? 'none' : 'block',
               }}
          >
            <div className="col" style={{textAlign: "right", marginBottom: "16px"}}>
              <button
                  className="filters"
                  variant="contained"
                  color="primary"
                  onClick={handleOpenFilterModal}
              >
                    <span className="filter-icon">
                      <img src="/Images/Filter.svg"/>
                    </span>
                &nbsp;&nbsp;
                <span className="filter-text">Filters</span>
              </button>

            </div>
          </div>
          <div className="row shadow-radius"
               style={{
                 backgroundColor: "#FFFFFF",
                 padding: "20px 20px 0px 20px",
                 marginBottom: "20px",
                 marginRight: "0px",
                 marginLeft: "0px",
                 display: window.location.pathname === '/customer/customerDashboard' ? 'none' : 'flex',
               }}
          >
            {metrcisData.interviewsMetrics.map((item, index) => (
                // if item.name ==="Feedback Pending orFeedback Provided" make class name col-2
                <div
                    className={`table-card-filters ${item.name === 'Candidate Technical Glitch' || item.name === 'Panel No Show' ||item.name === 'Candidate No Show' ||item.name === 'Panel Technical Glitch' ? 'col-2' : 'col-1'} ${activeCard === item.status ? 'btn btn-primary' : ''}`}
                    style={{
                      display: item.name === 'Un Assigned' || item.name === 'Un Claimed' || item.name === 'In Progress' || item.name === 'Approved' ? 'none' : 'inherit',
                      border: activeCard === item.status ? 'none' : 'inherit',
                    }}
                     onClick={() => filterBasedOnCards(item.status)}
                    key={index}>
                  <div>{item.name}</div>
                  <div
                      style={{
                        color: item.status === activeCard ? '#ffffff' : 'inherit'
                      }}
                  >{item.value}</div>
                </div>
            ))}
          </div>
          <div
              className="main-content shadow-radius"
          >
            <div className="applied-filters" style={{display: Object.keys(filters).length > 0 ? 'block' : 'none'}}>
              <div className="applied-filters-text">
                <h4>Applied Filters ({Object.keys(filters).length})</h4>
              </div>
              <br></br>
              <div className="applied-filters-list ">
                {Object.keys(filters).map((d, i) => {
                  return (filters[d] != '') ? <div>
                    <span className="applied-filter">
                      <label key={i}>{filterDict[d]}:- {filters[d]} &nbsp;{"  "}</label>
                      <i
                          className="fa fa-thin fa-circle-xmark"
                          style={{color: "#3c77dd"}}
                          onClick={() => {
                            console.log('removeFilter called with:', d, filters);
                            const newFilters = {...filters};
                            delete newFilters[d];
                            console.log("updatedFilters::", newFilters)
                            setFilters(newFilters);
                            handleApplyFilters(newFilters);
                          }}
                      ></i>
                    </span>
                  </div> : <></>
                })}
              </div>
              <br/>
            </div>
            <div className="custom-table-container"
            >
              <BootstrapTable
                  hover
                  bootstrap4
                  keyField="scheduleId"
                  data={filteredSchedulesData}
                  columns={scheduleColumns}
                  pagination={paginationFactory(options)}
                  noDataIndication={
                    dataLoading ?
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          width: '100%'
                        }}>
                          <CircularProgress/>
                        </Box>
                        :
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          width: '100%'
                        }}>
                          <span>No Data Found</span>
                        </Box>
                  }
              />

              {
                filterModalOpen ? <FilterModal
                    filters={filters}
                    open={filterModalOpen}
                    handleClose={handleCloseFilterModal}
                    handleApply={handleApplyFilters}
                /> : <></>
              }
            </div>
          </div>
        </div>
    );

  } else {
    return (
        <>
          <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
            <div className="card">
              <div className="card-body text-center">
                <h2 className="card-title">
                  <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                  Access Denied
                </h2>
                <p className="card-text">You do not have the required permissions to access this page.</p>
                <p className="card-text">Please contact your administrator for assistance.</p>
              </div>
            </div>
          </div>
        </>
    )
  }
}

