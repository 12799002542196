import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { getPanelInvoicesByPanel } from "../../service/api";
import InvoiceHelperView from "./InvoiceHelerView";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const PanelistInvoice = () => {
    const { panelEmailId } = useParams();
    const [panelDetailSummary, setPanelDetailSummary] = useState(null);
    const invoiceRef = useRef(null);

    useEffect(() => {
        const fetchPanelInvoices = async () => {
            const payload = {
                panelEmailId: panelEmailId,
                startDate: '2023-06-01',
                endDate: new Date().toISOString().split('T')[0] // Current date in 'YYYY-MM-DD' format
            };
            const data = await getPanelInvoicesByPanel(payload);
            setPanelDetailSummary(data);
        };

        fetchPanelInvoices();
    }, [panelEmailId]);

    const exportPDF = async () => {
        const input = invoiceRef.current;
        if (!input) return;

        try {
            const canvas = await html2canvas(input);
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4");

            // Add heading to the PDF
            pdf.setFontSize(18);
            pdf.text("Panel's Invoice", 10, 20);

            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
            pdf.save("invoice.pdf");
        } catch (error) {
            console.error("Error exporting PDF:", error);
        }
    };

    if (!panelDetailSummary) {
        return <div>Loading...</div>;
    }

    const ratesInIndia = panelDetailSummary.data.ratesInIndia;
    const summary = ratesInIndia.summary;
    const highDemand = ratesInIndia.highDemand;
    const rateCard = ratesInIndia.rateCard;

    return (
        <div>
            <div ref={invoiceRef}>
                <div className="shadow-radius" style={{ marginBottom: "20px" }}>
                    <div className="main-header">Panelist Details</div>
                    <div className="row">
                        <div className="col-4">
                            <div className="candidate-view-header2">Panelist Email</div>
                            <div className="custom-paragraph">{panelEmailId}</div>
                        </div>
                        <div className="col-4">
                            <div className="candidate-view-header2">Invoice From Month</div>
                            <div className="custom-paragraph">June 2023</div>
                        </div>
                        <div className="col-4" >
                            <div className="candidate-view-header2">Invoice To Month</div>
                            <div className="custom-paragraph">{new Date().toLocaleString('default', { month: 'long', year: 'numeric' })}</div>
                        </div>
                    </div>
                </div>
                <InvoiceHelperView header="Rates In India" summary={summary} highDemand={highDemand} rateCard={rateCard} />
                {/*<InvoiceHelperView header="OffStore Location" />*/}
            </div>

            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginRight: "20px",
                    marginBottom: "20px"
                }}
            >
                <Link className="btn btn-primary" style={{ marginRight: "20px" }} to="/admin/adminInvoice" >
                    Back
                </Link>
                <button className="btn btn-primary" onClick={exportPDF}>Export Invoice</button>
            </div>
        </div>
    );
};

export default PanelistInvoice;
