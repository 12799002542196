import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { isTokenValid } from '../service/http';

const RequireAuth = ({ children }) => {
  const location = useLocation();
  const isAuthenticated = isTokenValid();
  if (isAuthenticated) {
    return children;
  } else {
    return <Navigate to="/login" state={{ from: location }} />;
  }
};

export default RequireAuth;
