import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { createSkillMapping, deleteSkillMapping, getSkillMappings, updateSkillMapping } from "../../service/api";
import { toast } from "react-toastify";
import CircularProgress from '@mui/material/CircularProgress';
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export default function SkillMappings() {
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');
  
  const [ skillFamily, setSkillFamily] = React.useState("");
  const [ skill, setSkill] = React.useState("");
  // const [ serviceLine, setServiceLine] = React.useState("");
  // const [ poolName, setPoolName] = React.useState("");
  // const [ skillMappingData, setSkillMappingData] = React.useState([])
  const [dataLoading, setDataLoading] = useState(false);
  
  const skillMappingColumns = [
    {
      dataField: "skillMappingId",
      text: "Id",
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },

      sort: true,
    },
    {
      dataField: "skillFamily",
      text: "Skill Family",
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },

      sort: true,
    },
    {
      dataField: "skill",
      text: "Skill",
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },

      sort: true,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: actionFormatter,
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
    },
  ];

  const [skillMappingsData, setSkillMappingsData] = React.useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setDataLoading(true);
    getSkillMappings().then((response) => {
      setSkillMappingsData(response.data);
      setDataLoading(false);
    });
  },[]);

  useEffect(() => {
    setFilteredData(
      skillMappingsData.filter((ticket) =>
        Object.values(ticket).some(
          (value) =>
            value.toString().toLowerCase().includes(search.toLowerCase())
        )
      )
    );
  }, [search, skillMappingsData]);

  function actionFormatter(row, cell) {
    return (
      <>
        {/* <i style={{ color: "#3E67B1" }}
         data-toggle="modal"
         data-target="#skillMappingID"
        onClick={()=>{
          setSkillMappingData(cell)
        }}
        className="fa fa-light fa-pen-to-square"></i> */}
        &nbsp;&nbsp;&nbsp;&nbsp;
        <i style={{ color: "#3E67B1" }} className="fa fa-light fa-trash-can" onClick={()=>{
          deleteSkillMapping(cell.skillMappingId).then((res)=>{
            setDataLoading(true);
            getSkillMappings().then((response) => {
              setSkillMappingsData(response.data);
              setDataLoading(false);
            });
          })
        }}></i>
      </>
    );
  }

  const options = {
    sizePerPage: 5,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '50', value: 50 },
    ],
    hideSizePerPage: false,
    hidePageListOnlyOnePage: false,
    alwaysShowAllBtns: true,
    showTotal: true,
    nextPageText: "Next",
    prePageText: "Prev",
  };

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  if (hasAllPermissions || userPermissions.includes('LIST_MASTER_DATA')) {

    return (
      <>
        <Box sx={{ display: "flex" }}>

          <Box component="main"
            style={{ backgroundColor: "#F4F6FB", minHeight: "500px" }}
            sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
          >
            <>
              <div clas="container" style={{ marginLeft: "1%", marginRight: "1%" }} >

                <div className="row col-12">
                  <div className="col-6">
                    <TextField
                      placeholder="Search Skills"
                      size="small"
                      style={{
                        width: "370px",
                        background: "#FCFCFC 0% 0% no-repeat padding-box",
                        boxShadow: " 0px 3px 6px #3E67B133",
                        borderRadius: "4px",
                        opacity: "1",
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                  <div className="col-6"></div>

                </div>
                <br />
                <div className="row col-12">
                  <div className="col-4">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {setSkillFamily(e.target.value)}}
                      style={{
                        height: "50px",
                        width: "100%",
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        border: "1px solid #C7C7C7",
                        borderRadius: "6px",
                        opacity: "1",
                      }}
                      value={skillFamily}
                      placeholder="Enter Skill Family"
                    />
                  </div>
                  <div className="col-4">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {setSkill(e.target.value)}}
                      style={{
                        height: "50px",
                        width: "100%",
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        border: "1px solid #C7C7C7",
                        borderRadius: "6px",
                        opacity: "1",
                      }}
                      value={skill}
                      placeholder="Enter Skill"
                    />
                  </div>
                  <div className="col-4">
                  <button
                      className="btn btn-primary"
                      onClick={
                        ()=>{
                          createSkillMapping({skillFamily, skill}).then((res)=>{
                            setDataLoading(true);
                            setSkillFamily("");
                            setSkill("");
                            getSkillMappings().then((response) => {
                              setSkillMappingsData(response.data);
                              setDataLoading(false);
                            });
                          }).catch((err)=>{})
                          
                        }
                      }
                      style={{
                        height: "50px",
                        background: "#3E67B1 0% 0% no-repeat padding-box",
                        borderRadius: "4px",
                        opacity: "1",
                      }}
                    >
                      Create Skill Mapping
                    </button>
                    
                  </div>
                </div>
              </div>
            </>
            <br />
            <>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <BootstrapTable
                      className="table"
                      hover
                      bootstrap4
                      keyField="id"
                      data={filteredData}
                      columns={skillMappingColumns}
                      pagination={paginationFactory(options)}
                      filter={filterFactory()}
                      defaultSorted={defaultSorted}
                      noDataIndication={
                        dataLoading ? 
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                          <CircularProgress />
                        </Box> 
                        : 
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                          <span>No Data Found</span>
                        </Box>
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          </Box>
        </Box>
        
      </>
    );
  
  } else {
    return (
      <>
        <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
          <div className="card">
            <div className="card-body text-center">
              <h2 className="card-title">
                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                Access Denied
              </h2>
              <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}

