import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Grid } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import "./Authentication/LoginPage.css";

const breadCrumMappings = {
  'admin': { name: 'Admin', path: '' },
  'user': { name: 'User', path: '#' },
  'panel': { name: 'Panel', path: '' },
  'customer': { name: 'Customer', path: '' },
  'dashboard': { name: 'Dashboard', path: 'dashboard' },
  'paneldashboard': { name: 'Panel Dashboard', path: 'panelDashboard' },
  'customerdashboard': { name: 'Customer Dashboard', path: 'customerDashboard' },
  'vendordashboard': { name: 'Vendor Dashboard', path: 'vendorDashboard' },

  'authentication': { name: 'Authentication', path: 'authentication' },
  'groups': { name: 'Groups', path: 'groups' },
  'roles': { name: 'Roles', path: 'roles' },
  'permissions': { name: 'Permissions', path: 'permissions' },
  'users': { name: 'Users', path: 'users' },
  'panels': { name: 'Panels', path: 'panels' },
  'vendors': { name: 'Vendors', path: 'vendors' },
  'customers': { name: 'Customers', path: 'customers' },
  'customeradmins': { name: 'Customer Admins', path: 'customerAdmins' },

  'deliveryunits': { name: 'Delivery Units', path: 'deliveryUnits' },
  'adddeliveryunit': { name: 'Add Delivery Unit', path: 'addDeliveryUnit' },
  'editdeliveryunit': { name: 'Edit Delivery Unit', path: 'editDeliveryUnit' },

  'jobs': { name: 'Job Descriptions', path: 'jobs/all' },
  'addjob': { name: 'Add Job Description', path: 'addJob' },
  'editjob': { name: 'Edit Job Description', path: 'editJob' },
  'viewjob': { name: 'View Job Description', path: 'viewJob' },

  'candidates': { name: 'Candidates', path: 'candidates/all' },
  'claimedcandidates': { name: 'Claimed Candidates', path: 'claimedCandidates' },
  'addcandidate': { name: 'Add Candidate', path: 'addCandidate' },
  'editcandidate': { name: 'Edit Candidate', path: 'editCandidate' },
  'viewcandidate': { name: 'View Candidate', path: 'viewCandidate' },
  'viewcandidateforpanel': { name: 'View Candidate', path: 'viewCandidateForPanel' },
  'approvepanelclaims': { name: 'Approve Panel Claims', path: 'approvePanelClaims' },
  'downloadpdf': { name: 'Download Feedback', path: 'downloadPDF' },

  'interviews': { name: 'Interviews', path: 'interviews' },
  'closedinterviews': { name: 'Closed Interviews', path: 'closedInterviews' },
  'interviewschedules': { name: 'Scheduled Interviews', path: 'interviewSchedules/all' },
  'editinterviewschedule': { name: 'Edit Interview Schedule', path: 'editInterviewSchedule' },
  'viewinterviewschedule': { name: 'View Interview Schedule', path: 'viewInterviewSchedule' },

  'panelsearch': { name: 'Panel Search', path: 'panelSearch' },
  'tickets': { name: 'Tickets', path: 'tickets/all' },
  'updateticket': { name: 'Update Ticket', path: 'updateTicket' },
  'editprofile': { name: 'Edit Profile', path: 'editProfile' },
  'edituser': { name: 'Edit User', path: 'editUser' },

  'customerrequests': { name: 'Customer Requests', path: 'customerRequests' },
  'customertickets': { name: 'Customer Tickets', path: 'customerTickets' },
  'editcustomerticket': { name: 'Edit Customer Requests', path: 'editCustomerTicket' },
  'customerrequestform': { name: 'Create Customer Request', path: 'customerRequestForm' },

  'calendar': { name: 'Calendar', path: 'calendar' },
  'slotavailability': { name: 'Slot Availability', path: 'slotAvailability' },

  'countries': { name: 'Countries', path: 'countries' },
  'states': { name: 'States', path: 'states' },
  'cities': { name: 'Cities', path: 'cities' },
  'universities': { name: 'Universities', path: 'universities' },
  'skills': { name: 'Skills', path: 'skills' },
  'skillfamilies': { name: 'Skill Families', path: 'skillFamilies' },
  'skillmappings': { name: 'Skill Mappings', path: 'skillMappings' },
  'designations': { name: 'Designations', path: 'designations' },
  'profiletypes': { name: 'Profile Types', path: 'profileTypes' },
  'changepassword': { name: 'Change Password', path: 'changePassword' },
  'resetpassword': { name: 'Reset Password', path: 'resetPassword' },
  'forgotpassword': { name: 'Forgot Password', path: 'forgotPassword' },
  'paneloffer': { name: 'Panel Offer', path: 'panelOffer' },
}

const Header = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || {});
  let navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const editprofile = () => {
    navigate(`/user/editProfile/${user.profileType}/${user.userMongoId}`);
    setAnchorEl(null);
  };
  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
  };

  const gotoChangePassword = () => {
    navigate('/user/changePassword')
  }
  const pathArray = location.pathname.toLowerCase().split('/').filter(x => x);

  return (
   <>
      <div className="profile-container">
        <div className="row "style={{marginBottom:"8px"}} >
          <div className="col-sm" >
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
              {
                  pathArray.slice(0,2).map((path, index) => {
                  return <>
                    {/* <li
                      className={`breadcrumb-item breadcrumb-text ${
                        index === 1 ? "" : "active"
                      }`}
                      aria-current="page"
                      key={index}
                    >
                      {index === 1 ? (
                        breadCrumMappings[path].name
                      ) : (
                        <Link to={breadCrumMappings[path].path}>
                          {breadCrumMappings[path].name}
                        </Link>
                      )}
                    </li> */}
                    <li className={`breadcrumb-item breadcrumb-text ${
                        index === 1 ? "" : "active"
                      }`}
                      aria-current="page"
                      key={index}
                    >
                      {
                        breadCrumMappings[path] ?
                        <Link to={breadCrumMappings[path].path}>
                          {breadCrumMappings[path].name}
                        </Link> : null
                      }

                    </li>
                  </>
                })
              }
            </ol>
            <h5
              style={{
                font: "Open Sans",
                color: "#3A3A3A",
                letterSpacing: "0px",
                opacity: "1",
              }}
            >
              {
                pathArray.length === 1 ?
                  (breadCrumMappings[pathArray[0]] ? breadCrumMappings[pathArray[0]].name : '' )
                  :
                  (breadCrumMappings[pathArray[1]] ? breadCrumMappings[pathArray[1]].name : '' )
              }
            </h5>
          </nav>
        </div>
          <div className="col-sm d-flex justify-content-end">
            {/* <button
                type="button"
                className="btn "
                style={{
                  background: "#FCFCFC 0% 0% no-repeat padding-box",
                  boxShadow: "0px 3px 6px #3E67B133",
                  borderRadius: "4px",
                  opacity: "1",
                  marginRight: "10px",
                  marginLeft: "10px",
                  height: "46px",
                  width: "46px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
            >
              <img
                  src="/Images/helpicon.svg"
                  alt="help"
                  height="15px"
                  width="15px"
              />
            </button>
            <button
                type="button"
                className="btn "
                style={{
                  background: "#FCFCFC 0% 0% no-repeat padding-box",
                  boxShadow: "0px 3px 6px #3E67B133",
                  borderRadius: "4px",
                  marginRight: "10px",
                  marginLeft: "10px",
                  height: "46px",
                  width: "46px",
                  opacity: "1",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
            >
              <i className="fa-regular fa-bell p-auto"></i>
            </button> */}
            <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                style={{
                  background: "#FCFCFC 0% 0% no-repeat padding-box",
                  boxShadow: "0px 3px 6px #3E67B133",
                  borderRadius: "4px",
                  opacity: "1",
                  width: "180px",
                  marginLeft: "10px",
                  height: "46px",
                }}
                onClick={handleClick}
            >
              <i className="fa fa-light fa-user"></i>&nbsp;&nbsp;{" "}
              {user.userName}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
            >
              <MenuItem onClick={editprofile}>
                <i
                    className="fa fa-light fa-pen"
                    style={{
                      color: "#3E67B1",
                      opacity: "1",
                      paddingRight: "10px",
                    }}
                ></i>{" "}
                Edit Profile
              </MenuItem>
              <MenuItem onClick={gotoChangePassword}>
                <i
                    className="fa fa-light fa-key"
                    style={{
                      color: "#3E67B1",
                      opacity: "1",
                      paddingRight: "10px",
                    }}
                ></i>{" "}
                Change Password
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <i
                    className="fa fa-light fa-lock"
                    style={{
                      color: "#3E67B1",
                      opacity: "1",
                      paddingRight: "10px",
                    }}
                ></i>{" "}
                Logout
              </MenuItem>
            </Menu>
          </div>
      </div>
    </div>
  </>);
};

export default Header;
