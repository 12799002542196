import React, { useRef, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createCustomerRequest
} from "../../service/api";

import { toast } from "react-toastify";
import { Grid } from "@mui/material";

const CustomerRequestForm = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');
  
  function validateUserData(data){
    let result = {isValid:true,}
    let keyLabels = {
      'vendorCompanyName': "Vendor Company Name",
      'primaryMobile': "Mobile Number",
      'emailId': "Email ID",
      'name': "Name",
      'profileType': "User Type",
    };
    
    let required_fields = [
      // 'primaryMobile',
      'emailId',
      'name',
      'profileType',
    ];

    if (data.profileType == 'VENDOR') {
      if (!data['vendorCompanyName']) {
        result =  {isValid:false,mgs:`Field Missing ${keyLabels['vendorCompanyName']}`}
      }
    }

    required_fields.forEach(key=>{
      if(!data[key]){
        result =  {isValid:false,mgs:`Field Missing ${keyLabels[key]}`}
      }
    })

    
    return result;
  }
  const formRef = useRef(null);

  const [userData, setUserData] = useState({
    profileType: "",
    name: "",
    emailId: "",
    primaryMobile: "",
    companyName: user.companyName,
    vendorCompanyName: ""
  });

  const navigate = useNavigate();

  useEffect(() => {
  }, []);

  if (hasAllPermissions || userPermissions.includes('CREATE_CUSTOMER_REQUEST')) {

    return (
      <>
        <Box sx={{ display: "flex" }}>
          <Box
            component="main"
            style={{ backgroundColor: "#F4F6FB" }}
            sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
          >
            <form ref={formRef}>
              <Grid container spacing={2}>
              <Grid item xs={12} sm={4} 
                  // style={{
                  //   display: userData.profileType === 'CUSTOMER_ADMIN' ? 'inline': 'none'
                  // }}
                >
                  <label htmlFor="companyName">Company Name *</label>
                  <input
                    type="text"
                    disabled={true}
                    className="form-control"
                    placeholder="Enter Company"
                    name="companyName"
                    defaultValue={userData.companyName}
                    onChange={(event) =>
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        companyName: event.target.value,
                      }))
                    }
                    style={{
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: "1px solid #C7C7C7",
                      borderRadius: "6px",
                      opacity: "1",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label htmlFor="profileType">User Type *</label>
                  <select
                    type="text"
                    disabled={false}
                    className="form-control"
                    value={userData.profileType}
                    name="profileType"
                    onChange={(event) =>
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        profileType: event.target.value,
                      }))
                    }
                    style={{
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: "1px solid #C7C7C7",
                      borderRadius: "6px",
                      opacity: "1",
                    }}
                  >
                    <option value="">Select User Type *</option>
                    <option value="CUSTOMER_ADMIN">CUSTOMER_ADMIN</option>
                    <option value="VENDOR">VENDOR</option>
                  </select>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label htmlFor="name">Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Name"
                    name="name"
                    defaultValue={userData.name}
                    onChange={(event) =>
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        name: event.target.value,
                      }))
                    }
                    style={{
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: "1px solid #C7C7C7",
                      borderRadius: "6px",
                      opacity: "1",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label htmlFor="emailId">Email ID *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Email ID"
                    name="emailId"
                    defaultValue={userData.emailId}
                    onChange={(event) =>
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        emailId: event.target.value,
                      }))
                    }
                    style={{
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: "1px solid #C7C7C7",
                      borderRadius: "6px",
                      opacity: "1",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label htmlFor="primaryMobile">Mobile Number</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Primary Mobile"
                    name="primaryMobile"
                    defaultValue={userData.primaryMobile}
                    onChange={(event) =>
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        primaryMobile: event.target.value,
                      }))
                    }
                    style={{
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: "1px solid #C7C7C7",
                      borderRadius: "6px",
                      opacity: "1",
                    }}
                  />
                </Grid>
                
                <Grid item xs={12} sm={4}
                  style={{
                    display: userData.profileType === 'VENDOR' ? 'inline': 'none'
                  }}
                >
                  <label htmlFor="primaryMobile">Vendor Company *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Vendor Compnay"
                    name="vendorCompanyName"
                    defaultValue={userData.vendorCompanyName}
                    onChange={(event) =>
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        vendorCompanyName: event.target.value,
                      }))
                    }
                    style={{
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: "1px solid #C7C7C7",
                      borderRadius: "6px",
                      opacity: "1",
                    }}
                  />
                </Grid>
              </Grid>
              <br />
              <div className="row justify-content-center">
                <button
                  className="btn btn-outline-primary me-2"
                  style={{
                    border: "1px solid #3E67B1",
                    borderRadius: " 4px",
                    opacity: "1",
                    width: "96px",
                    height: "50px",
                  }}
                  onClick={() => { navigate('/customer/customerRequests'); }}
                >
                  BACK
                </button>
                <button
                  onClick={() => {
                    const fd = new FormData(formRef.current);
                    const formDataJson = {};
                    Array.from(fd.entries()).reduce((a, b) => {
                      a[b[0]] = b[1];
                      return a;
                    }, formDataJson);
                    let finalObj = {...userData, ...formDataJson}
                    const validation = validateUserData(finalObj);
                    if(!validation.isValid){
                      toast.error(validation.mgs)
                      return;
                    }
                    createCustomerRequest(finalObj).then((res) => {
                      toast.success('Customer Request created Successfully.!');
                      navigate('/customer/customerRequests');
                    }).catch(err=>{
                      if (err?.response?.data?.error.includes('duplicate key')) {
                        toast.error(`Unable to create customer request due to duplicate Email ID: ${finalObj.emailId}`)
                      } else {
                        toast.error(err.response.data.message);
                      }
                    });
                  }}
                  type="button"
                  className="btn btn-primary"
                  style={{
                    border: "1px solid #3E67B1",
                    borderRadius: " 4px",
                    opacity: "1",
                    width: "150px",
                    height: "50px",
                    display: (hasAllPermissions || userPermissions.includes('CREATE_CUSTOMER_REQUEST')) ? 'inline' : 'none'
                  }}
                >
                  Submit Request
                </button>
              </div>
            </form>
          </Box>
        </Box>
      </>
    );
  
  } else {
    return (
      <>
        <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
          <div className="card">
            <div className="card-body text-center">
              <h2 className="card-title">
                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                Access Denied
              </h2>
              <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
};

export default CustomerRequestForm;
