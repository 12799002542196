import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  getCustomerSchedules, getAssignedUsers
} from "../../service/api";
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useParams } from "react-router-dom";
import FilterModal from "./FilterModel";
import "../Candidate/Candidate.css";
import "../../CSS/dashboard.css";
import { toast } from "react-toastify";

function filterDataByDateRange(data, startDate, endDate) {
  const filteredData = data.filter((item) => {
      const itemDate = new Date(item.createdAt).toISOString().split('T')[0];
      return itemDate >= startDate && itemDate <= endDate;
  });
  return filteredData;
}

export default function CompletedCustomerSchedules() {
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');

  const { filterOptions } = useParams();
  const [searchStatus, setSearchStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [schedulesData, setSchedulesData] = useState([]);
  const [filteredSchedulesData, setFilteredSchedules] = useState([]);
  const [dataLoading, setDataLoading] = useState();
  const [filters, setFilters] = useState({});
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpenFilterModal = () => {
    setFilterModalOpen(true);
  };

  const handleCloseFilterModal = () => {
    setFilterModalOpen(false);
  };

  const handleApplyFilters = (newFilters) => {
    if (['all'].includes(newFilters.status) ) {
      newFilters.status = '';
    }
    const updatedFilters = Object.fromEntries(Object.entries(newFilters).filter(([key, value]) => value !== ""));
    setFilters(updatedFilters);
  };

  const populateSchedules = () => {
    setDataLoading(true);
    getAssignedUsers().then((res) => {
      const userDict = res?.data.reduce((acc, { userId, firstName, lastName }) => {
        acc[userId] = `${firstName} ${lastName}`;
        return acc;
      }, {});

      getCustomerSchedules().then((response) => {
        let completed = response?.data?.filter(e => !['SCHEDULED'].includes(e.status))
        if (startDate != '' && endDate != '') {
          completed = filterDataByDateRange(completed, startDate, endDate);
        }
        if (!['all'].includes(searchStatus)) {
          completed = completed.filter(e => [searchStatus].includes(e.status));
        }
        completed.forEach((e) => {
          e.slot = `${e.scheduledSlot?.slotDate}, ${e.scheduledSlot?.slotTime}`;
          e.assigneeFullName = userDict[e.coordinatorId];
        });
        setSchedulesData(completed);
        setDataLoading(false);
      }).catch((err) => {
        console.error(err);
      });
    });
  }

  useEffect(() => {
    if (filterOptions) {
      let [status, startDate, endDate] = filterOptions.split('|')
      console.log(status, startDate, endDate)
      setSearchStatus(status);
      setStartDate(startDate || '');
      setEndDate(endDate || '');
    }
    populateSchedules();
    handleApplyFilters({'status': searchStatus})
  }, [searchStatus]);

  useEffect(() => {
    const filterKeys = Object.keys(filters);
    if (filterKeys.length > 0) {
      const filteredData = schedulesData.filter(job =>
        filterKeys.every(key =>
          String(job[key]).toLowerCase().includes(String(filters[key]).toLowerCase())
        )
      );
      setFilteredSchedules(filteredData);
    } else {
      setFilteredSchedules(schedulesData);
    }
  }, [filters, schedulesData]);

  const scheduleColumns = [
    {
      dataField: 'customerId',
      text: 'Customer ID',
      sort: true,
    },
    {
      dataField: 'customerCompany',
      text: 'Customer Name',
      sort: true,
    },
    {
      dataField: 'jobId',
      text: 'Job ID',
      sort: true,
    },
    {
      dataField: 'jobName',
      text: 'Job Name',
      sort: true,
    },
    // {
    //   dataField: 'scheduleId',
    //   text: 'Schedule ID',
    //   sort: true,
    // },
    // {
    //   dataField: 'panelEmailId',
    //   text: 'Panel Email ID',
    //   sort: true,
    // },
    // {
    //   dataField: 'candidateEmailId',
    //   text: 'Candidate Email ID',
    //   sort: true,
    // },
    // {
    //   dataField: 'coordinatorEmailId',
    //   text: 'Coordinator Email ID',
    //   sort: true,
    // },
    {
      dataField: 'panelName',
      text: 'Panel Name',
      sort: true,
    },
    {
      dataField: 'candidateName',
      text: 'Candidate Name',
      sort: true,
    },
    {
      dataField: 'assigneeFullName',
      text: 'Coordinator Name',
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
    },
    {
      dataField: 'slot',
      text: 'Slot',
      sort: true,
      style: { minWidth: '180px'},
      // formatter: (cell, row) => (
      //   <>
      //     <span>  {row.scheduledSlot?.slotDate},  {row.scheduledSlot?.slotTime} </span>
      //   </>
      // )
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: (cell, row) => (
        <>
          <i
              title="View Schedule"
            className="fa fa-light fa-eye"
            onClick={() => {
              if ((hasAllPermissions || userPermissions.includes('VIEW_SCHEDULED_INTERVIEW'))) {
                navigate(`/user/viewInterviewSchedule/${row.scheduleId}/${row.customerId}`);
              }
            }}
            style={{
              paddingLeft: "10px",
              cursor: "pointer",
              opacity: !(hasAllPermissions || userPermissions.includes('VIEW_SCHEDULED_INTERVIEW')) ? 0.2 : 1,
              pointerEvents: !(hasAllPermissions || userPermissions.includes('VIEW_SCHEDULED_INTERVIEW')) ? 'none' : 'auto',
            }}
          ></i>
          {/* <i
            className="fa fa-light fa-pen"
            onClick={() => {
              if ((hasAllPermissions || userPermissions.includes('EDIT_SCHEDULED_INTERVIEW'))) {
                navigate(`/user/editInterviewSchedule/${row.scheduleId}/${row.customerId}`);
              }
            }}
            style={{
              paddingLeft: "10px",
              cursor: "pointer",
              opacity: !(hasAllPermissions || userPermissions.includes('EDIT_SCHEDULED_INTERVIEW')) ? 0.2 : 1,
              pointerEvents: !(hasAllPermissions || userPermissions.includes('EDIT_SCHEDULED_INTERVIEW')) ? 'none' : 'auto',
            }}
          ></i> */}
        </>
      ),
      style: () => {
        return {
          font: "Muli",
          minWidth: "120px",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
    }
  ];

  const options = {
    sizePerPage: 10,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '50', value: 50 },
    ],
    hideSizePerPage: false,
    hidePageListOnlyOnePage: false,
    alwaysShowAllBtns: true,
    showTotal: true,
    nextPageText: "Next",
    prePageText: "Prev",
  };

  const filterDict = {
    'customerCompany': 'Customer Company',
    'scheduleId': 'Schedule ID',
    'slot': 'Slot',
    'status': 'Status',
    'panelEmailId': 'Panel Email ID',
    'candidateEmailId': 'Candidate Email ID',
    'coordinatorEmailId': 'Coordinator Email ID',
    'panelName': 'Panel Name',
    'candidateName': 'Candidate Name',
    'coordinatorName': 'Coordinator Name',
  }

  if (hasAllPermissions || userPermissions.includes('LIST_CUSTOMER_SCHEDULES')) {

    return (
        <div className="container-page">
          <div className="row">
            <div className="col">
            </div>
            <div className="col" style={{textAlign: "right",marginBottom:"20px"}}>
              <button
                  className="filters"
                  variant="contained"
                  color="primary"
                  onClick={handleOpenFilterModal}
              >
                    <span className="filter-icon">
                      <img src="/Images/Filter.svg"/>
                    </span>
                &nbsp;&nbsp;
                <span className="filter-text">Filters</span>
              </button>
              {/* <button
                    onClick={addNewCandidate}
                    className="create-candidate">
                    Create Candidate Description
                  </button> */}
            </div>
          </div>
          <div className="main-content">
            <div className="applied-filters" style={{display: Object.keys(filters).length > 0 ? 'block' : 'none'}}>
              <div className="applied-filters-text">
                <h4>Applied Filters ({Object.keys(filters).length})</h4>
              </div>
              <br></br>
              <div className="applied-filters-list ">
                {Object.keys(filters).map((d, i) => {
                  return (filters[d] != '') ? <div>
                    <span className="applied-filter">
                      <label key={i}>{filterDict[d]}:- {filters[d]} &nbsp;{"  "}</label>
                      <i
                          className="fa fa-thin fa-circle-xmark"
                          style={{color: "#3c77dd"}}
                          onClick={() => {
                            console.log('removeFilter called with:', d, filters);
                            const newFilters = {...filters};
                            delete newFilters[d];
                            console.log("updatedFilters::", newFilters)
                            setFilters(newFilters);
                            handleApplyFilters(newFilters);
                          }}
                      ></i>
                    </span>
                  </div> : <></>
                })}
              </div>
              <br/>
            </div>

            <Box className="custom-table-container shadow-radius"
            >
              <BootstrapTable
                  hover
                  bootstrap4
                  keyField="scheduleId"
                  data={filteredSchedulesData}
                  columns={scheduleColumns}
                  pagination={paginationFactory(options)}
                  noDataIndication={
                    dataLoading ?
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          width: '100%'
                        }}>
                          <CircularProgress/>
                        </Box>
                        :
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          width: '100%'
                        }}>
                          <span>No Data Found</span>
                        </Box>
                  }
              />

              {
                filterModalOpen ? <FilterModal
                    filters={filters}
                    open={filterModalOpen}
                    handleClose={handleCloseFilterModal}
                    handleApply={handleApplyFilters}
                /> : <></>
              }
            </Box>
          </div>
        </div>
    );

  } else {
    return (
        <>
          <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
            <div className="card">
              <div className="card-body text-center">
                <h2 className="card-title">
                  <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                  Access Denied
                </h2>
                <p className="card-text">You do not have the required permissions to access this page.</p>
                <p className="card-text">Please contact your administrator for assistance.</p>
              </div>
            </div>
          </div>
        </>
    )
  }
}

