import React, { useRef, useState, useEffect } from "react";
import { useParams, useLocation,useNavigate  } from "react-router-dom";
import { getUserById,getCountries,getSkillFamilies,getDomains, getSkillsBySkillFamily,getProfileTypes,getStatesByCountryCode,getCitiesByStateCode,getRoles,updateUser } from "../service/api";
import { Grid, TextField, Checkbox } from "@mui/material";
import { toast } from "react-toastify";
import { Box, Typography } from '@mui/material';
import CreatableSelect from 'react-select/creatable';
import "./EditProfile.css";
// const countryStateCityRef = {};

const EditProfile = ({navigation}) => {
    function validateUserData(data){
        let result = {isValid:true,}
        const keys = ['country', 'email', 'firstName',  'profileType', 'roleId'];
        keys.forEach(key=>{
            if(!data[key]){
                result =  {isValid:false,mgs:`Field Missing ${key}`}
            }
        })
        return result;
    }
    const { userMongoId, profileType } = useParams();
    const user = JSON.parse(localStorage.getItem('user'));
    const userPermissions = user.permissions.map(e => e.permissionName);
    const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');

    const formRef = useRef(null);
    const custFormRef = useRef(null);
    const panelFormRef = useRef(null);
    const [roles, setRoles] = useState([]);
    const [countries , setCountries] = useState([]);
    const [skillFamilies , setSkillFamilies] = useState([]);
    const [skills, setSkills] = useState([]);
    const [profileTypes , setProfileTypes] = useState([]);
    const [domains, setDomains] = useState([]);


    // const [states, setStates] = useState([]);
    // const [cities, setCities] = useState([]);

    const [userData, setUserData] = useState({});
    const [rateCardDetails, setRateCardDetails] = useState({rates:[],ratesUS:[], ratesInt:[], ratesExt:[]});

    const navigate = useNavigate();


    // const statesByCountry = countries.reduce((acc, country) => {
    //   acc[country.name] = country.states;
    //   return acc;
    // }, {});
    // const citiesByState = {};

    useEffect(() => {
        getCountries().then((res) => {
            setCountries(res.data);
        });

        getSkillFamilies().then((res) => {
            const sfs = res.data?.map(e => ({'label': e.skillFamily, 'value': e.skillFamily}))
            setSkillFamilies(sfs);
        })

        getDomains().then((res) => {
            setDomains(res.data);
        })

        getProfileTypes().then((res) => {
            setProfileTypes(res.data);
        })

        getUserById(userMongoId, profileType).then((res) => {
            setUserData(res.data);
            const {ratesInIndia, ratesInUS, ratesExternal, ratesInternal} = res.data;
            setRateCardDetails({
                rates: ratesInIndia || [],
                ratesUS: ratesInUS || [],
                ratesExt: ratesExternal || [],
                ratesInt: ratesInternal || []
            })
        });
        getRoles().then((res) => {
            setRoles(res.data);
        });
    }, [userMongoId]);

    const updateSkillsData = async (selectedSkills) => {
        let finalSkills = [];
        for (let i=0; i< selectedSkills.length; i++) {
            if (selectedSkills[i] != '') {
                getSkillsBySkillFamily(selectedSkills[i]).then((data) => {
                    const skills = data?.data.map(e => ({'label': e.skill, 'value': e.skill}));
                    finalSkills = finalSkills.concat(skills)
                    setSkills(finalSkills)
                });
            } else {
                setSkills([])
            }
        }
    }

    const handleCountryChange = (event) => {
        const selectedCountry = event.target.value;
        setUserData((prevUserData) => ({
            ...prevUserData,
            country: selectedCountry,
            state: "",
            city: "",
        }));
        // getStatesByCountryCode(selectedCountry).then((res) => {
        //   setStates(res.data);
        // });
    };

    // const handleStateChange = (event) => {
    //   const selectedState = event.target.value;
    //   setUserData((prevUserData) => ({
    //     ...prevUserData,
    //     state: selectedState,
    //     city: "",
    //   }));
    //   getCitiesByStateCode(userData.country,selectedState).then((res) => {
    //     setCities(res.data);
    //   } );
    // };

    // const handleCityChange = (event) => {
    //   const selectedCity = event.target.value;
    //   setUserData((prevUserData) => ({
    //     ...prevUserData,
    //     city: selectedCity,
    //   }));
    // };

    const handleFormSubmit = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <Box sx={{ display: "flex", flexDirection: 'column' }}>
                <div style={{
                    backgroundColor:"#FFFFFF",
                    padding: "20px",
                    boxShadow: "0px 3px 6px #00000029",
                    borderRadius: "8px",
                    display: !["PANELIST","CUSTOMER","CUSTOMER_ADMIN","VENDOR"].includes(profileType) ? "flex":"none"}}>
                    <Box
                        component="main"
                    >
                        <form ref={formRef} onSubmit={handleFormSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="profileType">Profile Type</label>
                                    <select
                                        type="text"
                                        disabled="true"
                                        className="form-control"
                                        value={userData.profileType}
                                        name="profileType"
                                        onChange={(event) =>
                                            setUserData((prevUserData) => ({
                                                ...prevUserData,
                                                profileType: event.target.value,
                                            }))
                                        }
                                        style={{
                                            height: "50px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    >
                                        <option value="">Select Profile Type</option>
                                        {
                                            profileTypes.map((each) => {
                                                return (<option value={each.profileType}>{each.profileType}</option>)
                                            })
                                        }

                                    </select>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="firstName">First Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter First Name"
                                        name="firstName"
                                        defaultValue={userData.firstName}
                                        style={{
                                            height: "50px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="lastName">Last Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Last Name"
                                        name="lastName"
                                        defaultValue={userData.lastName}
                                        style={{
                                            height: "50px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="role">Role</label>
                                    {
                                        (userData.profileType != 'SUPER') ?
                                            <select
                                                type="text"
                                                disabled="true"
                                                className="form-control"
                                                placeholder="Enter Role"
                                                name="role"
                                                value={userData.roleId}
                                                onChange={(event) =>{
                                                    setUserData((prevUserData) => ({
                                                        ...prevUserData,
                                                        roleId: event.target.value,
                                                    }))
                                                }}
                                                style={{
                                                    height: "50px",
                                                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                                                    border: "1px solid #C7C7C7",
                                                    borderRadius: "6px",
                                                    opacity: "1",
                                                }}
                                            >
                                                <option value="">Select Role Type</option>
                                                {
                                                    roles.map((role) => {
                                                        return (<option value={role.roleId}>{role.roleName}</option>)
                                                    })
                                                }

                                            </select> :
                                            <input
                                                type="text"
                                                disabled="true"
                                                className="form-control"
                                                name="roleId"
                                                defaultValue="SUPER_ROLE"
                                                style={{
                                                    height: "50px",
                                                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                                                    border: "1px solid #C7C7C7",
                                                    borderRadius: "6px",
                                                    opacity: "1",
                                                }}
                                            />

                                    }

                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="emailId">Email ID</label>
                                    <input
                                        type="text"
                                        disabled="true"
                                        className="form-control"
                                        placeholder="Enter Email"
                                        name="emailId"
                                        defaultValue={userData.emailId}
                                        style={{
                                            height: "50px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="primaryMobile">Phone Number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Phone Number"
                                        name="primaryMobile"
                                        defaultValue={userData.primaryMobile}
                                        style={{
                                            height: "50px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="country">Country</label>
                                    <select
                                        type="text"
                                        className="form-control"
                                        value={userData.country}
                                        name="country"
                                        onChange={handleCountryChange}
                                        style={{
                                            height: "50px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    >
                                        <option value="">Select Country</option>
                                        {countries.map((country) => (
                                            <option key={country.countryCode} value={country.countryCode}>
                                                {country.countryName}
                                            </option>
                                        ))}
                                    </select>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="address">
                                        Address
                                    </label>
                                    <textarea
                                        rows="3"
                                        className="form-control"
                                        placeholder="Enter Address"
                                        name="address"
                                        defaultValue={userData.address}
                                        style={{
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <br />

                            <div className="row justify-content-center">
                                <button
                                    className="btn btn-outline-primary me-2"
                                    style={{
                                        border: "1px solid #3E67B1",
                                        borderRadius: " 4px",
                                        opacity: "1",
                                        width: "96px",
                                        height: "50px",
                                    }}
                                    onClick={() => {
                                        navigate(-1)
                                    }}
                                >
                                    BACK
                                </button>
                                <button
                                    onClick={() => {
                                        const fd= new FormData(formRef.current);
                                        const formDataJson ={}
                                        Array.from(fd.entries()).reduce((a,b)=>{
                                            a[b[0]] = b[1];
                                            return a
                                        }, formDataJson)
                                        let updatedObj = {...userData, ...formDataJson}
                                        updateUser(userMongoId, profileType, updatedObj).then((res) => {
                                            toast.success("User Updated Successfully.!");
                                            navigate(-1);
                                        }).catch(err=>{
                                            toast.error("Unable to update User.!");
                                        });
                                    }}
                                    type="submit"
                                    className="btn btn-primary"
                                    style={{
                                        border: "1px solid #3E67B1",
                                        borderRadius: " 4px",
                                        opacity: "1",
                                        width: "150px",
                                        height: "50px",
                                    }}
                                >
                                    UPDATE USER
                                </button>
                            </div>
                        </form>
                    </Box>
                </div>

                <div style={{ display: profileType == "PANELIST" ? "flex":"none"}}>
                    <Box
                        component="main"
                        style={{ backgroundColor: "#F4F6FB" }}
                        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
                    >
                        <form ref={panelFormRef} onSubmit={handleFormSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="profileType">Profile Type</label>
                                    <select
                                        type="text"
                                        disabled="true"
                                        className="form-control"
                                        value={userData.profileType}
                                        name="profileType"
                                        onChange={(event) =>
                                            setUserData((prevUserData) => ({
                                                ...prevUserData,
                                                profileType: event.target.value,
                                            }))
                                        }
                                        style={{
                                            height: "50px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    >
                                        <option value="">Select Profile Type</option>
                                        <option value="ADMIN">ADMIN</option>
                                        <option value="PANELIST">PANELIST</option>
                                        <option value="CUSTOMER">CUSTOMER</option>
                                        <option value="CUSTOMER_ADMIN">CUSTOMER_ADMIN</option>
                                        <option value="VENDOR">VENDOR</option>
                                    </select>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="firstName">First Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter First Name"
                                        name="firstName"
                                        defaultValue={userData.firstName}
                                        style={{
                                            height: "50px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="lastName">Last Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Last Name"
                                        name="lastName"
                                        defaultValue={userData.lastName}
                                        style={{
                                            height: "50px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="role">Role</label>
                                    <select
                                        type="text"
                                        disabled="true"
                                        className="form-control"
                                        placeholder="Enter Role"
                                        name="role"
                                        value={userData.roleId}
                                        onChange={(event) =>{
                                            setUserData((prevUserData) => ({
                                                ...prevUserData,
                                                roleId: event.target.value,
                                            }))
                                        }}
                                        style={{
                                            height: "50px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    >
                                        <option value="">Select Role Type</option>
                                        {
                                            roles.map((role) => {
                                                return (<option value={role.roleId}>{role.roleName}</option>)
                                            })
                                        }

                                    </select>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="emailId">Email ID</label>
                                    <input
                                        type="text"
                                        disabled={true}
                                        className="form-control"
                                        placeholder="Enter Email"
                                        name="emailId"
                                        defaultValue={userData.emailId}
                                        style={{
                                            height: "50px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="experience">
                                        Experience
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Experience"
                                        name="experience"
                                        defaultValue={userData.experience}
                                        style={{
                                            height: "50px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="skillFamily">
                                        Skill Family
                                    </label>
                                    <CreatableSelect
                                        isMulti
                                        value={userData?.skillFamily != '' ? userData?.skillFamily?.split(',').map(sf => ({ value: sf, label: sf })) : null}

                                        onChange={(selectedOptions) => {
                                            if (!Array.isArray(selectedOptions)) {
                                                return;
                                            }
                                            const selectedSkills = selectedOptions.map((option) => option.value);
                                            console.log("....", selectedSkills)
                                            setUserData((prevUserData) => ({
                                                ...prevUserData,
                                                skillFamily: selectedSkills.join(','),
                                            }))
                                            updateSkillsData(selectedSkills);
                                        }}
                                        onCreateOption={(inputValue) => {
                                            setUserData((prevUserData) => ({
                                                ...prevUserData,
                                                skillFamily: prevUserData.skillFamily
                                                    ? prevUserData.skillFamily + ',' + inputValue
                                                    : inputValue,
                                            }));
                                        }}
                                        options={skillFamilies}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="primarySkills">
                                        Primary Skills
                                    </label>
                                    <CreatableSelect
                                        isMulti
                                        value={userData?.primarySkills != '' ? userData?.primarySkills?.split(',').map(skill => ({ value: skill, label: skill })) : null}

                                        onChange={(selectedOptions) => {
                                            if (!Array.isArray(selectedOptions)) {
                                                return;
                                            }
                                            console.log("....", selectedOptions)
                                            const selectedSkills = selectedOptions.map((option) => option.value);
                                            setUserData((prevUserData) => ({
                                                ...prevUserData,
                                                primarySkills: selectedSkills.join(','),
                                            }))
                                        }}
                                        onCreateOption={(inputValue) => {
                                            setUserData((prevUserData) => ({
                                                ...prevUserData,
                                                primarySkills: prevUserData.primarySkills
                                                    ? prevUserData.primarySkills + ',' + inputValue
                                                    : inputValue,
                                            }));
                                        }}
                                        options={skills}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="secondarySkills">
                                        Secondary Skills
                                    </label>
                                    <CreatableSelect
                                        isMulti
                                        value={userData?.secondarySkills != '' ? userData?.secondarySkills?.split(',').map(skill => ({ value: skill, label: skill })) : null}

                                        onChange={(selectedOptions) => {
                                            if (!Array.isArray(selectedOptions)) {
                                                return;
                                            }
                                            const selectedSkills = selectedOptions.map((option) => option.value);
                                            setUserData((prevUserData) => ({
                                                ...prevUserData,
                                                secondarySkills: selectedSkills.join(','),
                                            }))
                                        }}
                                        onCreateOption={(inputValue) => {
                                            setUserData((prevUserData) => ({
                                                ...prevUserData,
                                                secondarySkills: prevUserData.secondarySkills
                                                    ? prevUserData.secondarySkills + ',' + inputValue
                                                    : inputValue,
                                            }));
                                        }}
                                        options={skills}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <label>Domain/Vertical </label>
                                    <CreatableSelect
                                        isMulti
                                        value={userData?.domain != '' ? userData?.domain?.split(',').map(dom => ({ value: dom, label: dom })) : null} 
                                        
                                        onChange={(selectedOptions) => {
                                            if (!Array.isArray(selectedOptions)) {
                                            return;
                                            }
                                            const selectedDomains = selectedOptions.map((option) => option.value);
                                            console.log("....", selectedDomains)
                                            setUserData((prevUserData) => ({
                                            ...prevUserData,
                                            domain: selectedDomains.join(','),
                                            }))
                                            updateSkillsData(selectedDomains);
                                        }}
                                        onCreateOption={(inputValue) => {
                                            setUserData((prevUserData) => ({
                                            ...prevUserData,
                                            domain: prevUserData.domain 
                                            ? prevUserData.domain + ',' + inputValue 
                                            : inputValue,
                                            }));
                                        }}
                                        options={domains}
                                        />
                                </Grid>



                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="linkedinProfile">
                                        Linkedin Profile
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Linkedin Profile Link"
                                        name="linkedinProfile"
                                        defaultValue={userData.linkedinProfile}
                                        style={{
                                            height: "50px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="country">Country</label>
                                    <select
                                        type="text"
                                        className="form-control"
                                        value={userData.country}
                                        name="country"
                                        onChange={handleCountryChange}
                                        style={{
                                            height: "50px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    >
                                        <option value="">Select Country</option>
                                        {countries.map((country) => (
                                            <option key={country.countryCode} value={country.countryCode}>
                                                {country.countryName}
                                            </option>
                                        ))}
                                    </select>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="primaryMobile">Phone Number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Phone Number"
                                        name="primaryMobile"
                                        defaultValue={userData.primaryMobile}
                                        style={{
                                            height: "50px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} display={userData.partnerEmail != '' ? 'flex': 'none'}>
                                    <label htmlFor="partnerEmail">
                                        Partner Email
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="partnerEmail"
                                        defaultValue={userData.partnerEmail}
                                        disabled={true}
                                        style={{
                                            height: "50px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="address">
                                        Address
                                    </label>
                                    <textarea
                                        rows="3"
                                        className="form-control"
                                        placeholder="Enter Address"
                                        name="address"
                                        defaultValue={userData.address}
                                        style={{
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <br />

                            <div className="row justify-content-center">
                                <button
                                    className="btn btn-outline-primary me-2"
                                    style={{
                                        border: "1px solid #3E67B1",
                                        borderRadius: " 4px",
                                        opacity: "1",
                                        width: "96px",
                                        height: "50px",
                                    }}
                                    onClick={() => {
                                        navigate(-1);
                                    }}
                                >
                                    BACK
                                </button>
                                <button
                                    onClick={() => {
                                        const fd = new FormData(panelFormRef.current);
                                        const formDataJson ={}
                                        Array.from(fd.entries()).reduce((a,b)=>{
                                            a[b[0]] = b[1];
                                            return a
                                        }, formDataJson)
                                        let updatedObj = {...userData, ...formDataJson}
                                        updateUser(userMongoId, profileType, updatedObj).then((res) => {
                                            toast.success("User Updated Successfully.!");
                                            navigate(-1);
                                        }).catch(err=>{
                                            toast.error("Unable to update User.!");
                                        });
                                    }}
                                    type="submit"
                                    className="btn btn-primary"
                                    style={{
                                        border: "1px solid #3E67B1",
                                        borderRadius: " 4px",
                                        opacity: "1",
                                        width: "150px",
                                        height: "50px",
                                    }}
                                >
                                    UPDATE USER
                                </button>
                            </div>

                        </form>
                    </Box>
                </div>

                <div style={{ display: ["CUSTOMER","CUSTOMER_ADMIN","VENDOR"].includes(profileType) ? "flex":"none"}}>
                    <div

                        style={{
                            backgroundColor: "#FFFFFF",
                            padding: "20px",
                            boxShadow: "0px 3px 6px #00000029",
                            borderRadius: "8px",
                            maxWidth: "80%",
                        }}
                    >
                        <form ref={custFormRef} onSubmit={handleFormSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="profileType">Profile Type</label>
                                    <select
                                        type="text"
                                        disabled="true"
                                        className="form-control"
                                        value={userData.profileType}
                                        name="profileType"
                                        onChange={(event) =>
                                            setUserData((prevUserData) => ({
                                                ...prevUserData,
                                                profileType: event.target.value,
                                            }))
                                        }
                                        style={{
                                            height: "50px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    >
                                        <option value="">Select Profile Type</option>
                                        <option value="ADMIN">ADMIN</option>
                                        <option value="PANELIST">PANELIST</option>
                                        <option value="CUSTOMER">CUSTOMER</option>
                                        <option value="CUSTOMER_ADMIN">CUSTOMER_ADMIN</option>
                                        <option value="VENDOR">VENDOR</option>
                                    </select>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="customerId">Customer ID</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Customer ID"
                                        name="customerId"
                                        defaultValue={userData.customerId || userData.userId}
                                        disabled={true}
                                        style={{
                                            height: "50px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}
                                    style={{
                                        display: userData.profileType === 'CUSTOMER_ADMIN' ? 'inline': 'none'
                                    }}
                                >
                                    <label htmlFor="customerId">Customer Admin ID</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Customer Admin ID"
                                        name="userId"
                                        defaultValue={userData.userId}
                                        disabled={true}
                                        style={{
                                            height: "50px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="firstName">First Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter First Name"
                                        name="firstName"
                                        defaultValue={userData.firstName}
                                        style={{
                                            height: "50px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="lastName">Last Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Last Name"
                                        name="lastName"
                                        defaultValue={userData.lastName}
                                        style={{
                                            height: "50px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="role">Role</label>
                                    <select
                                        type="text"
                                        disabled="true"
                                        className="form-control"
                                        placeholder="Enter Role"
                                        name="role"
                                        value={userData.roleId}
                                        onChange={(event) =>{
                                            setUserData((prevUserData) => ({
                                                ...prevUserData,
                                                roleId: event.target.value,
                                            }))
                                        }}
                                        style={{
                                            height: "50px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    >
                                        <option value="">Select Role Type</option>
                                        {
                                            roles.map((role) => {
                                                return (<option value={role.roleId}>{role.roleName}</option>)
                                            })
                                        }

                                    </select>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="emailId">Email</label>
                                    <input
                                        type="text"
                                        disabled="true"
                                        className="form-control"
                                        placeholder="Enter Email"
                                        name="emailId"
                                        defaultValue={userData.emailId}
                                        style={{
                                            height: "50px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="primaryMobile">Phone Number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Phone Number"
                                        name="primaryMobile"
                                        defaultValue={userData.primaryMobile}
                                        style={{
                                            height: "50px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="companyName">
                                        Company Name
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Company Name"
                                        name="companyName"
                                        defaultValue={userData.companyName}
                                        style={{
                                            height: "50px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="companySize">
                                        Company Size
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Company Size"
                                        name="companySize"
                                        defaultValue={userData.companySize}
                                        style={{
                                            height: "50px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="companyUrl">
                                        Company URL
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Company URL"
                                        name="companyUrl"
                                        defaultValue={userData.companyUrl}
                                        style={{
                                            height: "50px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="country">Country</label>
                                    <select
                                        type="text"
                                        className="form-control"
                                        value={userData.country}
                                        name="country"
                                        onChange={handleCountryChange}
                                        style={{
                                            height: "50px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    >
                                        <option value="">Select Country</option>
                                        {countries.map((country) => (
                                            <option key={country.countryCode} value={country.countryCode}>
                                                {country.countryName}
                                            </option>
                                        ))}
                                    </select>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <label htmlFor="address">
                                        Address
                                    </label>
                                    <textarea
                                        rows="3"
                                        className="form-control"
                                        placeholder="Enter Address"
                                        name="address"
                                        defaultValue={userData.address}
                                        style={{
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            border: "1px solid #C7C7C7",
                                            borderRadius: "6px",
                                            opacity: "1",
                                        }}
                                    />
                                </Grid>

                            </Grid>
                            <br />

                            <div className="row justify-content-center">
                                <button
                                    className="btn btn-outline-primary me-2"
                                    style={{
                                        border: "1px solid #3E67B1",
                                        borderRadius: " 4px",
                                        opacity: "1",
                                        width: "96px",
                                        height: "50px",
                                    }}
                                    onClick={() => {
                                        navigate(-1);
                                    }}
                                >
                                    BACK
                                </button>
                                <button
                                    onClick={() => {
                                        const fd= new FormData(custFormRef.current);
                                        const formDataJson ={}
                                        Array.from(fd.entries()).reduce((a,b)=>{
                                            a[b[0]] = b[1];
                                            return a
                                        }, formDataJson)
                                        let updatedObj = {...userData, ...formDataJson}
                                        updateUser(userMongoId, profileType, updatedObj).then((res) => {
                                            toast.success("User Updated Successfully.!");
                                            navigate(-1);
                                        }).catch(err=>{
                                            toast.error("Unable to update User.!");
                                        });
                                    }}
                                    type="submit"
                                    className="btn btn-primary"
                                    style={{
                                        border: "1px solid #3E67B1",
                                        borderRadius: " 4px",
                                        opacity: "1",
                                        width: "150px",
                                        height: "50px",
                                    }}
                                >
                                    UPDATE USER
                                </button>
                            </div>
                        </form>
                    </div>
                    <div style={{
                        backgroundColor: "#FFFFFF",
                        padding: "6px",
                        marginLeft: "20px",
                        boxShadow: "0px 3px 6px #00000029",
                        borderRadius: "8px",
                        width:"20%",
                        height:"240px"
                    }}>
                        <img src="https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg" alt="Profile Picture"
                             style={{
                                 width: "100%",
                                 height: "100%",
                                 objectFit: "cover",
                                 borderRadius: "8px"
                             }}  />
                    </div>
                </div>

                <br></br>
                <div style={{ display: (userData.partnerEmail == '' && profileType == "PANELIST") ? "flex":"none"}}>
                    <Box className="container" sx={{border: "1px solid #cecece",backgroundColor: "#FFFFFF",padding: "20px",boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)"}} >
                        <h4>Interview Rate Card (India)</h4>
                        <div>
                            <Box sx={{ display: "flex", alignItems: "center", padding: "10px 0", margin: "10px"}} >
                                <Checkbox />
                                <label style={{ marginRight: "10px", flex: 1 }}> {" "}
                                    <b>Category </b>
                                </label>
                                <label style={{ marginRight: "10px", flex: 1 }}>
                                    <b>Completed </b>
                                </label>
                                <label style={{ marginRight: "10px", flex: 1 }}>
                                    <b>Cancelled </b>
                                </label>
                                <label style={{ marginRight: "10px", flex: 1 }}>
                                    <b>Candidate No Show</b>
                                </label>
                                <label style={{ marginRight: "10px", flex: 1 }}>
                                    <b>Panel No Show</b>
                                </label>
                                <label style={{ marginRight: "10px", flex: 1 }}>
                                    <b>Panel Technical Glitch </b>
                                </label>
                                <label style={{ marginRight: "10px", flex: 1 }}>
                                    <b>Rescheduled </b>
                                </label>
                                <label style={{ marginRight: "10px", flex: 1 }}>
                                    <b>Candidate Technical Glitch </b>
                                </label>
                            </Box>
                        </div>
                        {Object.keys(rateCardDetails.rates).map((rate) => (
                            <div>
                                <Box sx={{display: !rateCardDetails.rates[rate].displayInAggreement?"none":"flex", alignItems: "center", border: "1px solid #cecece",padding: "10px 0", margin: "10px", }} >
                                    <Checkbox disabled={true} checked={rateCardDetails.rates[rate].displayInAggreement} />
                                    <label style={{ marginRight: "10px", flex: 1 }}> {rate.toUpperCase()} </label>
                                    {Object.keys(rateCardDetails.rates[rate]).map((key) => {
                                        if (key !== 'displayInAggreement' && key !== 'otherCharges' && key !== 'technicalGlitch'){
                                            return (
                                                <TextField placeholder={key}
                                                           disabled={true}
                                                           value={rateCardDetails.rates[rate][key]}
                                                           variant="outlined"
                                                           style={{ marginRight: "10px", flex: 1 }}
                                                />
                                            )
                                        }
                                    })}
                                </Box>
                            </div>
                        ))}
                    </Box>
                </div>
                <br></br>
                <div style={{ display: (userData.partnerEmail == '' && profileType == "PANELIST") ? "flex":"none"}}>
                    <Box className="container" sx={{border: "1px solid #cecece",backgroundColor: "#FFFFFF",padding: "20px",boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)"}} >
                        <h4>Interview Rate Card (US)</h4>
                        <div>
                            <Box sx={{ display: "flex", alignItems: "center", padding: "10px 0", margin: "10px"}} >
                                <Checkbox />
                                <label style={{ marginRight: "10px", flex: 1 }}> {" "}
                                    <b>Category </b>
                                </label>
                                <label style={{ marginRight: "10px", flex: 1 }}>
                                    <b>Completed </b>
                                </label>
                                <label style={{ marginRight: "10px", flex: 1 }}>
                                    <b>Cancelled </b>
                                </label>
                                <label style={{ marginRight: "10px", flex: 1 }}>
                                    <b>Candidate No Show</b>
                                </label>
                                <label style={{ marginRight: "10px", flex: 1 }}>
                                    <b>Panel No Show</b>
                                </label>
                                <label style={{ marginRight: "10px", flex: 1 }}>
                                    <b>Panel Technical Glitch </b>
                                </label>
                                <label style={{ marginRight: "10px", flex: 1 }}>
                                    <b>Rescheduled </b>
                                </label>
                                <label style={{ marginRight: "10px", flex: 1 }}>
                                    <b>Candidate Technical Glitch </b>
                                </label>
                            </Box>
                        </div>
                        {Object.keys(rateCardDetails.ratesUS).map((rate) => (
                            <div>
                                <Box sx={{display: !rateCardDetails.ratesUS[rate].displayInAggreement?"none":"flex", alignItems: "center", border: "1px solid #cecece",padding: "10px 0", margin: "10px", }} >
                                    <Checkbox disabled={true} checked={rateCardDetails.ratesUS[rate].displayInAggreement} />
                                    <label style={{ marginRight: "10px", flex: 1 }}> {rate.toUpperCase()} </label>
                                    {Object.keys(rateCardDetails.ratesUS[rate]).map((key) => {
                                        if (key !== 'displayInAggreement' && key !== 'otherCharges' && key !== 'technicalGlitch'){
                                            return (
                                                <TextField placeholder={key}
                                                           disabled={true}
                                                           value={rateCardDetails.ratesUS[rate][key]}
                                                           variant="outlined"
                                                           style={{ marginRight: "10px", flex: 1 }}
                                                />
                                            )
                                        }
                                    })}
                                </Box>
                            </div>
                        ))}
                    </Box>
                </div>
                <br></br>
                <div style={{ display: (userData.partnerEmail == '' && profileType == "PANELIST") ? "flex":"none"}}>
                    <Box className="container" sx={{border: "1px solid #cecece",backgroundColor: "#FFFFFF",padding: "20px",boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)"}} >
                        <h4>Interview Rate Card (Internal)</h4>
                        <div>
                            <Box sx={{ display: "flex", alignItems: "center", padding: "10px 0", margin: "10px"}} >
                                <Checkbox />
                                <label style={{ marginRight: "10px", flex: 1 }}> {" "}
                                    <b>Category </b>
                                </label>
                                <label style={{ marginRight: "10px", flex: 1 }}>
                                    <b>Completed </b>
                                </label>
                                <label style={{ marginRight: "10px", flex: 1 }}>
                                    <b>Cancelled </b>
                                </label>
                                <label style={{ marginRight: "10px", flex: 1 }}>
                                    <b>Candidate No Show</b>
                                </label>
                                <label style={{ marginRight: "10px", flex: 1 }}>
                                    <b>Panel No Show</b>
                                </label>
                                <label style={{ marginRight: "10px", flex: 1 }}>
                                    <b>Panel Technical Glitch </b>
                                </label>
                                <label style={{ marginRight: "10px", flex: 1 }}>
                                    <b>Rescheduled </b>
                                </label>
                                <label style={{ marginRight: "10px", flex: 1 }}>
                                    <b>Candidate Technical Glitch </b>
                                </label>
                            </Box>
                        </div>
                        {Object.keys(rateCardDetails.ratesInt).map((rate) => (
                            <div>
                                <Box sx={{display: !rateCardDetails.ratesInt[rate].displayInAggreement?"none":"flex", alignItems: "center", border: "1px solid #cecece",padding: "10px 0", margin: "10px", }} >
                                    <Checkbox disabled={true} checked={rateCardDetails.ratesInt[rate].displayInAggreement} />
                                    <label style={{ marginRight: "10px", flex: 1 }}> {rate.toUpperCase()} </label>
                                    {Object.keys(rateCardDetails.ratesInt[rate]).map((key) => {
                                        if (key !== 'displayInAggreement' && key !== 'otherCharges' && key !== 'technicalGlitch'){
                                            return (
                                                <TextField placeholder={key}
                                                           disabled={true}
                                                           value={rateCardDetails.ratesInt[rate][key]}
                                                           variant="outlined"
                                                           style={{ marginRight: "10px", flex: 1 }}
                                                />
                                            )
                                        }
                                    })}
                                </Box>
                            </div>
                        ))}
                    </Box>
                </div>
                <br></br>
                <div style={{ display: (userData.partnerEmail == '' && profileType == "PANELIST") ? "flex":"none"}}>
                    <Box className="container" sx={{border: "1px solid #cecece",backgroundColor: "#FFFFFF",padding: "20px",boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)"}} >
                        <h4>Interview Rate Card (External)</h4>
                        <div>
                            <Box sx={{ display: "flex", alignItems: "center", padding: "10px 0", margin: "10px"}} >
                                <Checkbox />
                                <label style={{ marginRight: "10px", flex: 1 }}> {" "}
                                    <b>Category </b>
                                </label>
                                <label style={{ marginRight: "10px", flex: 1 }}>
                                    <b>Completed </b>
                                </label>
                                <label style={{ marginRight: "10px", flex: 1 }}>
                                    <b>Cancelled </b>
                                </label>
                                <label style={{ marginRight: "10px", flex: 1 }}>
                                    <b>Candidate No Show</b>
                                </label>
                                <label style={{ marginRight: "10px", flex: 1 }}>
                                    <b>Panel No Show</b>
                                </label>
                                <label style={{ marginRight: "10px", flex: 1 }}>
                                    <b>Panel Technical Glitch </b>
                                </label>
                                <label style={{ marginRight: "10px", flex: 1 }}>
                                    <b>Rescheduled </b>
                                </label>
                                <label style={{ marginRight: "10px", flex: 1 }}>
                                    <b>Candidate Technical Glitch </b>
                                </label>
                            </Box>
                        </div>
                        {Object.keys(rateCardDetails.ratesExt).map((rate) => (
                            <div>
                                <Box sx={{display: !rateCardDetails.ratesExt[rate].displayInAggreement?"none":"flex", alignItems: "center", border: "1px solid #cecece",padding: "10px 0", margin: "10px", }} >
                                    <Checkbox disabled={true} checked={rateCardDetails.ratesExt[rate].displayInAggreement} />
                                    <label style={{ marginRight: "10px", flex: 1 }}> {rate.toUpperCase()} </label>
                                    {Object.keys(rateCardDetails.ratesExt[rate]).map((key) => {
                                        if (key !== 'displayInAggreement' && key !== 'otherCharges' && key !== 'technicalGlitch'){
                                            return (
                                                <TextField placeholder={key}
                                                           disabled={true}
                                                           value={rateCardDetails.ratesExt[rate][key]}
                                                           variant="outlined"
                                                           style={{ marginRight: "10px", flex: 1 }}
                                                />
                                            )
                                        }
                                    })}
                                </Box>
                            </div>
                        ))}
                    </Box>
                </div>
                <br></br>
                <br></br>

            </Box>
            <br></br>
            <br></br>

        </>
    );
};

export default EditProfile;
