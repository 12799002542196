import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import { Row, Col, Button, Container } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { deleteCalendarEvent } from "../../service/api";
import { toast } from "react-toastify";

function EventForm({ eventToEdit, onSave, onCancel }) {
  const [title, setTitle] = useState(eventToEdit ? eventToEdit.title : '');
  const [status, setStatus] = useState(eventToEdit ? eventToEdit.status : '');
  const [eventStartDate, setStartDateTime] = useState(eventToEdit ? new Date(eventToEdit.eventStartDate) : new Date());
  const [eventEndDate, setEndDateTime] = useState(eventToEdit ? new Date(eventToEdit.eventEndDate) : new Date());
  const [description, setDescription] = useState(eventToEdit ? eventToEdit.description : '');
  const [eventId, setEventID] = useState(eventToEdit?eventToEdit.eventId:'');

  // Add these useState declarations
  const [scheduleId, setScheduleId] = useState(eventToEdit ? eventToEdit.scheduleId : '');
  const [panelEmailId, setPanelEmailId] = useState(eventToEdit ? eventToEdit.panelEmailId : '');
  const [candidateEmailId, setCandidateEmailId] = useState(eventToEdit ? eventToEdit.candidateEmailId : '');

  useEffect(() => {
    if (eventToEdit) {
      setTitle(eventToEdit.title);
      setStatus(eventToEdit.status);
      setStartDateTime(new Date(eventToEdit.eventStartDate));
      setEndDateTime(new Date(eventToEdit.eventEndDate));
      setEventID(eventToEdit.eventId)
      setDescription(eventToEdit.description); // Assuming the eventToEdit has a description property
      setScheduleId(eventToEdit.scheduleId); // Assuming the eventToEdit has a scheduleId property
      setPanelEmailId(eventToEdit.panelEmailId); // Assuming the eventToEdit has a panelEmailId property
      setCandidateEmailId(eventToEdit.candidateEmailId); // Assuming the eventToEdit has a candidateEmailId property
    }
  }, [eventToEdit]);
  
  const refreshTitle = ()=>{
    setTitle(eventToEdit.title);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({
      title,
      status,
      eventId,
      eventStartDate,
      eventEndDate,
      description,        // added this
      scheduleId,         // added this
      panelEmailId,       // added this
      candidateEmailId    // added this
    });
  };

  
  const handleStartDateChange = (date) => {
    setStartDateTime(date);
    if (date >= eventEndDate) {
        const newEndTime = new Date(date);
        newEndTime.setHours(date.getHours() + 1);
        setEndDateTime(newEndTime);
    }
};

const getMinEndTime = () => {
    const minEndTime = new Date(eventStartDate);
    minEndTime.setMinutes(eventStartDate.getMinutes() + 1);
    return minEndTime;
};

const getMaxTime = () => {
  return new Date(2099, 12, 31, 23, 59); // Arbitrary future date, but with specific end-of-day time.
};

const getMinTimeStart = ()=>{
 const isSameDate = moment(eventStartDate).format('YYYY-MM-DD') ===  moment().format('YYYY-MM-DD')
 const isBefore  =  moment(eventStartDate).isBefore(moment())
 const isAfter = moment(eventStartDate).isAfter(moment())
  if(isSameDate)
    return moment().toDate();

  if(isAfter)
    return moment(eventStartDate).set({hour: 0, minute: 0, second: 0}).toDate()
  
  if(isBefore)
    return moment(eventStartDate).set({hour: 23, minute: 59, second: 0}).toDate()
}

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <label>Schedule ID:</label>
            <input type="number" disabled="true" className="form-control" value={scheduleId} onChange={(e) => setScheduleId(e.target.value)} />
          </Col>
          <Col>
            <label>Status:</label>
            <input type="text" disabled="true" className="form-control" value={status} onChange={(e) => setScheduleId(e.target.value)} />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Title:</label>
            <input type="text" className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} />
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col>
            <label>Description:</label>
            <input type="text" className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} />
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col>
            <label>Panel Email ID:</label>
            <input type="email" className="form-control" value={panelEmailId} onChange={(e) => setPanelEmailId(e.target.value)} />
          </Col>
          <Col>
            <label>Candidate Email ID:</label>
            <input type="email" className="form-control" value={candidateEmailId} onChange={(e) => setCandidateEmailId(e.target.value)} />
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col>
            <label>Start Time:</label>
            <DatePicker
              selected={eventStartDate}
              onChange={handleStartDateChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={30}
              dateFormat="MMMM d, yyyy h:mm aa"
              minDate={moment().toDate()}  // This will disable past dates
              minTime={getMinTimeStart()} 
              maxTime={getMaxTime()}
              className="form-control"
            />
          </Col>
          <Col>
            <label>End Time:</label>
            <DatePicker
              selected={eventEndDate}
              onChange={(date) => setEndDateTime(date)}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={30}
              dateFormat="MMMM d, yyyy h:mm aa"
              maxTime={getMaxTime()}
              minTime={getMinEndTime()} 
              minDate={moment(eventStartDate).toDate()}
              className="form-control"
            />
          </Col>
        </Row>
        <br></br>
        {/* <Row>
          <Col className="text-right mt-3">
            <Button style={{marginLeft: '50px'}} type="submit" color="primary">Update</Button>
            <Button style={{marginLeft: '50px', marginRight: '50px'}} type="button" color="warning" 
              onClick={()=>{
                deleteCalendarEvent(eventToEdit.eventId).then(res => {
                  onCancel();
                  window.location.reload(false);
                  toast.info('Event deleted Successfully.!');
                }).catch(e => toast.error('Unable to delete Event.!'))
              }}>
                Delete
              </Button>
            <Button type="button" color="secondary" onClick={()=>{onCancel()}}>Close</Button>
          </Col>
        </Row> */}
      </form>
    </Container>
  );
}

export default EventForm;

