import React, { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';

export default function FilterModal({ filters, open, handleClose, handleApply }) {
  const [localFilters, setLocalFilters] = useState({});
  const user = JSON.parse(localStorage.getItem('user'));
  
  useEffect(() => {
    setLocalFilters(filters)
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalFilters({ ...localFilters, [name]: value });
  };

  const handleApplyClick = () => {
    setLocalFilters(localFilters)
    handleApply(localFilters);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} style={{height: '50%'}}>
      <DialogTitle>Apply Filters</DialogTitle>
      <DialogContent>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-4">
          <TextField name="customerCompany" value={localFilters.customerCompany} label="Customer Name" variant="outlined" onChange={handleInputChange} fullWidth margin="normal" />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-4">
          <TextField name="jobName" value={localFilters.jobName} label="Job Name" variant="outlined" onChange={handleInputChange} fullWidth margin="normal" />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-4">
          <TextField name="candidateName" value={localFilters.candidateName} label="Candidate Name" variant="outlined" onChange={handleInputChange} fullWidth margin="normal" />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-4">
          <TextField name="primarySkills" value={localFilters.primarySkills} label="Primary Skills" variant="outlined" onChange={handleInputChange} fullWidth margin="normal" />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-4">
          <TextField name="assigneeFullName" value={localFilters.assigneeFullName} label="Assignee" variant="outlined" onChange={handleInputChange} fullWidth margin="normal" />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-4">
          <TextField name="status" value={localFilters.status} label="Status" variant="outlined" onChange={handleInputChange} fullWidth margin="normal" />
        </div>
      </div>
      <div className="row" style={{display: ["ADMIN","SUPER"].includes(user.profileType) ? 'block' : 'none'}}>
        <div className="col-sm-12 col-md-6 col-lg-4">
          <TextField name="deliveryUnit" value={localFilters.deliveryUnit} label="Delivery Unit" variant="outlined" onChange={handleInputChange} fullWidth margin="normal" />
        </div>
        
      </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleApplyClick}>Apply Filter</Button>
      </DialogActions>
    </Dialog>
  );
}
