import React,{useEffect, useState} from "react";
import { useParams, useLocation,useNavigate  } from "react-router-dom";
import Box from "@mui/material/Box";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import { deletePermission, getPermissions,createPermission, updatePermission } from "../../service/api";
import { toast } from "react-toastify";
import toastNotifications from "../toastNotifications/toastMessages";
import CircularProgress from '@mui/material/CircularProgress';

export default function Permissions() {
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');


  const [permissionName, setPermissionName] = useState("");
  const [permissionData, setPermissionData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const navigate = useNavigate();

  function validateData(data){
    let result = {isValid:true,}
    let required_fields = ['permissionName'];
    required_fields.forEach(key=>{
      if(!data[key]){
        result =  {isValid:false,mgs:`Field Missing ${key}`}
      }
    })
    return result;
  }

  const permissions_columns = [
    {
      dataField: "permissionId",
      text: "Permission Id",
      sort: true,
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          textAlign: "center",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          textAlign: "center",
          opacity: "1",
        };
      }
    },
    {
      dataField: "permissionName",
      text: "Permission",
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          textAlign: "center",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          textAlign: "center",
          opacity: "1",
        };
      },

      sort: true,
    },

    {
      dataField: "action",
      text: "Actions",
      hidden: user.profileType == 'SUPER' ? false : true,
      formatter: actionFormatter,
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          textAlign: "right",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          textAlign: "right",
          opacity: "1",
        };
      },
    },
  ];

  const [permissions_data , setPermissionsData] = React.useState([]); 

  useEffect(() => {
    setDataLoading(true);
    getPermissions().then((res) => {
      setPermissionsData(res.data);
      setDataLoading(false);
    });

  }, []);

  function actionFormatter(row, cell) {
    return (
      <>
        <i 
           data-toggle="modal"
           data-target="#permissionID"
          onClick={()=>{
            setPermissionData(cell)
          }}
        className="fa fa-light fa-pen-to-square"
        style={{ 
          color: "#3E67B1",
          display: user.profileType == 'SUPER' ? 'inline': 'none'
        }} 
        ></i>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <i 
        className="fa fa-light fa-trash-can" 
        style={{ 
          color: "#3E67B1",
          display: user.profileType == 'SUPER' ? 'inline': 'none'
        }} 
        onClick={()=>{
          deletePermission(cell.permissionId).then((res)=>{
            toast.success(res.message)
            getPermissions().then((res) => {
              setPermissionsData(res.data);
            });
          }).catch((err) => {
            if (err?.response?.data?.message) {
              toast.error(err?.response?.data?.message);
            } else {
              toast.error("An error occurred.!");
            }
          });
        }} ></i>
      </>
    );
  }

  const options = {
    sizePerPage: 10,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '50', value: 50 },
    ],
    hideSizePerPage: false,
    hidePageListOnlyOnePage: false,
    alwaysShowAllBtns: true,
    showTotal: true,
    nextPageText: "Next",
    prePageText: "Prev",
  };

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  if (hasAllPermissions || userPermissions.includes('LIST_PERMISSIONS')) {

    return (
      <>
        
        <div  className="container-page">

        <div
            className="main-content"
            style={{  background: "#fff", padding: "20px" }}
          > 
            <>
              <div
                clas="container"
                style={{ marginLeft: "1%", marginRight: "1%" }}
              >
                <div className="row"
                style={{ display: user.profileType == 'SUPER' ? 'flex': 'none'}}
                >
                  <label>
                    <h6>Permission</h6>
                  </label>
                  <div className="col-4">
                    <input
                      type="text"
                      onChange={(event) => setPermissionName(event.target.value)}
                      className="form-control"
                      style={{
                        height: "50px",
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        border: "1px solid #C7C7C7",
                        borderRadius: "6px",
                        opacity: "1",
                      }}
                      placeholder="Enter Permission Name"
                    />
                  </div>
                  <div className="col-4" style={{ textAlign: "left" }}>
                    <button
                      className="btn btn-primary"
                      style={{
                        height: "50px",
                        background: "#3E67B1 0% 0% no-repeat padding-box",
                        borderRadius: "4px",
                        opacity: "1",
                      }}
                      onClick={() => {
                        const validation = validateData({permissionName});
                        if(!validation.isValid){
                          toast.error(validation.mgs)
                          return;
                        }
                        createPermission({ permissionName}).then((res)=>{
                          navigate(0);
                          toast.success(res.message);
                          getPermissions().then((res) => {
                            setPermissionsData(res.data);
                          });
                        }).catch((err)=>{
                          toast.error(err.error);
                        });
                      }}
                    >
                      Create Permission
                    </button>
                  </div>
                  <div className="col-6"></div>
                </div>
              </div>
            </>
            <br />
            <>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <BootstrapTable
                      className="table"
                      hover
                      bootstrap4
                      keyField="id"
                      data={permissions_data}
                      columns={permissions_columns}
                      pagination={paginationFactory(options)}
                      filter={filterFactory()}
                      defaultSorted={defaultSorted}
                      noDataIndication={
                        dataLoading ? 
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                          <CircularProgress />
                        </Box> 
                        : 
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                          <span>No Data Found</span>
                        </Box>
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
        <>
        <div
          id="permissionID"
          className="modal fade bd-example-modal-lg"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Edit Permission
                </h5>
              </div>
              <div className="container" style={{ backgroundColor: "white" }}>
                <div className="form-group">
                  <label htmlFor="roleName">Permission Name</label>
                  <input
                    id="roleName"
                    className="form-control"
                    type="text"
                    value={permissionData.permissionName}
                    onChange={(e) =>{
                      
                      e.preventDefault();
                      const newRoleName = e.target.value;
                      setPermissionData((prevpermissionData) => ({
                        ...prevpermissionData,
                        permissionName: newRoleName,
                      }));
                    }}
                    style={{
                      height: "50px"
                    }}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">
                  Close
                </button>
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-primary"
                  onClick={() => {
                    updatePermission(permissionData.permissionId,permissionData)
                    .then((res) => {     
                      toast.success(toastNotifications.permissions.updateSuccess);
                      getPermissions().then((res) => {
                        setPermissionsData(res.data);
                      });
                    }).catch(err=>{
                      toast.error(toastNotifications.permissions.updateFail);
                    });
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
      
      </>
    );

  } else {
    return (
      <>
        <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
          <div className="card">
            <div className="card-body text-center">
              <h2 className="card-title">
                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                Access Denied
              </h2>
              <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}
