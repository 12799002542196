import React, { useRef, useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";

import "./Candidate.css";

import {
  getCandidate, getPanel, scheduleInterview, updateBonusAmount
} from '../../service/api';

import { toast } from "react-toastify";

export default function ApprovePanelClaims() {
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');

  const { candidateId, customerId } = useParams();
  const navigate = useNavigate();
  const formRef = useRef(null);

  const [meetingLink, setMeetingLink] = useState('')

  const [candidateObj, setCandidateData] = useState({
    customerId: customerId,
    custCoordinatorId: 0,
    candidateId: 0,
    panelId: 0,
    assigneeId: 0,
    jobId: 0,
    rateCategory: '',
    rateCard: '',
    isBonusApplicable: false,
    bonusAmount: 0,
    custCoordinatorName: '',
    custCoordinatorEmailId: '',
    customerCompany: '',
    // candidateId: candidateId,
    candidateName: '',
    candidateEmailId: '',
    candidateResumeId: '',
    contactNumber: '',
    alternateContanctNumber: '',
    highestQualification: '',
    course: '',
    institute: '',
    isCurrentlyEmployed: '',
    currentCompany: '',
    currentRole: '',
    currentCountry: 'IND',
    currentState: '',
    currentCity: '',
    totalExperience: '',
    primarySkills: '',
    secondarySkills: '',
    skillFamily: '',
    socialProfile: '',
    slotDate1: '',
    slotTime1: '',
    timezone1: '',
    slotDate2: '',
    slotTime2: '',
    timezone2: '',
    dynamicSkills: [],
    standardSkills: [],
    documents: [],
    overallFeedback: '',
    recommendations: '',
    remarks: '',
    status: '',
    slotNumber: 0
  });

  const rateCategories = {
    'ratesInIndia': 'Rates In India',
    'ratesInUS': 'Rates In US',
    'ratesInternal': 'Rates Internal',
    'ratesExternal': 'Rates External'
  };

  const rateCards = {
    'highDemand': 'High Demand',
    'niche': 'Niche',
    'legacy': 'Legacy',
    'leadership': 'Leadership',
    'others': 'Others'
  };

  const [panelObj, setPanelData] = useState({
      userId: 0,
      totalExperience: 0,
      primarySkills: '',
      secondarySkills: '',
      skillFamily: ''
  });

  useEffect(() => {
    getCandidate(candidateId).then((res) => {
      setCandidateData(res.data);
      let panelId = res.data?.panelId || 0;
      getPanel(panelId).then((res) => {
        setPanelData(res.data);
      });
    });
  }, [candidateId]);


  if (hasAllPermissions || userPermissions.includes('APPROVE_PANEL_CLAIMS')) {

    return (
      <>
        <div className="container-page">
          <div className="main-content" style={{ }}>

            <form ref={formRef}>
              <div className="candidate-details" style={{ marginTop: "20px", minHeight: "200px" }}>

                <div className="row">
                  <div className="col container-heading"><h6>Approve & Schedule Panel Claims</h6></div>
                </div>
                <div className="table-container" style={{ marginTop: "29px" }}>
                  <table className="table" style={{ padding: "5px" }}>
                    <thead>
                      <tr>
                        <th>Candidate Details</th>
                        <th>Panel Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Candidate Name: {candidateObj.candidateName}</td>
                        <td>Panel Name: {candidateObj.panelName}</td>
                      </tr>
                      <tr>
                        <td>Candidate Email ID: {candidateObj.candidateEmailId}</td>
                        <td>Panel Email ID: {candidateObj.panelEmailId}</td>
                      </tr>
                      <tr>
                        <td>Candidate Experience: {candidateObj.totalExperience}</td>
                        <td>Panel Experience: {panelObj.experience}</td>
                      </tr>
                      <tr>
                        <td>Candidate Skill Family: {candidateObj.skillFamily}</td>
                        <td>
                          Panel Skill Family: {panelObj.skillFamily}
                        </td>
                      </tr>
                      <tr>
                        <td>Candidate Skills: {candidateObj.primarySkills}</td>
                        <td>
                          Panel Skills: {panelObj.primarySkills}
                          {panelObj.secondarySkills && `, ${panelObj.secondarySkills}`}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="table-container" style={{ marginTop: "29px" }}>
                  <table className="table" style={{ padding: "5px" }}>
                    <thead>
                      <tr>
                        <th>Slot Date</th>
                        <th>Slot Time</th>
                        <th>Timezone</th>
                        <th>Interview Duration</th>
                        <th>Rate Category</th>
                        <th>Rate Card</th>
                        <th>Is Bonus Applicable</th>
                        <th>Bonus Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{candidateObj[`slotDate${candidateObj.slotNumber}`]}</td>
                        <td>{candidateObj[`slotTime${candidateObj.slotNumber}`]}</td>
                        <td>{candidateObj[`timezone${candidateObj.slotNumber}`]}</td>
                        <td>{candidateObj['interviewDuration']}</td>
                        <td>{rateCategories[candidateObj['rateCategory']]}</td>
                        <td>{rateCards[candidateObj['rateCard']]}</td>
                        <td>{candidateObj['isBonusApplicable'] == false ? 'No':'yes'}</td>
                        <td>{candidateObj['bonusAmount']}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br></br>

                <div className="candidate-details"
                  style={{
                    marginLeft: "0px",
                    marginRight: "0px",
                    minHeight:"100px",
                    display: candidateObj.isBonusApplicable ? 'block' : 'none'
                  }}>
                    <div className="row">
                      <div className="col">
                          <label><h6>Bonus Amount *</h6></label>
                          <input
                            className="form-control editable"
                            type="number"
                            placeholder="Enter Bonus Amount" name="alternateContanctNumber"
                            onChange={(event) => {
                              setCandidateData((prevCandidateData) => ({
                                ...prevCandidateData,
                                bonusAmount: event.target.value,
                              }));
                            }}
                            value={candidateObj.bonusAmount}
                          />
                      </div>

                      <div className="col">
                        <button
                          onClick={() => {
                            if (!(hasAllPermissions || userPermissions.includes('APPROVE_BONUS_AMOUNT'))) {
                              toast.error("Alert.!, You don't have access to update Bonus Amount, Check with Admin.!")
                              return;
                            }
                            updateBonusAmount(candidateId, {bonusAmount: candidateObj.bonusAmount}).then((res) => {
                              toast.success('Updated Bonus Amount Successfully!');
                            }).catch((err) => {
                              console.error(err);
                              toast.error("Unable to update Bonus Amount.!");
                            });
                          }}
                          type="button"
                          className="btn btn-primary"
                          style={{
                            border: '1px solid #3E67B1',
                            borderRadius: ' 4px',
                            opacity: '1',
                            width: '200px',
                            height: '50px',
                            marginTop: '30px'
                          }}
                        >
                          Update Bonus Amount
                        </button>
                      </div>
                    </div>
                    <br></br>
                 </div>

                <br></br>
                <div className="candidate-details"
                  style={{
                    marginLeft: "0px",
                    marginRight: "0px",
                    minHeight:"100px",
                  }}>
                  <div className="row" style={{ marginTop: '20px', paddingBottom: '5px' }}>
                    <div className="col-6">
                        {/* <label><h6>Meeting Link *</h6></label> */}
                        <input
                          className="form-control editable"
                          type="text"
                          placeholder="Enter Meeting Link *"
                          onChange={(event) => setMeetingLink(event.target.value)}
                          value={meetingLink}
                        />
                    </div>
                    <div className="col-3" style={{ }}>
                      <button
                        onClick={() => {
                          if (meetingLink == '') {
                            toast.error("Please Enter Meeting Link before schedule the interview.!")
                            return;
                          }

                          if (candidateObj.isBonusApplicable) {
                            if (candidateObj.bonusAmount === 0) {
                              toast.error("Please update Bonus Amount before schedule the interview.!")
                              return;
                            }
                          }

                          scheduleInterview(candidateId, meetingLink).then((res) => {
                            toast.success('Claim Approved and Interview Scheduled.!');
                            navigate('/user/claimedCandidates');
                          }).catch((err) => {
                            console.error(err);
                            toast.error(err?.response?.data?.error);
                          });
                        }}
                        type="button"
                        className="btn btn-primary"
                        style={{
                          border: '1px solid #3E67B1',
                          borderRadius: ' 4px',
                          opacity: '1',
                          width: '200px',
                          height: '50px',
                          display: (hasAllPermissions || userPermissions.includes('APPROVE_PANEL_CLAIMS')) ? 'inline' : 'none'
                        }}
                      >
                        Schedule Interview
                      </button>
                      </div>

                      <div className="col-2" style={{  }}>
                      <button
                        type="button"
                        className="btn btn-outline-primary me-2"
                        style={{
                          border: '1px solid #3E67B1',
                          borderRadius: ' 4px',
                          opacity: '1',
                          width: '96px',
                          height: '50px',
                        }}
                        onClick={()=>{
                          navigate(-1);
                        }}
                      >
                        BACK
                      </button>
                    </div>
                  </div>
                </div>

                <br></br>
                <br></br>

              </div>

            </form>
            <br></br>
            <br></br>
          </div>

        </div>
      </>
    );

  } else {
    return (
      <>
        <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
          <div className="card">
            <div className="card-body text-center">
              <h2 className="card-title">
                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                Access Denied
              </h2>
              <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}
