import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {textFilter} from 'react-bootstrap-table2-filter';
import {getAssignedUsers, getTickets, deleteTicket} from "../service/api";
import CircularProgress from '@mui/material/CircularProgress';
import "./Authentication/LoginPage.css";
import FilterModal from "./TicketFilterModel";
import { toast } from "react-toastify";


function filterDataByDateRange(data, startDate, endDate) {
    const filteredData = data.filter((item) => {
        const itemDate = new Date(item.createdAt).toISOString().split('T')[0];
        return itemDate >= startDate && itemDate <= endDate;
    });
    return filteredData;
}

const Tickets = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const userPermissions = user.permissions.map(e => e.permissionName);
    const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');

    const {filterOptions} = useParams();
    const [searchStatus, setSearchStatus] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const navigate = useNavigate();
    const [ticketsData, setTicketsData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);

    const [filters, setFilters] = useState({});
    const [filterModalOpen, setFilterModalOpen] = useState(false);


    const handleOpenFilterModal = () => {
        setFilterModalOpen(true);
    };

    const handleCloseFilterModal = () => {
        setFilterModalOpen(false);
    };

    const handleApplyFilters = (newFilters) => {
        if (['all', 'CREATED'].includes(newFilters.status) ) {
        newFilters.status = '';
        }
        const updatedFilters = Object.fromEntries(Object.entries(newFilters).filter(([key, value]) => value !== ""));
        setFilters(updatedFilters);
    };

    const populateTickets = () => {
        setDataLoading(true);
        getAssignedUsers().then((res) => {
            const userDict = res?.data.reduce((acc, { userId, firstName, lastName }) => {
              acc[userId] = `${firstName} ${lastName}`;
              return acc;
            }, {});
            getTickets().then((res) => {
                let tickets = res?.data || [];
                for (let i=0; i< tickets.length; i++) {
                    tickets[i]['assigneeFullName'] = userDict[tickets[i]['assigneeId']]
                }

                if (startDate != '' && endDate != '') {
                    tickets = filterDataByDateRange(tickets, startDate, endDate);
                }
                setTicketsData(tickets);
                setFilteredData(tickets);
            });
            setDataLoading(false);
        });
    }

    useEffect(() => {
        if (filterOptions) {
          let [status, startDate, endDate] = filterOptions.split('|')
          console.log(status, startDate, endDate)
          setSearchStatus(status);
          setStartDate(startDate || '');
          setEndDate(endDate || '');
        }
        populateTickets();
        handleApplyFilters({'status': searchStatus})
    }, [searchStatus]);

    useEffect(() => {
        const filterKeys = Object.keys(filters);
        if (filterKeys.length > 0) {
          const filteredData = ticketsData.filter(ticket =>
            filterKeys.every(key => {
                if (filters[key] == 'OPEN') {
                    return !['IN PROGRESS', 'CLOSED'].includes(ticket.status.toUpperCase());
                } else {
                    return String(ticket[key]).toLowerCase().includes(String(filters[key]).toLowerCase())
                }
            })
          );
          setFilteredData(filteredData);
        } else {
          setFilteredData(ticketsData);
        }
    }, [filters, ticketsData]);

    const ticketsColumns = [
        {
            dataField: "ticketId",
            text: "Ticket ID",
            sort: true,
            style: {
                whiteSpace: "nowrap",
            },
            headerStyle: () => {
                return {width: "auto", whiteSpace: "nowrap"};
            },
        },
        {
            dataField: "contactEmailId",
            text: "Contact Email ID",
            sort: true,
            style: {
                whiteSpace: "nowrap",
            },
        },
        {
            dataField: "requesterName",
            text: "Requester Name",
            sort: true,
            style: {
                whiteSpace: "nowrap",
            },
            headerStyle: () => {
                return {width: "auto", whiteSpace: "nowrap"};
            },
        },
        {
            dataField: "contactType",
            text: "Purpose",
            sort: true,
            style: {
                whiteSpace: "nowrap",
            },
        },
        {
            dataField: "assigneeFullName",
            text: "Assignee Name",
            sort: true,

            style: {
                whiteSpace: "nowrap",
            },
        },
        {
            dataField: "status",
            text: "Status",
            sort: true,

            style: {
                whiteSpace: "nowrap",
            },
        },
        {
            dataField: "createdDate",
            text: "Created Date",
            // filter: textFilter({placeholder: ' '}),
            sort: true,
            headerStyle: () => {
                return {width: "auto", whiteSpace: "nowrap"};
            },

        },
        {
            dataField: "elapsedTime",
            text: "Elapsed Time",
            sort: true,

            style: {
                whiteSpace: "nowrap",
            },
        },
        {
            dataField: "action",
            text: "Actions",
            formatter: (cell, row) => (
                <>
                <i title="View Ticket"
                    onClick={() => {
                        if ((hasAllPermissions || userPermissions.includes('EDIT_TICKET'))) {
                            navigate(`/admin/updateTicket/${row.ticketId}`);
                        }
                    }}
                    style={{
                        opacity: !(hasAllPermissions || userPermissions.includes('EDIT_TICKET')) ? 0.2 : 1,
                        pointerEvents: !(hasAllPermissions || userPermissions.includes('EDIT_TICKET')) ? 'none' : 'auto',
                    }}
                    className="fa fa-light fa-pen-to-square"

                ></i>
                <i
                    title="Delete Ticket"
                    onClick={ async () => {
                    if ((hasAllPermissions || userPermissions.includes('DELETE_TICKET'))) {
                        let isConfirmed =window.confirm("Are you sure you want to delete this ticket?");
                        if (isConfirmed) {
                            try {
                                await deleteTicket(row._id);
                                populateTickets();
                                toast.success('Ticket deleted Successfully.!');
                            } catch (err) {
                                console.error(err);
                                toast.error('Unable to delete Ticket.');
                            }
                        }
                    }
                    }}
                    style={{
                    color: '#3E67B1',
                    display: ['SUPER', 'ADMIN'].includes(user.profileType) ? 'inline' : 'none',
                    paddingLeft: "10px",
                    opacity: !(hasAllPermissions || userPermissions.includes('DELETE_TICKET')) ? 0.2 : 1,
                    pointerEvents: !(hasAllPermissions || userPermissions.includes('DELETE_TICKET')) ? 'none' : 'auto',
                    }}
                    className="fa fa-light fa-trash-can"
                ></i>
                </>
            ),
        },
    ];

    const options = {
        sizePerPage: 5,
        sizePerPageList: [
            {text: '5', value: 5},
            {text: '10', value: 10},
            {text: '15', value: 15},
            {text: '20', value: 20},
            {text: '50', value: 50},
        ],
        hideSizePerPage: false,
        hidePageListOnlyOnePage: false,
        alwaysShowAllBtns: true,
        showTotal: true,
        nextPageText: "Next",
        prePageText: "Prev",
    };


    const filterDict = {
        'contactType': 'Purpose',
        'requesterName': 'Requester Name',
        'contactEmailId': 'Contact Email ID',
        'contactPhoneNumber': 'Contact Phone Number',
        'assigneeFullName': 'Assignee Name',
        'status': 'Status'
    }


    if (hasAllPermissions || userPermissions.includes('LIST_TICKETS')) {

        return (
            <div  className="container-page">
                <div style={{  marginBottom: '20px' }}>
                    <div className="row">
                        <div className="col" style={{ textAlign: "right"}}>
                        <button
                            className="filters"
                            variant="contained"
                            color="primary"
                            onClick={handleOpenFilterModal}
                        >
                            &nbsp;
                            <span className="filter-icon">
                            <img src="/Images/Filter.svg" />
                            </span>
                            &nbsp;&nbsp;
                            <span className="filter-text">Filters</span>
                        </button>
                        </div>
                    </div>
                </div>
            <div
               className="main-content"
               style={{  background: "#fff", padding: "20px" }}
             >
                <div className="applied-filters" style={{ display: Object.keys(filters).length > 0 ? 'block': 'none'}}>
              <div className="applied-filters-text">
                <h4>Applied Filters ({Object.keys(filters).length})</h4>
              </div>
              <br></br>
              <div className="applied-filters-list ">
                {Object.keys(filters).map((d, i) => {
                  return (filters[d] != '')? <div>
                  <span className="applied-filter">
                    <label key={i}>{filterDict[d]}:- {filters[d]} &nbsp;{"  "}</label>
                    <i
                      className="fa fa-thin fa-circle-xmark"
                      style={{ color: "#3c77dd" }}
                      onClick={() => {
                        console.log('removeFilter called with:', d, filters);
                        const newFilters = { ...filters };
                        delete newFilters[d];
                        console.log("updatedFilters::", newFilters)
                        setFilters(newFilters);
                        handleApplyFilters(newFilters);
                      }}
                    ></i>
                  </span>
                  </div>: <></>
                })}
              </div>
              <br />
            </div>

            <br/>


                <div  className="custom-table-container1">
                        <BootstrapTable
                            hover
                            bootstrap4
                            keyField="ticketId"
                            data={filteredData}  // changed from ticketsData to filteredData
                            columns={ticketsColumns}
                            pagination={paginationFactory(options)}
                            filter={filterFactory()}
                            noDataIndication={
                                dataLoading ?
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                    }}>
                                        <CircularProgress/>
                                    </Box>
                                    :
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                        width: '100%'
                                    }}>
                                        <span>No Data Found</span>
                                    </Box>
                            }
                        />

                {
                    filterModalOpen?<FilterModal
                    filters={filters}
                    open={filterModalOpen}
                    handleClose={handleCloseFilterModal}
                    handleApply={handleApplyFilters}
                    />: <></>
                }

                        </div>
                    </div>
                    </div>

        );

    } else {
        return (
            <>
                <div className="container d-flex justify-content-center align-items-center"
                     style={{marginTop: "100px"}}>
                    <div className="card">
                        <div className="card-body text-center">
                            <h2 className="card-title">
                                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                                Access Denied
                            </h2>
                            <p className="card-text">You do not have the required permissions to access this page.</p>
                            <p className="card-text">Please contact your administrator for assistance.</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }

};

export default Tickets;
