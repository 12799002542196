import React, { useEffect, useState } from "react";
import {useNavigate  } from "react-router-dom";

import {
  Grid,
  Box,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { getTicketByID, getAssignedUsers, updateTickets, getListOfTimezones } from "../../service/api";
import CommentsHistory from "./CommentsHistory";
import RequesterDetails from "./RequesterDetails";
import CustomerDetails from "./CustomerDetails";
import { toast } from "react-toastify";
import toastNotifications from "../toastNotifications/toastMessages";

const UpdateTicket = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');
  
  const handleRateChange = (rate, key, e) => {
    let  val = +e.target.value;
    if (isNaN(val)){
      val = e.target.checked;
    }
    setTicketDetails(prevDetails => ({
      ...prevDetails,
      rates: {
        ...prevDetails.rates,
        [rate]: {
          ...prevDetails.rates[rate],
          [key]: val
        }
      }
    }));
  };
  const handleUSRateChange = (rate, key, e) => {
    let  val = +e.target.value;
    if (isNaN(val)){
      val = e.target.checked;
    }
    setTicketDetails(prevDetails => ({
      ...prevDetails,
      ratesUS: {
        ...prevDetails.ratesUS,
        [rate]: {
          ...prevDetails.ratesUS[rate],
          [key]: val
        }
      }
    }));
  };
  const handleInternalRateChange = (rate, key, e) => {
    let  val = +e.target.value;
    if (isNaN(val)){
      val = e.target.checked;
    }
    setTicketDetails(prevDetails => ({
      ...prevDetails,
      ratesInt: {
        ...prevDetails.ratesInt,
        [rate]: {
          ...prevDetails.ratesInt[rate],
          [key]: val
        }
      }
    }));
  };
  const handleExternalRateChange = (rate, key, e) => {
    let  val = +e.target.value;
    if (isNaN(val)){
      val = e.target.checked;
    }
    setTicketDetails(prevDetails => ({
      ...prevDetails,
      ratesExt: {
        ...prevDetails.ratesExt,
        [rate]: {
          ...prevDetails.ratesExt[rate],
          [key]: val
        }
      }
    }));
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = date.getMonth() + 1; // getMonth() is zero-based
    let day = date.getDate();

    // pad month and day with leading zeros if necessary
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  };

  const { ticketId } = useParams();
  const [ticketDataProps, setTicketDataProps] = useState({});
  const [ticketDetails, setTicketDetails] = useState({
    rates: {},
    ratesUS: {},
    ratesInt: {},
    ratesExt: {},
    contact: {},
    comments: {},
  });
  const [users, setUsers] = useState([]);

  const fetchTicketData = async () => {
    try {
      const res = await getTicketByID(ticketId);
      const { ratesInIndia, ratesInUS, ratesInternal, ratesExternal, comments, ...ticketProps } = res.data;
      setTicketDataProps(ticketProps);
      setTicketDetails((prevDetails) => ({
        ...prevDetails,
        rates: ratesInIndia,
        ratesUS: ratesInUS,
        ratesInt: ratesInternal,
        ratesExt: ratesExternal,
        comments,
      }));
      
    } catch (error) {
      toast.error("Error fetching ticket data:", error);
    }
  };

  const fetchUsers = async () => {
    try {
      // const res = await getIpaanelUsers();
      const res = await getAssignedUsers();
      setUsers(res.data);
    } catch (error) {
      toast.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchTicketData();
    fetchUsers();
  }, [ticketId]);

  const handleChange = (field, value) => {
    setTicketDataProps((prevProps) => ({ ...prevProps, [field]: value }));
  };
  const navigate = useNavigate();
  const handleUpdate = () => {
    const UpdateTicketData = {
      "status": "string",
      "priority": "string",
      "comments": {
        "comment": "string",
        "commentedBy": "string"
      },
      "address": "string",
      "assignee": "string",
      "assigneeEmailId": "string",
      "partnerEmail": "string",
      "ratesInIndia": {
        "highDemand": {
          "completed": 0,
          "cancelled": 0,
          "candidateNoShow": 0,
          "panelNoShow": 0,
          "panelTechnicalGlitch": 0,
          "rescheduled": 0,
          "candidateTechnicalGlitch": 0,
          "displayInAggreement": true
        },
        "niche": {
          "completed": 0,
          "cancelled": 0,
          "candidateNoShow": 0,
          "panelNoShow": 0,
          "panelTechnicalGlitch": 0,
          "rescheduled": 0,
          "candidateTechnicalGlitch": 0,
          "displayInAggreement": false
        },
        "legacy": {
          "completed": 0,
          "cancelled": 0,
          "candidateNoShow": 0,
          "panelNoShow": 0,
          "panelTechnicalGlitch": 0,
          "rescheduled": 0,
          "candidateTechnicalGlitch": 0,
          "displayInAggreement": false
        },
        "leadership": {
          "completed": 0,
          "cancelled": 0,
          "candidateNoShow": 0,
          "panelNoShow": 0,
          "panelTechnicalGlitch": 0,
          "rescheduled": 0,
          "candidateTechnicalGlitch": 0,
          "displayInAggreement": false
        },
        "others": {
          "completed": 0,
          "cancelled": 0,
          "candidateNoShow": 0,
          "panelNoShow": 0,
          "panelTechnicalGlitch": 0,
          "rescheduled": 0,
          "candidateTechnicalGlitch": 0,
          "displayInAggreement": false
        }
      },
      "ratesInUS": {
        "highDemand": {
          "completed": 0,
          "cancelled": 0,
          "candidateNoShow": 0,
          "panelNoShow": 0,
          "panelTechnicalGlitch": 0,
          "rescheduled": 0,
          "candidateTechnicalGlitch": 0,
          "displayInAggreement": true
        },
        "niche": {
          "completed": 0,
          "cancelled": 0,
          "candidateNoShow": 0,
          "panelNoShow": 0,
          "panelTechnicalGlitch": 0,
          "rescheduled": 0,
          "candidateTechnicalGlitch": 0,
          "displayInAggreement": false
        },
        "legacy": {
          "completed": 0,
          "cancelled": 0,
          "candidateNoShow": 0,
          "panelNoShow": 0,
          "panelTechnicalGlitch": 0,
          "rescheduled": 0,
          "candidateTechnicalGlitch": 0,
          "displayInAggreement": false
        },
        "leadership": {
          "completed": 0,
          "cancelled": 0,
          "candidateNoShow": 0,
          "panelNoShow": 0,
          "panelTechnicalGlitch": 0,
          "rescheduled": 0,
          "candidateTechnicalGlitch": 0,
          "displayInAggreement": false
        },
        "others": {
          "completed": 0,
          "cancelled": 0,
          "candidateNoShow": 0,
          "panelNoShow": 0,
          "panelTechnicalGlitch": 0,
          "rescheduled": 0,
          "candidateTechnicalGlitch": 0,
          "displayInAggreement": false
        }
      },
      "ratesInternal": {
        "highDemand": {
          "completed": 0,
          "cancelled": 0,
          "candidateNoShow": 0,
          "panelNoShow": 0,
          "panelTechnicalGlitch": 0,
          "rescheduled": 0,
          "candidateTechnicalGlitch": 0,
          "displayInAggreement": true
        },
        "niche": {
          "completed": 0,
          "cancelled": 0,
          "candidateNoShow": 0,
          "panelNoShow": 0,
          "panelTechnicalGlitch": 0,
          "rescheduled": 0,
          "candidateTechnicalGlitch": 0,
          "displayInAggreement": false
        },
        "legacy": {
          "completed": 0,
          "cancelled": 0,
          "candidateNoShow": 0,
          "panelNoShow": 0,
          "panelTechnicalGlitch": 0,
          "rescheduled": 0,
          "candidateTechnicalGlitch": 0,
          "displayInAggreement": false
        },
        "leadership": {
          "completed": 0,
          "cancelled": 0,
          "candidateNoShow": 0,
          "panelNoShow": 0,
          "panelTechnicalGlitch": 0,
          "rescheduled": 0,
          "candidateTechnicalGlitch": 0,
          "displayInAggreement": false
        },
        "others": {
          "completed": 0,
          "cancelled": 0,
          "candidateNoShow": 0,
          "panelNoShow": 0,
          "panelTechnicalGlitch": 0,
          "rescheduled": 0,
          "candidateTechnicalGlitch": 0,
          "displayInAggreement": false
        }
      },
      "ratesExternal": {
        "highDemand": {
          "completed": 0,
          "cancelled": 0,
          "candidateNoShow": 0,
          "panelNoShow": 0,
          "panelTechnicalGlitch": 0,
          "rescheduled": 0,
          "candidateTechnicalGlitch": 0,
          "displayInAggreement": true
        },
        "niche": {
          "completed": 0,
          "cancelled": 0,
          "candidateNoShow": 0,
          "panelNoShow": 0,
          "panelTechnicalGlitch": 0,
          "rescheduled": 0,
          "candidateTechnicalGlitch": 0,
          "displayInAggreement": false
        },
        "legacy": {
          "completed": 0,
          "cancelled": 0,
          "candidateNoShow": 0,
          "panelNoShow": 0,
          "panelTechnicalGlitch": 0,
          "rescheduled": 0,
          "candidateTechnicalGlitch": 0,
          "displayInAggreement": false
        },
        "leadership": {
          "completed": 0,
          "cancelled": 0,
          "candidateNoShow": 0,
          "panelNoShow": 0,
          "panelTechnicalGlitch": 0,
          "rescheduled": 0,
          "candidateTechnicalGlitch": 0,
          "displayInAggreement": false
        },
        "others": {
          "completed": 0,
          "cancelled": 0,
          "candidateNoShow": 0,
          "panelNoShow": 0,
          "panelTechnicalGlitch": 0,
          "rescheduled": 0,
          "candidateTechnicalGlitch": 0,
          "displayInAggreement": false
        }
      }
    }
    const updateObject = {};
    for(let key in ticketDetails){
      updateObject[key] = ticketDetails[key];
    }
    for(let key in ticketDataProps){
      updateObject[key] = ticketDataProps[key];
    }
    if (ticketDataProps.comments) {
      updateObject['comments'] = {
        "comment": ticketDataProps.comments || null,
        "commentedBy": "Anonymous" || null
      }
    } else {
      updateObject['comments'] = null;
    }
    updateObject.ratesInIndia = ticketDetails.rates;
    updateObject.ratesInUS = ticketDetails.ratesUS;
    updateObject.ratesInternal = ticketDetails.ratesInt;
    updateObject.ratesExternal = ticketDetails.ratesExt;
    const finalUpdateObject = {}
    for(const utd of Object.keys(UpdateTicketData)){
      finalUpdateObject[utd] = updateObject[utd];
    }
    
    finalUpdateObject.address = ticketDataProps.address;
    finalUpdateObject.assigneeId = ticketDataProps.assigneeId;
    finalUpdateObject.assignee = ticketDataProps.assigneeId;
    finalUpdateObject.slotDate = ticketDataProps.slotDate;
    finalUpdateObject.slotTime = ticketDataProps.slotTime;
    finalUpdateObject.timezone = ticketDataProps.timezone;
    finalUpdateObject.assigneeEmailId = users.filter((d) => d.userId === finalUpdateObject.assigneeId)[0]?.emailId
    updateTickets(ticketDataProps.ticketId, finalUpdateObject).then((res) => {
      toast.success(toastNotifications.updateTicket.updateSuccess);
      fetchTicketData();
      fetchUsers();
      navigate(-1)
    }).catch(err=>{
      console.log(err)
      toast.error("Unable to Update Ticket.");
    });
  };

  const {
    status,
    priority,
    contactType,
    createdDate,
    closedDate,
    referredByPartner,
    requesterName,
    contactEmailId,
    partnerEmail,
    assigneeId,
    comments,
    address,
    slotDate,
    slotTime,
    timezone
  } = ticketDataProps;

  if (hasAllPermissions || userPermissions.includes('EDIT_TICKET')) {

    return (
      <Grid container spacing={2} style={{marginBottom: "50px"}}>
        <Grid item xs={12} display={ticketDataProps.contactType === 'CUSTOMER' ? 'flex': 'none'}>
          <div
            className="container"
            style={{
              boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
              backgroundColor: "#FFFFFF",
              padding: "20px",
            }}
          >
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <label>
                  <h6>Ticket ID</h6>
                </label>
                <TextField
                  disabled={true}
                  value={ticketId}
                  variant="outlined"
                  placeholder="#19839284"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <label>
                  <h6>Status</h6>
                </label>
                <Select
                  value={(status + "").toUpperCase()}
                  onChange={(e) =>
                    handleChange("status", e.target.value.toUpperCase())
                  }
                  variant="outlined"
                  fullWidth
                >
                  <MenuItem value="CREATED">CREATED</MenuItem>
                  <MenuItem value="IN PROGRESS">IN PROGRESS</MenuItem>
                  {/* <MenuItem value="SEND_OFFER">SEND_OFFER</MenuItem> */}
                  {/* <MenuItem value="OFFER_ACCEPTED">PANEL_ACCEPTED</MenuItem>
                  <MenuItem value="OFFER_REJECTED">PANEL_REJECTED</MenuItem> */}
                  <MenuItem value="DEMO_ACCEPTED">DEMO_ACCEPTED</MenuItem>
                  <MenuItem value="DEMO_REJECTED">DEMO_REJECTED</MenuItem>
                  <MenuItem value="CREATE_USER">CREATE_USER</MenuItem>
                  <MenuItem value="CLOSED">CLOSED</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <label>
                  <h6>Priority</h6>
                </label>
                <Select
                  value={(priority + "").toUpperCase()}
                  onChange={(e) =>
                    handleChange("priority", e.target.value.toUpperCase())
                  }
                  variant="outlined"
                  fullWidth
                >
                  <MenuItem value="HIGH">HIGH</MenuItem>
                  <MenuItem value="MEDIUM">MEDIUM</MenuItem>
                  <MenuItem value="LOW">LOW</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <label>
                  <h6>Category</h6>
                </label>
                <Select
                  value={(contactType + "").toUpperCase()}
                  onChange={(e) =>
                    handleChange("contactType", e.target.value.toUpperCase())
                  }
                  variant="outlined"
                  disabled={true}
                  fullWidth
                >
                  <MenuItem value="PANELIST">PANELIST</MenuItem>
                  <MenuItem value="CUSTOMER">CUSTOMER</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <label>
                  <h6>Created Date </h6>
                </label>
                <TextField
                  value={formatDate(createdDate)}
                  disabled={true}
                  onChange={(e) => handleChange("createdDate", e.target.value)}
                  type="date"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <label>
                  <h6>Closed Date</h6>
                </label>
                <TextField
                  value={formatDate(closedDate)}
                  disabled={true}
                  onChange={(e) => handleChange("closedDate", e.target.value)}
                  type="date"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <label>
                  <h6>Customer Name </h6>
                </label>
                <TextField
                  value={requesterName}
                  onChange={(e) => handleChange("requesterName", e.target.value)}
                  type="text"
                  variant="outlined"
                  fullWidth
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <label>
                  <h6>Customer Email ID</h6>
                </label>
                <TextField
                  value={contactEmailId}
                  onChange={(e) => handleChange("contactEmailId", e.target.value)}
                  type="text"
                  disabled={true}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
            <br />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <label>
                  <h6>Slot Date </h6>
                </label>
                <TextField
                  value={formatDate(slotDate)}
                  onChange={(e) => handleChange("slotDate", e.target.value)}
                  type="date"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <label>
                  <h6>Slot Time </h6>
                </label>
                <TextField
                  value={slotTime}
                  onChange={(e) => handleChange("slotTime", e.target.value)}
                  type="text"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <label>
                  <h6>Timezone</h6>
                </label>
                <Select
                  value={timezone +''}
                  onChange={(e) => handleChange("timezone", +e.target.value)}
                  variant="outlined"
                  fullWidth
                >
                  <MenuItem key='null' value=''> Select Timezone</MenuItem>
                  {
                    getListOfTimezones().map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))
                  }
                </Select>
              </Grid>

            </Grid>
            <br />
            <Grid container spacing={2}>
              
              <Grid item xs={12} sm={4}>
                <label>
                  <h6>Assignee</h6>
                </label>
                <Select
                  value={assigneeId + ""}
                  onChange={(e) => handleChange("assigneeId", +e.target.value)}
                  variant="outlined"
                  fullWidth
                >
                  <MenuItem key={0} value={0}> Select Assignee</MenuItem>
                  {users.map((user) => (
                    <MenuItem key={user.userId} value={user.userId}>
                      {user.firstName} {user.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <label>
                  <h6>Assignee Email ID</h6>
                </label>
                <TextField
                  value={users.filter((d) => d.userId === assigneeId)[0]?.emailId}
                  disabled
                  type="text"
                  variant="outlined"
                  placeholder="assignee@ipaanel.com"
                  fullWidth
                />
              </Grid>
              
            </Grid>
            <br />
            <Grid container spacing={2}>
              
              <Grid item xs={12} sm={6}>
                <label>
                  <h6>Notes/Comments</h6>
                </label>
                <TextField
                  value={comments}
                  onChange={(e) => handleChange("comments", e.target.value)}
                  multiline
                  rows={2}
                  variant="outlined"
                  placeholder="Enter Description"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
              <label>
                  <h6>Address</h6>
                </label>
                <TextField
                  value={address}
                  onChange={(e) => handleChange("address", e.target.value)}
                  multiline
                  rows={2}
                  variant="outlined"
                  placeholder="Enter Address"
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Grid item xs={12} display={ticketDataProps.contactType === 'PANELIST' ? 'flex': 'none'}>
          <div
            className="container"
            style={{
              boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
              backgroundColor: "#FFFFFF",
              padding: "20px",
            }}
          >
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <label>
                  <h6>Ticket ID</h6>
                </label>
                <TextField
                  disabled={true}
                  value={ticketId}
                  variant="outlined"
                  placeholder="#19839284"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <label>
                  <h6>Status</h6>
                </label>
                <Select
                  value={(status + "").toUpperCase()}
                  onChange={(e) =>
                    handleChange("status", e.target.value.toUpperCase())
                  }
                  variant="outlined"
                  fullWidth
                >
                  <MenuItem value="CREATED">CREATED</MenuItem>
                  <MenuItem value="IN PROGRESS">IN PROGRESS</MenuItem>
                  <MenuItem value="SEND_OFFER">SEND_OFFER</MenuItem>
                  <MenuItem value="OFFER_ACCEPTED">PANEL_ACCEPTED</MenuItem>
                  <MenuItem value="OFFER_REJECTED">PANEL_REJECTED</MenuItem>
                  {/* <MenuItem value="DEMO_ACCEPTED">DEMO_ACCEPTED</MenuItem>
                  <MenuItem value="DEMO_REJECTED">DEMO_REJECTED</MenuItem> */}
                  <MenuItem value="CREATE_USER">CREATE_USER</MenuItem>
                  <MenuItem value="CLOSED">CLOSED</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <label>
                  <h6>Priority</h6>
                </label>
                <Select
                  value={(priority + "").toUpperCase()}
                  onChange={(e) =>
                    handleChange("priority", e.target.value.toUpperCase())
                  }
                  variant="outlined"
                  fullWidth
                >
                  <MenuItem value="HIGH">HIGH</MenuItem>
                  <MenuItem value="MEDIUM">MEDIUM</MenuItem>
                  <MenuItem value="LOW">LOW</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <br />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <label>
                  <h6>Category</h6>
                </label>
                <Select
                  value={(contactType + "").toUpperCase()}
                  onChange={(e) =>
                    handleChange("contactType", e.target.value.toUpperCase())
                  }
                  variant="outlined"
                  disabled={true}
                  fullWidth
                >
                  <MenuItem value="PANELIST">PANELIST</MenuItem>
                  <MenuItem value="CUSTOMER">CUSTOMER</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <label>
                  <h6>Created Date </h6>
                </label>
                <TextField
                  value={formatDate(createdDate)}
                  disabled={true}
                  onChange={(e) => handleChange("createdDate", e.target.value)}
                  type="date"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <label>
                  <h6>Closed Date</h6>
                </label>
                <TextField
                  value={formatDate(closedDate)}
                  disabled={true}
                  onChange={(e) => handleChange("closedDate", e.target.value)}
                  type="date"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
            <br />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <label>
                  <h6>Panelist Name </h6>
                </label>
                <TextField
                  value={requesterName}
                  onChange={(e) => handleChange("requesterName", e.target.value)}
                  type="text"
                  variant="outlined"
                  fullWidth
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <label>
                  <h6>Referred by Partner </h6>
                </label>
                <RadioGroup
                style={{"display":"inline","margin-left":"10px"}}
                  value={String(referredByPartner)}
                  onChange={(e) =>
                    handleChange("referredByPartner", e.target.value === "true")
                  }
                >
                  <br />
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Grid>

              <Grid item xs={12} sm={4}>
                <label>
                  <h6>Partner Email ID</h6>
                </label>
                <TextField
                  value={partnerEmail}
                  onChange={(e) => handleChange("partnerEmail", e.target.value)}
                  type="text"
                  variant="outlined"
                  placeholder="Enter Partner Email ID"
                  fullWidth
                />
              </Grid>
              
            </Grid>

            <br />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <label>
                  <h6>Slot Date </h6>
                </label>
                <TextField
                  value={formatDate(slotDate)}
                  onChange={(e) => handleChange("slotDate", e.target.value)}
                  type="date"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <label>
                  <h6>Slot Time </h6>
                </label>
                <TextField
                  value={slotTime}
                  onChange={(e) => handleChange("slotTime", e.target.value)}
                  type="text"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <label>
                  <h6>Timezone</h6>
                </label>
                <Select
                  value={timezone +''}
                  onChange={(e) => handleChange("timezone", +e.target.value)}
                  variant="outlined"
                  fullWidth
                >
                  <MenuItem key='null' value=''> Select Timezone</MenuItem>
                  {
                    getListOfTimezones().map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))
                  }
                </Select>
              </Grid>
            </Grid>

            <br />
            <Grid container spacing={2}>
              
              <Grid item xs={12} sm={4}>
                <label>
                  <h6>Assignee</h6>
                </label>
                <Select
                  value={assigneeId + ""}
                  onChange={(e) => handleChange("assigneeId", +e.target.value)}
                  variant="outlined"
                  fullWidth
                >
                  <MenuItem key={0} value={0}> Select Assignee</MenuItem>
                  {users.map((user) => (
                    <MenuItem key={user.userId} value={user.userId}>
                      {user.firstName} {user.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <label>
                  <h6>Assignee Email ID</h6>
                </label>
                <TextField
                  value={users.filter((d) => d.userId === assigneeId)[0]?.emailId}
                  disabled
                  type="text"
                  variant="outlined"
                  placeholder="assignee@ipaanel.com"
                  fullWidth
                />
              </Grid>
              
            </Grid>
            <br />
            <Grid container spacing={2}>
              
              {/* <Grid item xs={12} sm={4}>
                <label>
                  <h6>Assignee Email ID</h6>
                </label>
                <TextField
                  value={users.filter((d) => d.userId === assigneeId)[0]?.emailId}
                  disabled
                  type="text"
                  variant="outlined"
                  placeholder="assignee@ipaanel.com"
                  fullWidth
                />
              </Grid> */}

              <Grid item xs={12} sm={6}>
                <label>
                  <h6>Notes/Comments</h6>
                </label>
                <TextField
                  value={comments}
                  onChange={(e) => handleChange("comments", e.target.value)}
                  multiline
                  rows={2}
                  variant="outlined"
                  placeholder="Enter Description"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
              <label>
                  <h6>Address</h6>
                </label>
                <TextField
                  value={address}
                  onChange={(e) => handleChange("address", e.target.value)}
                  multiline
                  rows={2}
                  variant="outlined"
                  placeholder="Enter Address"
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Grid item xs={12} display={ticketDataProps.contactType === 'PANELIST' ? 'flex': 'none'}>
          <Box className="container" sx={{border: "1px solid #cecece",backgroundColor: "#FFFFFF",padding: "20px",boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)"}} >
            <h4>Interview Rate Card (India)</h4>
            <div>
              <Box sx={{ display: "flex", alignItems: "center", padding: "10px 0", margin: "10px"}} >
                <Checkbox />
                <label style={{ marginRight: "10px", flex: 1 }}> {" "}
                  <b>Category </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Completed </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Cancelled </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Candidate No Show</b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Panel No Show</b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Panel Technical Glitch </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Rescheduled </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Candidate Technical Glitch </b>
                </label>
              </Box>
            </div>
            {Object.keys(ticketDetails.rates).map((rate) => (
              <div>
                <Box sx={{ display: "flex", alignItems: "center", border: "1px solid #cecece",padding: "10px 0", margin: "10px", }} >
                
                  <Checkbox checked={ticketDetails.rates[rate].displayInAggreement} onChange={(e) => handleRateChange(rate, 'displayInAggreement', e)} />
                  <label style={{ marginRight: "10px", flex: 1 }}>
                    {rate.toUpperCase()}
                  </label>
                  {Object.keys(ticketDetails.rates[rate]).map((key) => {
                    if (key !== 'displayInAggreement' && key !== 'otherCharges' && key !== 'technicalGlitch')
                      return (
                        <TextField placeholder={key} onChange={(e) => handleRateChange(rate, key, e)} 
                          value={ticketDetails.rates[rate][key]}
                          variant="outlined"
                          style={{ marginRight: "10px", flex: 1 }}
                        /> 
                      )
                  })}
                </Box>
              </div>
            ))}
          </Box>
        </Grid>

        <Grid item xs={12} display={ticketDataProps.contactType === 'PANELIST' ? 'flex': 'none'}>
          <Box className="container" sx={{border: "1px solid #cecece",backgroundColor: "#FFFFFF",padding: "20px",boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)"}} >
            <h4>Interview Rate Card (US)</h4>
            <div>
              <Box sx={{ display: "flex", alignItems: "center", padding: "10px 0", margin: "10px"}} >
                <Checkbox />
                <label style={{ marginRight: "10px", flex: 1 }}> {" "}
                  <b>Category </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Completed </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Cancelled </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Candidate No Show</b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Panel No Show</b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Panel Technical Glitch </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Rescheduled </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Candidate Technical Glitch </b>
                </label>
              </Box>
            </div>
            {Object.keys(ticketDetails.ratesUS).map((rate) => (
              <div>
                <Box sx={{ display: "flex", alignItems: "center", border: "1px solid #cecece",padding: "10px 0", margin: "10px", }} >
                
                  <Checkbox checked={ticketDetails.ratesUS[rate].displayInAggreement} onChange={(e) => handleUSRateChange(rate, 'displayInAggreement', e)} />
                  <label style={{ marginRight: "10px", flex: 1 }}>
                    {rate.toUpperCase()}
                  </label>
                  {Object.keys(ticketDetails.ratesUS[rate]).map((key) => {
                    if (key !== 'displayInAggreement' && key !== 'otherCharges' && key !== 'technicalGlitch')
                    return (
                      <TextField placeholder={key} onChange={(e) => handleUSRateChange(rate, key, e)} 
                        value={ticketDetails.ratesUS[rate][key]}
                        variant="outlined"
                        style={{ marginRight: "10px", flex: 1 }}
                      /> 
                    )
                  })}
                </Box>
              </div>
            ))}
          </Box>
          
        </Grid>

        <Grid item xs={12} display={ticketDataProps.contactType === 'PANELIST' ? 'flex': 'none'}>
          <Box className="container" sx={{border: "1px solid #cecece",backgroundColor: "#FFFFFF",padding: "20px",boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)"}} >
            <h4>Interview Rate Card (Internal)</h4>
            <div>
              <Box sx={{ display: "flex", alignItems: "center", padding: "10px 0", margin: "10px"}} >
                <Checkbox />
                <label style={{ marginRight: "10px", flex: 1 }}> {" "}
                  <b>Category </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Completed </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Cancelled </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Candidate No Show</b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Panel No Show</b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Panel Technical Glitch </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Rescheduled </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Candidate Technical Glitch </b>
                </label>
              </Box>
            </div>
            {Object.keys(ticketDetails.ratesInt).map((rate) => (
              <div>
                <Box sx={{ display: "flex", alignItems: "center", border: "1px solid #cecece",padding: "10px 0", margin: "10px", }} >
                
                  <Checkbox checked={ticketDetails.ratesInt[rate].displayInAggreement} onChange={(e) => handleInternalRateChange(rate, 'displayInAggreement', e)} />
                  <label style={{ marginRight: "10px", flex: 1 }}>
                    {rate.toUpperCase()}
                  </label>
                  {Object.keys(ticketDetails.ratesInt[rate]).map((key) => {
                    if (key !== 'displayInAggreement' && key !== 'otherCharges' && key !== 'technicalGlitch')
                    return (
                      <TextField placeholder={key} onChange={(e) => handleInternalRateChange(rate, key, e)} 
                        value={ticketDetails.ratesInt[rate][key]}
                        variant="outlined"
                        style={{ marginRight: "10px", flex: 1 }}
                      /> 
                    )
                  })}
                </Box>
              </div>
            ))}
          </Box>
        </Grid>

        <Grid item xs={12} display={ticketDataProps.contactType === 'PANELIST' ? 'flex': 'none'}>
          <Box className="container" sx={{border: "1px solid #cecece",backgroundColor: "#FFFFFF",padding: "20px",boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)"}} >
            <h4>Interview Rate Card (External)</h4>
            <div>
              <Box sx={{ display: "flex", alignItems: "center", padding: "10px 0", margin: "10px"}} >
                <Checkbox />
                <label style={{ marginRight: "10px", flex: 1 }}> {" "}
                  <b>Category </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Completed </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Cancelled </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Candidate No Show</b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Panel No Show</b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Panel Technical Glitch </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Rescheduled </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Candidate Technical Glitch </b>
                </label>
              </Box>
            </div>
            {Object.keys(ticketDetails.ratesExt).map((rate) => (
              <div>
                <Box sx={{ display: "flex", alignItems: "center", border: "1px solid #cecece",padding: "10px 0", margin: "10px", }} >
                
                  <Checkbox checked={ticketDetails.ratesExt[rate].displayInAggreement} onChange={(e) => handleExternalRateChange(rate, 'displayInAggreement', e)} />
                  <label style={{ marginRight: "10px", flex: 1 }}>
                    {rate.toUpperCase()}
                  </label>
                  {Object.keys(ticketDetails.ratesExt[rate]).map((key) => {
                    if (key !== 'displayInAggreement' && key !== 'otherCharges' && key !== 'technicalGlitch')
                    return (
                      <TextField placeholder={key} onChange={(e) => handleExternalRateChange(rate, key, e)} 
                        value={ticketDetails.ratesExt[rate][key]}
                        variant="outlined"
                        style={{ marginRight: "10px", flex: 1 }}
                      /> 
                    )
                  })}
                </Box>
              </div>
            ))}
          </Box>
        </Grid>



        <Grid item xs={12} style={{paddingLeft: "40%"}}>
          <Button
              className="btn btn-outline-primary"
              style={{
                width: "96px",
                height: "50px",
                border: "1px solid #3E67B1",
                borderRadius: "4px",
                opacity: "1",
              }}
              onClick={() => { navigate(-1) }}
            > Back </Button> <span style={{paddingRight:"20px"}}> </span> 
            
            <Button 
            variant="contained" 
            color="primary" 
            onClick={handleUpdate} 
            style={{
              height: "50px",
              width: "96px",
              background: "#3E67B1 0% 0% no-repeat padding-box",
              borderRadius: "4px",
              opacity: "1",
              display: (hasAllPermissions || userPermissions.includes('EDIT_TICKET')) ? 'inline' : 'none'
            }}> Update </Button>
        </Grid>
        
        <Grid item xs={12}>
          <CommentsHistory commentData={ticketDetails.comments} />
        </Grid>
        
        <Grid item xs={12} display={ticketDataProps.contactType === 'CUSTOMER' ? 'flex': 'none'}>
          <CustomerDetails contactDetails={{contactEmailId: contactEmailId, contactType: contactType}} />
        </Grid>
        
        <Grid item xs={12} display={ticketDataProps.contactType === 'PANELIST' ? 'flex': 'none'}>
          <RequesterDetails contactDetails={{contactEmailId: contactEmailId, contactType: contactType}} />
        </Grid>
      </Grid>
    );
  
  } else {
    return (
      <>
        <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
          <div className="card">
            <div className="card-body text-center">
              <h2 className="card-title">
                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                Access Denied
              </h2>
              <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }

};

export default UpdateTicket;
