import React, { useRef, useState } from "react";
import { toast } from "react-toastify";

export default function ExtraDetailsModel(props) {
  const formRef = useRef(null);
  const cancelBtnRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [otp, setOTPInput] = useState("");
  
  const submitHandler = () => {
    const formData = new FormData(formRef.current);
    props.modelClick(formData, props.extraDetails).then((res) => {
      cancelBtnRef.current.click();
    });
  };

  return (
    <div
      id={props.modelID}
      className={"modal fade bd-example-modal-lg "}
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Additional Details
            </h5>
          </div>
          <div className="container" style={{ backgroundColor: "white" }}>
            <br />
            <form ref={formRef} name="contactForm">
              <div className="row">
                <div className="col">
                  <label>
                    <h6>Company Name</h6>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Company Name"
                    name="companyName"
                    style={{ width: "100%", height: "50px" }}
                  />
                </div>
                <div className="col">
                  <label>
                    <h6>Experience*</h6>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Experience"
                    name="experience"
                    style={{ width: "100%", height: "50px" }}
                  />
                </div>
                <div className="col">
                  <label>
                    <h6>Primary Skills*</h6>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Primary Skills"
                    name="primarySkills"
                    style={{ width: "100%", height: "50px" }}
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <label>
                    <h6>Secondary Skills</h6>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Secondary Skills"
                    name="secondarySkills"
                    style={{ width: "100%", height: "50px" }}
                  />
                </div>
                <div className="col">
                  <label>
                    <h6>Talk to you on*</h6>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Select Availability Date for Demo"
                    type="date"
                    name="slotDate"
                    style={{ width: "100%", height: "50px" }}
                  />
                </div>
                <div className="col">
                  <label>
                    <h6>Talk to you at*</h6>
                  </label>
                  <select
                    className="form-select"
                    style={{ width: "100%", height: "50px", cursor: "pointer" }}
                    name="slotTime"
                  >
                    <option value="Select Time">Select Time</option>
                    <option value="00:00 AM">00:00 AM</option>
                    <option value="01:00 AM">01:00 AM</option>
                    <option value="02:00 AM">02:00 AM</option>
                    <option value="03:00 AM">03:00 AM</option>
                    <option value="04:00 AM">04:00 AM</option>
                    <option value="05:00 AM">05:00 AM</option>
                    <option value="06:00 AM">06:00 AM</option>
                    <option value="07:00 AM">07:00 AM</option>
                    <option value="08:00 AM">08:00 AM</option>
                    <option value="09:00 AM">09:00 AM</option>
                    <option value="10:00 AM">10:00 AM</option>
                    <option value="11:00 AM">11:00 AM</option>
                    <option value="12:00 PM">12:00 PM</option>
                    <option value="13:00 PM">13:00 PM</option>
                    <option value="14:00 PM">14:00 PM</option>
                    <option value="15:00 PM">15:00 PM</option>
                    <option value="16:00 PM">16:00 PM</option>
                    <option value="17:00 PM">17:00 PM</option>
                    <option value="18:00 PM">18:00 PM</option>
                    <option value="19:00 PM">19:00 PM</option>
                    <option value="20:00 PM">20:00 PM</option>
                    <option value="21:00 PM">21:00 PM</option>
                    <option value="22:00 PM">22:00 PM</option>
                    <option value="23:00 PM">23:00 PM</option>
                  </select>
                </div>
              </div>
              <br />

              <div className="row">
                <div className="col">
                  <label>
                    <h6>LinkedIn Profile</h6>
                  </label>
                  <input
                    type="text"
                    style={{ width: "100%", height: "50px" }}
                    className="form-control"
                    placeholder="Enter LinkedIn Profile"
                    name="linkedinProfile"
                  />
                </div>
                <div className="col">
                  <label>
                    <h6>Referred By</h6>
                  </label>
                  <input
                    type="text"
                    style={{ width: "100%", height: "50px" }}
                    className="form-control"
                    placeholder="Enter Referred By"
                    name="referredBy"
                  />
                </div>
                <div className="col">
                  <label>
                    <h6>Referee Mobile Number</h6>
                  </label>
                  <input
                    type="text"
                    style={{ width: "100%", height: "50px" }}
                    className="form-control"
                    placeholder="Enter Referee Mobile Number"
                    name="refereeMobile"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <label>
                    <h6>Referee Email ID</h6>
                  </label>
                  <input
                    type="text"
                    style={{ width: "100%", height: "50px" }}
                    className="form-control"
                    placeholder="Enter Referee Email ID"
                    name="refereeEmail"
                  />
                </div>
                <div className="col">
                  <label>
                    <h6>
                      Upload Resume* (<b> &lt; 200 KB</b>)
                    </h6>
                  </label>
                  <label
                    for="resumeBase64"
                    style={{
                      height: "50px",
                      background: "#EEF4FF",
                      borderRadius: "4px",
                      opacity: 1,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                  <span
                    style={{
                      height: "38px",
                      border: "1px dashed #C2C2C2",
                      opacity: 1,
                      padding: "2%",
                      margin: "4%",
                    }}
                  >
                    <img src="./Images/pdfIcon.svg" />  {" "}
                    <span style={{ color: "#3E67B1" }}>Browse</span>
                    </span>
                    <input
                      style={{ display: "none" }}
                      id="resumeBase64"
                      type="file"
                      accept=".pdf,.doc,.docx"
                      name="resumeBase64"
                      onChange={(e) => {
                        setSelectedFile(e.target.files[0]);
                        const file = e.target.files[0];
                        if (file) {
                          const fileSizeKB = file.size / 1024;
                          if (fileSizeKB > 200) {
                            toast.error("File size exceeds the 200KB limit.");
                            e.target.value = "";
                          }
                        }
                      }}
                    />
                  </label>
                  {
                    selectedFile?.name ? <i>{selectedFile.name}</i> : <i>Supported only pdf, docx, doc</i>
                  }
                </div>
                <div className="col">
                  <label>
                    <h6>OTP</h6>
                  </label>
                  <input
                    type="text"
                    style={{ width: "100%", height: "50px" }}
                    className="form-control"
                    placeholder="Enter OTP"
                    onChange={(e) => setOTPInput(e.target.value)}
                    value={otp}
                    name="otp"
                  />
                </div>
              </div>
              <br />
            </form>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                ref={cancelBtnRef}
              >
                Close
              </button>
              <button
                type="button"
                disabled={otp ? false : true}
                onClick={submitHandler}
                className="btn btn-primary"
              >
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
