import {
    deleteCandidate, getAdminDashboardMetrics,
    getCandidateDocumentById,
    getCandidates,
    getCustomerDashboardMetrics,
} from "../../service/api";
import {toast} from "react-toastify";
import {useLocation, useNavigate, useParams} from "react-router-dom";

import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

export default function CandidateStatus()
{
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const dashboardFilter = queryParams.get('dashboardFilter');
    const user = JSON.parse(localStorage.getItem('user'));
    const userPermissions = user.permissions.map(e => e.permissionName);
    const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');
    const navigate = useNavigate();
    const candidateColumns = [
        {
            dataField: 'jobId',
            text: 'Job ID',
            sort: true,
        },
        {
            dataField: 'jobName',
            text: 'Job Name',
            sort: true,
            style: () => {
                return {
                    minWidth: "200px",
                };
            }
        },
        {
            dataField: 'candidateId',
            text: 'Candidate ID',
            sort: true,
        },
        {
            dataField: 'customerCompany',
            text: 'Customer Name',
            sort: true,
            // hidden: (!["ADMIN","SUPER"].includes(user.profileType))
        },
        {
            dataField: 'skillFamily',
            text: 'Skill Family',
            sort: true,
        },
        {
            dataField: 'primarySkills',
            text: 'Primary Skills',
            sort: true,
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
        },
        {
          dataField: 'overallFeedback',
            text: 'Feedback',
        //     format if overallFeedback is null then display "No Feedback"
            formatter: (cell, row) => {
                return row.overallFeedback ? row.overallFeedback : "No Feedback";
            }
        },
        {
            dataField: "action",
            text: "Actions",
            formatter: (cell, row) => (
                <>
                    <i
                        title="Edit Candidate"
                        onClick={() => {
                            if ((hasAllPermissions || userPermissions.includes('EDIT_CANDIDATE'))) {
                                navigate(`/user/editCandidate/${row._id}/${row.customerId}`);
                            }
                        }}
                        style={{
                            opacity: !(hasAllPermissions || userPermissions.includes('EDIT_CANDIDATE')) ? 0.2 : 1,
                            pointerEvents: !(hasAllPermissions || userPermissions.includes('EDIT_CANDIDATE')) ? 'none' : 'auto',
                        }}
                        className="fa fa-light fa-pen-to-square"
                    ></i>
                    <i
                        title="View Candidate"
                        className="fa fa-light fa-eye"
                        onClick={() => {
                            if ((hasAllPermissions || userPermissions.includes('VIEW_CANDIDATE'))) {
                                navigate(`/user/viewCandidate/${row._id}/${row.customerId}`);
                            }
                        }}
                        style={{
                            paddingLeft: "10px",
                            cursor: "pointer",
                            opacity: !(hasAllPermissions || userPermissions.includes('VIEW_CANDIDATE')) ? 0.2 : 1,
                            pointerEvents: !(hasAllPermissions || userPermissions.includes('VIEW_CANDIDATE')) ? 'none' : 'auto',
                        }}
                    ></i>
                    <i
                        className="fa fa-file-pdf"
                        title="Download Feedback"
                        onClick={() => {
                            if (['FEEDBACK PROVIDED', 'CLOSED'].includes(row.status) && (hasAllPermissions || userPermissions.includes('DOWNLOAD_FEEDBACK'))) {
                                const newTab = window.open('', '_blank');
                                newTab.location.href = `/admin/downloadPDF/${row._id}`;
                            } else {
                                toast.warn('Warning: Access Denied');
                            }
                        }}
                        style={{
                            paddingLeft: "10px",
                            cursor: "pointer",
                            opacity: !(['FEEDBACK PROVIDED', 'CLOSED'].includes(row.status) && (hasAllPermissions || userPermissions.includes('VIEW_CANDIDATE'))) ? 0.2 : 1,
                            pointerEvents: !(['FEEDBACK PROVIDED', 'CLOSED'].includes(row.status) && (hasAllPermissions || userPermissions.includes('VIEW_CANDIDATE'))) ? 'none' : 'auto',
                        }}
                    ></i>
                </>
            ),
            style: () => {
                return {
                    font: "Muli",
                    minWidth: "120px",
                    letterSpacing: "0.28px",
                    color: "#3A3A3A",
                    opacity: "1",
                };
            },
        },
    ];
    const options = {
        sizePerPage: 10,
        sizePerPageList: [
            // {text: '5', value: 5},
            {text: '10', value: 10},
            {text: '15', value: 15},
            {text: '20', value: 20},
            {text: '50', value: 50},
        ],
        hideSizePerPage: false,
        hidePageListOnlyOnePage: false,
        alwaysShowAllBtns: true,
        showTotal: true,
        nextPageText: "Next",
        prePageText: "Prev",
    };
    const [CandidatesData, setCandidatesData] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [metricsData, setMetricsData] = useState([]);
    const [filteredCandidates, setFilteredCandidates] = useState([]);
    const [activeCard, setActiveCard] = useState(null);
    useEffect(() => {
        const fetchCandidates = async () => {
            try {
                const response = await getCandidates();
                let candidates = response?.data.sort((a, b) => b.candidateId - a.candidateId);
                setCandidatesData(candidates);
                console.log(candidates);
                setDataLoading(false);
            } catch (err) {
                console.error(err);
            }
        };
        const fetchMetrics = async () => {
            try {
                if(user['profileType'] === 'ADMIN'){
                    const response = await getAdminDashboardMetrics();
                    setMetricsData(response.data);
                }else{
                    const response = await getCustomerDashboardMetrics();
                    setMetricsData(response.data);
                }
            } catch (err) {
                console.error(err);
            }
        };

        fetchCandidates();
        fetchMetrics();
    }, []); // Empty array means this effect runs once on mount and not on updates

    useEffect(() => {
        if (dashboardFilter) {
            setActiveCard(dashboardFilter);
            setFilteredCandidates(CandidatesData.filter(candidate => candidate.overallFeedback === dashboardFilter.toUpperCase()));
        } else {
            setFilteredCandidates(CandidatesData);
        }
    }, [dashboardFilter, CandidatesData]);
    return(
        <div style={{
            minHeight: "100vh",
        }}>
            <div className="row shadow-radius"
                 style={{
                     backgroundColor: "#FFFFFF",
                     padding: "20px 20px 0px 20px",
                     marginBottom: "20px",
                     marginRight: "0px",
                     marginLeft: "0px",

                 }}
            >
                {metricsData.feedbackMetrics && metricsData.feedbackMetrics.map((item, index) => (
                    <div key={index}
                         className={`table-card-filters ${item.name === 'Feedback Pending' || item.name === 'Feedback Provided' ? 'col-2' : 'col-1'} `}
                            style={{
                                backgroundColor: activeCard === item.name ? "rgb(62, 103, 177)" : "",
                                color: activeCard === item.name ? "white" : "",
                                cursor: "pointer",
                            }}
                         onClick={() => {
                                setActiveCard(item.name);
                                setFilteredCandidates(CandidatesData.filter(candidate => candidate.overallFeedback === item.name.toUpperCase()));
                             }}

                    >
                            <div>{item.name}</div>
                            <div className="card-text"
                            style={{
                                color: activeCard === item.name ? "white" : "",
                            }}
                            >{item.value}</div>
                    </div>
                ))}
            </div>
            <div className="main-content shadow-radius">
                <BootstrapTable
                    hover
                    bootstrap4
                    keyField="candidateId"
                    data={filteredCandidates}
                    columns={candidateColumns}
                    pagination={paginationFactory(options)}
                    noDataIndication={
                        dataLoading ?
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                width: '100%'

                            }}>
                                <CircularProgress/>
                            </Box>
                            :
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                width: '100%'
                            }}>
                                <span>No Data Found</span>
                            </Box>
                    }
                />
            </div>
        </div>
    );
}
