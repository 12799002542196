
import React from 'react';
import './CustomerReports.css';
import PieChartComponent from "../../helpers/charts/PieChartComponent"; // Make sure to create this CSS file for custom styles
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const CustomerReports = () => {
    const metricsData = {
        jobsMetrics: [
            { name: 'Uploaded JD\'s', status: 'uploaded_jds', value: 1000 },
            { name: 'Uploaded Candidates', status: 'uploaded_candidates', value: 1000 },
            { name: 'Completed Interviews', status: 'completed_interviews', value: 500 },
            { name: 'Rescheduled Interviews', status: 'rescheduled_interviews', value: 150 },
            { name: 'Cancelled Interviews', status: 'cancelled_interviews', value: 150 },
            { name: 'Selected Candidates', status: 'selected_candidates', value: 50 },
            { name: 'Rejected Candidates', status: 'rejected_candidates', value: 450 },
            // Add more items as needed
        ]
    };
    const data = [
        {
            candidateId: '9378274',
            name: 'Gopi Krishna',
            jobId: '63784',
            jobRole: 'Product Designer',
            coordinator: 'Harsha',
            date: '10-03-2023',
            timePanelist: '09:00 - 10:00 Pavan Kumar'
        },
        {
            candidateId: '9378274',
            name: 'Banu Prakash',
            jobId: '63784',
            jobRole: 'Graphic Designer',
            coordinator: 'Gopi Krishna',
            date: '10-03-2023',
            timePanelist: '09:00 - 10:00 Pavan Kumar'
        },
        {
            candidateId: '9378274',
            name: 'Chandra',
            jobId: '63784',
            jobRole: 'Product Designer',
            coordinator: 'Gopi Krishna',
            date: '10-03-2023',
            timePanelist: '09:00 - 10:00 Pavan Kumar'
        },
        {
            candidateId: '9378274',
            name: 'Dharma',
            jobId: '63784',
            jobRole: 'Graphic Designer',
            coordinator: 'Gopi Krishna',
            date: '10-03-2023',
            timePanelist: '09:00 - 10:00 Pavan Kumar'
        },
        {
            candidateId: '9378274',
            name: 'Eswar Reddy',
            jobId: '63784',
            jobRole: 'Product Designer',
            coordinator: 'Gopi Krishna',
            date: '10-03-2023',
            timePanelist: '09:00 - 10:00 Pavan Kumar'
        },{
            candidateId: '9378274',
            name: 'Dharma',
            jobId: '63784',
            jobRole: 'Graphic Designer',
            coordinator: 'Gopi Krishna',
            date: '10-03-2023',
            timePanelist: '09:00 - 10:00 Pavan Kumar'
        },
        {
            candidateId: '9378274',
            name: 'Eswar Reddy',
            jobId: '63784',
            jobRole: 'Product Designer',
            coordinator: 'Gopi Krishna',
            date: '10-03-2023',
            timePanelist: '09:00 - 10:00 Pavan Kumar'
        },{
            candidateId: '9378274',
            name: 'Dharma',
            jobId: '63784',
            jobRole: 'Graphic Designer',
            coordinator: 'Gopi Krishna',
            date: '10-03-2023',
            timePanelist: '09:00 - 10:00 Pavan Kumar'
        },
        {
            candidateId: '9378274',
            name: 'Eswar Reddy',
            jobId: '63784',
            jobRole: 'Product Designer',
            coordinator: 'Gopi Krishna',
            date: '10-03-2023',
            timePanelist: '09:00 - 10:00 Pavan Kumar'
        },{
            candidateId: '9378274',
            name: 'Dharma',
            jobId: '63784',
            jobRole: 'Graphic Designer',
            coordinator: 'Gopi Krishna',
            date: '10-03-2023',
            timePanelist: '09:00 - 10:00 Pavan Kumar'
        },
        {
            candidateId: '9378274',
            name: 'Eswar Reddy',
            jobId: '63784',
            jobRole: 'Product Designer',
            coordinator: 'Gopi Krishna',
            date: '10-03-2023',
            timePanelist: '09:00 - 10:00 Pavan Kumar'
        },{
            candidateId: '9378274',
            name: 'Dharma',
            jobId: '63784',
            jobRole: 'Graphic Designer',
            coordinator: 'Gopi Krishna',
            date: '10-03-2023',
            timePanelist: '09:00 - 10:00 Pavan Kumar'
        },
        {
            candidateId: '9378274',
            name: 'Eswar Reddy',
            jobId: '63784',
            jobRole: 'Product Designer',
            coordinator: 'Gopi Krishna',
            date: '10-03-2023',
            timePanelist: '09:00 - 10:00 Pavan Kumar'
        },
        // More data...
    ];
    const columns = [
        { dataField: 'candidateId', text: 'Candidate ID', sort: true },
        { dataField: 'name', text: 'Name', sort: true, style: { color: '#3E67B1' } },
        { dataField: 'jobId', text: 'Job ID', sort: true , style: { color: '#3E67B1' } },
        { dataField: 'jobRole', text: 'Job Role', sort: true },
        { dataField: 'coordinator', text: 'Coordinator', sort: true, style: { color: '#3E67B1' }  },
        { dataField: 'date', text: 'Date', sort: true },
        { dataField: 'timePanelist', text: 'Time Panelist', sort: true },
    ];
    const options = {
        sizePerPage: 10,
        sizePerPageList: [
            { text: '10', value: 10 },
            { text: '15', value: 15 },
            { text: '20', value: 20 },
            { text: '50', value: 50 },
        ],
        hideSizePerPage: false,
        hidePageListOnlyOnePage: false,
        alwaysShowAllBtns: true,
        showTotal: true,
        nextPageText: "Next",
        prePageText: "Prev",
    };
    const [activeCard, setActiveCard] = React.useState('');

    const filterBasedOnCards = (status) => {
        setActiveCard(status);
        // Implement your filter logic here
    };
    return (
        <>
            <div className="row shadow-radius custom-container">
                <div className="col-md-6 col-lg-3">
                    <h5>Account Name</h5>
                    <select className="form-control custom-select">
                        <option value="">Select Account</option>
                        <option value="account1">Account 1</option>
                        <option value="account2">Account 2</option>
                        <option value="account3">Account 3</option>
                    </select>
                </div>
                <div className="col-md-6 col-lg-3">
                    <h5>Select Period</h5>
                    <select className="form-control custom-select">
                        <option value="">Select Period</option>
                        <option value="lastWeek">Last Week</option>
                        <option value="lastMonth">Last Month</option>
                        <option value="lastYear">Last Year</option>
                    </select>
                </div>
                <div className="col-md-6 col-lg-3">
                    <h5>Start Date</h5>
                    <input className="form-control custom-input" type="date"/>
                </div>
                <div className="col-md-6 col-lg-3">
                    <h5>End Date</h5>
                    <input className="form-control custom-input" type="date"/>
                </div>
                <div className="row justify-content-center mt-3">
                    <div className="col-6 col-md-4 col-lg-2">
                        <button className="btn btn-outline-primary custom-button">
                            Clear
                        </button>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2">
                        <button className="btn btn-primary custom-button">
                            Search
                        </button>
                    </div>
                </div>
            </div>
            <div className="row shadow-radius"
                 style={{backgroundColor: "#FFFFFF", padding: "20px 20px 0px 20px", margin: "0px 0px 20px 0px"}}>
                <div className="col-12 col-md-6 col-lg-6">
                    <div className="row">
                        {metricsData.jobsMetrics.map((item, index) => (
                            <div
                                className={`table-card-filters py-2 col-6 col-md-4 col-lg-4 ${activeCard === item.status ? 'btn btn-primary' : ''}`}
                                style={{border: activeCard === item.status ? 'none' : '1px solid #E8E8E8'}}
                                onClick={() => filterBasedOnCards(item.status)} key={index}>
                                <div>{item.name}</div>
                                <div
                                    style={{color: item.status === activeCard ? '#ffffff' : 'inherit'}}>{item.value}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-5">
                    <PieChartComponent/>
                </div>
            </div>
            <div className="row shadow-radius custom-container">
                <div className="col-md-6 col-lg-4 d-flex align-items-center">
                    <span className="mx-3" style={{fontWeight: 'bold'}}> Search</span>
                    <input className="form-control custom-input" type="text"/>
                </div>
                <BootstrapTable
                    bootstrap4
                    keyField='candidateId'
                    data={data}
                    columns={columns}
                    pagination={paginationFactory(options)}
                />
            </div>
        </>
    );
}

export default CustomerReports;
