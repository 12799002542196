import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { TextField, InputAdornment, Switch } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { getPanelSearchList, updateUser } from '../../service/api';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment-timezone';


const PanelSearch = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');
  
  const navigate = useNavigate();
  const [panelsData, setPanelsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");
  const [dataLoading, setDataLoading] = useState(false);

  const populatePanels = () => {
    setDataLoading(true);
    getPanelSearchList().then((res) => {
      setPanelsData(res.data);
      setFilteredData(res.data);
      setDataLoading(false);
    });
  }

  useEffect(() => {
    populatePanels();
  }, []);

  useEffect(() => {
    setFilteredData(
      panelsData.filter((user) =>
        Object.values(user).some(
          (value) => {
            if(value) {
              return value.toString().toLowerCase().includes(search.toLowerCase()) 
            } else {
              return false;
            }
          }
        )
      )
    );
  }, [search, panelsData]);

  const panelColumns = [
    {
      dataField: 'firstName',
      text: 'Name',
      sort: true,
      headerStyle: () => {
        return {width: "auto", whiteSpace: "nowrap"};
      },
      formatter: (cell, row) => (
        <>
          <span> {row.firstName} {row.lastName} </span>
        </>
      ),
    },
    {
      dataField: 'emailId',
      text: 'Email',
      sort: true,
      headerStyle: () => {
        return {width: "auto", whiteSpace: "nowrap"};
      },
    },
    {
      dataField: 'primaryMobile',
      text: 'Phone Number',
      sort: true,
      headerStyle: () => {
        return {width: "auto", whiteSpace: "nowrap"};
      },
    },
    {
      dataField: 'experience',
      text: 'Experience',
      sort: true,
      headerStyle: () => {
        return {width: "auto", whiteSpace: "nowrap"};
      },
    },
    {
      dataField: 'domain',
      text: 'Domain',
      sort: true,
      headerStyle: () => {
        return {width: "auto", whiteSpace: "nowrap"};
      },
    },
    {
      dataField: 'skillFamily',
      text: 'Skill Family',
      sort: true,
      headerStyle: () => {
        return {width: "auto", whiteSpace: "nowrap"};
      },
    },
    {
      dataField: 'primarySkills',
      text: 'Primary Skills',
      sort: true,
      headerStyle: () => {
        return {width: "auto", whiteSpace: "nowrap"};
      },
    },
    {
      dataField: 'secondarySkills',
      text: 'Secondary Skills',
      sort: true,
      headerStyle: () => {
        return {width: "auto", whiteSpace: "nowrap"};
      },
    },
    {
      dataField: 'slots',
      text: 'Slots',
      style: { 
        display: 'flex',
        flexWrap: 'wrap',
        padding: '10px',
        width: '380px'
      },
      sort: true,
      formatter: (cell, row) => (
        <>
          {row.utcSlots && row.utcSlots.length > 0 ? (
            row.utcSlots.map((slot, index) => {
              let localTime = moment.utc(slot).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm');
              return (
                <span key={index} className="badge badge-pill badge-primary" style={{ margin: '2px', width: '30%' }}>
                  {localTime}
                </span>
              );
            })
          ) : <span style={{minHeight: '92px'}}> No Slots</span>}
        </>
      ),
    }
  ];


  const viewDetails = (panelEmailI) => {
    navigate(`/admin/panelSearchDetails/${panelEmailI}`);
  }
  
  const options = {
    sizePerPage: 5,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '50', value: 50 },
    ],
    hideSizePerPage: false,
    hidePageListOnlyOnePage: false,
    alwaysShowAllBtns: true,
    showTotal: true,
    nextPageText: "Next",
    prePageText: "Prev",
  };

  if (hasAllPermissions || userPermissions.includes('LIST_PANELS')) {

    return (
      <div  className="container-page">
         <div
            className="main-content"
            style={{  background: "#fff", padding: " 20px",borderRadius:"8px" }}
          >
           <TextField
            placeholder="Search"
            size="small"
            style={{
              background: "#FCFCFC 0% 0% no-repeat padding-box",
              boxShadow: " 0px 3px 6px #3E67B133",
              borderRadius: "4px",
              opacity: "1",
              height: "40px"
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <br />
          <div className="custom-table-container">
          <BootstrapTable
            hover
            bootstrap4
            keyField="id"
            data={filteredData}
            columns={panelColumns}
            pagination={paginationFactory(options)}
            filter={filterFactory()}
            noDataIndication={
              dataLoading ? 
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                <CircularProgress />
              </Box> 
              : 
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                <span>No Data Found</span>
              </Box>
            }
          />
        </div>
      </div>
      </div>
    );
  
  } else {
    return (
      <>
        <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
          <div className="card">
            <div className="card-body text-center">
              <h2 className="card-title">
                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                Access Denied
              </h2>
              <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }

};

export default PanelSearch;


