import React, { useEffect, useState } from 'react'
import PDF from "../../src/static/dummy.pdf";
import { useParams } from 'react-router-dom';
import { getTicketDetails, putTicketDetails } from '../service/api';

import {
  Grid,
  Box,
  Checkbox,
  TextField,
} from "@mui/material";
import { toast } from 'react-toastify';
export default function PanelOffer() {
  const [accepted , setAccepted] = useState(false);
  const { userHash } = useParams();
  const [ userDataObj, setUserData ] = useState({});
  const [ticketDetails, setTicketDetails] = useState({rates:[],ratesUS:[], ratesInt:[], ratesExt:[]});
  
  useEffect(()=>{
    getTicketDetails(userHash).then(data => {
      const userData = data.data;
      const {ratesInIndia,ratesInUS, ratesExternal, ratesInternal, status} = userData ;
      const {requesterName,contactEmailId,contactPhoneNumber}  = userData ;
      setUserData({requesterName,contactEmailId,contactPhoneNumber})
      setTicketDetails({
        rates:ratesInIndia,
        ratesUS:ratesInUS,
        ratesExt: ratesExternal,
        ratesInt: ratesInternal,
        status: status
      })
    })
  },[])

  return (
    <>
    <div style={{textAlign:'center',background:'blue',height:'150px'}}>
      <div className="container">
          <div className="row" style={{textAlign:'left'}}>
              <div className="col">
                <img
                  src="/Images/panls-logo-white.png"
                  alt="IPannel Logo"
                  style={{width: "200px"}}
                />
              </div>
          </div>
      <br/>
      <div className="row">
          <div className="col">
          <h1 style={{color:'whitesmoke',fontWeight:'bold'}}>Panel Agreement</h1>
          </div>
          </div>
      </div>
      </div>
      <>
      <br/>
      <div className="container">
          <div className="row">
              <div className="col"><h3>Panel Details</h3></div>
          </div>
          <div className="row">
              <div className="col"> <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={userDataObj?.requesterName}
                        name="name"
                        disabled={true}
                        value={userDataObj?.requesterName}
                        style={{
                          height: "50px",
                          background: "#FFFFFF 0% 0% no-repeat padding-box",
                          border: "1px solid #C7C7C7",
                          borderRadius: "6px",
                          opacity: "1",
                          textTransform: "uppercase"
                        }}
                      /></div>
                      <div className="col">
                      <label htmlFor="emailId">Email ID</label>
                      <input
                        type="text"
                        disabled={true}
                        value={userDataObj?.contactEmailId}
                        className="form-control"
                        placeholder="Enter Email"
                        name="emailId"
                        style={{
                          height: "50px",
                          background: "#FFFFFF 0% 0% no-repeat padding-box",
                          border: "1px solid #C7C7C7",
                          borderRadius: "6px",
                          opacity: "1",
                          textTransform: "uppercase"
                        }}
                      />
                      </div>
                    <div className="col">
                      <label htmlFor="mobileNo">Mobile No</label>
                      <input
                      value={userDataObj?.contactPhoneNumber}
                        type="text"
                        disabled={true}
                        className="form-control"
                        placeholder="Enter Mobile No"
                        name="mobileNo"
                        style={{
                          height: "50px",
                          background: "#FFFFFF 0% 0% no-repeat padding-box",
                          border: "1px solid #C7C7C7",
                          borderRadius: "6px",
                          opacity: "1",
                        }}
                      />
                    </div>
          </div>
          <br/>
      </div>
      </>
      <br/>
      <>
        <Grid item xs={12}>
          <Box className="container" sx={{border: "1px solid #cecece",backgroundColor: "#FFFFFF",padding: "20px",boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)"}} >
            <h4>Interview Rate Card (India)</h4>
            <div>
              <Box sx={{ display: "flex", alignItems: "center", padding: "10px 0", margin: "10px"}} >
                <Checkbox  style={{'display':'none', marginRight: "10px"}}/>
                <label style={{ marginRight: "10px", flex: 1 }}> {" "}
                  <b>Category </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Completed </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Cancelled </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Candidate No Show</b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Panel No Show</b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Panel Technical Glitch </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Rescheduled </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Candidate Technical Glitch </b>
                </label>
              </Box>
            </div>
            {Object.keys(ticketDetails.rates).map((rate) => (
              <div>
                <Box sx={{ display: !ticketDetails.rates[rate].displayInAggreement?"none":"flex", alignItems: "center", border: "1px solid #cecece",padding: "10px 0", margin: "10px", }} >
                
                  <Checkbox disabled={true} checked={ticketDetails.rates[rate].displayInAggreement}
                    />
                  <label style={{ marginRight: "10px", flex: 1 }}>
                    {rate.toUpperCase()}
                  </label>
                  {Object.keys(ticketDetails.rates[rate]).map((key) => {
                    if (key !== 'displayInAggreement' && key !== 'otherCharges' && key !== 'technicalGlitch')
                    return (
                      <TextField placeholder={key}  
                        disabled={true}
                        value={ticketDetails.rates[rate][key]}
                        variant="outlined"
                        style={{ marginRight: "10px", flex: 1 }}
                      /> 
                    )
                  })}
                </Box>
              </div>
            ))}
          </Box>
        </Grid>
        <br></br>
        <Grid item xs={12}>
          <Box className="container" sx={{border: "1px solid #cecece",backgroundColor: "#FFFFFF",padding: "20px",boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)"}} >
            <h4>Interview Rate Card (US)</h4>
            <div>
              <Box sx={{ display: "flex", alignItems: "center", padding: "10px 0", margin: "10px"}} >
  
                <label style={{ marginRight: "10px", flex: 1 }}> {" "}
                  <b>Category </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Completed </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Cancelled </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Candidate No Show</b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Panel No Show</b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Panel Technical Glitch </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Rescheduled </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Candidate Technical Glitch </b>
                </label>
              </Box>
            </div>
            {Object.keys(ticketDetails.ratesUS).map((rate) => (
              <div>
                <Box sx={{  display: !ticketDetails.ratesUS[rate].displayInAggreement ? "none":"flex", alignItems: "center", border: "1px solid #cecece",padding: "10px 0", margin: "10px", }} >
                  <Checkbox  disabled={true} checked={ticketDetails.ratesUS[rate].displayInAggreement} />
                  <label style={{ marginRight: "10px", flex: 1 }}> {rate.toUpperCase()}</label>
                  {Object.keys(ticketDetails.ratesUS[rate]).map((key) => {
                    if (key !== 'displayInAggreement' && key !== 'otherCharges' && key !== 'technicalGlitch') {
                      return (
                        <TextField placeholder={key} 
                          disabled={true}
                          value={ticketDetails.ratesUS[rate][key]}
                          variant="outlined"
                          style={{ marginRight: "10px", flex: 1 }}
                        /> 
                      )
                    }
                  })}
                </Box>
              </div>
            ))}
          </Box>
          
        </Grid>
        
        <br></br>
        <Grid item xs={12}>
          <Box className="container" sx={{border: "1px solid #cecece",backgroundColor: "#FFFFFF",padding: "20px",boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)"}} >
            <h4>Interview Rate Card (Internal)</h4>
            <div>
              <Box sx={{ display: "flex", alignItems: "center", padding: "10px 0", margin: "10px"}} >
  
                <label style={{ marginRight: "10px", flex: 1 }}> {" "}
                  <b>Category </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Completed </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Cancelled </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Candidate No Show</b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Panel No Show</b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Panel Technical Glitch </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Rescheduled </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Candidate Technical Glitch </b>
                </label>
              </Box>
            </div>
            {Object.keys(ticketDetails.ratesInt).map((rate) => (
              <div>
                <Box sx={{  display: !ticketDetails.ratesInt[rate].displayInAggreement ? "none":"flex", alignItems: "center", border: "1px solid #cecece",padding: "10px 0", margin: "10px", }} >
                  <Checkbox  disabled={true} checked={ticketDetails.ratesInt[rate].displayInAggreement} />
                  <label style={{ marginRight: "10px", flex: 1 }}> {rate.toUpperCase()}</label>
                  {Object.keys(ticketDetails.ratesInt[rate]).map((key) => {
                    if (key !== 'displayInAggreement' && key !== 'otherCharges' && key !== 'technicalGlitch') {
                      return (
                        <TextField placeholder={key} 
                          disabled={true}
                          value={ticketDetails.ratesInt[rate][key]}
                          variant="outlined"
                          style={{ marginRight: "10px", flex: 1 }}
                        /> 
                      )
                    }
                  })}
                </Box>
              </div>
            ))}
          </Box>
        </Grid>
  
        <br></br>
        <Grid item xs={12}>
          <Box className="container" sx={{border: "1px solid #cecece",backgroundColor: "#FFFFFF",padding: "20px",boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)"}} >
            <h4>Interview Rate Card (External)</h4>
            <div>
              <Box sx={{ display: "flex", alignItems: "center", padding: "10px 0", margin: "10px"}} >
  
                <label style={{ marginRight: "10px", flex: 1 }}> {" "}
                  <b>Category </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Completed </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Cancelled </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Candidate No Show</b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Panel No Show</b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Panel Technical Glitch </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Rescheduled </b>
                </label>
                <label style={{ marginRight: "10px", flex: 1 }}>
                  <b>Candidate Technical Glitch </b>
                </label>
              </Box>
            </div>
            {Object.keys(ticketDetails.ratesExt).map((rate) => (
              <div>
                <Box sx={{  display: !ticketDetails.ratesExt[rate].displayInAggreement ? "none":"flex", alignItems: "center", border: "1px solid #cecece",padding: "10px 0", margin: "10px", }} >
                  <Checkbox  disabled={true} checked={ticketDetails.ratesExt[rate].displayInAggreement} />
                  <label style={{ marginRight: "10px", flex: 1 }}> {rate.toUpperCase()}</label>
                  {Object.keys(ticketDetails.ratesExt[rate]).map((key) => {
                    if (key !== 'displayInAggreement' && key !== 'otherCharges' && key !== 'technicalGlitch') {
                      return (
                        <TextField placeholder={key} 
                          disabled={true}
                          value={ticketDetails.ratesExt[rate][key]}
                          variant="outlined"
                          style={{ marginRight: "10px", flex: 1 }}
                        /> 
                      )
                    }
                  })}
                </Box>
              </div>
            ))}
          </Box>
        </Grid>
  
  
      <br></br>
      {/* <div className="container" style={{border:'1px solid black'}}>
        <br/>
        <div className="row">
          <div className="col" style={{textAlign:'left',marginLeft:'5%'}}>
              <h3>User Agreement</h3>
          </div>
          <div className="col" style={{textAlign:'right',marginRight:'5%'}}>
          <i className="fa fa-light fa-arrow-down-to-bracket" style={{color: "#023792"}}>download</i>
          </div>
        </div>
        <br/>
        <div className="row" style={{background:'skyblue'}}>
          <div className="col" style={{ width: '100%', height: '600px',margin:'3%' }}>
          <embed src={PDF} width="100%" height="100%" type="application/pdf" />
          </div>
        </div>
      </div> */}
      <br/>
      <div className="container">
        <div className="row">
          <div className="col">
          <Checkbox checked={accepted} onClick={()=>{setAccepted(!accepted)}}></Checkbox>
          <label className="form-check-label"  for="flexCheckDefault">
              Agree to Terms and Conditions
          </label>
          </div> 
        </div>
        <br/>
        <div className="row">
          <div className="col" style={{textAlign:"center"}}>
            <button type="button" className="btn btn-secondary" >
              Close
            </button>&nbsp;
            <button
              type="button"
              className="btn btn-primary"
              onClick={()=>{
                console.log("accepted", accepted)
                if (!accepted) {
                  toast.error('Please accept the Terms & Conditions.!');
                  return;
                }
                let obj = {
                  'status': 'OFFER_ACCEPTED'
                }
                putTicketDetails(userHash, obj).then(res =>{
                  toast.success('You have accepted the Offer.!');
                })
                .catch((err)=>{
                  toast.error(err);
                })
              }}
              style={{
                display: ticketDetails.status == 'SEND_OFFER' ? 'inline' : 'none'
              }}
            >
              Accept
            </button>
          </div>
        </div>
        <br/>
        </div>
      </>
    </>
  )
}

