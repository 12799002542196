import React, { useRef, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import SideNav from "./SideNav";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getUserById,
  getCountries,
  getStatesByCountryCode,
  getCitiesByStateCode,
  getRoles,
  updateUser,
  createUser,
  getCustomers,
  getCustomerCompanies
} from "../service/api";

import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import toastNotifications from "./toastNotifications/toastMessages";

const countryStateCityRef = {};


const AddProfile = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');
  
  function validateUserData(data){
    let result = {isValid:true,}
    let required_fields = ['firstName', 'profileType', 'emailId', 'roleId', 'country'];
    required_fields.forEach(key=>{
      if(!data[key]){
        result =  {isValid:false,mgs:`Field Missing ${key}`}
      }
    })
    return result;
  }
  const location = useLocation();
  const formRef = useRef(null);
  const [roles, setRoles] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [customers, setCustomers] = useState([]);

  const [userData, setUserData] = useState({
    ticketId: 0,
    customerId: 0,
    customerCompany: '',
    profileType: "",
    firstName: "",
    lastName: "",
    aliasName: "",
    emailId: "",
    primaryMobile: "",
    roleId: "",
    gender: "",
    alternateEmail: "",
    alternatePhoneNumber: "",
    country: "",
    state: "",
    city: "",
    zip: "",
  });

  const navigate = useNavigate();
  const citiesByState = {};

  useEffect(() => {
    getCountries().then((res) => {
      setCountries(res.data);
    });
    getRoles().then((res) => {
      setRoles(res.data);
    });
    getCustomerCompanies().then((res) => {
      const customers = (res.data).map( c => {
        return {'customerId': c.userId, 'customerCompany': c.companyName}
      });
      setCustomers(customers)
    })
  }, []);

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setUserData((prevUserData) => ({
      ...prevUserData,
      country: selectedCountry,
      state: "",
      city: "",
    }));
    getStatesByCountryCode(selectedCountry).then((res) => {
      setStates(res.data);
    });
  };


  const handleGenderChange = (event) => {
    const gender = event.target.value;

    setUserData((prevUserData) => ({
      ...prevUserData,
      gender: gender,
    }));
  };


  if (hasAllPermissions || userPermissions.includes('CREATE_USER')) {

    return (
      <>
        <Box sx={{ display: "flex" }}>
          <Box
            component="main"
            style={{ backgroundColor: "#F4F6FB" }}
            sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
          >
            <form ref={formRef}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <label htmlFor="profileType">User Type *</label>
                  <select
                    type="text"
                    disabled={false}
                    className="form-control"
                    value={userData.profileType}
                    name="profileType"
                    onChange={(event) =>
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        profileType: event.target.value,
                      }))
                    }
                    style={{
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: "1px solid #C7C7C7",
                      borderRadius: "6px",
                      opacity: "1",
                    }}
                  >
                    <option value="">Select User Type</option>
                    <option value="ADMIN">ADMIN</option>
                    <option value="CUSTOMER_ADMIN">CUSTOMER_ADMIN</option>
                    <option value="VENDOR">VENDOR</option>
                  </select>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label htmlFor="role">Role *</label>
                  <select
                    type="text"
                    className="form-control"
                    placeholder="Enter Role"
                    name="role"
                    value={userData.roleId}
                    onChange={(event) =>
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        roleId: event.target.value,
                      }))
                    }
                    style={{
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: "1px solid #C7C7C7",
                      borderRadius: "6px",
                      opacity: "1",
                    }}
                  >
                    <option value="">Select Role Type</option>
                    {roles.map((role) => {
                      return (
                        <option key={role.roleId} value={role.roleId}>
                          {role.roleName}
                        </option>
                      );
                    })}
                  </select>
                </Grid>
                <Grid item xs={12} sm={4} style={{ display: ["CUSTOMER_ADMIN","VENDOR"].includes(userData.profileType) ? 'inline': 'none'}}>
                  <label htmlFor="userId">Customer Company *</label>
                  <select
                    type="text"
                    className="form-control"
                    placeholder="Enter Role"
                    name="customerId"
                    value={userData.customerId}
                    onChange={(event) =>
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        customerId: event.target.value,
                      }))
                    }
                    style={{
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: "1px solid #C7C7C7",
                      borderRadius: "6px",
                      opacity: "1",
                    }}
                  >
                    <option value="">Select Customer Company</option>
                    {customers.map((customer) => {
                      return (
                        <option key={customer.customerId} value={customer.customerId}>
                          {customer.customerCompany}
                        </option>
                      );
                    })}
                  </select>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label htmlFor="firstName">First Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter First Name"
                    name="firstName"
                    defaultValue={userData.firstName}
                    onChange={(event) =>
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        firstName: event.target.value,
                      }))
                    }
                    style={{
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: "1px solid #C7C7C7",
                      borderRadius: "6px",
                      opacity: "1",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Last Name"
                    name="lastName"
                    defaultValue={userData.lastName}
                    onChange={(event) =>
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        lastName: event.target.value,
                      }))
                    }
                    style={{
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: "1px solid #C7C7C7",
                      borderRadius: "6px",
                      opacity: "1",
                    }}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={4}>
                  <label htmlFor="aliasName">Alias Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Alias Name"
                    name="aliasName"
                    defaultValue={userData.aliasName}
                    onChange={(event) =>
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        aliasName: event.target.value,
                      }))
                    }
                    style={{
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: "1px solid #C7C7C7",
                      borderRadius: "6px",
                      opacity: "1",
                    }}
                  />
                </Grid> */}
                <Grid item xs={12} sm={4}>
                  <label htmlFor="emailId">Email ID *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Email ID"
                    name="emailId"
                    defaultValue={userData.emailId}
                    onChange={(event) =>
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        emailId: event.target.value,
                      }))
                    }
                    style={{
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: "1px solid #C7C7C7",
                      borderRadius: "6px",
                      opacity: "1",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label htmlFor="alternateEmail">Alternate Email ID</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Alternate Email ID"
                    name="alternateEmail"
                    defaultValue={userData.alternateEmail}
                    onChange={(event) =>
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        alternateEmail: event.target.value,
                      }))
                    }
                    style={{
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: "1px solid #C7C7C7",
                      borderRadius: "6px",
                      opacity: "1",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label htmlFor="primaryMobile">Primary Mobile</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Primary Mobile"
                    name="primaryMobile"
                    defaultValue={userData.primaryMobile}
                    onChange={(event) =>
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        primaryMobile: event.target.value,
                      }))
                    }
                    style={{
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: "1px solid #C7C7C7",
                      borderRadius: "6px",
                      opacity: "1",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label htmlFor="country">Country *</label>
                  <select
                    type="text"
                    className="form-control"
                    value={userData.country}
                    name="country"
                    onChange={handleCountryChange}
                    style={{
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: "1px solid #C7C7C7",
                      borderRadius: "6px",
                      opacity: "1",
                    }}
                  >
                    <option value="">Select Country</option>
                    {countries.map((country) => (
                      <option
                        key={country.countryCode}
                        value={country.countryCode}
                      >
                        {country.countryName}
                      </option>
                    ))}
                  </select>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label htmlFor="gender">Gender</label>
                  <br></br>
                  <label className="form-check-label">
                    <input
                      className="form-check-input radio-inline"
                      onChange={handleGenderChange}
                      type="radio"
                      name="gender"
                      value="male"
                      checked={userData.gender === "male"}
                    />
                    Male
                  </label>

                  <label className="form-check-label">
                    <input
                      className="form-check-input radio-inline"
                      onChange={handleGenderChange}
                      type="radio"
                      name="gender"
                      value="female"
                      checked={userData.gender === "female"}
                    />
                    Female
                  </label>
                </Grid>
              </Grid>
              <br />
              <div className="row justify-content-center">
                <button
                  className="btn btn-outline-primary me-2"
                  style={{
                    border: "1px solid #3E67B1",
                    borderRadius: " 4px",
                    opacity: "1",
                    width: "96px",
                    height: "50px",
                  }}
                  onClick={() => { navigate(`/admin/users`); }}
                >
                  BACK
                </button>
                <button
                  onClick={() => {
                    const fd = new FormData(formRef.current);
                    const formDataJson = {};
                    Array.from(fd.entries()).reduce((a, b) => {
                      a[b[0]] = b[1];
                      return a;
                    }, formDataJson);
                    let finalObj = {...userData, ...formDataJson}
                    const validation = validateUserData(finalObj);
                    if(!validation.isValid){
                      toast.error(validation.mgs)
                      return;
                    }
                    createUser(finalObj).then((res) => {
                      toast.success(toastNotifications.addUser.updateSuccess);
                      navigate('/admin/users');
                    }).catch(err=>{
                      toast.error(toastNotifications.addUser.updateFail);
                    });
                  }}
                  type="button"
                  className="btn btn-primary"
                  style={{
                    border: "1px solid #3E67B1",
                    borderRadius: " 4px",
                    opacity: "1",
                    width: "150px",
                    height: "50px",
                  }}
                >
                  Create User
                </button>
              </div>
            </form>
          </Box>
        </Box>
      </>
    );
  
  } else {
    return (
      <>
        <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
          <div className="card">
            <div className="card-body text-center">
              <h2 className="card-title">
                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                Access Denied
              </h2>
              <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }

};

export default AddProfile;
