import React, { useRef, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import { createDeliveryUnit, getCustomerCompanies } from "../../service/api";

import { toast } from "react-toastify";
import { Grid } from "@mui/material";

const AddDeliveryUnit = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');

  function validateDeliveryUnitData(data){
    let result = {isValid:true,}
    let keyLabels = {
      'deliveryUnit': "Delivery Unit",
      'accountName': "Account Name",
      'lineOfBusiness': "Line Of Business",
      'vertical': "Vertical",
      'customerId': "Customer Company",
    };

    let required_fields = [
      'deliveryUnit',
      'accountName',
      'lineOfBusiness',
      'vertical',
      'customerId',
    ];
    required_fields.forEach(key=>{
      if(!data[key]){
        result =  {isValid:false,mgs:`Field Missing ${keyLabels[key]}`}
      }
    })
    return result;
  }
  const formRef = useRef(null);

  const [customers, setCustomers] = useState([]);
  const [delUnitData, setDeliveryUnitData] = useState({
    customerId: user.customerId || null,
    vertical: "",
    lineOfBusiness: "",
    accountName: "",
    deliveryUnit: "",
    remarks: ""
  });

  const navigate = useNavigate();

  useEffect(() => {
    getCustomerCompanies().then((res) => {
      const customers = (res.data).map( c => {
        return {'customerId': c.userId, 'customerCompany': c.companyName}
      });
      setCustomers(customers)
    });
  }, []);

  if (hasAllPermissions || userPermissions.includes('CREATE_DELIVERY_UNIT')) {

    return (
      <>
        <Box sx={{ display: "flex" }}>
          <Box
            component="main"
            style={{ backgroundColor: "#F4F6FB" }}
            sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
          >
            <form ref={formRef}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <label htmlFor="customerId">Customer Company *</label>
                  <select
                    type="text"
                    className="form-control"
                    placeholder="Enter Role"
                    name="customerId"
                    value={delUnitData.customerId}
                    disabled={!['SUPER','ADMIN'].includes(user.profileType)}
                    style={{
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: "1px solid #C7C7C7",
                      borderRadius: "6px",
                      opacity: "1",
                    }}
                  >
                    <option value="">Select Customer Company</option>
                    {customers.map((customer) => {
                      return (
                        <option key={customer.customerId} value={customer.customerId}>
                          {customer.customerCompany}
                        </option>
                      );
                    })}
                  </select>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label htmlFor="vertical">Vertical *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Vertical"
                    name="vertical"
                    defaultValue={delUnitData.vertical}
                    onChange={(event) =>
                      setDeliveryUnitData((prevDeliveryUnitData) => ({
                        ...prevDeliveryUnitData,
                        vertical: event.target.value,
                      }))
                    }
                    style={{
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: "1px solid #C7C7C7",
                      borderRadius: "6px",
                      opacity: "1",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label htmlFor="lineOfBusiness">Line Of Business *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Line Of Business"
                    name="lineOfBusiness"
                    defaultValue={delUnitData.lineOfBusiness}
                    onChange={(event) =>
                      setDeliveryUnitData((prevDeliveryUnitData) => ({
                        ...prevDeliveryUnitData,
                        lineOfBusiness: event.target.value,
                      }))
                    }
                    style={{
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: "1px solid #C7C7C7",
                      borderRadius: "6px",
                      opacity: "1",
                    }}
                  />
                </Grid>

              {/*</Grid>*/}
              {/*<Grid container spacing={2} style={{marginTop: "10px"}}>*/}
                <Grid item xs={12} sm={6}>
                  <label htmlFor="accountName">Account Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Account Name"
                    name="accountName"
                    defaultValue={delUnitData.accountName}
                    onChange={(event) =>
                      setDeliveryUnitData((prevDeliveryUnitData) => ({
                        ...prevDeliveryUnitData,
                        accountName: event.target.value,
                      }))
                    }
                    style={{
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: "1px solid #C7C7C7",
                      borderRadius: "6px",
                      opacity: "1",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label htmlFor="deliveryUnit">Delivery Unit *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Delivery Unit Name"
                    name="deliveryUnit"
                    defaultValue={delUnitData.deliveryUnit}
                    onChange={(event) =>
                      setDeliveryUnitData((prevDeliveryUnitData) => ({
                        ...prevDeliveryUnitData,
                        deliveryUnit: event.target.value,
                      }))
                    }
                    style={{
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: "1px solid #C7C7C7",
                      borderRadius: "6px",
                      opacity: "1",
                    }}
                  />
                </Grid>
              </Grid>
              <br />
              <div className="row justify-content-center">
                <button
                  className="btn btn-outline-primary me-2"
                  style={{
                    border: "1px solid #3E67B1",
                    borderRadius: " 4px",
                    opacity: "1",
                    width: "96px",
                    height: "50px",
                  }}
                  onClick={() => { navigate('/customer/deliveryUnits'); }}
                >
                  BACK
                </button>
                <button
                  onClick={() => {
                    const fd = new FormData(formRef.current);
                    const formDataJson = {};
                    Array.from(fd.entries()).reduce((a, b) => {
                      a[b[0]] = b[1];
                      return a;
                    }, formDataJson);
                    let finalObj = {...delUnitData, ...formDataJson}
                    const validation = validateDeliveryUnitData(finalObj);
                    if(!validation.isValid){
                      toast.error(validation.mgs)
                      return;
                    }
                    createDeliveryUnit(finalObj).then((res) => {
                      toast.success('Delivery Unit Created Successfully.!');
                      navigate('/customer/deliveryUnits');
                    }).catch(err=>{
                      toast.error(err.response.data.message);
                    });
                  }}
                  type="button"
                  className="btn btn-primary"
                  style={{
                    border: "1px solid #3E67B1",
                    borderRadius: " 4px",
                    opacity: "1",
                    width: "200px",
                    height: "50px",
                    display: (hasAllPermissions || userPermissions.includes('CREATE_DELIVERY_UNIT')) ? 'inline' : 'none'
                  }}
                >
                  CREATE DELIVERY UNIT
                </button>
              </div>
            </form>
          </Box>
        </Box>
      </>
    );

  } else {
    return (
      <>
        <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
          <div className="card">
            <div className="card-body text-center">
              <h2 className="card-title">
                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                Access Denied
              </h2>
              <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
};

export default AddDeliveryUnit;
