import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { getCustomerContactByEmail } from '../../service/api';

const CustomerDetails = ({contactDetails}) => {

  const [name, setName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companySize, setCompanySize] = useState('');
  const [companyUrl, setCompanyUrl] = useState('');
  const [country, setCountry] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [emailId, setEmailId] = useState('');
  const [description, setDescription] = useState('');
  
  if(contactDetails.contactType == 'CUSTOMER' && contactDetails.contactEmailId) {
    getCustomerContactByEmail(contactDetails.contactEmailId).then((res) => {
      if (res.data) {
        setName(res.data.name);
        setCountry(res.data.country);
        setMobileNumber(res.data.phoneNumber);
        setCompanyName(res.data.companyName);
        setEmailId(res.data.emailId);
        setCompanySize(res.data.companySize);
        setCompanyUrl(res.data.companyUrl);
        setDescription(res.data.description);
      }
    });
  }

  return (
    <Box
      className="container"
      sx={{
        border: "1px solid #cecece",
        backgroundColor: "#FFFFFF",
        padding: "20px",
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
        display: 'flex',
        gap: '20px'
      }}
    >
    <Box sx={{ flex: 2, display: 'flex', flexDirection: 'row', gap: '20px'  }} >
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <Typography variant="body1"><b>Name</b></Typography>
        <Typography variant="body2">{name}</Typography>

        
        <Typography variant="body1"><b>Company URL</b></Typography>
        <Typography variant="body2">{companyUrl}</Typography>

        <Typography variant="body1"><b>Mobile Number</b></Typography>
        <Typography variant="body2">{mobileNumber}</Typography>

        
      </Box>
      
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <Typography variant="body1"><b>Company Name</b></Typography>
        <Typography variant="body2">{companyName}</Typography>

        <Typography variant="body1"><b>Company Size</b></Typography>
        <Typography variant="body2">{companySize}</Typography>

        <Typography variant="body1"><b>Country</b></Typography>
        <Typography variant="body2">{country}</Typography>

      </Box>
    </Box>

    </Box>
  );
};

export default CustomerDetails;
