import React, { useEffect, useState } from "react";
// import * as moment from 'moment'
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import { updatePanelSlots, getUserSlots } from "../../service/api";
import { toast } from "react-toastify";


function printTimes() {
  const time_list = []
  let time = moment().startOf('day');
  do {
    time_list.push(time.format('HH:mm'));
    time.add(30, 'minutes');
  } while (time.diff(moment().endOf('day'), 'minutes') <= 0);
  time_list.pop()
  return time_list;
}

export default function SlotAvailability() {
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');

  const emailId = user.emailId;
  const [startDate, setStartDate] = useState(new Date());
  const [selectedSlots, setSelectedSlots] = useState({})

  useEffect(()=>{
    getUserSlots().then(slotsData => {
      let slotsObj = {};
      for(let i=0; i< slotsData?.slots.length; i++){
        let slotDate = slotsData.slots[i]['slotDate'];
        let slotTimes = slotsData.slots[i]['slotTimes'];
        slotsObj[slotDate] = slotTimes;
      }
      setSelectedSlots(slotsObj)
    })
  },[])


  const getSlotsForSelectedDate = {};
  for (let date in selectedSlots) {
    getSlotsForSelectedDate[date] = {};
    selectedSlots[date].forEach(time => {
      getSlotsForSelectedDate[date][time] = true;
    });
  }

  const addOrRemoveSlots = (slotTime)=>{
    const selectedDate = moment(startDate).format("YYYY-MM-DD");
    if(!selectedSlots[selectedDate]){
      selectedSlots[selectedDate]=[slotTime]
    } else{
      if (!selectedSlots[selectedDate].includes(slotTime)) {
        selectedSlots[selectedDate].push(slotTime)
      } else {
        let index = selectedSlots[selectedDate].indexOf(slotTime);
        selectedSlots[selectedDate].splice(index, 1);
      }
    }
    setSelectedSlots((prevData)=>({
      ...prevData,
      [selectedDate]: selectedSlots[selectedDate]
    }))
  }

  if (hasAllPermissions || userPermissions.includes('VIEW_SLOT_AVAILABILITY')) {
    return (
      <>


<div  className="container-page">
         <div
            className="main-content"
            style={{  background: "#fff", padding: "20px" }}
          >
            <div className="row">
          <div className="col-6" style={{marginLeft:"20px"}}>
            <label style={{ paddingRight: "10px"}}>
              <h6> Select Slot Date:</h6>
            </label>
                <DatePicker
                  className="form-control"
                  style={{
                    height: "50px",
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    border: "1px solid #C7C7C7",
                    borderRadius: "6px",
                    opacity: "1",
                  }}
                  selected={startDate} 
                  minDate={moment().toDate()}
                  maxDate={moment().add(15, 'days').toDate()}
                  onChange={(date) => setStartDate(new Date(date))} 
                  highlightDates={[
                    {
                      highlighted: Object.keys(selectedSlots).map(d=>new Date(d)).filter(e => new Date(e) >= new Date())
                    }
                  ]} 
                />
            </div>
           <div className="col d-flex justify-content-end align-items-end"
           style={{paddingRight:"35px"}}
           >
             <p>
               Current Timezone: {moment.tz.guess()}
             </p>
           </div>
           </div>

          


          <div className="row">
            <div className="row">
                <div className="display-slot-time" style={{marginLeft:"20px"}}>
                  {printTimes().map((slotTime, index) => (
                    <div className="slot-value" key={index}>
                      <button
                        className="btn btn-light"
                        onClick={()=>{
                          addOrRemoveSlots(slotTime)
                        }}
                        style={{
                          backgroundColor: getSlotsForSelectedDate[moment(startDate).format("YYYY-MM-DD")]?.[slotTime]
                            ? "green"
                            : "",
                          color: getSlotsForSelectedDate[moment(startDate).format("YYYY-MM-DD")]?.[slotTime] ? "white" : "black",
                          border: "1px solid gray",
                        }}
                      >
                        {slotTime}
                      </button>
                    </div>
                  ))}
                </div>
                </div>
            </div>


            <button
              className="btn btn-primary"
              style={{
                width:"132px",
                height:"50px",
                background: "#3E67B1 0% 0% no-repeat padding-box",
                borderRadius: "4px",
                opacity: "1",
                marginLeft: "552px",

                display: (hasAllPermissions || userPermissions.includes('VIEW_SLOT_AVAILABILITY')) ? 'inline' : 'none'
              }}
              onClick={async () => {
                let panelSlotPayload = {
                  'emailId': emailId,
                  'slots': Object.keys(selectedSlots).map(e => {
                    return {
                      'slotDate': e,
                      'slotTimes': selectedSlots[e]
                    }
                  }),
                  'timezone': moment.tz.guess()
                };
                updatePanelSlots(panelSlotPayload).then((res) => {
                  toast.success('Slots Updated..!')
                }).catch(err=>{
                  toast.error('Unable to update the slots. Please try again.!');
                });
              }}
              >UPDATE SLOTS</button>

          </div>

</div>
      </>

    );
  } else {
    return (
      <>
        <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
          <div className="card">
            <div className="card-body text-center">
              <h2 className="card-title">
                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                Access Denied
              </h2>
              <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
};
