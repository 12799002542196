import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { getPanelContactByEmail, getPanelDocumentById } from '../../service/api';

const RequesterDetails = ({contactDetails}) => {

  const [name, setName] = useState('');
  const [country, setCountry] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [emailId, setEmailId] = useState('');
  const [primarySkills, setPrimarySkills] = useState('');
  const [secondarySkills, setSecondarySkills] = useState('');
  const [resumeDocId, setResumeDocId] = useState('');
  
  if(contactDetails.contactType == 'PANELIST' && contactDetails.contactEmailId) {
    getPanelContactByEmail(contactDetails.contactEmailId).then((res) => {
      if (res.data) {
        setName(res.data.name);
        setCountry(res.data.country);
        setMobileNumber(res.data.phoneNumber);
        setCompanyName(res.data.companyName);
        setEmailId(res.data.emailId);
        setPrimarySkills(res.data.primarySkills);
        setSecondarySkills(res.data.secondarySkills);
        setResumeDocId(res.data.resumeDocId);
      }
    });
  }

  return (
    <Box
      className="container"
      sx={{
        border: "1px solid #cecece",
        backgroundColor: "#FFFFFF",
        padding: "20px",
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
        display: 'flex',
        gap: '20px'
      }}
    >
    <Box sx={{ flex: 2, display: 'flex', flexDirection: 'row', gap: '20px'  }} >
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <Typography variant="body1"><b>Name</b></Typography>
        <Typography variant="body2">{name}</Typography>

        <Typography variant="body1"><b>Country</b></Typography>
        <Typography variant="body2">{country}</Typography>

        <Typography variant="body1"><b>Mobile Number</b></Typography>
        <Typography variant="body2">{mobileNumber}</Typography>

        <Typography variant="body1"><b>Company Name</b></Typography>
        <Typography variant="body2">{companyName}</Typography>
        </Box>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <Typography variant="body1"><b>Email ID</b></Typography>
        <Typography variant="body2">{emailId}</Typography>

        <Typography variant="body1"><b>Primary Skills</b></Typography>
        <Typography variant="body2">{primarySkills}</Typography>

        <Typography variant="body1"><b>Secondary Skills</b></Typography>
        <Typography variant="body2">{secondarySkills}</Typography>

        <Typography variant="body1"><b>Resume</b></Typography>
        <Typography variant="body2">
          <i className="fa-solid fa-download">
            {/* <Button 
              onClick={async () => {
              let data = await getPanelDocumentById(resumeDocId);
              const a = document.createElement('a');
              a.href = data.resumeBase64;
              a.download = resumeDocId;
              a.click();
              }}>
              {name.replace(/ /g,'').toUpperCase()}.pdf
            </Button> */}
            <Button 
              onClick={async () => {
                let data = await getPanelDocumentById(resumeDocId);
                const blobUrl = URL.createObjectURL(data);
                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = resumeDocId;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(blobUrl);
              }}>
              {resumeDocId}
            </Button>
          </i>
        </Typography>
        </Box>
      </Box>

    </Box>
  );
};

export default RequesterDetails;
