import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { TextField, InputAdornment, Button, Switch, CircularProgress } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { getAssignedUsers, getCustomerRequests } from '../../service/api';
import { toast } from "react-toastify";

const CustomerTickets = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');

  const navigate = useNavigate();
  const [customerRequestsData, setCustomerRequestsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    setDataLoading(true);
    getAssignedUsers().then((res) => {
      const userDict = res?.data.reduce((acc, { userId, firstName, lastName }) => {
        acc[userId] = `${firstName} ${lastName}`;
        return acc;
      }, {});
      getCustomerRequests().then((data) => {
        for (let i=0; i< data.length; i++) {
          data[i]['assigneeFullName'] = userDict[data[i]['assigneeId']]
        }
        setCustomerRequestsData(data.reverse());
        setFilteredData(data);
        setDataLoading(false);
      });
    });
  }, []);

  useEffect(() => {
    setFilteredData(
      customerRequestsData.filter((cusReq) =>
        Object.values(cusReq).some(
          (value) => {
            if(value) {
              return value.toString().toLowerCase().includes(search.toLowerCase())
            } else {
              return false;
            }
          }
        )
      )
    );
  }, [search, customerRequestsData]);

  const customerRequestsColumns = [
    {
      dataField: 'name',
      text: 'User Name',
      sort: true,
      style: {
        whiteSpace: "nowrap",
    },
      headerStyle: () => {
        return {width: "auto", whiteSpace: "nowrap"};
    },
    },
    {
      dataField: 'profileType',
      text: 'Profile Type',
      sort: true,
    },
    {
      dataField: 'emailId',
      text: 'Email ID',
      sort: true,
    },
    {
      dataField: 'primaryMobile',
      text: 'Mobile Number',
      sort: true,
      headerStyle: () => {
        return {width: "auto", whiteSpace: "nowrap"};
    },
    },
    {
      dataField: 'companyName',
      text: 'Company Name',
      sort: true,
    },
    {
      dataField: 'vendorCompanyName',
      text: 'Vendor Company',
      sort: true,
      headerStyle: () => {
        return {width: "auto", whiteSpace: "nowrap"};
      },
    },
    // {
    //   dataField: 'assigneeName',
    //   text: 'Assignee',
    //   sort: true,
    // },
    {
      dataField: 'assigneeFullName',
      text: 'Assignee',
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: (cell, row) => (
        <i title="View Candidate"
          onClick={() => {
            if ((hasAllPermissions || userPermissions.includes('EDIT_CUSTOMER_TICKET'))) {
              navigate(`/admin/editCustomerTicket/${row.cusReqId}`);
            }
          }}
          style={{
            opacity: !(hasAllPermissions || userPermissions.includes('EDIT_CUSTOMER_TICKET')) ? 0.2 : 1,
            pointerEvents: !(hasAllPermissions || userPermissions.includes('EDIT_CUSTOMER_TICKET')) ? 'none' : 'auto',
          }}
          className="fa fa-light fa-pen-to-square"
        ></i>
      ),
    },
  ];

  const options = {
    sizePerPage: 5,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '50', value: 50 },
    ],
    hideSizePerPage: false,
    hidePageListOnlyOnePage: false,
    alwaysShowAllBtns: true,
    showTotal: true,
    nextPageText: "Next",
    prePageText: "Prev",
  };

  if (hasAllPermissions || userPermissions.includes('LIST_CUSTOMER_TICKETS')) {

    return (
      <div  className="container-page">
        <div
            className="main-content"
            style={{  background: "#fff", padding: "20px" }}
          >
          <TextField
            placeholder="Search"
            size="small"
            style={{
              background: "#FCFCFC 0% 0% no-repeat padding-box",
              boxShadow: " 0px 3px 6px #3E67B133",
              borderRadius: "4px",
              opacity: "1",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <br />
          <div className="custom-table-container1">
          <BootstrapTable
            hover
            bootstrap4
            keyField="id"
            data={filteredData}
            columns={customerRequestsColumns}
            pagination={paginationFactory(options)}
            filter={filterFactory()}
            noDataIndication={
              dataLoading ?
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                <CircularProgress />
              </Box>
              :
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                <span>No Data Found</span>
              </Box>
            }
          />
          </div>
        </div>
      </div>
    );

  } else {
    return (
      <>
        <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
          <div className="card">
            <div className="card-body text-center">
              <h2 className="card-title">
                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                Access Denied
              </h2>
              <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
};

export default CustomerTickets;
