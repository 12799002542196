import React, { useRef, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {  Tab, Tabs } from "react-bootstrap";
import "./Candidate.css";
import {
    getJobs, getCandidates, getCustomerDeliveryUnits,
    getCountries, getStates, getCities,
    getSkillFamilies, getAllSkills, getSkillsBySkillFamily,
    getCustomers, getVendors, getDeliveryUnits,
    postCandidate, uploadCandidates, uploadCandidateDocument,
    uploadResumesToServer, getJob,
} from '../../service/api';
import { toast } from "react-toastify";
import Papa from 'papaparse';
import moment from "moment";
import CreatableSelect from 'react-select/creatable';

const timeOptions = Array.from({ length: 48 }, (_, index) => {
    const hour = Math.floor(index / 2).toString().padStart(2, '0');
    const minute = (index % 2 === 0) ? '00' : '30';
    return { value: `${hour}:${minute}`, label: `${hour}:${minute}` };
});

export default function AddCandidate2() {
    const user = JSON.parse(localStorage.getItem('user'));
    const userPermissions = user.permissions.map(e => e.permissionName);
    const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');
    const [lpChecklistTabKey, setLPChecklistTabKey] = useState('home')
    const changeLPChecklistTab = (key) => {
        if (key !== lpChecklistTabKey) {
            setLPChecklistTabKey(key)
        }
    }
    const MAX_RESUME_FILE_SIZE = 5 * 100 * 1024 ; // 500 KB
    const MAX_CSV_FILE_SIZE = 1 * 1024 * 1024; // 1MB
    const MAX_RESUMES_ZIP_FILE_SIZE = 50 * 1024 * 1024; // 1MB
    const [selectedForm, setSelectedForm] = useState("form1");
    const [jobs, setJobs] = useState([]);
    const [countries, setCountries] = useState([]);
    // const [states, setStates] = useState([]);
    // const [cities, setCities] = useState([]);
    const [skillFamilies, setSkillFamilies] = useState([]);
    const [customersData, setCustomers] = useState([]);
    const navigate = useNavigate();
    // const [candidatesData, setCandidatesData] = useState([]);
    // const [skillFamilyData, setSkillFamiliesData] = useState([]);
    // const [skillFamilyDict, setSkillFamiliesDict] = useState({});
    // const [filteredCandidatesData, setFilteredCandidates] = useState([]);
    // const [dataLoading, setDataLoading] = useState();
    // const [search, setSearch] = useState("");
    const [candidateResume, setCandidateResume] = useState(null);
    const [candidateResumeName, setCandidateResumeName] = useState('Choose a file');
    const [bulkCSV, setBulkCSV] = useState(null);
    const [resumesZip, setResumesZip] = useState(null);
    const [bulkJobId, setBulkJobId] = useState(0);
    const [resumesJobId, setResumesJobId] = useState(0);
    const [skills, setSkills] = useState([]);
    const formRef = useRef(null);
    const formRef2 = useRef(null);
    const [csvData, setCSVData] = useState([]);
    const [error, setError] = useState(null);


    const delay = (milliseconds) => new Promise(resolve => setTimeout(resolve, milliseconds));
    const today = new Date().toISOString().split('T')[0];

    function validateCandidateData(data){
        let result = {isValid:true,}
        let keyLabels = {
            'candidateResumeId': 'Candidate Resume',
            'relevantExperience': 'Relevant Experience',
            'totalExperience' : 'Total Experience',
            'isCurrentlyEmployed' : 'Is Currently Employed',
            'highestQualification': 'Highest Qualification',
            'timezone2' : 'Timezone 2',
            'slotTime2' : 'SlotTime 2',
            'slotDate2' : 'SlotDate 2',
            'timezone1' : 'Timezone 1',
            'slotTime1' : 'SlotTime 1',
            'slotDate1' : 'SlotDate 1',
            'contactNumber' : 'Contact Number',
            'primarySkills' : 'Primary Skills',
            'currentCountry': 'Current Country',
            'candidateEmailId' : 'Candidate Email ID',
            'candidateName' : 'Candidate Name',
            'jobId' : 'Job Name',
            'customerId': 'Customer Name',
        }
        let required_fields = [
            'candidateResumeId',
            // 'relevantExperience',
            // 'totalExperience',
            // 'isCurrentlyEmployed',
            // 'highestQualification',
            // 'timezone2',
            // 'slotTime2',
            // 'slotDate2',
            'timezone1',
            'slotTime1',
            'slotDate1',

            'primarySkills',
            'currentCountry',
            'candidateEmailId',
            'candidateName',
            'jobId',
            'customerId',
        ];
        required_fields.forEach(key=>{
            if (key == 'primarySkills') {
                if(data[key] && data[key].length == 0) {
                    result =  {isValid:false,mgs:`Field Missing '${keyLabels[key]}'`}
                }
            } else {
                if(!data[key]){
                    result =  {isValid:false,mgs:`Field Missing '${keyLabels[key]}'`}
                }
            }
        })
        if (data.slotDate1 != "" || data.slotDate2 != "") {
            if (data.slotDate1 == data.slotDate2 && data.slotTime1 == data.slotTime2) {
                result =  {isValid:false,mgs:`Slot1 and Slot2 cannot be the same'`}
                result.isValid = false;
            }
        }
        return result;
    }

    function validateBulkCandidateData(data) {
        let result = { isValid: true, errors: [] };
        let reqFields = [
            'customerId', 'jobId', 'candidateName', 'candidateEmailId', 'contactNumber',
            'highestQualification', 'primarySkills',
            'isCurrentlyEmployed', 'totalExperience', 'relevantExperience',
            'currentCompany', 'currentCountry',
            'slotDate1', 'slotTime1', 'timezone1',
            'slotDate2', 'slotTime2', 'timezone2',
        ];

        let intFields = ['customerId', 'jobId'];
        let dateFields = ['slotDate1', 'slotDate2'];
        let timeFields = ['slotTime1', 'slotTime2'];
        let timezoneFields = ['timezone1', 'timezone2'];

        let customerId = user.customerId;
        let invalidCustomerId = 0;

        for (let i = 0; i < data.length; i++) {
            if (data[i]['customerId'] !== customerId ) {
                invalidCustomerId = data[i]['customerId']
            }

            reqFields.forEach(key => {
                if (!data[i][key]) {
                    result.isValid = false;
                    result.errors.push(`Field Missing: ${key}`);
                }
            });

            intFields.forEach(key => {
                try {
                    let value = parseInt(data[i][key]);
                    if (data[i][key] && !Number.isInteger(value)) {
                        result.isValid = false;
                        result.errors.push(`Invalid Integer value: ${key}: ${value}`);
                    }
                } catch (error) {
                    console.log(`Unable to parse the key value: ${data[i][key]}`)
                }

            });

            dateFields.forEach(key => {
                if (data[i][key] && !moment(data[i][key], 'YYYY-MM-DD', true).isValid()) {
                    result.isValid = false;
                    result.errors.push(`Invalid Slot Date: ${key}:${data[i][key]}, Expected in YYYY-MM-DD format`);
                }
            });

            timeFields.forEach(key => {
                if (data[i][key] && !moment(data[i][key], 'HH:mm', true).isValid()) {
                    result.isValid = false;
                    result.errors.push(`Invalid Slot Time: ${key}:${data[i][key]}, Expected in HH:mm 24 hours format`);
                }
            });

            timezoneFields.forEach(key => {
                if (!data[i][key] || typeof data[i][key] !== 'string') {
                    result.isValid = false;
                    result.errors.push(`Invalid Timezone: ${key}:${data[i][key]}`);
                }
            });

            if (invalidCustomerId !== 0 || !['SUPER', 'ADMIN'].includes(user.profileType)) {
                result.isValid = false;
                result.errors.push(`UnAuthorized to upload candidates against customerId: ${invalidCustomerId}`);
            }
        }
        return result;
    }
    const location = useLocation();
    const jobData = location.state ? location.state.job : null;    const [candidateObj, setCandidateData] = useState(
        jobData?{
                jobName: jobData?.jobName,
                customerId: jobData?.customerId,
                custCoordinatorId: jobData?.custCoordinatorId,
                jobId: jobData?.jobId,
                custCoordinatorName: jobData?.custCoordinatorName,
                custCoordinatorEmailId: jobData?.custCoordinatorEmailId,
                customerCompany: jobData?.customerCompany,
                candidateName: '',
                candidateEmailId: '',
                candidateResumeId: '',
                contactNumber: '',
                alternateContanctNumber: '',
                highestQualification: '',
                course: '',
                institute: '',
                isCurrentlyEmployed: '',
                currentCompany: '',
                currentRole: '',
                currentCountry: 'IND',
                currentState: '',
                currentCity: '',
                totalExperience: '',
                primarySkills: jobData?.primarySkills.split(','), //['Python', 'Shell', 'Unix'],
                secondarySkills: jobData?.secondarySkills.split(','), //['NodeJS', 'BigData']
                skillFamily: jobData?.skillFamily,
                socialProfile: '',
                slotDate1: '',
                slotTime1: '',
                timezone1: 'Asia/Kolkata(UTC+5:30)',
                slotDate2: '',
                slotTime2: '',
                timezone2: 'Asia/Kolkata(UTC+5:30)',
                dynamicSkills: [],
                standardSkills: [],
                documents: [],
                overallFeedback: '',
                recommendations: '',
                remarks: '',
            }:
        {
            jobName: '',
        customerId: 0,
        custCoordinatorId: 0,
        jobId: 0,
        custCoordinatorName: '',
        custCoordinatorEmailId: '',
        customerCompany: '',
        candidateName: '',
        candidateEmailId: '',
        candidateResumeId: '',
        contactNumber: '',
        alternateContanctNumber: '',
        highestQualification: '',
        course: '',
        institute: '',
        isCurrentlyEmployed: '',
        currentCompany: '',
        currentRole: '',
        currentCountry: 'IND',
        currentState: '',
        currentCity: '',
        totalExperience: '',
        primarySkills: [],
        secondarySkills: [],
        skillFamily: '',
        socialProfile: '',
        slotDate1: '',
        slotTime1: '',
        timezone1: 'Asia/Kolkata(UTC+5:30)',
        slotDate2: '',
        slotTime2: '',
        timezone2: 'Asia/Kolkata(UTC+5:30)',
        dynamicSkills: [],
        standardSkills: [],
        documents: [],
        overallFeedback: '',
        recommendations: '',
        remarks: '',
    });

    const [templateData, setTemplateData] = useState([
        {
            "customerId": 6,
            "candidateName": "Candidate Name",
            "candidateEmailId": "candidate@gmail.com",
            "contactNumber": '1234567890',
            "currentCountry": "IND",
            "highestQualification": "B.TECH",
            "isCurrentlyEmployed": "Yes",
            "currentCompany": "Some Company",
            "totalExperience": "10 Years 2 Months",
            "relevantExperience": "7 years 4 Months",
            "primarySkills": "Python|Shell|Unix",
            "secondarySkills": "NodeJS|BigData",
            "socialProfile": "",
            "slotDate1": "2023-11-11",
            "slotTime1": "10:00",
            "timezone1": 'Asia/Kolkata(UTC+5:30)',
            "slotDate2": "2023-11-13",
            "slotTime2": "18:00",
            "timezone2": 'Asia/Kolkata(UTC+5:30)',
            "remarks": "Created By CSV File",
            "resumeExt": "pdf"
        }
    ]);

    const downloadCandidatesTemplate = () => {
        const csv = Papa.unparse(templateData, {
            columns: {
                slotDate1: value => ({value: value.toString(), type: 'string'}),
                slotDate2: value => ({value: value.toString(), type: 'string'}),
            }
        });
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'candidatesTemplate.csv';
        a.click();
        window.URL.revokeObjectURL(url);
    };

    useEffect(() => {
        getJobs().then((res) => {
            const jobs = (res.data).filter(e => e.status == 'APPROVED').map( job => {
                return {
                    'jobId': parseInt(job.jobId),
                    'jobName': job.jobName
                }
            });
            setJobs(jobs);
        });
        // getAllSkills().then((res) => {
        //   const skills = res?.data.map(e => ({'label': e.skill, 'value': e.skill}))
        //   setSkills(skills);
        // });

        getCountries().then((res) => {
            setCountries(res.data);
        });
        getSkillFamilies().then((res) => {
            setSkillFamilies(res.data);
        });
        getCustomers().then((res) => {
            const customers = (res.data).filter( e => e.profileType === 'CUSTOMER').map( cust => {
                return {
                    'customerId': parseInt(cust.userId),
                    'companyName': cust.companyName
                }
            });
            setCustomers(customers);
        });

    }, []);

    const updateSkillsData = async (skillFamily) => {
        if (skillFamily != '') {
            getSkillsBySkillFamily(skillFamily).then((data) => {
                const skills = data?.data.map(e => ({'label': e.skill, 'value': e.skill}))
                setSkills(skills)
            });
        } else {
            setSkills([])
        }
    }

    const handleCandidateResumeChange = async (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile.size > MAX_RESUME_FILE_SIZE) {
            toast.error('File size exceeds the maximum allowed size of 100 KB.');
            setCandidateResume(null);
            setCandidateResumeName('Choose a file');
            candidateObj['candidateResumeId'] = "";
        } else {
            setCandidateResume(selectedFile);
            setCandidateResumeName(selectedFile ? selectedFile.name : 'Choose a file');
            const ext = selectedFile.name?.split('.').pop();
            candidateObj['candidateResumeId'] = `${candidateObj.jobId}_${candidateObj.candidateEmailId.replace(/[._@-]/g, '').toLowerCase()}.${ext}`;
        }
    }
    const handleCreateCandidate = async (candidateObj) => {
        try {
            candidateObj.primarySkills = candidateObj.primarySkills.join(',');
            candidateObj.secondarySkills = candidateObj.secondarySkills.join(',');
            const ext = candidateResume?.name?.split('.').pop();
            candidateObj['candidateResumeId'] = `${candidateObj.jobId}_${candidateObj.candidateEmailId.replace(/[._@-]/g, '').toLowerCase()}.${ext}`;

            const response = await postCandidate(candidateObj);
            const resumeFilename = response.data?.candidateResumeId;
            toast.success('Candidate has been created successfully.!');
            if (resumeFilename) {
                const formData = new FormData();
                formData.append('candidateDocument', candidateResume);
                await uploadCandidateDocument(resumeFilename, formData);
                toast.success('Candidate Resume has been uploaded.!');
                navigate('/user/candidates/all');
            } else {
                toast.error('Unable to upload Candidate Resume.!')
            }
        } catch (error) {
            console.error('Error while saving candidate details:', error);
            toast.error(error.response.data.message);
        }
    };
    const handleFormSelection = (event) => {
        setSelectedForm(event.target.value);
    };
    const handleResumesZipChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > MAX_RESUMES_ZIP_FILE_SIZE) {
                toast.error('File size exceeds the maximum allowed size of 50 MB.');
                setResumesZip(null);
            } else {
                setResumesZip(event.target.files[0]);
            }
        } else {
            setResumesZip(null);
        }
        event.target.value = null;
    };
    const handleBulkCSVChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > MAX_CSV_FILE_SIZE) {
                toast.error('File size exceeds the maximum allowed size of 1 MB.');
                setBulkCSV(null);
            } else {
                setBulkCSV(file);
                Papa.parse(file, {
                    header: true,
                    skipEmptyLines: true,
                    complete: (result) => {
                        console.log(result)
                        setCSVData(result.data);
                    },
                    error: (err) => {
                        console.error(err)
                        setError(err.message);
                    },
                });
            }
        } else {
            setBulkCSV(null);
        }
    };
    const handleUploadCandidate = async () => {
        try {
            await delay(2000);
            const candidatesArray = csvData.map(eachRecord => ({
                ...eachRecord,
                jobId: parseInt(bulkJobId),
                primarySkills: eachRecord['primarySkills'].replace(/\|/g, ','),
                secondarySkills: eachRecord['secondarySkills'].replace(/\|/g, ','),
                candidateResumeId: `${bulkJobId}_${eachRecord.candidateEmailId.replace(/[._@-]/g, '').toLowerCase()}.${eachRecord.resumeExt}`
            }));

            if(candidatesArray.length == 0) {
                toast.error('Invalid CSV File with 0 Records.!');
                return;
            }

            let isValidData = validateBulkCandidateData(candidatesArray);

            console.log(isValidData)

            if(isValidData.isValid) {
                const response = await uploadCandidates(candidatesArray);
                toast.success(response.message);
                setCSVData([]);
                setBulkCSV(null);
                setBulkJobId(0);
                navigate('/user/candidates/all');
            } else {
                for(let i=0; i < isValidData.errors.length; i++){
                    toast.error(`Error: ${isValidData.errors[i]}`)
                }
            }
        } catch (error) {
            console.error('Error uploading candidates data:', error);
            toast.error(error.response.data.message);
        }
    };

    const handleResumesUpload = async () => {
        try {
            await delay(2000);
            if (resumesZip) {
                const formData = new FormData();
                formData.append('resumesZip', resumesZip);
                formData.append('jobId', resumesJobId);
                let response = await uploadResumesToServer(formData);
                console.log(response)
                toast.success('Resumes has been uploaded, Upload Candidates now..!');
                setResumesZip(null);
                setResumesJobId(0);
            } else {
                toast.error('Unable to upload Resumes, Please try again.!')
            }

        } catch (error) {
            console.error('Error uploading candidates data:', error);
            toast.error(error.response.data.message);
        }
    };
    console.log('candidateObj:', candidateObj);
    if (hasAllPermissions || userPermissions.includes('CREATE_CANDIDATE')) {
        return (
            <>
                <div className="container-page">
                    <div className="main-content" style={{ }}>
                        <div className="row justify-content-center">
                            <div className="col-auto d-flex align-items-center">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        value='form1'
                                        checked={selectedForm === 'form1'}
                                        onChange={handleFormSelection}
                                    />
                                    {/* <label className="form-check-label" for="form1"> */}
                                    Create Candidate
                                    {/* </label> */}
                                </div>
                            </div>
                            <div className="col-auto d-flex align-items-center">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        value='form2'
                                        checked={selectedForm === 'form2'}
                                        onChange={handleFormSelection}
                                    />
                                    {/* <label className="form-check-label" for="form2"> */}
                                    Bulk Candidates Upload
                                    {/* </label> */}
                                </div>
                            </div>
                        </div>
                        <form ref={formRef} style={{ display: selectedForm == 'form1' ? 'inline': 'none' }}>
                            <Tabs
                                defaultActiveKey="home"
                                className="mb-3 mt-2"
                                style={{marginLeft: '20px'}}
                                onSelect={(e) => { if (e !== lpChecklistTabKey) { changeLPChecklistTab(e)}}}
                            >
                                <Tab eventKey="home" title="Candidate Details">
                                    <div className="candidate-details" style={{ marginTop: "20px", minHeight: "200px" }}>
                                        <div className="row">
                                            <div className="col container-heading"><h6>Candidate Details</h6></div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <label>
                                                    <h6>Customer Name *</h6>
                                                </label>
                                                <select
                                                    className="form-control-lg-8 drop-down"
                                                    style={{ width: "100%", height: "50%" }}
                                                    name="customerId"
                                                    value={candidateObj.customerId}
                                                    onChange={(event) => {
                                                        setCandidateData((prevCandidateData) => ({
                                                            ...prevCandidateData,
                                                            customerId: event.target.value
                                                        }));
                                                    }}
                                                >
                                                    <option value="">Select Customer</option>
                                                    {customersData.map((c) => (
                                                        <option
                                                            key={c.customerId}
                                                            value={c.customerId}
                                                        >
                                                            {c.companyName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col"></div>
                                            <div className="col"></div>
                                        </div>
                                        <br></br>
                                        <div className="row">
                                            {/*<div className="col">*/}
                                            {/*    <label><h6>Job Name *</h6></label>*/}
                                            {/*    <select*/}
                                            {/*        className="form-control-lg-8 drop-down"*/}
                                            {/*        style={{ width: "100%", height: "50%" }}*/}
                                            {/*        name="jobId"*/}
                                            {/*        onChange={(event) => {*/}
                                            {/*            setCandidateData((prevCandidateData) => ({*/}
                                            {/*                ...prevCandidateData,*/}
                                            {/*                jobId: event.target.value*/}
                                            {/*            }));*/}
                                            {/*        }}*/}
                                            {/*        value={candidateObj.jobId}*/}
                                            {/*    >*/}
                                            {/*        <option value="">Select Job</option>*/}
                                            {/*        {jobs.map((job) => (*/}
                                            {/*            <option*/}
                                            {/*                key={job.jobId}*/}
                                            {/*                value={job.jobId}*/}
                                            {/*            >*/}
                                            {/*                {job.jobId} - {job.jobName}*/}
                                            {/*            </option>*/}
                                            {/*        ))}*/}
                                            {/*    </select>*/}
                                            {/*</div>*/}
                                            <div className="col-4">
                                                <label><h6>Job ID *</h6></label>
                                                <select
                                                    className="form-control-lg-8 drop-down"
                                                    style={{width: "100%", height: "50%"}}
                                                    name="jobId"
                                                    onChange={(event) => {
                                                        setCandidateData((prevCandidateData) => ({
                                                            ...prevCandidateData,
                                                            jobId: event.target.value
                                                        }));
                                                    }}
                                                    value={candidateObj.jobId}
                                                >
                                                    <option value={candidateObj.jobId}>{candidateObj.jobId?candidateObj.jobId:"Select Job ID"}</option>
                                                    {jobs.map((job) => (
                                                        <option
                                                            key={job.jobId}
                                                            value={job.jobId}
                                                        >
                                                            {job.jobId}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-4">
                                                <label><h6>Job Name *</h6></label>
                                                <select
                                                    className="form-control-lg-8 drop-down"
                                                    style={{width: "100%", height: "50%"}}
                                                    name="jobName"
                                                    onChange={(event) => {
                                                        setCandidateData((prevCandidateData) => ({
                                                            ...prevCandidateData,
                                                            jobName: event.target.value
                                                        }));
                                                    }}
                                                    value={candidateObj.jobName}
                                                >
                                                    <option value={candidateObj.jobName}>{candidateObj.jobName?candidateObj.jobName:"Select job Name"}</option>
                                                    {jobs.map((job) => (
                                                        <option
                                                            key={job.jobId}
                                                            value={job.jobName}
                                                        >
                                                            {job.jobName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <label><h6>Candidate Name *</h6></label>
                                                <input className="form-control editable"
                                                       placeholder="Enter Candidate name" name="candidateName"
                                                       onChange={(event) => {
                                                           setCandidateData((prevCandidateData) => ({
                                                               ...prevCandidateData,
                                                               candidateName: event.target.value,
                                                           }));
                                                       }}
                                                       value={candidateObj.candidateName}/>
                                            </div>
                                            <div className="col-4">
                                                <label><h6>Candidate Email ID *</h6></label>
                                                <input className="form-control editable"
                                                       placeholder="Enter Candidate EmailId" name="candidateEmailId"
                                                       onChange={(event) => {
                                                           setCandidateData((prevCandidateData) => ({
                                                               ...prevCandidateData,
                                                               candidateEmailId: event.target.value,
                                                           }));
                                                       }}
                                                       value={candidateObj.candidateEmailId}/>
                                            </div>
                                        </div>

                                        <br></br>

                                        <div className="row">
                                            <div className="col">
                                                <label><h6>Skill Family *</h6></label>
                                                <select
                                                    className="form-control-lg-8 drop-down"
                                                    style={{width: "100%", height: "50%"}}
                                                    name="skillFamily"
                                                    onChange={(event) => {
                                                        setCandidateData((prevCandidateData) => ({
                                                            ...prevCandidateData,
                                                            skillFamily: event.target.value,
                                                        }));
                                                        updateSkillsData(event.target.value);
                                                    }}
                                                    value={candidateObj.skillFamily}
                                                >
                                                    <option value="">Select Skill Family</option>
                                                    {skillFamilies.map((sf) => (
                                                        <option
                                                            key={sf.skillFamily}
                                                            value={sf.skillFamily}
                                                        >
                                                            {sf.skillFamily}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col">
                                                <label><h6>Primary Skills *</h6></label>
                                                <CreatableSelect
                                                    isMulti
                                                    value={candidateObj.primarySkills != '' ? candidateObj.primarySkills.map(skill => ({ value: skill, label: skill })) : null}
                                                    onChange={(selectedOptions) => {
                                                        if (!Array.isArray(selectedOptions)) {
                                                            return;
                                                        }
                                                        const selectedSkills = selectedOptions.map((option) => option.value);
                                                        setCandidateData((prevCandidateData) => ({
                                                            ...prevCandidateData,
                                                            primarySkills: selectedSkills,
                                                        }));
                                                    }}
                                                    onCreateOption={(inputValue) => {
                                                        setCandidateData((prevCandidateData) => ({
                                                            ...prevCandidateData,
                                                            primarySkills: prevCandidateData.primarySkills.concat(inputValue),
                                                        }));
                                                    }}
                                                    options={skills}
                                                />
                                            </div>

                                            <div className="col">
                                                <label><h6>Secondary Skills</h6></label>
                                                <CreatableSelect
                                                    isMulti
                                                    value={candidateObj.secondarySkills != '' ? candidateObj.secondarySkills.map(skill => ({ value: skill, label: skill })) : null}
                                                    onChange={(selectedOptions) => {
                                                        if (!Array.isArray(selectedOptions)) {
                                                            return;
                                                        }
                                                        const selectedSkills = selectedOptions.map((option) => option.value);
                                                        setCandidateData((prevJobData) => ({
                                                            ...prevJobData,
                                                            secondarySkills: selectedSkills,
                                                        }));
                                                    }}
                                                    onCreateOption={(inputValue) => {
                                                        setCandidateData((prevJobData) => ({
                                                            ...prevJobData,
                                                            secondarySkills: prevJobData.secondarySkills.concat(inputValue),
                                                        }));
                                                    }}
                                                    options={skills}
                                                />
                                            </div>
                                        </div>

                                        <br></br>

                                        <div className="row">
                                            <div className="col">
                                                <label><h6>Country *</h6></label>
                                                <select
                                                    className="form-control-lg-8 drop-down"
                                                    style={{ width: "100%", height: "50%" }}
                                                    name="currentCountry"
                                                    value={candidateObj.currentCountry}
                                                    onChange={(event) => {
                                                        setCandidateData((prevCandidateData) => ({
                                                            ...prevCandidateData,
                                                            currentCountry: event.target.value,
                                                        }));
                                                    }}
                                                >
                                                    <option value="">Select Country</option>
                                                    {countries.map((country) => (
                                                        <option
                                                            key={country.countryCode}
                                                            value={country.countryCode}
                                                        >
                                                            {country.countryCode}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col">
                                                <label><h6>Contact Number</h6></label>
                                                <input className="form-control editable"
                                                       placeholder="Enter Contact Number"
                                                       name="contactNumber"
                                                       onChange={(event) => {
                                                           setCandidateData((prevCandidateData) => ({
                                                               ...prevCandidateData,
                                                               contactNumber: event.target.value,
                                                           }));
                                                       }}
                                                       value={candidateObj.contactNumber} />
                                            </div>
                                            <div className="col">
                                                <label><h6>Alternate Contact Number</h6></label>
                                                <input className="form-control editable"
                                                       placeholder="Enter Alternate Number" name="alternateContanctNumber"
                                                       onChange={(event) => {
                                                           setCandidateData((prevCandidateData) => ({
                                                               ...prevCandidateData,
                                                               alternateContanctNumber: event.target.value,
                                                           }));
                                                       }}
                                                       value={candidateObj.alternateContanctNumber} />
                                            </div>

                                        </div>

                                    </div>

                                    <div className="candidate-details" style={{ marginTop: "20px", minHeight:"200px" }}>
                                        <div className="row">
                                            <div className="col container-heading"><h6>Candidate Slots</h6></div>
                                        </div>
                                        <div className="availability-slot">
                                            <div className="row">
                                                <div className="col container-heading">Availability Slot 1</div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <label>
                                                        <h6>Date *</h6>
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="form-control editable "
                                                        placeholder="Select Date"
                                                        name="slotDate1"
                                                        onChange={(event) => {
                                                            if (event.target.value === candidateObj.slotDate2 && candidateObj.slotTime1 == candidateObj.slotTime2) {
                                                                toast.warning("Slot1 and Slot2 cannot be the same");
                                                            }
                                                            setCandidateData((prevCandidateData) => ({
                                                                ...prevCandidateData,
                                                                slotDate1 : event.target.value,
                                                            }));
                                                        }}
                                                        min={today}
                                                        value={candidateObj.slotDate1}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <label>
                                                        <h6>Time *</h6>
                                                    </label>
                                                    <select
                                                        className="form-control-lg-8 drop-down"
                                                        style={{ width: "100%", height: "50%" }}
                                                        name="slotTime1"
                                                        onChange={(event) => {
                                                            if (candidateObj.slotDate1 == candidateObj.slotDate2 && event.target.value == candidateObj.slotTime2) {
                                                                toast.warning("Slot1 and Slot2 cannot be the same");
                                                            }
                                                            setCandidateData((prevCandidateData) => ({
                                                                ...prevCandidateData,
                                                                slotTime1 : event.target.value,
                                                            }));
                                                        }}
                                                        value={candidateObj.slotTime1}
                                                    >
                                                        {timeOptions.map((option, index) => (
                                                            <option key={index} value={option.value}>{option.label}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="col">
                                                    <label>
                                                        <h6>Timezone *</h6>
                                                    </label>
                                                    <select
                                                        className="form-control-lg-8 drop-down"
                                                        style={{ width: "100%", height: "50%" }}
                                                        name="timezone1"
                                                        onChange={(event) => {
                                                            setCandidateData((prevCandidateData) => ({
                                                                ...prevCandidateData,
                                                                timezone1 : event.target.value,
                                                            }));
                                                        }}
                                                        value={candidateObj.timezone1}
                                                    >
                                                        <option value="America/New_York(UTC-5:00)">America/New_York(UTC-5:00)</option>
                                                        <option value="America/Los_Angeles(UTC-7:00)">America/Los_Angeles(UTC-7:00)</option>
                                                        <option value="Asia/Kolkata(UTC+5:30)">Asia/Kolkata(UTC+5:30)</option>
                                                        <option value="Asia/Singapore(UTC+8:00)">Asia/Singapore(UTC+8:00)</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="availability-slot">
                                            <div className="row">
                                                <div className="col container-heading">Availability Slot 2</div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <label>
                                                        <h6>Date </h6>
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="form-control editable "
                                                        placeholder="Select Date"
                                                        name="slotDate2"
                                                        onChange={(event) => {
                                                            if (candidateObj.slotDate1 == event.target.value && candidateObj.slotTime1 == candidateObj.slotTime2) {
                                                                toast.warning("Slot1 and Slot2 cannot be the same");
                                                            }
                                                            setCandidateData((prevCandidateData) => ({
                                                                ...prevCandidateData,
                                                                slotDate2 : event.target.value,
                                                            }));
                                                        }}
                                                        min={today}
                                                        value={candidateObj.slotDate2}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <label>
                                                        <h6>Time </h6>
                                                    </label>
                                                    <select
                                                        className="form-control-lg-8 drop-down"
                                                        style={{ width: "100%", height: "50%" }}
                                                        name="slotTime2"
                                                        onChange={(event) => {
                                                            if (candidateObj.slotDate1 == candidateObj.slotDate2 && candidateObj.slotTime2 == event.target.value) {
                                                                toast.warning("Slot1 and Slot2 cannot be the same");
                                                            }
                                                            setCandidateData((prevCandidateData) => ({
                                                                ...prevCandidateData,
                                                                slotTime2 : event.target.value,
                                                            }));
                                                        }}
                                                        value={candidateObj.slotTime2}
                                                    >
                                                        {timeOptions.map((option, index) => (
                                                            <option key={index} value={option.value}>{option.label}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="col">
                                                    <label>
                                                        <h6>Timezone </h6>
                                                    </label>
                                                    <select
                                                        className="form-control-lg-8 drop-down"
                                                        style={{ width: "100%", height: "50%" }}
                                                        name="timezone2"
                                                        onChange={(event) => {
                                                            setCandidateData((prevCandidateData) => ({
                                                                ...prevCandidateData,
                                                                timezone2 : event.target.value,
                                                            }));
                                                        }}
                                                        value={candidateObj.timezone2}
                                                    >
                                                        <option value="America/New_York(UTC-5:00)">America/New_York(UTC-5:00)</option>
                                                        <option value="America/Los_Angeles(UTC-7:00)">America/Los_Angeles(UTC-7:00)</option>
                                                        <option value="Asia/Kolkata(UTC+5:30)">Asia/Kolkata(UTC+5:30)</option>
                                                        <option value="Asia/Singapore(UTC+8:00)">Asia/Singapore(UTC+8:00)</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    <div className="candidate-details" style={{ marginTop: "20px", minHeight:"100px" }}>
                                        <div className="row">
                                            <div className="col container-heading"><h6>Education Details</h6></div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <label><h6>Highest Qualification </h6></label>
                                                <input className="form-control editable"
                                                       placeholder="Ex: B.Tech"
                                                       onChange={(event) => {
                                                           setCandidateData((prevCandidateData) => ({
                                                               ...prevCandidateData,
                                                               highestQualification: event.target.value,
                                                           }));
                                                       }}
                                                       name="highestQualification"
                                                       value={candidateObj.highestQualification}/>
                                            </div>
                                            <div className="col">
                                                <label><h6>Course </h6></label>
                                                <input className="form-control editable"
                                                       placeholder="Ex: Computers Engineering"
                                                       onChange={(event) => {
                                                           setCandidateData((prevCandidateData) => ({
                                                               ...prevCandidateData,
                                                               course: event.target.value,
                                                           }));
                                                       }}
                                                       name="course"
                                                       value={candidateObj.course}/>
                                            </div>
                                            <div className="col">
                                                <label><h6>Institute </h6></label>
                                                <input className="form-control editable"
                                                       placeholder="Enter Institute Name"
                                                       onChange={(event) => {
                                                           setCandidateData((prevCandidateData) => ({
                                                               ...prevCandidateData,
                                                               institute: event.target.value,
                                                           }));
                                                       }}
                                                       name="institute"
                                                       value={candidateObj.institute}/>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="candidate-details" style={{ marginTop: "20px", minHeight:"240px" }}>
                                        <div className="row">
                                            <div className="col container-heading"><h6>Employment Details</h6></div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <label><h6>Are you currently Employed? </h6></label>
                                                <select
                                                    className="form-control-lg-8 drop-down"
                                                    style={{ width: "100%", height: "50%" }}
                                                    name="isCurrentlyEmployed"
                                                    value={candidateObj.isCurrentlyEmployed}
                                                    onChange={(event) => {
                                                        setCandidateData((prevCandidateData) => ({
                                                            ...prevCandidateData,
                                                            isCurrentlyEmployed: event.target.value,
                                                        }));
                                                    }}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </div>
                                            <div className="col">
                                                <label><h6>Total Experience </h6></label>
                                                <input className="form-control editable"
                                                       placeholder="Ex: 5 years 2 Months"
                                                       onChange={(event) => {
                                                           setCandidateData((prevCandidateData) => ({
                                                               ...prevCandidateData,
                                                               totalExperience: event.target.value,
                                                           }));
                                                       }}
                                                       name="totalExperience"
                                                       value={candidateObj.totalExperience}/>
                                            </div>
                                            <div className="col">
                                                <label><h6>Relevant Experience </h6></label>
                                                <input className="form-control editable"
                                                       placeholder="Ex: 5 years 2 Months"
                                                       onChange={(event) => {
                                                           setCandidateData((prevCandidateData) => ({
                                                               ...prevCandidateData,
                                                               relevantExperience: event.target.value,
                                                           }));
                                                       }}
                                                       name="relevantExperience"
                                                       value={candidateObj.relevantExperience}/>
                                            </div>
                                        </div>

                                        <br></br>

                                        <div className="row">
                                            <div className="col">
                                                <label><h6>Current Company</h6></label>
                                                <input className="form-control editable"
                                                       placeholder="Enter Current Company"
                                                       onChange={(event) => {
                                                           setCandidateData((prevCandidateData) => ({
                                                               ...prevCandidateData,
                                                               currentCompany: event.target.value,
                                                           }));
                                                       }}
                                                       name="currentCompany"
                                                       value={candidateObj.currentCompany}/>
                                            </div>
                                            <div className="col">
                                                <label><h6>Current Role/Designation</h6></label>
                                                <input className="form-control editable"
                                                       placeholder="Enter Current Role"
                                                       onChange={(event) => {
                                                           setCandidateData((prevCandidateData) => ({
                                                               ...prevCandidateData,
                                                               currentRole: event.target.value,
                                                           }));
                                                       }}
                                                       name="currentRole"
                                                       value={candidateObj.currentRole}/>
                                            </div>
                                            <div className="col">
                                            </div>
                                        </div>
                                    </div>

                                    <div className="candidate-details" style={{ marginTop: "20px", minHeight:"100px" }}>
                                        <div className="row">
                                            <div className="col container-heading"><h6>Social Profile Link</h6></div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                {/* <label><h6>URL </h6></label> */}
                                                <input className="form-control editable"
                                                       placeholder="Ex: http://linkedin.in/your-name"
                                                       onChange={(event) => {
                                                           setCandidateData((prevCandidateData) => ({
                                                               ...prevCandidateData,
                                                               socialProfile: event.target.value,
                                                           }));
                                                       }}
                                                       name="socialProfile"
                                                       value={candidateObj.socialProfile}/>
                                            </div>
                                            <div className="col">

                                            </div>
                                        </div>

                                    </div>

                                    <div className="comments-remarks" style={{ margin: "20px", padding: "20px" }} >
                                        <div className="row">
                                            <div className="col">
                                                <label>
                                                    <h6>Upload Candidate Resume *</h6>
                                                </label>
                                                <label
                                                    for="candidateResume"
                                                    style={{
                                                        height: "50px",
                                                        width: "500px",
                                                        background: "#EEF4FF 0% 0% no-repeat padding-box",
                                                        borderRadius: "4px",
                                                        opacity: 1,
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                        <span
                            style={{
                                width: "500px",
                                height: "50px",
                                /* UI Properties */
                                background: "#EEF4FF 0% 0% no-repeat padding-box",
                                borderRadius: "4px",
                                opacity: 1,
                                display: "flex",
                                alignItems: "center",
                                alignContent: "center",
                            }}
                        >
                          <img src="/Images/pdfIcon.svg" style={{paddingLeft: '20px'}} />
                          <span style={{paddingLeft: '10px'}}> {"  "} </span>
                          <span style={{ color: "#3E67B1", paddingLeft: '10px', paddingRight: '10px' }}> Browse </span>
                          <span style={{marginLeft: '5%'}}> {candidateResumeName}</span>

                        </span>
                                                    <input
                                                        style={{ display: "none" }}
                                                        id="candidateResume"
                                                        type="file"
                                                        name="candidateResume"
                                                        accept=".pdf,.docx"
                                                        onChange={handleCandidateResumeChange}
                                                    />

                                                </label>
                                                <i>
                                                    Only PDF/Docx Files Supported with Size lessthan 500KB
                                                </i>
                                            </div>
                                            <div className="col">
                                            </div>
                                        </div>
                                    </div>

                                    <div className="comments-remarks" style={{ margin: "20px", padding: "20px" }} >
                                        <div className="row">
                                            <label><h6>Remarks</h6></label>
                                            <div className="col-10">
                        <textarea
                            rows="3"
                            className="form-control editable "
                            placeholder="Enter Remarks"
                            name="remarks"
                            onChange={(event) => {
                                setCandidateData((prevCandidateData) => ({
                                    ...prevCandidateData,
                                    remarks: event.target.value,
                                }));
                            }}
                            value={candidateObj.remarks}
                        />
                                            </div>
                                        </div>
                                        <div className="row" style={{ marginTop: '20px', textAlign: 'center', paddingBottom: '5px' }}>
                                            <div className="col" style={{ textAlign: 'center' }}>
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-primary me-2"
                                                    style={{
                                                        border: '1px solid #3E67B1',
                                                        borderRadius: ' 4px',
                                                        opacity: '1',
                                                        width: '96px',
                                                        height: '50px',
                                                    }}
                                                    onClick={()=>{
                                                        navigate(-1);
                                                    }}
                                                >
                                                    BACK
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        const validation = validateCandidateData(candidateObj);
                                                        if(!validation.isValid){
                                                            toast.error(validation.mgs)
                                                            return;
                                                        }
                                                        handleCreateCandidate(candidateObj);
                                                    }}
                                                    type="button"
                                                    className="btn btn-primary"
                                                    style={{
                                                        border: '1px solid #3E67B1',
                                                        borderRadius: ' 4px',
                                                        opacity: '1',
                                                        width: '96px',
                                                        height: '50px',
                                                        display: (hasAllPermissions || userPermissions.includes('CREATE_CANDIDATE')) ? 'inline' : 'none'
                                                    }}
                                                >
                                                    CREATE
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>

                            </Tabs>
                        </form>

                        {/* BulkForm */}
                        <form ref={formRef2} style={{ marginTop: '54px', display: selectedForm == 'form2' ? 'inline': 'none' }}>

                            <div className="candidate-details" style={{ marginTop: "20px" }}>

                                <div className="row">
                                    <div className="col">Please ensure Resume name should be candidate Email Address without special characters. Extension must be <b>.zip</b> only!
                                        <br></br>
                                        If Email Address is <b>Maichel.John@gmail.com</b> then resume name should be <b>maicheljohngmailcom.pdf</b></div>
                                </div>
                                <br></br>

                                <div className="row">
                                    <div className="col-6">
                                        <label>
                                            <h6>Upload Resumes Zip *</h6>
                                        </label>
                                        <label
                                            for="resumesZip"
                                            style={{
                                                height: "50px",
                                                width: "100%",
                                                background: "#EEF4FF 0% 0% no-repeat padding-box",
                                                borderRadius: "4px",
                                                opacity: 1,
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                    <span
                        style={{
                            width: "100%",
                            height: "50px",
                            background: "#EEF4FF 0% 0% no-repeat padding-box",
                            borderRadius: "4px",
                            opacity: 1,
                            display: "flex",
                            alignItems: "center",
                            alignContent: "center",
                        }}
                    >
                      <img src="/Images/pdfIcon.svg" style={{paddingLeft: '20px'}} />
                      <span style={{paddingLeft: '10px'}}> {"  "} </span>
                      <span style={{ color: "#3E67B1", paddingLeft: '10px', paddingRight: '10px' }}> Browse </span>
                      <span style={{marginLeft: '10px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',  maxWidth: '50%' }}> {resumesZip?.name}</span>

                    </span>
                                            <input
                                                style={{ display: "none" }}
                                                id="resumesZip"
                                                type="file"
                                                name="resumesZip"
                                                accept=".zip"
                                                onChange={handleResumesZipChange}
                                            />
                                        </label>
                                        <span> Size must be lessthan 5 MB </span>
                                    </div>
                                    <div className="col-3">
                                        <label>
                                            <h6>Job Name *</h6>
                                        </label>
                                        <select
                                            className="form-control-lg-8 drop-down"
                                            style={{ width: "100%", height: "50%" }}
                                            name="resumesJobId"
                                            onChange={(event) => {
                                                setResumesJobId(event.target.value);
                                            }}
                                            value={resumesJobId}
                                        >
                                            <option value="">Select Job</option>
                                            {jobs.map((job) => (
                                                <option
                                                    key={job.jobId}
                                                    value={job.jobId}
                                                >
                                                    {job.jobId} - {job.jobName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-3">
                                        <button
                                            onClick={() => {
                                                if (resumesJobId == 0) {
                                                    toast.error('Job Name is missing')
                                                    return;
                                                }
                                                if (!resumesZip) {
                                                    toast.error('Resumes Zip File is missing')
                                                    return;
                                                }
                                                console.log(resumesZip, resumesJobId)
                                                handleResumesUpload()
                                            }}
                                            type="button"
                                            className="btn btn-primary"
                                            style={{
                                                border: '1px solid #3E67B1',
                                                borderRadius: ' 4px',
                                                opacity: '1',
                                                width: '180px',
                                                height: '50px',
                                                marginTop: '30px',
                                                display: (hasAllPermissions || userPermissions.includes('CREATE_CANDIDATE')) ? 'inline' : 'none'
                                            }}
                                        >
                                            UPLOAD RESUMES
                                        </button>

                                    </div>
                                </div>

                                <br></br>
                                <br></br>
                                <div className="row">
                                    <div className="col"></div>
                                    <div className="col">
                                        <a href="#" onClick={downloadCandidatesTemplate}>Download Candidates Sample Template</a>
                                    </div>
                                </div>

                                <br></br>

                                <div className="row">
                                    <div className="col-6">
                                        <label>
                                            <h6>Upload Candidates CSV *</h6>
                                        </label>
                                        <label
                                            for="bulkUpload"
                                            style={{
                                                height: "50px",
                                                width: "100%",
                                                background: "#EEF4FF 0% 0% no-repeat padding-box",
                                                borderRadius: "4px",
                                                opacity: 1,
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                    <span
                        style={{
                            width: "100%",
                            height: "50px",
                            background: "#EEF4FF 0% 0% no-repeat padding-box",
                            borderRadius: "4px",
                            opacity: 1,
                            display: "flex",
                            alignItems: "center",
                            alignContent: "center",
                        }}
                    >
                      <img src="/Images/pdfIcon.svg" style={{paddingLeft: '20px'}} />
                      <span style={{paddingLeft: '10px'}}> {"  "} </span>
                      <span style={{ color: "#3E67B1", paddingLeft: '10px', paddingRight: '10px' }}> Browse </span>
                      <span style={{marginLeft: '10px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',  maxWidth: '50%' }}> {bulkCSV?.name}</span>

                    </span>
                                            <input
                                                style={{ display: "none" }}
                                                id="bulkUpload"
                                                type="file"
                                                name="bulkUpload"
                                                accept=".csv"
                                                onChange={handleBulkCSVChange}
                                            />
                                        </label>
                                        <span> Size must be lessthan 1 MB </span>
                                    </div>
                                    <div className="col-3">
                                        <label>
                                            <h6>Job Name *</h6>
                                        </label>
                                        <select
                                            className="form-control-lg-8 drop-down"
                                            style={{ width: "100%", height: "50%" }}
                                            name="jobId"
                                            onChange={(event) => {
                                                setBulkJobId(event.target.value);
                                            }}
                                            value={bulkJobId}
                                        >
                                            <option value="">Select Job *</option>
                                            {jobs.map((job) => (
                                                <option
                                                    key={job.jobId}
                                                    value={job.jobId}
                                                >
                                                    {job.jobId} - {job.jobName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-3">
                                        <button
                                            onClick={() => {
                                                if (bulkJobId == 0) {
                                                    toast.error('Job Name is missing')
                                                    return;
                                                }
                                                if (!bulkCSV) {
                                                    toast.error('CSV File is missing')
                                                    return;
                                                }
                                                console.log(bulkCSV, bulkJobId)
                                                handleUploadCandidate()
                                            }}
                                            type="button"
                                            className="btn btn-primary"
                                            style={{
                                                border: '1px solid #3E67B1',
                                                borderRadius: ' 4px',
                                                opacity: '1',
                                                width: '180px',
                                                height: '50px',
                                                marginTop: '30px',
                                                display: (hasAllPermissions || userPermissions.includes('CREATE_CANDIDATE')) ? 'inline' : 'none'
                                            }}
                                        >
                                            UPLOAD CANDIDATES
                                        </button>

                                    </div>
                                </div>



                                <br></br>



                            </div>

                        </form>

                    </div>

                </div>
            </>
        );

    } else {
        return (
            <>
                <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
                    <div className="card">
                        <div className="card-body text-center">
                            <h2 className="card-title">
                                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                                Access Denied
                            </h2>
                            <p className="card-text">You do not have the required permissions to access this page.</p>
                            <p className="card-text">Please contact your administrator for assistance.</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
