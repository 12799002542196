
const toastNotifications = {
    "addUser": {
      "updateSuccess": "User Created Successfully",
      "updateFail": "Failed To Update!"
    },
    "rolesModel": {
      "updateSuccess": "Role Update Successful",
      "updateFail": "Failed to Update Role"
    },
    "changePassword": {
      "updateSuccess": "Password Change Successful",
      "updateFail": "Failed to Change Password"
    },
    "contactForm": {
      "otpSuccess": "Successfully generated OTP",
      "ticketCreatedSuccess": "Ticket created Successful",
      "error": "Error occurred"
    },
    "updateTicket": {
      "fetchError": "Error fetching ticket data",
      "fetchUserError": "Error fetching users",
      "updateSuccess": "Ticket Update Successful"
    },
    "editProfile": {
      "updateSuccess": "User Updated Successfully",
      "updateFail": "User Updating Failed"
    },
    "editUser": {
      "updateSuccess": "User Updated Successfully",
      "updateFail": "Failed To Update!"
    },
    "groups": {
      "updateSuccess": "Update Group Successful",
      "updateFail": "Failed To Update Group"
    },
    "permissions": {
      "updateSuccess": "Update Permission Successful",
      "updateFail": "Failed To Update Permission"
    },
    "roles": {
      "updateSuccess": "Update Role Successful",
      "updateFail": "Failed To Update Role"
    },
    "skillFamilies": {
      "updateSuccess": "Family Update Successful",
      "updateFail": "Family Update Failed"
    },
    "skills": {
      "updateSuccess": "Skill Update Successful",
      "updateFail": "Failed TO Update Skill"
    },
    "states": {
      "updateSuccess": "State Update Successful",
      "updateFail": "State Update Error"
    },
    "universities": {
      "updateSuccess": "Update University Successful",
      "updateFail": "Failed to Update University"
    }
  }
  

export default toastNotifications;