import React from 'react';
import { Box, Avatar, Pagination, FormControl, Select, MenuItem } from '@mui/material';

const CommentsHistory = ({commentData}) => {
  const commentsData = commentData;
  const [commentsPerPage, setCommentsPerPage] = React.useState(5); // Number of comments per page
  const [page, setPage] = React.useState(1);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleCommentsPerPageChange = (event) => {
    setCommentsPerPage(event.target.value);
  };
  
  let commentsArray = Object.values(commentsData);
  let commentsToShow = commentsArray.length === 0 ? [] : commentsArray.reverse().slice((page - 1) * commentsPerPage, page * commentsPerPage);

  const convertDateString = (dateStr) => {
    if(dateStr) {
      const date = new Date(dateStr);
      const localDateString = date.toLocaleString();
      return localDateString;
    } else {
      return ''
    }
  }


  return (
    <div
    className="container"
    style={{
      boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
      backgroundColor: "#FFFFFF",
      padding: "20px",
    }}
    >
    <Box>
      {commentsToShow.map((comment, index) => (
        <Box   key={index} sx={{ mb: 2 }}>
          <div style={{
            border: "1px solid #E0E0E0",
            backgroundColor: "#FFFFFF",
            padding: "20px",
          }}>
            <Box  sx={{ display: 'flex' }}>
              <Box sx={{flex:2 , display: 'flex', alignItems: 'center' }}>
                <Avatar src={comment.avatar} sx={{ mr: 2 }} />
                <span style={{padding:'10px'}} >{comment.commentedBy}</span>
              </Box>
              <span style={{padding:'10px',float:'right'}} >Date and Time: <b>{convertDateString(comment.commentedDate)}</b></span>
            </Box>
            <Box sx={{ display: 'flex' }}>
             <span style={{flex:1 ,padding:'10px'}} > Notes/Comments:</span>
              <span style={{flex:11,padding:'10px'}}><b>{comment.comment}</b></span>
            </Box>
          </div>
        </Box>
      ))}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
        <FormControl sx={{ minWidth: 120, display:"flex",flexDirection: 'row', gap: '20px'  }}>
          {/* <InputLabel>View</InputLabel> */}
          <span sx={{flex:1}}>View </span>
          <Select
          sx={{flex:3}}
            value={commentsPerPage}
            onChange={handleCommentsPerPageChange}
          >
            <MenuItem value={2}>2 </MenuItem>
            <MenuItem value={5}>5 </MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={20}>20</MenuItem>
          </Select>
          <span sx={{flex:1}}> comments per page</span>
        </FormControl>
        <Pagination
          count={Math.ceil(commentsData.length / commentsPerPage)}
          page={page}
          onChange={handleChangePage}
          getItemAriaLabel={(type, page, selected) => {
            if (type === 'next') {
              return 'next >';
            }
            if (type === 'previous') {
              return '< prev';
            }
            return page.toString();
          }}
        />
      </Box>
    </Box>
    </div>
  );
};

export default CommentsHistory;
