import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { TextField, InputAdornment, Button, Switch } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { getCustomerRequests } from '../../service/api';
import { toast } from "react-toastify";
import CircularProgress from '@mui/material/CircularProgress';

const CustomerRequests = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');

  const profileType = user.profileType;
  const [customerRequestsData, setCustomerRequestsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    setDataLoading(true);
    getCustomerRequests().then((data) => {
      let cusReqs = data.sort((a, b) =>(b.cusReqId - a.cusReqId));
      setCustomerRequestsData(cusReqs);
      setFilteredData(cusReqs);
      setDataLoading(false);
    });
  }, []);

  useEffect(() => {
    setFilteredData(
      customerRequestsData.filter((cusReq) =>
        Object.values(cusReq).some(
          (value) => {
            if(value) {
              return value.toString().toLowerCase().includes(search.toLowerCase())
            } else {
              return false;
            }
          }
        )
      )
    );
  }, [search, customerRequestsData]);

  const customerRequestsColumns = [
    {
      dataField: 'customerId',
      text: 'Customer ID',
      // filter: textFilter({placeholder: ' '}),
      sort: true,
    },
    {
      dataField: 'companyName',
      text: 'Company Name',
      // filter: textFilter({placeholder: ' '}),
      sort: true,
    },
    {
      dataField: 'cusReqId',
      text: 'Req ID',
      // filter: textFilter({placeholder: ' '}),
      sort: true,
    },
    {
      dataField: 'name',
      text: 'User Name',
      // filter: textFilter({placeholder: ' '}),
      sort: true,
    },
    {
      dataField: 'profileType',
      text: 'Profile Type',
      // filter: textFilter({placeholder: ' '}),
      sort: true,
    },
    {
      dataField: 'emailId',
      text: 'Email ID',
      // filter: textFilter({placeholder: ' '}),
      sort: true,
    },
    {
      dataField: 'primaryMobile',
      text: 'Mobile Number',
      // filter: textFilter({placeholder: ' '}),
      sort: true,
    },
    
    // {
    //   dataField: 'vendorCompanyName',
    //   text: 'Vendor Company',
    //   // filter: textFilter({placeholder: ' '}),
    //   sort: true,
    // },
    {
      dataField: 'assigneeName',
      text: 'Assignee Email ID',
      // filter: textFilter({placeholder: ' '}),
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      // filter: textFilter({placeholder: ' '}),
      sort: true,
    }
  ];

  const newCustomerRequest = () => {
    navigate(`/customer/customerRequestForm`);
  }

  const options = {
    sizePerPage: 5,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '50', value: 50 },
    ],
    hideSizePerPage: false,
    hidePageListOnlyOnePage: false,
    alwaysShowAllBtns: true,
    showTotal: true,
    nextPageText: "Next",
    prePageText: "Prev",
  };

  if (hasAllPermissions || userPermissions.includes('LIST_CUSTOMER_REQUESTS')) {

    return (
      <div className="container-page" sx={{ display: "flex" }}>
        <div className="row" style={{backgroundColor: "#F4F6FB",margin:"0px"}}>
          <div className="col" style={{padding:"0px"}} >
            <button
                onClick={newCustomerRequest}
                className="btn btn-primary"
                style={{
                  height: "50px",
                  background: "#3E67B1 0% 0% no-repeat padding-box",
                  borderRadius: "4px",
                  opacity: "1",
                  float: "right",
                  display: (hasAllPermissions || userPermissions.includes('CREATE_CUSTOMER_REQUEST')) ? 'inline' : 'none',
                  marginBottom: "20px",
                }}
            >
              New Customer Request
            </button>
          </div>
          <div className="custom-table-container shadow-radius">
            <BootstrapTable
                hover
                bootstrap4
                keyField="id"
                data={filteredData}
                columns={customerRequestsColumns}
                pagination={paginationFactory(options)}
                filter={filterFactory()}
                noDataIndication={
                  dataLoading ?
                      <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                        <CircularProgress/>
                      </Box>
                      :
                      <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        width: '100%'
                      }}>
                        <span>No Data Found</span>
                      </Box>
                }
            />
          </div>
        </div>
      </div>
    );

  } else {
    return (
        <>
          <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
            <div className="card">
              <div className="card-body text-center">
                <h2 className="card-title">
                  <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                  Access Denied
                </h2>
                <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
};

export default CustomerRequests;
