import React, { useState } from "react";
import { TextField, InputAdornment, IconButton, Snackbar } from "@mui/material";

import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { resetPassword  } from "../../service/api";  // Assuming you have a reset password API
import { toast } from "react-toastify";


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ResetPassword() {
  let query = useQuery();
  let navigate = useNavigate();
  const [emailId, setEmailId] = useState(query.get("emailId"));
  const [resetToken, setResetToken] = useState(query.get("resetToken"));
  const [profileType, setProfileType] = useState(query.get("profileType"));
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  function resetPasswordHandler(){
    if(password!==confirmPassword){
      toast.error("Password and confirm password do not match!");
      return;
    }

    // Assuming resetPasswordAPI accepts an object { emailId, resetToken, password }
    resetPassword({emailId, resetToken, newPassword:password, profileType}).then((res) => {
        toast.success("Password reset successful!");
        navigate('/login');
    }).catch((err)=>{
        console.log(err)
        toast.error(`Error: ${err}`);
    });
  }

  return (
    <>
  
      <section className="vh-100 gradient-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="card  text-white" style={{ borderRadius: "1em" }}>
              <div className="card-body p-5 text-center">
                <img
                  src="/Images/iPannel.png"
                  style={{ height: "50%", width: "30%" }}
                />

                <div className="mb-md-5 mt-md-4 pb-5">
                  <TextField
                    placeholder="emailId"
                    value={emailId}
                    disabled
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <PersonOutlineRoundedIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <br></br>
                  <TextField
                    placeholder="profileType"
                    value={profileType}
                    disabled
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <PersonOutlineRoundedIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <br></br>

                  <TextField
                    placeholder="New Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <LockOpenIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <br></br>

                  <TextField
                    placeholder="Confirm New Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    type="password"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <LockOpenIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div>
      </div>
                <button className="btn btn-primary btn-lg px-5"  onClick={resetPasswordHandler}>
                  Reset Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
