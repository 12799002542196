import React, { useRef, useState, useEffect } from "react";
import { useParams, useLocation,useNavigate  } from "react-router-dom";
import { getSchedule, updateSchedule } from "../../service/api";
import { toast } from "react-toastify";
import { 
    Box, 
} from '@mui/material';

export default function EditInterviewSchedule() {
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');
  
  const { scheduleId, customerId } = useParams();
  const formRef = useRef(null);

  const navigate = useNavigate();

  const [scheduleObj, setScheduleData] = useState({
    scheduleId: scheduleId,
    customerId: customerId,
    candidateId: 0,
    panelId: 0,
    customerEmailId: "",
    customerCompany: "",
    jobName: "",
    candidateName: "",
    candidateEmailId: "",
    panelName: "",
    panelEmailId: "",
    coordinatorEmailId: "",
    zoomLink: "",
    status: "",
    scheduledSlot: {}
  });

  useEffect(() => {
    console.log(scheduleId, customerId)
    getSchedule(scheduleId, customerId).then((res) => {
      setScheduleData(res.data);
    });
    
  }, [scheduleId]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
  };

  if (hasAllPermissions || userPermissions.includes('EDIT_SCHEDULED_INTERVIEW')) {

    return (
      <>
      <Box sx={{ display: "flex", flexDirection: 'column' }}>
        <div>
            <Box
                component="main"
                style={{ backgroundColor: "#F4F6FB" }}
                sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
              >
              <form ref={formRef} onSubmit={handleFormSubmit}>
              <div className="job-details" style={{ marginTop: "20px" }}>
                <div className="row" >
                  <div className="col">
                    <label><h6>Schedule ID *</h6></label>
                    <input type="text" className="form-control editable" 
                    placeholder="" 
                    name="scheduleId"
                    disabled={true} 
                    value={scheduleObj.scheduleId}/>
                  </div>
                  <div className="col">
                    <label><h6>Customer Name *</h6></label>
                    <input type="text" className="form-control editable" 
                    placeholder="Customer Name" name="customerCompany" 
                    disabled={true} 
                    value={scheduleObj.customerCompany}/>
                  </div>
                  <div className="col">
                  <label><h6>Status *</h6></label>
                    <select 
                      className="form-control-lg-8 drop-down" 
                      style={{ width: "100%", height: "50%" }} 
                      name="status"
                      onChange={(event) => {
                        setScheduleData((prevScheduleData) => ({
                          ...prevScheduleData,
                          status: event.target.value
                        }));
                      }}
                      value={scheduleObj.status}
                      >
                      <option value="SCHEDULED">SCHEDULED</option>
                      <option value="CANCELED">CANCELED</option>
                      <option value="COMPLETED">COMPLETED</option>
                      <option value="PANEL NO SHOW">PANEL NO SHOW</option>
                      <option value="CANDIDATE NO SHOW">CANDIDATE NO SHOW</option>
                      <option value="PANEL TECHNICAL GLITCH">PANEL TECHNICAL GLITCH</option>
                      <option value="CANDIDATE TECHNICAL GLITCH">CANDIDATE TECHNICAL GLITCH</option>
                      <option value="RESCHEDULED">RESCHEDULED</option>
                      
                    </select>
                  </div>
                </div>

                <br></br>

                <div className="row" >
                  <div className="col">
                    <label><h6>Job Name *</h6></label>
                    <input type="text" className="form-control editable" 
                    placeholder="" 
                    name="jobName"
                    disabled={true} 
                    value={scheduleObj.jobName}/>
                  </div>
                  <div className="col">
                    <label><h6>Panel Name *</h6></label>
                    <input type="text" className="form-control editable" 
                    placeholder="Panel Name" name="panelName" 
                    disabled={true} 
                    value={scheduleObj.panelName}/>
                  </div>
                  <div className="col">
                    <label><h6>Candidate Name *</h6></label>
                    <input type="text" className="form-control editable" 
                    placeholder="Candidate Name" name="candidateName" 
                    disabled={true} 
                    value={scheduleObj.candidateName}/>
                  </div>
                </div>
                
                <br></br>

                <div className="row" >
                  <div className="col">
                    <label><h6>Coordinator Email ID *</h6></label>
                    <input type="text" className="form-control editable" 
                    placeholder="Coordinator Email ID" 
                    name="coordinatorEmailId"
                    disabled={true} 
                    value={scheduleObj.coordinatorEmailId}/>
                  </div>
                  <div className="col">
                    <label><h6>Panel Email ID*</h6></label>
                    <input type="text" className="form-control editable" 
                    placeholder="Panel Email ID" name="panelEmailId" 
                    disabled={true} 
                    value={scheduleObj.panelEmailId}/>
                  </div>
                  <div className="col">
                    <label><h6>Candidate Email ID*</h6></label>
                    <input type="text" className="form-control editable" 
                    placeholder="Candidate Email ID" name="candidateEmailId" 
                    disabled={true} 
                    value={scheduleObj.candidateEmailId}/>
                  </div>
                </div>

                <br></br>

                <div className="row" >
                  <div className="col">
                    <label><h6>Slot Date *</h6></label>
                    <input type="text" className="form-control editable" 
                    placeholder="" 
                    name="slotDate"
                    disabled={true} 
                    value={scheduleObj.scheduledSlot?.slotDate}/>
                  </div>
                  <div className="col">
                    <label><h6>Slot Time *</h6></label>
                    <input type="text" className="form-control editable" 
                    placeholder="" 
                    name="slotTime"
                    disabled={true} 
                    value={scheduleObj.scheduledSlot?.slotTime}/>
                  </div>
                  <div className="col">
                    <label><h6>Slot Timezone *</h6></label>
                    <input type="text" className="form-control editable" 
                    placeholder="" 
                    name="timezone"
                    disabled={true} 
                    value={scheduleObj.scheduledSlot?.timezone}/>
                  </div>
                </div>
                <br></br>

                <div className="row" >
                  <div className="col">
                    <label><h6>Rate Category *</h6></label>
                    <input type="text" className="form-control editable" 
                    placeholder="" 
                    name="rateCategory"
                    disabled={true} 
                    value={scheduleObj.rateCategory}/>
                  </div>
                  <div className="col">
                    <label><h6>Rate Card *</h6></label>
                    <input type="text" className="form-control editable" 
                    placeholder="" 
                    name="rateCard"
                    disabled={true} 
                    value={scheduleObj.rateCard}/>
                  </div>
                  <div className="col">
                    <label><h6>Bonus Amount *</h6></label>
                    <input type="text" className="form-control editable" 
                    placeholder="" 
                    name="bonusAmount"
                    disabled={true} 
                    value={scheduleObj.bonusAmount}/>
                  </div>
                </div>
                
                <br></br>

                <div className="row">
                  
                  <div className="col"
                    style={{
                      display: scheduleObj.status == 'SCHEDULED' ? 'inline' : 'none'
                    }}
                  >
                    <label><h6>Meeting Link *</h6></label>
                    <input type="text" className="form-control editable" 
                    placeholder="" 
                    name="zoomLink"
                    disabled={false} 
                    onChange={(event) => {
                      setScheduleData((prevScheduleData) => ({
                        ...prevScheduleData,
                        zoomLink: event.target.value
                      }));
                    }}
                    value={scheduleObj.zoomLink}/>
                    {/* <br></br>
                    <div
                      style={{
                        border: "1px solid #ccc",
                        padding: '12px',
                        borderRadius: '5px'
                      }}
                    >
                      <a href={scheduleObj.zoomLink} target="_blank" rel="noopener noreferrer">
                        Click Here to Join Meeting
                      </a>
                    </div> */}
                  </div>
                  <div className="col">
                    <label><h6>Created At *</h6></label>
                    <input type="text" className="form-control editable" 
                    placeholder="" 
                    name="createdAt"
                    disabled={true} 
                    value={scheduleObj.createdAt}/>
                  </div>
                  <div className="col"
                    style={{
                      display: scheduleObj.status != 'SCHEDULED' ? 'inline' : 'none'
                    }}
                  >
                  </div>
                  <div className="col">
                  </div>

                </div>

              </div>


                <br />
                <div className="row justify-content-center">
                  <button
                    className="btn btn-outline-primary me-2"
                    style={{
                      border: "1px solid #3E67B1",
                      borderRadius: " 4px",
                      opacity: "1",
                      width: "96px",
                      height: "50px",
                    }}
                    onClick={() => {
                      // if (scheduleObj.status == 'SCHEDULED') {
                      //   navigate('/admin/interviewSchedules/all')
                      // } else {
                      //   navigate('/admin/closedInterviews/all')
                      // }
                      navigate(-1);
                    }}
                  >
                    BACK
                  </button>
                  <button
                    onClick={() => {
                      const fd= new FormData(formRef.current);
                      const formDataJson ={}
                      Array.from(fd.entries()).reduce((a,b)=>{
                        a[b[0]] = b[1];
                        return a
                      }, formDataJson)
                      let updatedObj = {...scheduleObj, ...formDataJson}

                      updateSchedule(scheduleId, customerId, updatedObj).then((res) => {
                        toast.success('Updated the Schedule.!');
                        // if (scheduleObj.status == 'SCHEDULED') {
                        //   navigate('/admin/interviewSchedules/all')
                        // } else {
                        //   navigate('/admin/closedInterviews/all')
                        // }
                        navigate(-1);
                      }).catch(err=>{
                        toast.error('Unable to update Schedule, Please try again.!');
                      });
                    }}
                    type="submit"
                    className="btn btn-primary"
                    style={{
                      border: "1px solid #3E67B1",
                      borderRadius: " 4px",
                      opacity: "1",
                      width: "200px",
                      height: "50px",
                      display: (hasAllPermissions || userPermissions.includes('EDIT_SCHEDULED_INTERVIEW')) ? 'inline' : 'none'

                    }}
                  >
                    UPDATE SCHEDULE
                  </button>
                </div>
              </form>
            </Box>
        </div>

      </Box>
      <br></br>
      <br></br>
      
    </>
    );
  
  } else {
    return (
      <>
        <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
          <div className="card">
            <div className="card-body text-center">
              <h2 className="card-title">
                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                Access Denied
              </h2>
              <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
};

        