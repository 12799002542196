import React, {useState} from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {TextField, InputAdornment, IconButton, MenuItem} from "@mui/material";
import {loginUser} from "../../service/api";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

// import "./LoginPage.css";

function LoginPage() {
    const [emailId, setEmailId] = useState("");
    const [password, setPassword] = useState("");
    const [profileType, setProfileType] = useState("USER");
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState({
        emailId: "",
        password: "",
        profileType: "",
        general: "",
    });
    const navigate = useNavigate();

    const validateEmail = (emailId) => {
        return emailId.length > 0;
    };

    const profileTypes = [
        {value: "ADMIN", label: "ADMIN"},
        {value: "CUSTOMER", label: "CUSTOMER"},
        {value: "CUSTOMER_ADMIN", label: "CUSTOMER_ADMIN"},
        {value: "PANELIST", label: "PANELIST"},
        {value: "VENDOR", label: "VENDOR"},
    ];

    const validatePassword = (password) => password.length > 0;

    const handlePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const login = (e) => {
        e.preventDefault();

        let isValidEmail = validateEmail(emailId);
        let isValidPassword = validatePassword(password);

        if (!isValidEmail || !isValidPassword) {
            toast.error("Invalid Email/Password.!");
            setError({
                emailId: isValidEmail ? "" : "Invalid emailId address.",
                password: isValidPassword ? "" : "Please enter the password.",
            });
            return;
        }

        loginUser({emailId, password, profileType})
            .then(() => {
                toast.success("Login Successful..!");
                // TODO - add vendor dashboard
                if (profileType == "PANELIST") {
                    navigate("/panel/");
                } else if (
                    profileType == "CUSTOMER" ||
                    profileType == "CUSTOMER_ADMIN"
                ) {
                    navigate("/customer/");
                } else if (profileType === 'VENDOR') {
                    navigate("/user/vendorDashboard");
                } else {
                    navigate("/admin/");
                }
            })
            .catch((err) => {
                toast.error(err.response.data.message);
                setError({
                    ...error,
                    general:
                        err.response.data.message || "An error occurred while logging in.",
                });
            });
    };

    const forgotPassword = () => navigate("/forgotPassword");

    return (
        <>
            {/* <div> */}
            <div className="row" style={{width: "100%", height: "100%"}}>
                <div
                    className="col-md-5 col-sm-12"
                    style={{backgroundColor: "#3E67B1", minHeight: "663px"}}
                >
                    <div style={{textAlign: "left", margin: "30px 0px 0px 30px"}}>
                        <img
                            src="/panls-logo-wht(2).png"
                            className="img-fluid"
                            alt="Centered Image"
                            height="30%"
                            width="20%"
                        />
                    </div>
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={{height: "90%"}}
                    >
                        <img
                            src="/Images/Resume-amico.svg"
                            className="img-fluid"
                            alt="Centered Image"
                            height="100%"
                            width="70%"
                        />
                    </div>
                </div>
                <div
                    className="col-md-7 col-sm-12"
                    style={{backgroundColor: "#F4F6FB"}}
                >
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={{height: "100%"}}
                    >
                        <form onSubmit={login}>
                            <div className="form mb-4">
                                <h3
                                    className="loginpage-heading"
                                    style={{textAlign: "center"}}
                                >
                                    Welcome Back
                                </h3>
                                <h6
                                    style={{textAlign: "center", marginTop: "16px"}}
                                    className="loginpage-side-heading"
                                >
                                    Start Managing Your Hiring. Hire Faster and Better
                                </h6>
                            </div>

                            <div className="d-flex flex-column align-items-center">
                                <div className="form">
                                    <label className="text-start">
                                        <h6 className="loginpage-side-heading ">Profile Type</h6>
                                    </label>
                                    <br/>
                                    <div class="select-wrapper">
                                        <select
                                            value={profileType}
                                            placeholder="Select Profile Type"
                                            onChange={(e) => setProfileType(e.target.value)}
                                            style={{
                                                width: "350px",
                                                height: "50px",
                                                background: "#FFFFFF 0% 0% no-repeat padding-box",
                                                border: "1px solid #C7C7C7",
                                                borderRadius: "6px",
                                                opacity: 1,
                                            }}
                                        >
                                            {/* <option
                        className="select-options"
                        disabled
                        hidden
                        selected
                      >
                        Select Profile Type
                      </option> */}
                                            {profileTypes.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                    className="select-options"
                                                >
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {/* <TextField
                    select
                    value={profileType}
                    placeholder="profileType"
                    onChange={(e) => setProfileType(e.target.value)}
                    style={{ width: "280px" }}
                  >
                    {profileTypes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField> */}
                                </div>

                                <div className="form" style={{marginTop: "20px"}}>
                                    <label>
                                        <h6 className="loginpage-side-heading">Email</h6>
                                    </label>
                                    <br/>
                                    {/* <TextField
                    error={Boolean(error.emailId)}
                    helperText={error.emailId}
                    onChange={(e) => setEmailId(e.target.value)}
                  /> */}
                                    {/* <input
                    type="email"
                    placeholder="   Enter Email"
                    style={{
                      width: "350px",
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: "1px solid #C7C7C7",
                      borderRadius: "6px",
                      opacity: 1,
                    }}
                    onChange={(e) => setEmailId(e.target.value)}
                  /> */}
                                    <TextField
                                        id="outlined-start-adornment"
                                        type={"text"}
                                        placeholder="Enter Email"
                                        onChange={(e) => setEmailId(e.target.value)}
                                        sx={{
                                            width: {sm: 100, md: 300},
                                            "& .MuiInputBase-root": {
                                                height: 50,
                                            },
                                        }}
                                        style={{
                                            width: "350px",
                                            height: "50px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                            // border: "1px solid #C7C7C7",
                                            // borderRadius: "6px",
                                            opacity: 1,
                                        }}
                                        size="small"
                                    />
                                </div>

                                {/* <div className="input-group">
                  <input
                    className="form-control"
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter Password"
                    onChange={(e) => setPassword(e.target.value)}
                    style={{
                      width: "350px",
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: "1px solid #C7C7C7",
                      borderRadius: "6px",
                      opacity: 1,
                    }}
                  />
                  <span
                    className="input-group-text"
                    onClick={() => handlePasswordVisibility()}
                  >
                    {" "}
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </span>
                </div> */}
                                <div className="form" style={{marginTop: "20px"}}>
                                    <label>
                                        <h6 className="loginpage-side-heading">Password</h6>
                                    </label>
                                    <br/>
                                    <TextField
                                        id="outlined-start-adornment"
                                        type={showPassword ? "text" : "password"}
                                        error={Boolean(error.password)}
                                        helperText={error.password}
                                        placeholder="Enter Password"
                                        onChange={(e) => setPassword(e.target.value)}
                                        sx={{
                                            width: {sm: 100, md: 300},
                                            "& .MuiInputBase-root": {
                                                height: 50,
                                            },
                                        }}
                                        style={{
                                            width: "350px",
                                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                                        }}
                                        size="small"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment style={{marginRight: "-14px"}}>
                                                    <IconButton onClick={handlePasswordVisibility}>
                                                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {/* <input
                    type={showPassword ? "text" : "password"}
                    placeholder="   Enter Password"
                    onChange={(e) => setPassword(e.target.value)}
                    style={{
                      width: "350px",
                      height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: "1px solid #C7C7C7",
                      borderRadius: "6px",
                      opacity: 1,
                    }}
                  />
                  <i className="fa fa-regular fa-eye-slash"></i> */}
                                </div>

                                {/* <div className="clearfix">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="form1Example3"
                  />
                  <label
                    className="float-left"
                    style={{ fontWeight: "bold" }}
                    htmlFor="form1Example3"
                  >
                    Remember me
                  </label>
                  <a
                    className="float-right"
                    style={{ marginLeft: "89px", fontWeight: "bold" }}
                    href="#!"
                    onClick={forgotPassword}
                  >
                    Forgot password
                  </a>
                </div> */}
                                <div className="login-button " style={{marginTop: "30px"}}>
                                    <button
                                        type="submit"
                                        style={{width: "350px"}}
                                        className="btn btn-primary btn-lg btn-block loginpage-side-heading"
                                    >
                                        Login
                                    </button>
                                </div>

                                <div className="row" style={{marginTop: "16px"}}>
                                    <a
                                        className="loginpage-side-heading"
                                        style={{textAlign: "center"}}
                                        href="#!"
                                        onClick={forgotPassword}
                                    >
                                        Forgot password
                                    </a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </>
    );
}

export default LoginPage;
