import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { toast } from "react-toastify";
import CircularProgress from '@mui/material/CircularProgress';
import { getPanelInvoices } from "../../service/api";

const PanelistInvoices = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const userPermissions = user.permissions.map(e => e.permissionName);
    const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');

    const navigate = useNavigate();
    const [invoicesData, setInvoicesData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [search, setSearch] = useState("");
    const [dataLoading, setDataLoading] = useState(false);

    const populateInvoices = () => {
        setDataLoading(true);
        getPanelInvoices().then((res) => {
            setInvoicesData(res.data);
            setFilteredData(res.data);
            setDataLoading(false);
            console.log(res.data)
        });
    }

    useEffect(() => {
        populateInvoices();
    }, []);

    useEffect(() => {
        setFilteredData(
            invoicesData.filter((invoice) =>
                Object.values(invoice).some(
                    (value) => {
                        if (value) {
                            return value.toString().toLowerCase().includes(search.toLowerCase());
                        } else {
                            return false;
                        }
                    }
                )
            )
        );
    }, [search, invoicesData]);

    const invoiceColumns = [
        {
            dataField: 'panelName',
            text: 'Panel Name',
            sort: true,
            headerStyle: () => {
                return { width: "auto", whiteSpace: "nowrap",textAlign: 'center' };
            },
            style: { textAlign: 'center'}
        },
        {
            dataField: 'panelEmailId',
            text: 'Email',
            sort: true,
            headerStyle: () => {
                return { width: "auto", whiteSpace: "nowrap",textAlign: 'center' };
            },
            style: { textAlign: 'center'}
        },
        {
            dataField: 'totalInterviews',
            text: 'Total ',
            sort: true,
            headerStyle: () => {
                return { width: "auto",textAlign: 'center' };
            },
            style: { textAlign: 'center'}
        },
        {
            dataField: 'statusCounts.COMPLETED',
            text: 'Completed ',
            sort: true,
            headerStyle: () => {
                return { width: "auto",textAlign: 'center'};
            },
            style: { textAlign: 'center'}
        },
        {
            dataField: 'statusCounts.CANCELED',
            text: 'Canceled ',
            sort: true,
            headerStyle: () => {
                return { width: "auto", whiteSpace: "nowrap", textAlign: 'center'};
            },
            style: { textAlign: 'center'}
        },
        {
            dataField: 'statusCounts.RESCHEDULED',
            text: 'Rescheduled ',
            sort: true,
            headerStyle: () => {
                return { width: "auto", whiteSpace: "nowrap",textAlign: 'center' };
            },
            style: { textAlign: 'center'}
        },
        {
            dataField: 'statusCounts.PANEL NO SHOW',
            text: 'Panel No Show',
            sort: true,
            headerStyle: () => {
                return { width: "auto", whiteSpace: "nowrap",textAlign: 'center' };
            },
            style: { textAlign: 'center'}
        },
        {
            dataField: 'statusCounts.CANDIDATE NO SHOW',
            text: 'Candidate No Show',
            sort: true,
            headerStyle: () => {
                return { width: "auto", whiteSpace: "nowrap",textAlign: 'center' };
            },
            style: { textAlign: 'center'}
        },
        {
            dataField: 'technicalGlitches',
            text: 'Technical Glitches',
            sort: true,
            formatter: (cell, row) => (
                <>
                    {row.statusCounts["PANEL TECHNICAL GLITCH"] + row.statusCounts["CANDIDATE TECHNICAL GLITCH"]}
                </>
            ),
            headerStyle: () => {
                return { width: "auto", whiteSpace: "nowrap" };
            },
            style: { textAlign: 'center'}
        },

        {
            dataField: 'action',
            text: 'Actions',
            headerStyle: () => {
                return { width: "auto", whiteSpace: "nowrap" ,textAlign: 'center'};
            },
            style: { width: '120px',textAlign: 'center' },
            sort: true,
            formatter: (cell, row) => (
                <>
                    <i
                        onClick={() => navigateToInvoice(row)}
                        style={{
                            color: '#3E67B1',
                        }}
                        className="fa fa-light fa-pen-to-square"
                    ></i>
                </>
            ),
        }
    ];
    const navigateToInvoice = (row) => {
        navigate('/panel/PanelInvoice/{}'.replace('{}', row.panelEmailId))
    }

    const options = {
        sizePerPage: 5,
        sizePerPageList: [
            { text: '5', value: 5 },
            { text: '10', value: 10 },
            { text: '15', value: 15 },
            { text: '20', value: 20 },
            { text: '50', value: 50 },
        ],
        hideSizePerPage: false,
        hidePageListOnlyOnePage: false,
        alwaysShowAllBtns: true,
        showTotal: true,
        nextPageText: "Next",
        prePageText: "Prev",
    };

    return (
        <div className="container-page">
            <div
                className="main-content"
                style={{ background: "#fff", padding: "20px", borderRadius: "8px" }}
            >
                <TextField
                    placeholder="Search"
                    size="small"
                    style={{
                        background: "#FCFCFC 0% 0% no-repeat padding-box",
                        boxShadow: "0px 3px 6px #3E67B133",
                        borderRadius: "4px",
                        opacity: "1",
                        height: "40px"
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
                <br />
                <div className="custom-table-container">
                    <BootstrapTable
                        hover
                        bootstrap4
                        keyField="id"
                        data={filteredData}
                        columns={invoiceColumns}
                        pagination={paginationFactory(options)}
                        noDataIndication={
                            dataLoading ?
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100%',
                                    width: '100%'
                                }}>
                                    <CircularProgress />
                                </Box>
                                :
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100%',
                                    width: '100%'
                                }}>
                                    <span>No Data Found</span>
                                </Box>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default PanelistInvoices;
