import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { TextField, InputAdornment, Switch } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { getVendors, updateUser, deleteUser } from '../service/api';
import { toast } from "react-toastify";
import toastNotifications from "./toastNotifications/toastMessages";
import CircularProgress from '@mui/material/CircularProgress';

const Vendors = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');

  const navigate = useNavigate();
  const [vendorsData, setVendorsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");
  const [dataLoading, setDataLoading] = useState(false);

  const populateUsers = () => {
    setDataLoading(true);
    getVendors().then((res) => {
      setVendorsData(res.data);
      setFilteredData(res.data);
      setDataLoading(false);
    });
  }
  useEffect(() => {
    populateUsers();
  }, []);

  useEffect(() => {
    setFilteredData(
      vendorsData.filter((user) =>
        Object.values(user).some(
          (value) => {
            if(value) {
              return value.toString().toLowerCase().includes(search.toLowerCase())
            } else {
              return false;
            }
          }
        )
      )
    );
  }, [search, vendorsData]);

  const vendorsColumns = [
    {
      dataField: 'customerId',
      text: 'Customer ID',
      sort: true,
      headerStyle: () => {
        return {width: "auto", whiteSpace: "nowrap"};
      },
    },
    {
      dataField: 'companyName',
      text: 'Company Name',
      sort: true,
      headerStyle: () => {
        return {width: "auto", whiteSpace: "nowrap"};
    },
    },
    {
      dataField: 'userId',
      text: 'Vendor ID',
      sort: true,
      headerStyle: () => {
        return {width: "auto", whiteSpace: "nowrap"};
      },
    },
    {
      dataField: 'firstName',
      text: 'Name',
      sort: true,
      headerStyle: () => {
        return {width: "auto", whiteSpace: "nowrap"};
    },
    },
    {
      dataField: 'profileType',
      text: 'Profile Type',
      sort: true,
      headerStyle: () => {
        return {width: "auto", whiteSpace: "nowrap"};
    },
    },
    {
      dataField: 'roleName',
      text: 'Role',
      sort: true,
      headerStyle: () => {
        return {width: "auto", whiteSpace: "nowrap"};
      },
    },
    {
      dataField: 'emailId',
      text: 'Email',
      sort: true,
      headerStyle: () => {
        return {width: "auto", whiteSpace: "nowrap"};
    },
    },
    // {
    //   dataField: 'primaryMobile',
    //   text: 'Phone Number',
    //   filter: textFilter({placeholder: ' '}),
    //   sort: true,
    // },
    // {
    //   dataField: 'isActive',
    //   text: 'Status',
    //   filter: textFilter({placeholder: ' '}),
    //   sort: true,
    //   formatter: (cell, row) => (
    //     <span>
    //     {row.isActive ? 'Active': 'InActive'}
    //     </span>
    //   )
    // },
    {
      dataField: 'action',
      text: 'Actions',
      style: { width: '120px' },
      sort: true,
      formatter: (cell, row) => (
        <>
        <i
          onClick={() => editUser(row._id, row.profileType)}
          style={{
            color: '#3E67B1',
            opacity: !(hasAllPermissions || userPermissions.includes('EDIT_USER')) ? 0.2 : 1,
            pointerEvents: !(hasAllPermissions || userPermissions.includes('EDIT_USER')) ? 'none' : 'auto',
          }}
          className="fa fa-light fa-pen-to-square"
        ></i>
        <i
          title="Delete User"
          onClick={ async () => {
            if ((hasAllPermissions || userPermissions.includes('DELETE_USER'))) {
              let isConfirmed = window.confirm('Are you sure you want to delete this user?');
              if(isConfirmed) {
                try {
                  await deleteUser(row._id, row.profileType);
                  populateUsers();
                  toast.success('User deleted Successfully.!');
                } catch (err) {
                  console.error(err);
                  toast.error('Unable to delete User.');
                }
              }
            }
          }}
          style={{
            color: '#3E67B1',
            display: ['SUPER', 'ADMIN'].includes(user.profileType) ? 'inline' : 'none',
            paddingLeft: "10px",
            opacity: !(hasAllPermissions || userPermissions.includes('DELETE_USER')) ? 0.2 : 1,
            pointerEvents: !(hasAllPermissions || userPermissions.includes('DELETE_USER')) ? 'none' : 'auto',
          }}
          className="fa fa-light fa-trash-can"
        ></i>
        {/* <Switch
          size="small"
          checked={row.isActive}
          onChange={(e) => updateVendorStatus(row._id, row.profileType, !row.isActive)}
          inputProps={{ 'aria-label': 'controlled' }}
          style={{
            color: '#3E67B1',
            opacity: !(hasAllPermissions || userPermissions.includes('EDIT_USER')) ? 0.2 : 1,
            pointerEvents: !(hasAllPermissions || userPermissions.includes('EDIT_USER')) ? 'none' : 'auto',
          }}
        /> */}
        </>
      ),
    }
  ];

  const updateVendorStatus = (userMongoId, profileType, updatedStatus) =>{
    updateUser(userMongoId, profileType, {isUserActive: updatedStatus}).then((res) => {
      toast.success(toastNotifications.editUser.updateSuccess);
      getVendors().then((res) => {
        setVendorsData(res.data);
      });
    }).catch(err=>{
      toast.error(toastNotifications.editUser.updateFail);
    });
  }

  const editUser = (userMongoId, profileType) => {
    if ((hasAllPermissions || userPermissions.includes('EDIT_USER'))) {
      navigate(`/admin/editUser/${profileType}/${userMongoId}`);
    }
  }

  const options = {
    sizePerPage: 5,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '50', value: 50 },
    ],
    hideSizePerPage: false,
    hidePageListOnlyOnePage: false,
    alwaysShowAllBtns: true,
    showTotal: true,
    nextPageText: "Next",
    prePageText: "Prev",
  };

  if (hasAllPermissions || userPermissions.includes('LIST_VENDORS')) {

    return (
      <div  className="container-page">
         <div
            className="main-content"
            style={{  background: "#fff", padding: " 20px",borderRadius:"8px" }}
          >
          <TextField
            placeholder="Search"
            size="small"
            style={{
              background: "#FCFCFC 0% 0% no-repeat padding-box",
              boxShadow: " 0px 3px 6px #3E67B133",
              borderRadius: "4px",
              opacity: "1",
              height: "40px"
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <br />
          <div className="custom-table-container">

          <BootstrapTable
            hover
            bootstrap4
            keyField="id"
            data={filteredData}
            columns={vendorsColumns}
            pagination={paginationFactory(options)}
            filter={filterFactory()}
            noDataIndication={
              dataLoading ?
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                <CircularProgress />
              </Box>
              :
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                <span>No Data Found</span>
              </Box>
            }
          />
          </div>
        </div>
      </div>
    );

  } else {
    return (
      <>
        <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
          <div className="card">
            <div className="card-body text-center">
              <h2 className="card-title">
                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                Access Denied
              </h2>
              <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
};

export default Vendors;
